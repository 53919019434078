import React from 'react';
import {Col, ControlLabel, FormGroup, InputGroup, OverlayTrigger, Popover, Row, Well} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from "./AdminAdvanceSection.module.css";
import Switch from "react-switch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const AdminAdvanceSection = props => {



    return (
        <Well bsSize="large">
            <Row>
                <Col xs={4} xsOffset={2}>
                    <FormGroup controlId="onHold" className={classes.ButtonSliderGroup}>
                        <ControlLabel>
                            On Hold? {" "}
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="right"
                                overlay={(
                                    <Popover id="popover-positioned-right" title="On Hold">
                                        Checking this will put the role ‘On Hold’. Please note it will let the customer know the role has been put on hold.
                                    </Popover>
                                )}>
                                <FontAwesomeIcon icon={faQuestionCircle}/>
                            </OverlayTrigger>
                        </ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.OnHoldChanged} checked={props.onHold} />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup controlId="completedRole" className={classes.ButtonSliderGroup}>
                        <ControlLabel>
                            Complete Role? {" "}
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="right"
                                overlay={(
                                    <Popover id="popover-positioned-right" title="Complete Role">
                                        Checking this will change a role from ‘Active’ to ‘Completed’. If the role is under ‘Completed’, turning the check ‘off’ will reopen the role.
                                        Note: If ‘off’, the role will automatically close as soon as it reaches its designated number of ‘Total interviews’.
                                    </Popover>
                                )}>
                                <FontAwesomeIcon icon={faQuestionCircle}/>
                            </OverlayTrigger>
                        </ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.completedRoleChanged} checked={props.completedRole} />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        </Well>
    )
};

export default AdminAdvanceSection;
AdminAdvanceSection.propTypes = {
    completedRole: PropTypes.bool.isRequired,
    onHold: PropTypes.bool.isRequired,
    OnHoldChanged: PropTypes.func.isRequired,
    completedRoleChanged : PropTypes.func.isRequired
};


AdminAdvanceSection.defaultProps = {};