import React from 'react';
import {FormControl} from "react-bootstrap";
import classes from './TalentForReviewFilter.module.css';

const TalentForReviewFilter = props => {


    const handleFilterChanged = (e) => {
        props.talentForReviewFilterChanged(e.target.value);
    };

    return (
        <div className={classes.OptionsWrapper}>
            <FormControl
                value={props.filter}
                onChange={handleFilterChanged}
                className={classes.DropDownForm}
                componentClass="select"
                placeholder="select">
                {props.children}
            </FormControl>
        </div>
    )
};

export default TalentForReviewFilter;