import PropTypes from 'prop-types';
import {Grid, Col} from "react-bootstrap";
import classes from './MetricsRow.module.css';
import BootstrapRowColCombo from "../../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";

const MetricsRow = ({ rowSelected, row }) => {

  return (
    <div
      className={classes.MetricRow}
      onClick={() => {
        rowSelected(row);
      }}
    >
      <BootstrapRowColCombo>
        <Col style={{ width: '80%', paddingLeft: 0 }} md={8}>
          <div style={{color: '#242424'}}>
            {row.name}
            <p className={classes.LocationRow}>{row?.info?.office_locations}</p>
            <p className={classes.LocationRow}>Order: HRGH-{row.id.toString().padStart(6, '0')}</p>
          </div>
        </Col>
        {row?.completed && (
          <Col style={{ width: '20%', paddingRight: 0 }} md={4}>
            <p style={{color: '#838485', textAlign: 'end'}}>Completed</p>
          </Col>
        )}
      </BootstrapRowColCombo>
    </div>
  );
};

export default MetricsRow;

MetricsRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowSelected: PropTypes.func.isRequired,
};
