import React, {Fragment} from 'react';
import {Pagination} from "react-bootstrap";

const usePagination = (currentPage, lastPage, handler) => {
    const goFirst = currentPage !== 1 ? <Pagination.First onClick={()=>handler(1)}/> : null;

    const goPrev = currentPage !== 1 ? <Pagination.Prev onClick={()=>handler(currentPage - 1)}/> : null;

    const beforeItems = (
        <Fragment>
            {currentPage - 3 > 0 ?
                <Pagination.Item onClick={()=>handler(currentPage - 3)}>{currentPage - 3}</Pagination.Item> : null}
            {currentPage - 2 > 0 ?
                <Pagination.Item onClick={()=>handler(currentPage - 2)}>{currentPage - 2}</Pagination.Item> : null}
            {currentPage - 1 > 0 ?
                <Pagination.Item onClick={()=>handler(currentPage - 1)}>{currentPage - 1}</Pagination.Item> : null}
        </Fragment>
    );

    const currentItem = lastPage > 1 ? <Pagination.Item active>{currentPage}</Pagination.Item> :null;

    const afterItems = (
        <Fragment>
            {currentPage + 1 <= lastPage ?
                <Pagination.Item onClick={()=>handler(currentPage + 1)}>{currentPage + 1}</Pagination.Item> : null}
            {currentPage + 2 <= lastPage ?
                <Pagination.Item onClick={()=>handler(currentPage + 2)}>{currentPage + 2}</Pagination.Item> : null}
            {currentPage + 3 <= lastPage ?
                <Pagination.Item onClick={()=>handler(currentPage + 3)}>{currentPage + 3}</Pagination.Item> : null}
        </Fragment>
    );

    const goNext = lastPage !== currentPage ? <Pagination.Last onClick={()=>handler(currentPage + 1)} /> : null;

    const goLast = lastPage !== currentPage ? <Pagination.Last onClick={()=>handler(lastPage)} /> : null;

    const pagination = (
        <Pagination>
            {goFirst}
            {goPrev}
            {beforeItems}
            {currentItem}
            {afterItems}
            {goNext}
            {goLast}
        </Pagination>
    );

    return [pagination];
};

export default usePagination;