import React from 'react';
import PropTypes from 'prop-types';
import classes from './NewAdvanceSliderButton.module.css';
import Switch from "react-switch";
import {ControlLabel, FormGroup, OverlayTrigger} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const NewAdvanceSliderButton = ({label, value, disabled, onChange, popOver, hideSlider}) => {
    const helpIcon = popOver ? (
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popOver}>
            <FontAwesomeIcon style={{cursor: 'pointer'}} className={classes.Icon} icon={faQuestionCircle}/>
        </OverlayTrigger>
    ) : null;

    return (
        <FormGroup className={classes.NewAdvanceSliderButton}>
            <ControlLabel className={classes.Label}>
                {label} {helpIcon}
            </ControlLabel>
          { !hideSlider &&  <Switch disabled={disabled} height={20} width={40} onChange={onChange} checked={value}/> }
        </FormGroup>
    )
};

export default React.memo(NewAdvanceSliderButton);

NewAdvanceSliderButton.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    popOver: PropTypes.object
};

NewAdvanceSliderButton.defaultProps = {};