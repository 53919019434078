import React, {useReducer} from 'react';
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import classes from './RolesHeader.module.css';
import {Checkbox} from 'react-bootstrap';
import {getCompaniesExport} from '../../../utils/services/turboPlus2RestCalls';
import HeaderOptionsButton from "../HeaderOptionsButton/HeaderOptionsButton";
import RoleLimitDisplay from "./Components/RoleLimitDisplay/RoleLimitDisplay";
import {patchCompanyItem} from "../../../store/actions";
import HeaderOptionsItem from "../HeaderOptionsButton/Components/HeaderOptionsItem/HeaderOptionsItem";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import { showToastSuccess, showToastWarning } from '../../../utils/toastMessages';

const init = () => {
    return {
        menuOpen: false,
        showExportModal: false,


        exportCandidateList: false,
        isLoading: false
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'setMenuOpenStatus':
            return {
                ...state,
                menuOpen: action.payload
            }
        case 'showExportModal':
            return {
                ...state,
                showExportModal: true
            }
        case 'closeModals':
            return {
                ...state,
                showExportModal: false
            }
        case 'exportCandidatesChanged':
            return {
                ...state,
                exportCandidateList: !state.exportCandidateList,
            }
        case 'isLoadingStateChanged':
            return {
                ...state,
                isLoading: action.payload,
            }
        default:
            throw new Error();
    }
}

const RolesHeader = () => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, {}, init);

    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = companies.find(x => x.id === companyId);
    const isSingleCompany = !!company;
    const headerText = isSingleCompany ? company.name : "All Customers";

    const handleMenuClicked = () => {
        dispatch({type:'setMenuOpenStatus', payload: !state.menuOpen});
    }

    const handleArchiveClicked = () => {
        dispatch({type:'setMenuOpenStatus', payload: false});
        dispatchRedux(patchCompanyItem(company.id,{archived: !company.archived}));
    }

    const handleExportClicked = () => {
        dispatch({type: 'showExportModal'});
    }

    const handleClickedOutside = () => {
        dispatch({type:'setMenuOpenStatus', payload: false});
    }

    const handleCancelModal = () => {
        dispatch({type: 'closeModals'});
    }

    const handleExportCompaniesSubmit = () => {
        dispatch({type: 'isLoadingStateChanged', payload: true});
        let params = {};

        params['get-talent'] = 0;

        if(companyId){
            params['company-id'] = companyId;
            
            if(state.exportCandidateList) {
                params['get-talent'] = 1;
            }
        }

        getCompaniesExport({params})
            .then((response) => {
                dispatch({type: 'closeModals'});
                dispatch({type: 'isLoadingStateChanged', payload: false});
                if (response.success) {
                    showToastSuccess(
                        <div className="toastContainer">
                            <div className={classes.toastTitle}>Your export is being processed.</div>
                            <div>{response.message}</div>
                        </div>
                    )
                }
            })
            .catch(e => {
                console.log(e);
                dispatch({type: 'closeModals'});
                dispatch({type: 'isLoadingStateChanged', payload: false});
                showToastWarning(
                    <div className="toastContainer">
                        <div className={classes.toastTitle}>An export is already in progress</div>
                        <div>{e.message}</div>
                    </div>
                )
            });
    }

    const handleExportCandidateListChanged = () => {
        dispatch({type: 'exportCandidatesChanged'});
    }

    return (
        <Row className={classes.HeaderWrapper}>
            <Col xs={11} lg={9}>
                <h1 className={classes.Header}>
                    {headerText}
                    {isSingleCompany && company.archived && <span className={classes.Archived}>Archived</span>}
                </h1>
            </Col>

            {company &&
            <Col xs={12} lg={2}>
                {company.active_role_limit > 0 &&
                <RoleLimitDisplay roleLimit={company.active_role_limit} roleCount={company.active_role_count} />
                }
            </Col>
            }

            <Col xs={12} lg={1} lgOffset={company?0:2}>
                <HeaderOptionsButton company={company}
                                     menuClicked={handleMenuClicked} isOpen={state.menuOpen} clickedOutSide={handleClickedOutside}>
                    <HeaderOptionsItem onClick={handleExportClicked} icon="download">
                        Export
                    </HeaderOptionsItem>

                    {/*If on company page*/}
                    {company &&
                    <HeaderOptionsItem onClick={handleArchiveClicked} icon="archive">
                        {company.archived ? 'Unarchive Company':'Archive Company'}
                    </HeaderOptionsItem>
                    }
                </HeaderOptionsButton>
            </Col>

            {/*Add Modal for export here*/}
            {state.showExportModal &&
            <ConfirmationModal show={state.showExportModal} icon="download" onHide={handleCancelModal}
                               title={company ? `Export '${company.name}'` : "Export 'All Companies'"}
                               onSubmit={handleExportCompaniesSubmit} loading={state.isLoading}>
                <div>You’re exporting the list of roles from {company ? company.name : "all companies"}</div>
                {!!companyId &&
                <Checkbox checked={state.exportCandidateList} readOnly onChange={handleExportCandidateListChanged} className={classes.CheckBox}>
                    Include Candidate List
                </Checkbox>
                }
            </ConfirmationModal>
            }
        </Row>
    );
}

export default RolesHeader;