import React, { Fragment, useEffect, useReducer } from 'react';
import { Col, Row, Form, Button, Alert, Popover, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NewTitleHeader from "../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import InputAreaDivider from "../../Ui/Inputs/InputAreaDivider/InputAreaDivider";
import NewAdvanceTextInput from "../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import NewAdvanceSliderButton from "../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
import AddUsersSearch from "../AccountSection/Components/CompanyUsersForm/Components/AddUsersSearch/AddUsersSearch";
import RoleUserItem from "../AccountSection/Components/CompanyUsersForm/Components/RoleUserItem/RoleUserItem";
import { validateEmail } from "../../../utils/misc";
import { useFirstRender } from "../../../hooks/useFirstRender";
import useDebounce from "../../../hooks/useDebounce";
import { getUsers } from "../../../utils/services/turboPlus2RestCalls";
import { getCompanyUsersRequest, postRoleToCompanyRequest } from "../../../store/actions";
import NewUserForm from "../AccountSection/Components/CompanyUsersForm/Components/NewUserForm/NewUserForm";
import { addUserToRolebot, getUser } from '../../../utils/services/turboPlus2RestCalls'
import {
    companyNameValidation,
    isSuccess,
    stripeOutNonDigits,
    formatCurrencyFromDollarAmount,
    isDollarAmount,
    isSourcingOnly
} from "../../../utils/validation";
import PropTypes from 'prop-types';

const filterUsersAlreadyAddedById = (haystack, needles) => {
    const ids = needles.map(x => x.id);
    return haystack.filter(x => !ids.includes(x.id));
}

const initialState = (props) => {
    return {
        showUserProfile: false,
        showUsersList: true,
        showAddUserSearchForm: true,
        showAddUserSearchButton: true,
        showAddUserForm: false,

        companyUsers: props.company.users ? props.company.users : [],

        searchValue: '',
        isEmail: false,
        loading: false,
        searchResults: [],

        showAddNewUserSection: false,

        //RoleInfo
        roleName: props.roleName,
        isTrial: false,
        collectCard: false,
        sourcingOnly: false,
        users: [],
        billableUserId: null,
        //NewUserItems
        email: '',
        userMenuIdOpen: null,
        //validation rules
        submitDisabled: true,
        validation: {
            nameValidState: props.roleRequestId ? 'success' : null,
            validateName: false,
        },
        //role request area
        roleRequestId: props.roleRequestId,
        preLoadUsers: props.preLoadUsers,
        preLoadUsersRunning: false,
        notes: ''
    };
};

function reducer(state, action) {
    switch (action.type) {
        case 'SearchValueChanged':
            return {
                ...state,
                searchValue: action.payload,
                loading: true,
                isEmail: validateEmail(action.payload),
                searchResults: action.payload === "" ?
                    filterUsersAlreadyAddedById(state.companyUsers, state.users) :
                    state.searchResults
            }
        case 'searchResultsReturned':
            return {
                ...state,
                loading: false,
                searchResults: filterUsersAlreadyAddedById(action.payload.data, state.users),
                showAddNewUserSection: state.isEmail && action.payload.data.length === 0
            }
        case 'startedUsersSearch':
            return {
                ...state,
                loading: true,
                searchResults: [],
            }
        case 'roleNameChanged': {
            const isValid = companyNameValidation(action.payload);
            return {
                ...state,
                roleName: action.payload,
                validation: {
                    ...state.validation,
                    nameValidState: isValid ? 'success' : 'error',
                    validateName: true
                }
            }
        }
        case 'isTrailChanged':
            return {
                ...state,
                isTrial: action.payload,
            }
        case 'isCollectCardChanged':
            return {
                ...state,
                collectCard: action.payload,
            }
        case 'isSourcingChanged':
            return {
                ...state,
                sourcingOnly: isSourcingOnly(action.payload)
            }
        case 'searchInputFocused':
            return {
                ...state,
                searchResults: state.searchValue === "" ?
                    filterUsersAlreadyAddedById(state.companyUsers, state.users) : state.searchResults
            }
        case 'addingCompanyUserToRole':
            return {
                ...state,
                searchResults: []
            }
        case 'companyUserAddedToRole': {
            const users = [
                action.payload,
                ...state.users
            ];

            return {
                ...state,
                users,
                searchResults: [],
                searchValue: '',

                //check-remove if this email is in the preLoadUsers array and turn off the preLoadUsersRunning
                preLoadUsers: state.preLoadUsers.filter(x => x.email.toLowerCase() !== action.payload.email.toLowerCase()),
                preLoadUsersRunning: false
            }
        }
        case 'showCreateNewUserForm':
            return {
                ...state,
                showAddUserSearchButton: false,
                showAddUserForm: true,
                showAddUserSearchForm: false,
                email: action.payload
            }
        case 'cancelCreateNewUserForm':
            return {
                ...state,
                showAddUserForm: false,
                showAddUserSearchForm: true,
                showAddNewUserSection: false,
                email: null,

                // if the user cancels this email add we remove him from the list and continue.
                preLoadUsers: state.preLoadUsers.filter(x => x.email !== state.email),
                preLoadUsersRunning: false
            }
        case 'userMenuClicked':
            return {
                ...state,
                userMenuIdOpen: action.payload
            }
        case 'newUserAddedToRolebot':
            return {
                ...state,
                showAddUserSearchForm: true,
                showAddUserForm: false,
                showAddNewUserSection: false,
                users: [action.payload, ...state.users],
                searchResults: [],
                email: '',
                searchValue: '',

                //check-remove if this email is in the preLoadUsers array and turn off the preLoadUsersRunning
                preLoadUsers: state.preLoadUsers.filter(x => x.email.toLowerCase() !== action.payload.email.toLowerCase()),
                preLoadUsersRunning: false
            }
        case 'removeUserFromList':
            return {
                ...state,
                users: state.users.filter(x => x.id !== action.payload)
            }
        case 'setBillableUserId':
            return {
                ...state,
                billableUserId: action.payload
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'propDataUpdated':
            return {
                ...state,
                roleRequestId: action.payload.roleRequestId,
                companyUsers: action.payload.company.users ? action.payload.company.users : [],
                roleName: state.roleName ? state.roleName : action.payload.roleName,
                preLoadUsers: action.payload.preLoadUsers,
                users: [],
                roleType: action.payload.company.role_type,
            }
        //preload users area.
        case 'preloadUserStarted':
            return {
                ...state,
                preLoadUsersRunning: true,
            }

        case 'roleNotesChanged':
            return {
                ...state,
                notes: action.payload
            }

        default:
            throw new Error();
    }
}

const AddRoleForm = (props) => {
    const dispatchRedux = useDispatch();
    const rolesLoading = useSelector(state => state.roles.loading);
    const sourcingOnlyData = useSelector(state => state.companies.data.filter(x => x.id === props.company.id))
    const sourOnlyData = useSelector(state => state.roleRequests.data.filter(x => x.id === props.roleRequestId)[0])
    const [state, dispatch] = useReducer(reducer, props, initialState);
    const searchDebounced = useDebounce(state.searchValue, 1000);
    const isFirstRender = useFirstRender();

    //Refreshing data
    useEffect(() => {
        if (!isFirstRender) {
            dispatch({ type: 'propDataUpdated', payload: props });
            if (sourOnlyData && sourOnlyData.sourcing_only) {
                dispatch({ type: 'isSourcingChanged', payload: sourOnlyData.sourcing_only })
            }
        }
    }, [
        props.company,
        props.company.users,
        props.roleName,
        props.roleRequestId,
        props.preLoadUsers,
    ])

    useEffect(() => {
        if (sourcingOnlyData && sourcingOnlyData[0].role_type.name === 'Hybrid (Sourcing & Turnkey)' ||
            sourcingOnlyData && sourcingOnlyData[0].role_type.name === 'Turnkey') {
            dispatch({ type: 'isSourcingChanged', payload: false })
        } else if (sourcingOnlyData && sourcingOnlyData[0].role_type.name === 'Sourcing Only') {
            dispatch({ type: 'isSourcingChanged', payload: true })
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (state.preLoadUsers.length) {
                dispatch({ type: 'preloadUserStarted', payload: props });
                if (sourOnlyData && sourOnlyData.sourcing_only) {
                    dispatch({ type: 'isSourcingChanged', payload: sourOnlyData.sourcing_only })
                }

                const email = state.preLoadUsers[0].email;

                try {
                    let users;
                    const searchByEmailResponse = await getUsers({ 'params': { 'email': email } });
                    users = searchByEmailResponse.data.data;

                    if (users.length) {
                        handleAddExistingUserToRole(users[0].id);
                    } else {
                        dispatch({ type: 'showCreateNewUserForm', payload: email });
                    }
                } catch (e) {

                }
            }
        })()
    }, [state.preLoadUsers]);

    /**
     * Load the users for this company. Read notes in RoleRequestSection component. If there are any filters in play
     * for companies there is a chance this dispatch will NOT return users. This is by design.
     */
    useEffect(() => {
        dispatchRedux(getCompanyUsersRequest(props.company.id));
    }, []);

    //Search email debounce
    useEffect(() => {
        let isMounted = true;
        if (!isFirstRender && searchDebounced !== '') {

            dispatch({ type: 'startedUsersSearch' })

            let params = {
                'page': 1,
                'per_page': 50
            };

            if (state.isEmail) {
                params['email'] = searchDebounced;
            } else {
                params['search'] = searchDebounced;
            }

            getUsers({ params })
                .then(({ data }) => {
                    if (isMounted) {
                        dispatch({ type: 'searchResultsReturned', payload: data });
                    }
                })
                .catch(e => console.log);
        }
        return () => { isMounted = false };
    }, [searchDebounced]);

    //Validation
    useEffect(() => {
        const { nameValidState } = state.validation;
        if (
            isSuccess(nameValidState) &&
            state.users.length > 0
            //cardBillingPassValidation()
        ) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [
        state.validation,
        state.users,
        state.billableUserId,
        state.collectCard,
        state.isTrial
    ])

    const cardBillingPassValidation = () => {
        return !(
            state.billableUserId === null &&
            (true && state.collectCard === false && state.isTrial === false)
        );
    }

    const handleSearchValueChanged = (e) => {
        dispatch({ type: "SearchValueChanged", payload: e.currentTarget.value });
    }

    const handleAddUserClicked = () => {
        handleShowCreateNewUserForm(state.searchValue);
    }

    const handleAddExistingUserToRole = (userId) => {
        dispatch({ type: 'addingCompanyUserToRole' });
        getUser(userId, { params: { 'get-payment-info': 1 } })
            .then(({ user }) => {
                dispatch({ type: 'companyUserAddedToRole', payload: user });
            })
            .catch(e => console.log(e))
    }

    const handleRoleNameChanged = (e) => {
        dispatch({ type: 'roleNameChanged', payload: e.currentTarget.value })
    }

    const handleRoleNotes = (e) => {
        dispatch({ type: 'roleNotesChanged', payload: e.currentTarget.value })
    }

    const handleIsInvoiceChanged = (value) => {
        dispatch({ type: 'isInvoiceChanged', payload: value });
    }

    const handleIsTrailChanged = (value) => {
        dispatch({ type: 'isTrailChanged', payload: value });
    }

    const handleCollectCardChanged = (value) => {
        dispatch({ type: 'isCollectCardChanged', payload: value });
    }

    const handleSourcingOnlyChanged = (value) => {
        dispatch({ type: 'isSourcingChanged', payload: value });
    }

    const handleSearchInputFocused = () => {
        dispatch({ type: 'searchInputFocused' })
    }

    const handleShowCreateNewUserForm = (email) => {
        dispatch({ type: 'showCreateNewUserForm', payload: email });
    }

    const handleCancelCreateNewUser = () => {
        dispatch({ type: 'cancelCreateNewUserForm' });
    }

    const handleTalentMenuClicked = (id) => {
        const menuItem = state.userMenuIdOpen === id ? null : id;
        dispatch({ type: 'userMenuClicked', payload: menuItem });
    }

    const handleRoleInterviewsChanged = (e) => {
        dispatch({ type: 'isInterviewsChanged', payload: stripeOutNonDigits(e.currentTarget.value) });
    }

    const handleRolePriceChanged = (e) => {
        dispatch({ type: 'isPriceChanged', payload: e.currentTarget.value.replace(/\$/, '') });
    }

    const handleNewUserSubmit = (payload) => {
        addUserToRolebot(payload)
            .then(({ user }) => {
                dispatch({ type: 'newUserAddedToRolebot', payload: user })
            })
            .catch(e => console.log(e));
    }

    const handleRemoveUser = (userId) => {
        dispatch({ type: 'removeUserFromList', payload: userId });
    }

    const handleSetBillableUserId = (userId) => {
        dispatch({ type: 'setBillableUserId', payload: userId });
    }

    const handleNewRoleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            name: state.roleName,
            is_trial: state.isTrial,
            // force_card: state.collectCard,
            users: state.users,
            sourcing_only: state.sourcingOnly,
            notes: state.notes
        }

        if (state.billableUserId) {
            payload.billable_user_id = state.billableUserId;
        }

        if (state.roleRequestId) {
            payload.role_request_id = state.roleRequestId;
        }

        dispatchRedux(postRoleToCompanyRequest(props.company.id, payload));
    }



    const collectCardPopover = (
        <Popover id="collectCardPopover" title="Force Collect Card">
            Checking this will force the user to submit a card. Can only be used if <strong style={{ color: "red" }}>ALL</strong> the users added to the role are missing card data.
        </Popover>
    );

    const sourcingOnly = (
        <Popover id="sourcingOnly" title="No Sourcing">
            Checking this will show a message telling Admins <strong style={{ color: "red" }}>NOT</strong> to contact talent under this role.
        </Popover>
    );

    return (
        <Fragment>

            <Form>
                <BootstrapRowColCombo>
                    {/*Basic Role Info Area*/}
                    <BootstrapRowColCombo>
                        <NewTitleHeader title="Basic Role Info" />
                    </BootstrapRowColCombo>
                    <Row>
                        <Col xs={12} lg={6}>
                            <NewAdvanceTextInput
                                controlId="form-role-name"
                                label="Role Name"
                                value={state.roleName}
                                onChange={handleRoleNameChanged}
                                required
                                validationState={state.validation.nameValidState}
                            />
                        </Col>

                        {props.showCompanyInput &&
                            <Col xs={12} lg={6}>
                                <NewAdvanceTextInput
                                    controlId="form-company-name"
                                    label="Company Name"
                                    value={props.company.name}
                                    onChange={() => { }}
                                    disabled
                                    required
                                    validationState={state.validation.nameValidState}
                                />
                            </Col>
                        }

                    </Row>

                    <InputAreaDivider />

                    {/*Settings and Billing Area*/}
                    <BootstrapRowColCombo>
                        <NewTitleHeader title="Settings & Billing" />
                    </BootstrapRowColCombo>

                    <Row>
                        <Col xs={4}>
                            <NewAdvanceSliderButton label="Is Trial?" value={state.isTrial} onChange={handleIsTrailChanged} />
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={4}>
                            <NewAdvanceSliderButton label="Sourcing Only?" popOver={sourcingOnly} value={state.sourcingOnly} onChange={handleSourcingOnlyChanged} />
                        </Col>
                        {sourcingOnlyData[0].role_type.name == "Hybrid (Sourcing & Turnkey)" &&
                            <Col xs={3}>
                                <span style={{ fontSize: "12px", marginLeft: "-12px" }}>Selected by User {state.sourcingOnly} </span>
                            </Col>
                        }

                    </Row>

                    <InputAreaDivider />
                    <BootstrapRowColCombo>
                        <NewTitleHeader title="Role notes" />
                    </BootstrapRowColCombo>
                    <Row>
                        <Col xs={12} lg={12}>
                            <FormControl componentClass="textarea" value={state.notes} onChange={handleRoleNotes} />
                        </Col>
                    </Row>
                    <InputAreaDivider />

                    {state.preLoadUsersRunning && state.showAddUserForm && (
                        <Alert bsStyle="warning">
                            <p>
                                This is a new user the customer has requested be added to the role. Fill out the missing info and Add.
                            </p>
                            <p>
                                To Skip this user click the cancel button.
                            </p>
                        </Alert>
                    )}

                    {/*User Add Form*/}
                    {state.showAddUserForm &&
                        <NewUserForm
                            onCancelClicked={handleCancelCreateNewUser}
                            email={state.email}
                            onSubmit={handleNewUserSubmit}
                            disableIsAdmin={true}
                        />
                    }


                    {/*Add Users To Role*/}
                    {!state.preLoadUsersRunning && state.showAddUserSearchForm &&
                        <Fragment>
                            <BootstrapRowColCombo>
                                <NewTitleHeader title="Add Admins to Role" />
                            </BootstrapRowColCombo>
                            <AddUsersSearch
                                label="Enter User's Name or Email Address"
                                searchResults={state.searchResults}
                                searchValue={state.searchValue}
                                handleSearchValueChanged={handleSearchValueChanged}
                                showNewAddUserSection={state.showAddNewUserSection}
                                onAddNewUserButtonClicked={handleAddUserClicked}
                                onAddExistingUserClicked={handleAddExistingUserToRole}
                                handleAddUserClicked={handleNewUserSubmit}
                                onSearchFocused={handleSearchInputFocused}
                                loading={state.loading}
                            />
                        </Fragment>
                    }

                    {/*List users tied to this role*/}
                    {state.users.length > 0 &&
                        <Fragment>
                            <BootstrapRowColCombo>
                                <NewTitleHeader title='Role Admin List' />
                            </BootstrapRowColCombo>
                            {state.users.map(x =>
                                <RoleUserItem
                                    key={x.id}
                                    user={x}
                                    menuOpen={state.userMenuIdOpen === x.id}
                                    menuClicked={handleTalentMenuClicked}
                                    removeUserClicked={handleRemoveUser}
                                    isBillable={x.id === state.billableUserId}
                                    makeBillable={() => handleSetBillableUserId(x.id)}
                                />)}
                        </Fragment>
                    }

                    <Row sm={12}>
                        {props.showCancelButton &&
                            <Col xs={4} xsOffset={4}>
                                <Button disabled={state.preLoadUsersRunning || rolesLoading} block bsStyle="info" onClick={props.onCancelClicked}>cancel</Button>
                            </Col>
                        }
                        <Col xs={4} xsOffset={props.showCancelButton ? 0 : 8}>
                            <Button disabled={state.submitDisabled || rolesLoading} block bsStyle="info" onClick={handleNewRoleSubmit}>Create & Finish</Button>
                        </Col>
                    </Row>

                </BootstrapRowColCombo>
            </Form>
        </Fragment>
    )
};

export default AddRoleForm;

AddRoleForm.propTypes = {
    company: PropTypes.object.isRequired,
    roleName: PropTypes.string,
    roleRequestId: PropTypes.number,
    preLoadUsers: PropTypes.array,
    onCancelClicked: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showCompanyInput: PropTypes.bool
};

AddRoleForm.defaultProps = {
    roleName: '',
    roleRequestId: null,
    preLoadUsers: [],
    showCancelButton: false,
    showCompanyInput: false
};