import React, {useEffect, useReducer} from 'react';
import LookALikeSearch from './components/LookALikeSearch/LookALikeSearch';
import TalentForReviewForm from './components/TalentForReviewForm/TalentForReviewForm';
import {getTalentProspects, patchTalentForReview, getTalentProspectsStats} from "../../../../../utils/services/turboPlus2RestCalls";
import AddTalentForReviewForm from './components/AddTalentForReviewForm/AddTalentForReviewForm';
import {showToastSuccess} from "../../../../../utils/toastMessages";

const init = () => {
    return {
        loading: false,
        talentForReview: {
            talent: [],
            page: 1,
            lastPage: 1,
            perPage: 10,
            filter: 'waiting',
            stats: {
                prospect_approved: 0,
                declined: 0,
                saved: 0,
                waiting: 0
            }
        }
    };
};

function reducer(state, action) {
    switch (action.type) {
        case 'talentForReviewFetching':
            return {
                ...state,
                loading: true,
                refreshTalentToggle: false
            }
        case 'talentForReviewedReturned':
            return {
                ...state,
                loading: false,
                talentForReview: {
                    ...state.talentForReview,
                    talent: action.payload.data,
                    page: action.payload.current_page,
                    lastPage: action.payload.last_page,
                    perPage: action.payload.per_page
                }
            }
        case 'talentForReviewUpdated':
            return {
                ...state,
                talentForReview: {
                    ...state.talentForReview,
                    talent: state.talentForReview.talent.map(
                        x => x.id === action.payload.id ? {...x, ...action.payload.talent, pivot: {...x.pivot, ...action.payload.talent}}:x
                    )
                }
            }
        case 'talentForReviewPageChanged':
            return {
                ...state,
                talentForReview: {
                    ...state.talentForReview,
                    page: action.payload
                }
            }
        case 'talentForReviewFilterChanged':
            return {
                ...state,
                talentForReview : {
                    ...state.talentForReview,
                    filter: action.payload
                }
            }
        case 'talentForReviewStatsReturned':
            return {
                ...state,
                talentForReview: {
                    ...state.talentForReview,
                    stats: action.payload
                }
            }
        case 'refreshTalentForReview':
            return {
                ...state,
                refreshTalentToggle: !state.refreshTalentToggle
            }
        default:
            throw new Error();
    }
}

const ReviewTalentSection = props => {
    const [state, dispatch] = useReducer(reducer, props, init);

    useEffect(() => {
        loadTalentForReview();
    }, [
        state.talentForReview.page,
        state.talentForReview.filter,
        state.refreshTalentToggle
    ]);

    const loadTalentForReview = () => {
        dispatch({type: 'talentForReviewFetching'});
        getTalentProspects(props.roleId, {
            params: {
                page: state.talentForReview.page,
                perPage: state.talentForReview.perPage,
                filter: state.talentForReview.filter
            }
        })
            .then(({talent_prospects}) => {
                dispatch({type:'talentForReviewedReturned', payload: talent_prospects});
            })
            .then(() => getTalentProspectsStats(props.roleId))
            .then(({stats}) => {
                dispatch({type: 'talentForReviewStatsReturned', payload: stats});
            })
            .catch(e => console.log(e));
    };

    const updateTalentForReview = (payload) => {
        patchTalentForReview(payload.id, props.roleId, payload)
            .then(() => {
                dispatch({type: 'talentForReviewUpdated', payload: {id: payload.id, talent: payload}});
                showToastSuccess("Talent for review has been updated");
            })
            .catch(e => console.log(e));
    };

    const talentForReviewPageChanged = (page) => {
        dispatch({type: 'talentForReviewPageChanged', payload: page});
    };

    const talentForeReviewStatusUpdated = (talentId, payload) => {
        // When a talend for review changes status it no longer belongs on this list.
        // To prevent odd reloading or the could of items in a page to dwindle down oddly we just change its status.
        // When user refreshes or changes page it will pull new data.
        patchTalentForReview(talentId, props.roleId, payload)
            .then(() => {
                let newTalentStatus;

                switch (true) {
                    case payload.saved === 1:
                        newTalentStatus = {saved: 1, prospect_approved: null}
                        break;
                    case payload.prospect_approved === 1:
                        newTalentStatus = {saved: 0, prospect_approved: 1}
                        break;
                    case payload.prospect_approved === 0:
                        newTalentStatus = {saved: 0, prospect_approved: 0}
                        break;
                    default:
                        //no default
                }

                dispatch({type: 'talentForReviewUpdated', payload: {id: talentId, talent: newTalentStatus}});
            })
            .then(() => getTalentProspectsStats(props.roleId))
            .then(({stats}) => {
                dispatch({type: 'talentForReviewStatsReturned', payload: stats});
            })
            .catch(e => console.log(e));
    };

    const talentForReviewFilterChanged = (filter) => {
        dispatch({type: 'talentForReviewFilterChanged', payload: filter});
    };

    const handleRefreshTalentList = () => {
        dispatch({type: 'refreshTalentForReview'});
    };
    return (
        <div>
            <TalentForReviewForm
                roleId={props.roleId}
                loading={state.loading}
                talent={state.talentForReview.talent}
                page={state.talentForReview.page}
                lastPage={state.talentForReview.lastPage}
                filter={state.talentForReview.filter}
                stats={state.talentForReview.stats}
                talentForReviewPageChanged={talentForReviewPageChanged}
                talentForeReviewStatusUpdated={talentForeReviewStatusUpdated}
                updateTalentForReview={updateTalentForReview}
                talentForReviewFilterChanged={talentForReviewFilterChanged}
                onRefreshTalent={handleRefreshTalentList}
            />

            <AddTalentForReviewForm
                roleId={props.roleId}
                reloadAddedTalent={loadTalentForReview}
            />

            {/* <LookALikeSearch
                roleId={props.roleId}
            /> */}
        </div>
    )
};

export default ReviewTalentSection;
