import React from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from "react-bootstrap";
import classes from './FormEditButtonArea.module.css';

const EditButtonSection = ({onEditClicked, editDisabled, editBtnText}) => {
    return (
        <div className={classes.ButtonWrapper}>
            <Button disabled={editDisabled} className={classes.Button} onClick={onEditClicked} bsStyle="link">{editBtnText}</Button>
        </div>
    )
}

const CancelSaveSection = ({onCancelClicked, onSaveClicked, editDisabled, saveDisabled}) => {
    return (
        <div className={classes.TwoButtonWrapper}>
            <Button disabled={editDisabled} className={classes.Button} onClick={onCancelClicked} bsStyle="link" >Cancel</Button>
            <Button disabled={saveDisabled} className={classes.Button} onClick={onSaveClicked} bsStyle="link" >Save</Button>
        </div>
    )
}

const FormEditButtonArea = (
    {
        onEditClicked,
        onSaveClicked,
        onCancelClicked,
        inEditMode,
        showEdit,
        showBack,
        editDisabled,
        saveDisabled,
        editBtnText = 'Edit'
    }) => {

    const editButtons = inEditMode ?
        <CancelSaveSection disabled={editDisabled} saveDisabled={saveDisabled} onCancelClicked={onCancelClicked} onSaveClicked={onSaveClicked}/> :
        <EditButtonSection onEditClicked={onEditClicked} editDisabled={editDisabled} editBtnText={editBtnText}/>;

    return (
        <Row>
            <Col xs={4}>
                { showBack &&
                <Button disabled={editDisabled} className={classes.Button} onClick={onCancelClicked} bsStyle="link">&lt; Back</Button>}
            </Col>
            <Col xs={4} smOffset={4}>
                {showEdit && editButtons}
            </Col>
        </Row>

    )
};

export default React.memo(FormEditButtonArea);

FormEditButtonArea.propTypes = {
    onEditClicked: PropTypes.func.isRequired,
    onSaveClicked: PropTypes.func.isRequired,
    onCancelClicked: PropTypes.func.isRequired,
    inEditMode: PropTypes.bool,
    showEdit: PropTypes.bool,
    showBack: PropTypes.bool,
    saveDisabled: PropTypes.bool
};

FormEditButtonArea.defaultProps = {
    inEditMode: false,
    showEdit: true,
    editDisabled: false,
    saveDisabled: true
};