import React from 'react';
import {Label, Tooltip, OverlayTrigger} from "react-bootstrap";
import classes from './RoleNameItem.module.css';

const RoleNameItem = props => {

    const {roleId,roleName} = props;

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip id={'roleNameTooltip'+roleId}>{roleName}</Tooltip>}>
            <div className={classes.RoleNameItem}>
                <Label bsStyle="default">{roleId}</Label> {roleName}
            </div>
        </OverlayTrigger>
    );
};

export default RoleNameItem;