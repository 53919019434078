import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import SearchCriteriaRoleTags from "./components/SearchCriteriaRoleTags/SearchCriteriaRoleTags";
import propTypes from "prop-types";

const SearchCriteriaRoleTagsWrapper = (props) => {
    const wrapperRef = useRef(null);

    return (
        <Row>
            <Col xs={12}>
                <div ref={wrapperRef}>
                    <SearchCriteriaRoleTags {...props}/>
                </div>
            </Col>
        </Row>
    )
};

export default SearchCriteriaRoleTagsWrapper

SearchCriteriaRoleTagsWrapper.propTypes = {
};

SearchCriteriaRoleTagsWrapper.defaultProps = {};