import React from 'react';
import {ButtonGroup, Button, Row, Col} from 'react-bootstrap'
import PropTypes from 'prop-types';
import classes from './TopModeSelector.module.css';

const TopModeSelector = props => {
    return (
        <Row>
            <Col xs={12}>
                <ButtonGroup justified style={{marginBottom: '20px'}} className={classes.ButtonWrapper}>
                    <Button
                        className={props.mode==='addTalent' ? classes.DisabledButton: null}
                        disabled={props.mode==='addTalent'}
                        onClick={() => props.modeChanged('addTalent')}
                        href="#">Add</Button>
                    <Button
                        className={props.mode==='review' ? classes.DisabledButton: null}
                        disabled={props.mode==='review'}
                        onClick={() => props.modeChanged('review')}
                        href="#">Review</Button>
                    <Button
                        className={props.mode==='roleInfo' ? classes.DisabledButton: null}
                        disabled={props.mode==='roleInfo'}
                        onClick={() => props.modeChanged('roleInfo')}
                        href="#">Role Info</Button>
                </ButtonGroup>
            </Col>
        </Row>
    )
};

export default TopModeSelector;

TopModeSelector.propTypes = {
    mode: PropTypes.oneOf(['addTalent','review','roleInfo']).isRequired,
    modeChanged: PropTypes.func.isRequired

};