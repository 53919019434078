import React from 'react';
import BootstrapRowColCombo from "../../../../../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import classes from './AddUserButtonSection.module.css';
import {Button, Col, Row} from "react-bootstrap";

const AddUserButtonSection = ({onAddUserClicked}) => {
    return (
        <BootstrapRowColCombo>
            <div className={classes.ButtonWrapper}>
                <Row>
                    <Col xs={2} xsOffset={5}>
                        <Button className={classes.Button} block bsStyle="success" onClick={onAddUserClicked}>+ Add User</Button>
                    </Col>
                </Row>

            </div>
        </BootstrapRowColCombo>
    )
}

export default AddUserButtonSection;