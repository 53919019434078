import {useState, useEffect, useRef} from 'react';
import moment from 'moment';

const buildScheduledTime = function (lastNotifyOn, amount, time) {
    return moment.utc(lastNotifyOn).add(amount, time);
};

export const isBefore = (time) => {
    return moment().isBefore(time);
};

const useSendReminderTimerLogic = (lastNotifyOn, timeAmount, timeUnit) => {
    const timer = useRef(0);

    const [amount] = useState(timeAmount);
    const [time] = useState(timeUnit);
    const [ticker, setTicker] = useState(0);
    const [scheduledTime, setScheduledTime] = useState(buildScheduledTime(lastNotifyOn, amount, time));

    const [isTimerRunning, setTimerIsRunning] = useState(false);
    const [runningText, setRunningText] = useState("");

    useEffect(() => {
        if (isBefore(scheduledTime)) {
            timer.current = setInterval(() => setTicker(ticker + 1), 1000);
            setTimerIsRunning(true);
            setRunningText( moment().to(scheduledTime, true));
        } else {
            setTimerIsRunning(false);
            setRunningText("");
        }

        return () => timer.current && clearInterval(timer.current);
    }, [scheduledTime, ticker]);

    const setNotifiedOn = (x) => {
        setScheduledTime(buildScheduledTime(x,amount,time));
    };

    return [{isTimerRunning, runningText}, setNotifiedOn];
};

export default useSendReminderTimerLogic;