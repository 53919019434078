import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import classes from './DateRangeButton.module.css';

const buildDayString = (date) => {
    return moment(date).format("MMM D, YYYY");
};

const buildButtonString = (dateData) => {
    return dateData ? `${buildDayString(dateData[0])} - ${buildDayString(dateData[1])}` : 'Select a date range';
};

const DateRangeButton = props => {
    const handleShowCalendar = (e) => {
        e.stopPropagation();
        props.calendarFilterToggle();
    };

    return (
        <div className={classes.FilterButton} onClick={handleShowCalendar}>
            <span className={classes.Spacer}>
                <FontAwesomeIcon icon={faFilter}/>
            </span>
            <span>{buildButtonString(props.calendarFilterDate)}</span>
            <span className={classes.Spacer}></span>
        </div>
    )
};

export default DateRangeButton;

DateRangeButton.propTypes = {
    calendarFilterToggle: PropTypes.func.isRequired,
};

DateRangeButton.defaultProps = {};