import React from 'react';
import {Col} from 'react-bootstrap';
import classes from './Divider.module.css';

const Divider = () => {
    return (
        <Col xs={12}>
            <div className={classes.Divider}/>
        </Col>
    )
};

export default Divider;