import React, {useEffect, useState} from 'react';
import classes from './LeftColumns.module.css';
import ColumnHeader from "../ColumnHeader/ColumnHeader";
import {useSelector, useDispatch} from 'react-redux';
import {openSideDrawer, closeSideDrawer} from '../../../../store/actions';
import {getCompaniesList, toggleIsArchived} from '../../../../store/actions';
import MainMenuItem from "./components/MainMenuItem/MainMenuItem";
import SearchCompany from "./components/SearchCompany/SearchCompany";
import CompanyMenuItem from "./components/CompanyMenuItem/CompanyMenuItem";
import OpenCloseArrowButton from "./components/OpenCloseArrowButton/OpenCloseArrowButton";
import {Scrollbars} from 'react-custom-scrollbars-2';
import ArchiveLeftMenuSection from "./components/ArchiveLeftMenuSection/ArchiveLeftMenuSection";

const filterArchived = (companies) => {
    return companies.filter(x => x.archived === false);
}

const filterNonArchived = (companies) => {
    return companies.filter(x => x.archived === true);
}

const LeftColumn = () => {
    const dispatch = useDispatch();
    const sideDrawerOpen = useSelector(state => state.rolesSearchSettings.sideDrawerOpen);
    const showArchived = useSelector(state => state.rolesSearchSettings.showArchived);
    const companiesStore = useSelector(state => state.companies.data);

    const [companies, setCompanies] = useState([]);
    const [archivedCompanies, setArchivedCompanies] = useState([]);
    const [searchCompanyValue, setSearchCompanyValue] = useState("");

    // Get the full list of companies
    useEffect(() => {
        dispatch(getCompaniesList({params: {'show-archived': showArchived ? 1 : 0}}));
    }, [showArchived]);

    // As the user searches we update from store.
    useEffect(() => {
        setCompanies(
            filterArchived(companiesStore).filter(x => x.name.toLowerCase().includes(searchCompanyValue.toLowerCase()))
        );
        setArchivedCompanies(
            filterNonArchived(companiesStore).filter(x => x.name.toLowerCase().includes(searchCompanyValue.toLowerCase()))
        );
    }, [companiesStore, searchCompanyValue])

    const menuToggled = () => {
        if (sideDrawerOpen) {
            dispatch(closeSideDrawer());
        } else {
            dispatch(openSideDrawer());
        }
    };

    const handleSearchValueChanged = (e) => {
        setSearchCompanyValue(e.target.value);
    }

    const handleArchivedClicked = () => {
        dispatch(toggleIsArchived());
    }

    const handleCancelClicked = () => {
        setSearchCompanyValue('');
    }

    const columnStyles = sideDrawerOpen ?
        [classes.LeftColumnWrapper, classes.Open].join(" ") : classes.LeftColumnWrapper;

    return (
        <div className={columnStyles}>
            <OpenCloseArrowButton open={sideDrawerOpen} onClick={menuToggled}/>
            <div className={classes.LeftColumn}>
                <ColumnHeader/>
                <MainMenuItem/>
                <SearchCompany
                    value={searchCompanyValue}
                    onChange={handleSearchValueChanged}
                    onCancelClicked={handleCancelClicked}
                />
                <Scrollbars autoHide className={classes.CompaniesWrapper}>
                    {companies.map((x) => <CompanyMenuItem key={x.id} company={x} isArchive={false}/>)}
                    {companies.length > 0 && archivedCompanies.length > 0 && <div className={classes.Divider}/>}
                    {archivedCompanies.map((x) => <CompanyMenuItem key={x.id} company={x} isArchive={true}/>)}
                </Scrollbars>
                <ArchiveLeftMenuSection showArchived={showArchived} showArchivedClicked={handleArchivedClicked}/>
            </div>
        </div>
    )
};

export default LeftColumn;