import React from 'react';
import PropTypes from 'prop-types';
import classes from './LargeMetricWheel.module.css';
import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';

const LargeMetricWheel = props => {

    const legend = (
        <Legend iconSize={30} iconType="rect" layout="vertical" align="right" verticalAlign="middle"
                wrapperStyle={{
                    fontSize: '18px',
                    marginRight: '100px',
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
                }}/>
    );

    return (
        <div className={classes.LargeMetricWheel}>
                <div className={classes.TitleWrapper}>
                    <div className={classes.TextWrapper}>
                        <span className={classes.Icon} style={{color: props.iconColor}}>{props.icon}</span><span className={classes.Title}>{props.title}</span>
                    </div>
                    <div className={classes.TextWrapper}>
                        {isNaN(props.summaryPercentage) ? 0 : props.summaryPercentage}% <span className={classes.SummaryBlock}>{isNaN(props.summaryValue) ? 0 : props.summaryValue} {props.summaryTitle}</span>
                    </div>
                </div>
                <div className={classes.PieChartWrapper}>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                label
                                dataKey="value"
                                data={props.data}
                                innerRadius={60}
                                outerRadius={70}
                                startAngle={90}
                                endAngle={-270}
                                paddingAngle={1}
                            >
                                {props.data.map((x, index) => <Cell key={`cell-${index}`} fill={x.color}/>)}
                            </Pie>
                            {legend}
                        </PieChart>
                    </ResponsiveContainer>
                </div>


        </div>
    )
};



export default LargeMetricWheel;

LargeMetricWheel.propTypes = {
    data: PropTypes.array.isRequired,
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    summaryPercentage: PropTypes.number.isRequired,
    summaryTitle: PropTypes.string.isRequired,
    summaryValue: PropTypes.number.isRequired,
    iconColor: PropTypes.string,
};

LargeMetricWheel.defaultProps = {
    iconColor: '#000000'
};