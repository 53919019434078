import * as actionTypes from  '../actions/actionsTypes';

const initialState = {
    userId: null,
    authenticated: false,
    passwordReset: false,
    email: null,
    error: null,
    loading: false
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_LOADING:
            return {
                ...state,
                error: null,
                loading: true
            }
        case actionTypes.AUTH_SIGNUP_SUCCESS:
            return {
                ...state,
                email: action.username,
                error: null,
                loading: false
            }
        case actionTypes.AUTH_SIGNIN_SUCCESS:
            return {
                ...state,
                email: action.email,
                authenticated: true,
                error: null,
                loading: false
            }
        case actionTypes.AUTH_NEEDS_PASSWORD_CHANGE:
            return {
                ...state,
                passwordReset: true,
                error: null,
                loading: false,
                authenticated: false,
            }
        case actionTypes.AUTH_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false
            }
        case actionTypes.AUTH_UPDATE_TOKEN:
            return {
                ...state,
                authenticated: true,
                error: null,
                loading: false
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                authenticated: false,
            }
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                userId: null,
                authenticated: false,
                email: null,
            }
        default:
            return state;
    }
}

export default reducer;
