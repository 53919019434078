import React from 'react';
import classes from './DuelMetricBarBox.module.css';
import PropTypes from 'prop-types';
import {BarChart, YAxis, XAxis, Bar, ResponsiveContainer} from "recharts";

const DuelMetricBarBox = props => {

    const bar1 = [{
        value1: props.barValueOne,
        value2: 100-props.barValueOne
    }];

    const bar2 = [{
        value1: props.barValueTwo,
        value2: 100-props.barValueTwo
    }]

    return (
        <div className={classes.DuelMetricBarBox}>
            <div style={{color: props.iconColor}} className={classes.Icon}>{props.icon}</div>

            <div className={classes.BarSections}>
                <div className={classes.BarSection}>
                    <div className={classes.BarText}>
                        <div>{props.barTitleOne}</div><div>{props.barValueOne}%</div>
                    </div>
                    <div className={classes.BarWrapper}>
                        <ResponsiveContainer width="100%" height={21}>
                            <BarChart
                                className={classes.RechartsWrapper}
                                data={bar1}
                                layout="vertical"
                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            >
                                <XAxis type="number" hide/>
                                <YAxis type="category" hide/>
                                <Bar barSize={10} stackId='a' dataKey="value1" fill={props.primaryColor} />
                                <Bar barSize={10} stackId='a' dataKey="value2" fill={props.SecondaryColor} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>


                <div className={classes.BarSection}>
                    <div className={classes.BarText}>
                        <div>{props.barTitleTwo}</div><div>{props.barValueTwo}%</div>
                    </div>
                    <div className={classes.BarWrapper}>
                        <ResponsiveContainer width="100%" height={21}>
                            <BarChart
                                className={classes.RechartsWrapper}
                                data={bar2}
                                layout="vertical"
                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            >
                                <XAxis type="number" hide/>
                                <YAxis type="category" hide/>
                                <Bar barSize={10} stackId='a' dataKey="value1" fill={props.primaryColor} />
                                <Bar barSize={10} stackId='a' dataKey="value2" fill={props.SecondaryColor} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            <div className={classes.Title}>
                {props.title}
            </div>
            <div className={classes.StatsWrapper}>
                <div>
                    {props.subTitleOne}: {props.subTitleOneValue}
                </div>
                <div>
                    {props.subTitleTwo}: {props.subTitleTwoValue}
                </div>
            </div>
        </div>
    )
};

export default DuelMetricBarBox;

DuelMetricBarBox.propTypes = {
    // color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    barTitleOne: PropTypes.string.isRequired,
    barValueOne: PropTypes.number.isRequired,
    barTitleTwo: PropTypes.string.isRequired,
    barValueTwo: PropTypes.number.isRequired,
    subTitleOne: PropTypes.string.isRequired,
    subTitleOneValue: PropTypes.number.isRequired,
    subTitleTwo: PropTypes.string.isRequired,
    subTitleTwoValue: PropTypes.number.isRequired,
    primaryColor: PropTypes.string.isRequired,
    SecondaryColor: PropTypes.string,
    iconColor: PropTypes.string,
};

DuelMetricBarBox.defaultProps = {
    subTitleOneValue: 0,
    subTitleTwoValue: 0,
    SecondaryColor: '#dfe1e1',
    iconColor: '#000000'
};