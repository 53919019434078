import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classes from './Tag.module.css';
import {IoIosClose} from 'react-icons/io';

const Tag = ({ id, name, handleDelete, color, ...rest}) => {
    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition
    } = useSortable({ id,
    transition: {
        duration: 500,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    }

    const handleDeleteClicked = (e) => {
        handleDelete(id);
    }

    const tagColor = rest?.pivot?.is_ideal ? classes.TagBlue : classes.TagWhite;

    return (
        <div ref={setNodeRef} className={[classes.OuterTag, tagColor].join(' ')} style={style} >
            <span className={classes.InnerTag} {...attributes} {...listeners}>{name}</span>
            <span className={classes.closeSection} onClick={handleDeleteClicked}><IoIosClose className={classes.CloseIcon}/></span>
        </div>
    )
}

export default Tag
