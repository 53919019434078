import React from 'react';
import classes from './ColumnHeader.module.css';

const ColumnHeader = () => {
    return (
        <div className={classes.ColumnHeader}>
            Customers
        </div>
    )
};

export default ColumnHeader;