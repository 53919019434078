import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classes from './Tag.module.css';

//TODO: merge with Tag component under RoleTags to only have one!
const Tag = ({name, keywords}) => {
    const {
        transform,
        transition
    } = useSortable({ name,
    transition: {
        duration: 500,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        background: keywords.includes(name) ? '#DFE1E1' : 'inherit'
    }

    return (
        <div className={classes.OuterTag} style={style} >
            <span className={classes.InnerTag}>{name}</span>
        </div>
    )
}

export default Tag
