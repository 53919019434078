import React from 'react';
import rolebotImage from "../../../../assets/images/rolebot-bot.png";
import {Col, Row} from 'react-bootstrap'
import classes from './EmptyTalentListScreen.module.css';

const EmptyTalentListScreen = props => {
    return (
        <Row className={classes.CreateRoleScreen}>
            <Col xs={12}>
                <img className={classes.RolebotImage} src={rolebotImage} alt="rolebot" />
            </Col>
            <Col xs={12}>
                <h1 className={classes.Header}>Start adding talent! <br/> The customer is waiting!</h1>
            </Col>
        </Row>
    )
};

export default EmptyTalentListScreen;
