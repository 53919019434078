import React from 'react';
import classes from '../../TalentInputGroup/TalentInputGroup.module.css';
import {Col, Row} from "react-bootstrap";

const FormGreyWrapper = props => {
    return (
        <Row className={classes.FormGreyWrapper}>
            <Col xs={12}>
                <div xs={12} className={classes.innerWrapper}>
                    {props.children}
                </div>
            </Col>
        </Row>
    )
};

export default FormGreyWrapper;