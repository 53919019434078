import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row, Well} from "react-bootstrap";
import {inputNullToEmptyString} from '../AdminFormValidation/AdminFormValidation';

const AdminNotesSection = props => {
  return (
      <Well bsSize="large">
          <Row>
              <Col xs={12}>
                  <FormGroup controlId="roleNotes" validationState={props.notesValidationState}>
                      <ControlLabel>Role Notes</ControlLabel>
                      <FormControl
                          value={inputNullToEmptyString(props.notesValue)}
                          onChange={props.handleNotesChanged}
                          componentClass="textarea"/>
                      <FormControl.Feedback/>
                      <HelpBlock>0 to 7000 chars only</HelpBlock>
                  </FormGroup>
              </Col>
          </Row>
      </Well>
  );
};

export default React.memo(AdminNotesSection);