import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStickyNote} from "@fortawesome/free-solid-svg-icons";
import classes from './RoleNotesItem.module.css';

const RoleNotesItem = props => {

    const {id, chargeId, notes, billing_notes} = props;

    const iconClass = notes || billing_notes ? classes.IconHighlighted : classes.Icon;

    const tooltip = (
        <Tooltip id={'notesId' + id}>
            Click to see all notes.
        </Tooltip>
    );

    const handleShowNotesModal = () => {
        props.handleShowNotesModal(chargeId);
    };

    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
            <div className={classes.NotesIcon}>
                <FontAwesomeIcon onClick={handleShowNotesModal} className={iconClass} icon={faStickyNote}/>
            </div>
        </OverlayTrigger>
    );
};

export default RoleNotesItem;