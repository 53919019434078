import React, { useState } from 'react';
import classes from './TalentRowInfoForm.module.css';
import {
    Alert,
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    OverlayTrigger,
    Popover,
    Row
} from "react-bootstrap";
import FormGreyWrapper from "../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";
import TalentInterviewItem from "../TalentInterviewItem/TalentInterviewItem";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useSelector } from 'react-redux';
import ContactOutTag from "./Components/ContactOutTag/ContactOutTag";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { autoScheduleTalent } from "../../../utils/services/turboPlus2RestCalls";
import _ from "lodash";
import { showToastError, showToastSuccess } from "../../../utils/toastMessages";

const nullToEmptyStringFix = (value) => {
    return value === null ? "" : value;
};

const TalentRowInfoForm = props => {
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal1] = useState(false);

    const outcomes = useSelector(state => state.globalLists.outcomes);
    const currentRole = useSelector(state => state.rolesSearchSettings.roleRowOpen);

    const {
        id,
        name,
        email,
        mobile_number,
        alt_number,
        link,
        notes,
        outcome,
        gender,
        is_diverse,
        interviews,
    } = props.talent;

    const {
        interested_on,
    } = props.talent.pivot;

    const { accepted, outcome_id, calendar_link } = props.talent.pivot;
    const [interviewLink, setInterviewLink] = useState(calendar_link || '');
    const [generateInterviewLinkButtonIsLoading, setGenerateInterviewLinkButtonIsLoading] = useState(false);

    const talentAccepted = accepted !== null && accepted !== false; // rename this to talentAccepted!!

    const hasInterview = !!outcome && outcome.is_interview;

    const interviewStatusActive = !!props.interviews.filter(x => !x.voided_at).length;

    const handleDeleteInterviewsClicked = () => {
        setShowDeleteModal1(true);
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal1(false);
    };

    const handleDeleteConfirm = () => {
        setLoading(true);
        props.handleDeleteTalentInterviews(id);
    };

    const handleGenerateCalendarLink = () => {
        setGenerateInterviewLinkButtonIsLoading(true);
        autoScheduleTalent(currentRole, id)
            .then(response => {
                setInterviewLink(response.cronofy);
                setGenerateInterviewLinkButtonIsLoading(false);
                showToastSuccess("Interview link generated successfully");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                showToastError(message, errors);
                setGenerateInterviewLinkButtonIsLoading(false);
            });
    }

    const showOutcomeSection = () =>{
        // if the talent does not have interviews show the outcome section
        if(!interviews.length){
            return true;
        }
        // if the talent has interviews and the interviews are all cancelled show the outcome section
        if(interviews.length && interviews.every(interview => (interview.cancelled_at || interview.voided_at))){
            return true;
        }
        return false;
    }

    const helpFeedbackPopOver = (
        <Popover>
            Contact Information for this LinkedIn url was previously requested by a customer via API.
            The information displayed is the information that ContactOut returned for this url at the time.
            Please note the information may have changed, and/or been updated since.
        </Popover>
    )

    const helpFeedbackTag = (
        <OverlayTrigger trigger={['focus', 'hover']} placement="top" overlay={helpFeedbackPopOver}>
            <BsFillQuestionCircleFill className={classes.HelpIcon} />
        </OverlayTrigger>
    )

    const infoSection = (
        <FormGreyWrapper title="Talent Info">
            <Row>


                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-name">
                        <ControlLabel>Name:</ControlLabel>
                        <FormControl
                            type="text"
                            value={nullToEmptyStringFix(name)}
                            onChange={props.onInputChange}
                            placeholder="Name of talent"
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-email">
                        <ControlLabel>Email:</ControlLabel>
                        <FormControl
                            type="email"
                            value={nullToEmptyStringFix(email)}
                            onChange={props.onInputChange}
                            placeholder="Email"
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-mobile_number">
                        <ControlLabel>Mobile Number:</ControlLabel>
                        <FormControl
                            type="text"
                            value={nullToEmptyStringFix(mobile_number)}
                            onChange={props.onInputChange}
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-alt_number">
                        <ControlLabel>Alt Number:</ControlLabel>
                        <FormControl
                            type="text"
                            value={nullToEmptyStringFix(alt_number)}
                            onChange={props.onInputChange}
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-gender">
                        <ControlLabel>Gender:</ControlLabel>
                        <FormControl
                            value={nullToEmptyStringFix(gender)}
                            onChange={props.onInputChange}
                            componentClass="select"
                            placeholder="select">
                            <option value="select">select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="unknown">Unknown</option>
                        </FormControl>
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup
                        controlId="input-is_diverse">
                        <ControlLabel>Is Diverse:</ControlLabel>
                        <FormControl
                            value={is_diverse ? "1" : "0"}
                            onChange={props.onInputChange}
                            componentClass="select"
                            placeholder="select">
                            <option value="select">select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </FormControl>
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup
                        controlId="input-link">
                        <ControlLabel>Profile Link:</ControlLabel>
                        <FormControl
                            type="text"
                            value={nullToEmptyStringFix(link)}
                            onChange={props.onInputChange}
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>

                {props.contactOutInfo.length > 0 &&
                    <Col xs={12}>
                        <FormGroup
                            controlId="input-link">
                            <ControlLabel>ContactOut info Returned {helpFeedbackTag}</ControlLabel>
                            <div className={classes.ContactOutWrapper}>
                                {props.contactOutInfo.map(c => <ContactOutTag key={c.id} type={c.type} value={c.value} />)}
                            </div>
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                }

                {props.rocketReachInfo.length > 0 &&
                    <Col xs={12}>
                        <FormGroup
                            controlId="input-link">
                            <ControlLabel>Rocket Reach info Returned {helpFeedbackTag}</ControlLabel>
                            <div className={classes.ContactOutWrapper}>
                                {props.rocketReachInfo.map(c => <ContactOutTag key={c.id} type={c.type} value={c.value} />)}
                            </div>
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                }

                <Col xs={12}>
                    <FormGroup
                        controlId="input-notes">
                        <ControlLabel>Notes:</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            type="textarea"
                            rows="3"
                            value={nullToEmptyStringFix(notes)}
                            onChange={props.onInputChange}
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
            </Row>
        </FormGreyWrapper>
    );

    const interviewAlertSection = props.hasInterviewData ? (
        <Alert bsStyle="danger">
            <strong>The customer may get charged when you add a interview date.</strong>
        </Alert>
    ) : null;

    const interviewSection = talentAccepted ? (
        <React.Fragment>
            {interested_on && email && !props.isSourcingOnly &&
                <React.Fragment>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <ControlLabel>Generate unique interview link</ControlLabel>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={12}>
                            <Button disabled={generateInterviewLinkButtonIsLoading} onClick={handleGenerateCalendarLink}>Generate Calendar Link</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={12}>
                            <FormControl type={'text'} value={interviewLink} />
                        </Col>
                    </Row>
                </React.Fragment>
            }
            <Row>
                <Col lg={6}>
                    <FormGroup controlId="time-zone">
                        <ControlLabel>Interview Time Zone:</ControlLabel><br />
                        {/*https://raw.githubusercontent.com/yury-dymov/react-bootstrap-timezone-picker/master/src/timezones.json*/}
                        <TimezonePicker
                            style={{ width: "100%" }}
                            absolute={true}
                            defaultValue="America/Los_Angeles"
                            value={props.interviewTimeZone}
                            placeholder="Select timezone..."
                            timezones={{
                                "(GMT-08:00) Pacific Time": "America/Los_Angeles",
                                "(GMT-07:00) Mountain Time": "America/Denver",
                                "(GMT-06:00) Central Time": "America/Chicago",
                                "(GMT-05:00) Eastern Time": "America/New_York",
                            }}
                            onChange={props.handleTimeZoneChanged}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup controlId="interview-date" className={classes.FormGroup}>
                        <ControlLabel>Interview Date and Time:</ControlLabel>
                        <DatePickerInput
                            id="interview-date"
                            selected={props.interviewDate}
                            onChange={props.handleInterviewDateChanged}
                            showTimeSelect
                            placeholderText="Click to select a date"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
                <Col xs={12} style={{ marginBottom: "15px" }}>
                    <div className={classes.InterViewWrapper}>
                        {props.interviews.map(x => <TalentInterviewItem
                            key={x.id}
                            interview={x}
                            handleInterviewCanceled={props.handleInterviewCanceled}
                        />)}
                    </div>
                </Col>
                {interviewStatusActive ? (
                    <Col xs={4} xsOffset={8}>
                        <Button block disabled={loading} onClick={handleDeleteInterviewsClicked}>
                            <FontAwesomeIcon icon={faExclamationTriangle} /> Void And Refund Interviews <FontAwesomeIcon
                                icon={faExclamationTriangle} />
                        </Button>
                    </Col>
                ) : null}
            </Row>
        </React.Fragment>
    ) : null;

    const followUpInterviewTabs = talentAccepted ? (
        <FormGreyWrapper>
            {interviewSection}
        </FormGreyWrapper>
    ) : null;

    const bottomButtonRow = (
        <Row>
            <Col xs={12}>
                <Col xs={12} lg={2} className={classes.DeleteButton}>
                    <Button disabled={interviewStatusActive} block onClick={props.handleDeleteClicked}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: "17px" }} />
                    </Button>
                </Col>
                <Col xs={12} lg={2} lgOffset={8}>
                    <Button disabled={props.formLoading} block type="submit" bsStyle="success">
                        {props.formLoading ? "Saving" : "Save"}
                    </Button>
                </Col>
            </Col>
        </Row>
    );

    const finalOutcomeSection = (props.talent.pivot.accepted && showOutcomeSection()) ? (
        <FormGreyWrapper title="Final Outcome">
            <Row>
                <Col lg={6}>
                    <FormGroup controlId="outcome-text">
                        <ControlLabel>Outcome:</ControlLabel>
                        <FormControl
                            componentClass="select"
                            placeholder="select"
                            onChange={props.handleOutcomeChanged}
                            value={outcome_id ? outcome_id : ""}
                            disabled={hasInterview}>
                            <option value="">select an outcome</option>
                            {outcomes
                                .filter(({ is_interview }) => hasInterview ? !!is_interview : !is_interview)
                                .map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                        </FormControl>
                    </FormGroup>
                </Col>
            </Row>
        </FormGreyWrapper>
    ) : null;

    const voidInterviewsModal = showDeleteModal ? (
        <ConfirmationModal
            show={true}
            disabled={loading}
            handleCancel={handleDeleteCancel}
            handleConfirm={handleDeleteConfirm}>
            <h1 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
                WARNING!!!!
            </h1>
            <h2 style={{ textAlign: "center" }}>
                Do you want to void <span style={{ color: "red", fontWeight: "bold" }}>ALL</span> interviews
                for this talent and refund <span style={{ color: "red", fontWeight: "bold" }}>ALL</span> charges
                for this talent if any?
            </h2>
            <p style={{ textAlign: "center" }}>*Refunds only apply to non invoice roles.</p>
        </ConfirmationModal>
    ) : null;

    return (
        <Form onSubmit={props.onSubmit} autoComplete="off">
            {voidInterviewsModal}
            {infoSection}
            {followUpInterviewTabs}
            {finalOutcomeSection}
            {bottomButtonRow}
        </Form>
    )
};

export default TalentRowInfoForm;
