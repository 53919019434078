import React from 'react';
import {Col, Panel, Row} from "react-bootstrap";
import classes from "./TalentProspectItem.module.css";
import {faMinus, faTimes, faCheck, faPenSquare} from "@fortawesome/free-solid-svg-icons";
import LittleIconWrapper from '../LittleIconWrapper/LittleIconWrapper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TalentProspectItem = props => {

    const handleApprovedClicked = () => {
        props.talentForeReviewStatusUpdated(props.id, {prospect_approved: 1});
    };

    const handleSavedClicked = () => {
        props.talentForeReviewStatusUpdated(props.id, {saved: 1});
    };

    const handleDeclinedClicked = () => {
        props.talentForeReviewStatusUpdated(props.id, {prospect_approved: 0});
    }

    const formatCompanyName = (company) => {
        let toBeExcluded= " · Full-time"
        if (company && company.includes(toBeExcluded)) {
            return company.replace(toBeExcluded, "");
        } else {
            return company;
        }
    }

    return (
        <Row xs={12} className={classes.TalentProspectItem}>
            <Col xs={7}>
                <div className={classes.TalentName}>
                    <Panel.Toggle>
                        <FontAwesomeIcon className={classes.PenIcon} icon={faPenSquare} color="#BFBFBF"/>
                    </Panel.Toggle>
                    <a href={props.link} target="_blank">{props.name}</a>
                    { props.is_recommended ? <span className={classes.RecommendedTalentBadge}>AI</span> : null }
                </div>
            </Col>
            <Col xs={3}>
                <div className={classes.TalentCompany}>
                    {formatCompanyName(props.company)}
                </div>
            </Col>
            <Col xs={2}>
                <div  className={classes.TalentOptions}>
                    <LittleIconWrapper type="prospect_approved" active={props.prospect_approved} color="green" onClick={handleApprovedClicked} icon={faCheck}/>
                    <LittleIconWrapper type="saved" active={props.saved} color="gold" onClick={handleSavedClicked} icon={faMinus}/>
                    <LittleIconWrapper type="declined" active={!props.prospect_approved && !props.saved && props.prospect_approved !== null} color="red" onClick={handleDeclinedClicked} icon={faTimes}/>
                </div>
            </Col>
        </Row>
    )
};

export default TalentProspectItem;
