import React from 'react';
import PropTypes from 'prop-types';
import classes from "./RecommendationSection.module.css";

function RecommendationSection(props) {
    return <div className={classes.BottomBlueTextArea}>
        {props.recommendations.length ? props.recommendations.map((e, i) => (
            <span key={i} className={e.created_at ? classes.roleTag__jobTitleDark : classes.roleTag__jobTitle} onClick={() => props.onTagAdded(e.id)}>
                +{e.name}
            </span>)) : null}
    </div>;
}

export default RecommendationSection;

RecommendationSection.propTypes = {
    recommendations: PropTypes.any,
    onTagAdded: PropTypes.func
};

RecommendationSection.defaultProps = {};