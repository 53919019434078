import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import CreditCardSection from "./Component/CreditCardSection/CreditCardSection";

const AddCreditCardArea = ({user, cancel, companyId, submit}) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    return (
        <Elements stripe={stripePromise}>
            <CreditCardSection user={user} cancel={cancel} companyId={companyId} submit={submit}/>
        </Elements>
    )
};

export default AddCreditCardArea;

AddCreditCardArea.propTypes = {
    user: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired
};

AddCreditCardArea.defaultProps = {};