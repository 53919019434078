import React from 'react';
import PropTypes from 'prop-types';
import classes from './UserFoundSection.module.css';

const UserFoundSection = ({users, userClicked}) => {
    return (
        <div className={classes.UserWrapper}>
            {users.map(x =>
                <div key={x.id} className={classes.UserItem} onClick={() => userClicked(x.id)}>
                    <span className={classes.Name}>{x.name ?  x.name  : 'No Name'}</span>
                    <span className={classes.Email}>({x.email})</span>
                </div>)}
        </div>
    )
};

export default UserFoundSection;

UserFoundSection.propTypes = {
    users: PropTypes.array.isRequired,
    userClicked: PropTypes.func.isRequired
};

UserFoundSection.defaultProps = {};