import { getRoleBasicInfo, getRoles, getTypeOfPlans, getMondayBoardsDropdown } from "../../utils/services/turboPlus2RestCalls";
import { showToastError } from "../../utils/toastMessages";

export const getRoleList = (companyId, params) => {
    return (dispatch) => {
        dispatch(rolesListLoading());

        getRoles({ params })
            .then(response => {
                dispatch(rolesListReturned(response.data));
            })
            .catch(e => {
                console.log(e);
                dispatch(rolesListReturned([]));
                showToastError(e.message);
            });
    };
}

export const rolesListLoading = () => {
    return {
        type: 'GET_ROLES_REQUEST'
    }
};

export const rolesListReturned = (data) => {
    return {
        type: 'GET_ROLES_SUCCESS',
        payload: { data }
    }
};

export const roleRowClicked = (roleId) => {
    return {
        type: 'ROLE_ROW_CLICKED',
        payload: roleId
    }
};

export const getRoleInfo = (roleId) => {
    return (dispatch) => {
        getRoleBasicInfo(roleId)
            .then(({ role }) => {
                dispatch({
                    type: "GOT_ROLE",
                    payload: role
                })
            })
            .catch(e => console.log(e));
    };
}

export const getTypeOfPlan = () => {
    return (dispatch) => {
        getTypeOfPlans()
            .then((data) => {
                dispatch({
                    type: "GET_TYPE_OF_PLAN",
                    payload: data
                });
            })
            .catch(e => console.log(e));
    };
}

export const getMondayBoardsDd = () => {
    return (dispatch) => {
        getMondayBoardsDropdown()
            .then((data) => {
                dispatch({
                    type: "GET_MONDAY_BOARDS_DROPDOWN",
                    payload: data
                });
            })
            .catch(e => console.log(e));
    };
}
