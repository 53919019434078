import React from 'react';
import classes from './RoleRequestItem.module.css';
import {Row, Col, Button} from 'react-bootstrap'
import PropTypes from 'prop-types';
import moment from 'moment';
import {MdHighlightOff, MdSlowMotionVideo, MdControlPoint} from 'react-icons/md';

const RoleRequestItem = ({request, onDeleteClicked, onHoldClicked, onCreateClicked, onCloseRoleClicked, onOpenRoleClicked}) => {
    return (
        <div className={classes.RoleRequestItem}>
            <Row>
                <Col xs={12}>
                    <Col xs={12} lg={1}>
                        <div className={classes.IconWrapper}>
                            {request.type === 'new' && <MdControlPoint className={classes.CreateIcon}/>}
                            {request.type === 'close' && <MdHighlightOff className={classes.CloseIcon}/>}
                            {request.type === 'open' && <MdSlowMotionVideo className={classes.OpenIcon}/>}
                        </div>
                    </Col>
                    <Col xs={12} lg={5}>
                        <div className={classes.UserInfoWrapper}>
                            <div className={classes.Name}>{request.customer.name}</div>
                            <div className={classes.RoleName}>{request.name}</div>
                            <div className={classes.Date}>{moment.utc(request.created_at).local().format('M/D/YY h:mma')}</div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className={classes.ButtonToolBar}>
                            <Col xs={12} lg={4} lgOffset={request.on_hold ? 4:0}>
                                <Button
                                    block
                                    className={classes.Button}
                                    onClick={() => onDeleteClicked(request.id)}
                                >
                                    Delete
                                </Button>
                            </Col>
                            {!request.on_hold &&
                            <Col xs={12} lg={4}>
                                <Button
                                    block
                                    className={classes.Button}
                                    onClick={() => onHoldClicked(request.id)}
                                    bsStyle="info">
                                    Hold
                                </Button>
                            </Col>
                            }

                            {request.type === 'new' &&
                            <Col xs={12} lg={4}>
                                <Button
                                    block
                                    className={classes.Button}
                                    onClick={() => onCreateClicked(request.id)}
                                    bsStyle="primary">
                                    Create
                                </Button>
                            </Col>
                            }

                            {request.type === 'open' &&
                            <Col xs={12} lg={4}>
                                <Button
                                    block
                                    className={classes.Button}
                                    onClick={() => onOpenRoleClicked(request.id)}
                                    bsStyle="primary">
                                    Reactivate
                                </Button>
                            </Col>
                            }

                            {request.type === 'close' &&
                            <Col xs={12} lg={4}>
                                <Button
                                    block
                                    className={classes.Button}
                                    onClick={() => onCloseRoleClicked(request.id)}
                                    bsStyle="primary">
                                    Close
                                </Button>
                            </Col>
                            }
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>
    )
};

export default RoleRequestItem;

RoleRequestItem.propTypes = {
    onDeleteClicked: PropTypes.func.isRequired,
    onHoldClicked: PropTypes.func.isRequired,
    onCreateClicked: PropTypes.func.isRequired,
    onCloseRoleClicked: PropTypes.func.isRequired,
    onOpenRoleClicked: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired
};

RoleRequestItem.defaultProps = {};