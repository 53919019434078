import  { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import auth from './reducers/auth';
import filters, {initialState as filtersInit } from './reducers/filters';
import {initialState as roleSearchSettingsInit} from './reducers/rolesSearchSettings';
import globalLists from './reducers/globalLists';
import companies from './reducers/companies';
import rolesSearchSettings from './reducers/rolesSearchSettings';
import roles from './reducers/roles';
import roleHistory from './reducers/roleHistory';
import siteSettings, {initialState as siteSettingsInit} from './reducers/siteSettings';
import roleRequests from './reducers/roleRequests';
import metrics from './reducers/metrics';
import thunk from 'redux-thunk';
import {throttle} from 'lodash';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
    auth,
    siteSettings,
    globalLists,
    rolesSearchSettings,
    filters,
    companies,
    roles,
    roleRequests,
    roleHistory,
    metrics
});

// Build the site settings rehydration object from local storage.
// We do a merge in case we add more settings they are not cleared out by rehydration.
const siteSettingsStorage = localStorage.getItem('siteSettings') ? {
    ...siteSettingsInit,
    ...JSON.parse(localStorage.getItem('siteSettings'))
} : undefined;

const filterStorage = localStorage.getItem('filters') ? {
    ...filtersInit,
    ...JSON.parse(localStorage.getItem('filters'))
} : undefined;

const searchSettingsStorage = localStorage.getItem('searchSettings') ? {
    ...roleSearchSettingsInit,
    ...JSON.parse(localStorage.getItem('searchSettings'))
} : undefined;

const preLoadState = {
    auth: localStorage.getItem('auth') ?
        {
            ...JSON.parse(localStorage.getItem('auth')),
            error: null
        } : undefined,
    siteSettings: siteSettingsStorage,
    filters: filterStorage,
    rolesSearchSettings: searchSettingsStorage
}

const store = createStore(reducer, preLoadState, composeEnhancers(
    applyMiddleware(thunk)
));

store.subscribe( throttle( () => {
    console.log("SUBSCRIBE UPDATED!!!");
    localStorage.setItem('auth', JSON.stringify(store.getState().auth));
}, 5000));

export default store;