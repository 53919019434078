import {Col, Row, Button, Form, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import React, {useEffect, useReducer, useRef, Fragment} from "react";
import classes from './MondayRoleLinker.module.css';
import {getMondayBoardsDropdown, getMondayGroups, reSyncMondayData} from '../../../utils/services/turboPlus2RestCalls';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {showToastError, showToastSuccess} from "../../../utils/toastMessages";
import _ from "lodash";

function reducer(state, action) {
    switch (action.type) {
        case 'receivedBoards':
            return {
                ...state,
                boards: action.payload,
                selectedBoardDisabled: state.roleLinkedToMonday,
                selectedBoard: action.payload.find(x => x.board_id === state.selectedBoardId),
                selectedBoardId: action.payload.find(x => x.board_id === state.selectedBoardId)?.board_id,
            };
        case 'receivedGroups':
            return {
                ...state,
                groups: state.roleLinkedToMonday ? action.payload.groups : action.payload.groups.filter(x => !x.role),
                selectedGroupDisabled: state.roleLinkedToMonday,
                disableSubmit: !state.roleLinkedToMonday,
                selectedGroup: action.payload.groups.find(x => x.group_id === state.selectedGroupId)
            };
        case "boardSelected":
            return {
                ...state,
                groups: [],
                selectedBoardId: action.payload,
                selectedGroupId: null,
                disableSubmit: !action.payload || !state.selectedGroupId,
                selectedGroupDisabled: true
            };
        case 'groupSelected':
            let selectedGroup = state.groups.find(x => x.group_id === action.payload);
            return {
                ...state,
                selectedGroupId: action.payload,
                selectedGroupName: selectedGroup ? selectedGroup.title : "",
                disableSubmit: !action.payload
            };
        case 'formSubmitted':
            return {
                ...state,
                formLoading: true
            };
        case 'showDisconnectModal':
            return {
                ...state,
                showMondayModal: true
            };
        case 'cancelDisconnectModal':
            return {
                ...state,
                showMondayModal: false,
                formLoading: false
            };
        case "modalLoading":
            return {
                ...state,
                modalLoading: true
            };
        case "mondayDataSyncing":
            return {
                ...state,
                mondayDataSyncing: action.payload
            };
        case 'reset':
            return {
                ...state,
                ...action.payload
            };
        default:
            throw new Error();
    }
}

const MondayRoleLinker = props => {
    const componentActive = useRef(false);

    const [state, dispatch] = useReducer(reducer, {
        roleId: props.roleId,
        roleLinkedToMonday: !!props.boardId && !!props.groupId,
        selectedBoard: null,
        selectedGroup: null,
        selectedBoardId: props.boardId,
        selectedGroupId: props.groupId,
        selectedGroupName: null,
        selectedBoardDisabled: true,
        selectedGroupDisabled: true,
        disableSubmit: true,
        formLoading: false,
        showMondayModal: false,
        modalLoading: false,
        mondayDataSyncing: false,
        boards: [],
        groups: []
    });

    useEffect(() => {
        componentActive.current = true;
        return () => {
            componentActive.current = false;
        }
    });

    useEffect(() => {
        ( async () => {
            try {
                const boards = await getMondayBoardsDropdown();
                if(componentActive.current){
                    dispatch({type: "receivedBoards", payload : boards});

                    if(props.boardId){
                        const groups = await getMondayGroups(state.selectedBoardId, state.roleId);
                        if(componentActive.current){
                            dispatch({type: "receivedGroups", payload : groups});
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })();
    },[props.boardId, props.groupId]);

    useEffect(() => {
        dispatch({type: "reset",
            payload : {
                roleLinkedToMonday: !!props.boardId && !!props.groupId,
                selectedBoardId: props.boardId,
                selectedGroupId: props.groupId,
                formLoading: false,
                showMondayModal: false
            }});
    }, [props.boardId, props.groupId]);

    const refreshMondayData = () => {
        dispatch({type: "mondayDataSyncing", payload: true});
        reSyncMondayData()
            .then(response => {
                if(componentActive.current){
                    props.initRole();
                    dispatch({type: "mondayDataSyncing", payload: false});
                    showToastSuccess(response.message);
                }
            })
            .catch(e => {
                dispatch({type: "mondayDataSyncing", payload: false});
                const errors = _.flattenDeep(Object.values(e.errors));
                showToastError(e.message, errors);
            });
    };

    const nullOutEmptyString = (value) => {
        return value === "" ? null : value;
    };

    const fetchGroups =  async (boardId) => {
        try {
            const groups = await getMondayGroups(boardId, state.roleId);
            if(componentActive.current){
                dispatch({type: "receivedGroups", payload : groups});
            }
        } catch (e) {
            console.log(e);
        }
    };

    const roleSelected = (e) => {
        dispatch({type: "boardSelected", payload : nullOutEmptyString(e.target.value)});
        if(e.target.value !== ""){
            fetchGroups(e.target.value);
        }
    };

    const groupSelected = (e) => {
        dispatch({type: "groupSelected", payload : nullOutEmptyString(e.target.value)});
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        dispatch({type: "formSubmitted"});
        if(state.roleLinkedToMonday){
            showDisconnectModal();
        } else {
            submitUpdate();
        }
    };

    const showDisconnectModal = () => {
        dispatch({type: 'showDisconnectModal'});
    };

    const handleCancelDisconnect = () => {
        dispatch({type: 'cancelDisconnectModal'});
    };

    const submitUpdate = () => {
        props.updateRole({monday_group_id: {monday_board_id: state.selectedBoardId , monday_group_id: state.selectedGroupId, monday_group_name: state.selectedGroupName}})
    };

    const submitDisconnect = () => {
        dispatch({type: 'modalLoading'});
        props.updateRole({monday_group_id: null})
    };

    const modal = state.showMondayModal ? (
        <Row>
            <Col xs={12}>
                <ConfirmationModal
                    show={state.showMondayModal}
                    disabled={state.modalLoading}
                    handleCancel={handleCancelDisconnect}
                    handleConfirm={submitDisconnect}
                >
                    <h2 style={{fontWeight:"Bold"}}>Are you sure you want to disconnect monday.com from this role?</h2>
                    <p>
                        Clicking confirm will disconnect this role
                        from <span className={classes.ModalGroupName}>{state.selectedGroup?.title}</span> at monday.com.
                        This will break all relations that you can add back later.
                    </p>
                </ConfirmationModal>
            </Col>
        </Row>
    ) : null;

    return (
        <Fragment>
            {modal}
            <Row className={classes.MondayRoleLinker}>
                <Form onSubmit={formSubmitted}>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup controlId="mondayBoardSelect">
                            <ControlLabel>Board (monday.com)</ControlLabel>{' '}
                            <FormControl
                                componentClass="select"
                                placeholder="select"
                                value={state.selectedBoardId ? state.selectedBoardId : ""}
                                disabled={state.selectedBoardDisabled}
                                onChange={roleSelected}>
                                <option value="">select board</option>
                                {state.boards.map(x => <option
                                    key={x.board_id}
                                    value={x.board_id}
                                >{x.name + " - ID:" + x.board_id}</option>)}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup controlId="mondaySelectGroup">
                            <ControlLabel>Group (monday.com)</ControlLabel>
                            <FormControl
                                componentClass="select"
                                placeholder="select"
                                value={state.selectedGroupId ? state.selectedGroupId: ""}
                                disabled={state.selectedGroupDisabled}
                                onChange={groupSelected}>
                                <option value="">select group</option>
                                {state.groups.map(x => <option key={x.id} value={x.group_id}>{x.title}</option>)}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={8} md={10} lg={2}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <Button disabled={state.disableSubmit || state.formLoading} block type="submit">
                                {state.roleLinkedToMonday ? "Clear" :"Link"}
                            </Button>
                        </FormGroup>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <Button disabled={state.mondayDataSyncing} block onClick={refreshMondayData}>
                                Sync
                            </Button>
                        </FormGroup>
                    </Col>
                </Form>
            </Row>
        </Fragment>
    );
};

// TODO: Why is this here?!
const shouldUpdate = (prev, next) => {
    return prev.boardId === next.boardId &&
        prev.groupId === next.groupId;
};

export default React.memo(MondayRoleLinker, shouldUpdate);
