import React from 'react';
import {Tooltip, OverlayTrigger, Popover} from 'react-bootstrap';
import classes from './RoleRowItemTop.module.css';
import {roleRowClicked} from '../../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faDollarSign, faLink, faFlag} from "@fortawesome/free-solid-svg-icons";
import AdminBubbleCluster from '../AdminBubbleCluster/AdminBubbleCluster';
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import apolloIcon from "../../../assets/images/apollo-icon.png";
import greenhouseIcon from "../../../assets/svg/greenhouse_icon.svg";
import leverIcon from "../../../assets/svg/lever_icon.svg";
import sapIcon from "../../../assets/svg/sap-logo.svg";
import icimsIcon from "../../../assets/images/icims_wordmark.png";

const RoleRowItemTop = props => {
    const dispatch = useDispatch();

    const {
        id,
        customer_id,
        name,
        status,
        info,
        admin,
        on_hold,
        created_at,
        completed,
        talent_count,
        talentAdmins,
        monday_group,
        force_card,
        is_trial,
        sourcing_only,
        role_requests,
        show_info
    } = props.role;

    const {
        talent_in_progress_count,
        talent_skipped_count,
        talent_scheduled_at_count,
        talent_rejected_count,
        talent_waiting_count,
        talent_refused_count,
        talent_interview_count,
        talent_interested_count
    } = props.role.metrics || ({
        talent_in_progress_count: 0,
        talent_skipped_count: 0,
        talent_scheduled_at_count: 0,
        talent_rejected_count: 0,
        talent_waiting_count: 0,
        talent_refused_count: 0,
        talent_interview_count: 0,
        talent_interested_count: 0
    });

    const mode = useSelector(state => state.siteSettings.mode);

    const company = useSelector(state => state.companies.data.find(x => customer_id === x.id));

    const companyName = (company && company.name) ? company.name : "";

    const chevronClass = props.isOpen ? [classes.ChevronIcon, classes.ChevronIconActive].join(' ') : classes.ChevronIcon;

    const dealId = id.toString().padStart(6, '0');

    const handleRowClicked = (e) => {
        const id = e.currentTarget.dataset.id;
        dispatch(roleRowClicked(parseInt(id, 10)));
    };

    const notReadyToolTip = (
        <Tooltip id="notReadyToolTip">
            <strong>Talent waiting for customer to review</strong>
        </Tooltip>
    );

    const talentAcceptedToolTip = (
        <Tooltip id="talentAcceptedToolTip">
            <strong>Talent the customer has accepted</strong>
        </Tooltip>
    );

    const talentRefusedToolTip = (
        <Tooltip id="talentRefusedToolTip">
            <strong>Talent not interested or no response</strong>
        </Tooltip>
    );

    const talentRejectedToolTip = (
        <Tooltip id="talentRejectedToolTip">
            <strong>Talent the customer has rejected</strong>
        </Tooltip>
    );

    const talentInterviewToolTip = (
        <Tooltip id="talentInterviewToolTip">
            <strong>Talent with interview set</strong>
        </Tooltip>
    );

    const talentSkippedToolTip = (
        <Tooltip id="talentInterviewToolTip">
            <strong>Talent the customer has skipped</strong>
        </Tooltip>
    );

    const scheduledToolTip = (
        <Tooltip id="scheduledToolTip">
            <strong>Talent is scheduled and NOT visible to customer</strong>
        </Tooltip>
    );

    let buildRightMessageArea = () => {
        const rightMessageAreaContent = (
          <div className={classes.NumberIconsWrapper}>
            <OverlayTrigger placement="top" overlay={scheduledToolTip}>
              <span className={classes.ScheduledStatus}>{talent_scheduled_at_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={notReadyToolTip}>
              <span className={classes.NotReadyStatus}>{talent_waiting_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={talentAcceptedToolTip}>
              <span className={classes.ReadyStatus}>{talent_in_progress_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={talentRefusedToolTip}>
              <span className={classes.RefusedStatus}>{talent_refused_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={talentRejectedToolTip}>
              <span className={classes.RejectedStatus}>{talent_rejected_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={talentSkippedToolTip}>
              <span className={classes.SkippedStatus}>{talent_skipped_count}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={talentInterviewToolTip}>
              <span className={classes.InterviewSet}>{talent_interview_count}</span>
            </OverlayTrigger>
          </div>
        );

        if (completed) {
            return (
              <div className={classes.DealCompletedWrapper}>
                <span className={classes.DealCompleted}>
                    <FontAwesomeIcon icon={faDollarSign}/> Deal Completed <FontAwesomeIcon icon={faDollarSign}/>
                </span>
                {rightMessageAreaContent}
              </div>
            );
        }

        if (show_info) {
            return <span className={classes.WaitingForCustomer}>Waiting For Role Info</span>;
        }

        if (force_card) {
            return <span className={classes.WaitingForCustomer}>Waiting For Card Submit</span>;
        }

        if (talent_count === 0 && talent_scheduled_at_count === 0) {
            return <span className={classes.ReadyStatus}>Start Adding Talent!</span>;
        }

        return rightMessageAreaContent;
    };

    const infoTime = info ? (
        <span style={{color: "#979797", fontSize: "14px", fontFamily: "Helvetica"}}>
            {"- "}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="infoTimeTooltip">Info filled on</Tooltip>}>
                <span>{moment.utc(info.created_at).local().format("L")}</span>
            </OverlayTrigger>
        </span>
    ) : (
        <span style={{color: "#979797", fontSize: "14px", fontFamily: "Helvetica"}}>
            {"- "}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="roleTimeTooltip">Role created on</Tooltip>}>
                <span>{moment.utc(created_at).local().format("L")}</span>
            </OverlayTrigger>
        </span>
    );

    const mondayLinkedIcon = monday_group ? <FontAwesomeIcon icon={faLink}/> : null;

    const interestTalent = talent_interested_count ? (
        <span className={classes.InterestedFlagArea}>
            <FontAwesomeIcon className={classes.Flag} icon={faFlag}/> <span
            className={classes.FlagCount}>{talent_interested_count}</span>
        </span>
    ) : null;

    const buildRowStyles = () => {
        // On Hold has the highest priority and is set by Valerie Team.
        if(on_hold){
            return [classes.RoleRowItemTop, classes.PriorityOnHoldRow].join(" ");
        }

        if(status === 'pink'){
            return [classes.RoleRowItemTop, classes.PriorityPink].join(" ");
        }

        if(status === 'blue'){
            return [classes.RoleRowItemTop, classes.PriorityBlue].join(" ");
        }

        // If the above dont return then we return the default grey row color
        return classes.RoleRowItemTop;
    };

    const trialIcon = is_trial ? <span className={classes.TrialIcon}>TRIAL</span> : null;

    const sourcingIcon = sourcing_only ? <span className={classes.SourcingIcon}>Sourcing</span> : null;

    const pendingClosure = role_requests && role_requests.filter(x => x = x.type === "close").length > 0 ?
        <span className={classes.SourcingIcon}>Pending Closure</span> : null;

    const pendingOpen = role_requests && role_requests.filter(x => x = x.type === "open").length > 0 ?
        <span className={classes.SourcingIcon}>Pending Open</span> : null;

    const isConnectedToApollo = props.role.apollo_sequence_id ?
        <React.Fragment>
            <img className={classes.ApolloIcon} src={apolloIcon} alt="apollo-icon" />
            <span style={{color: "#FF0000", fontSize: "14px"}}>{props.role.failedApolloSyncCandidatesCount  > 0 && props.role.apollo_clear_notifications === 0 ? props.role.failedApolloSyncCandidatesCount : ""}</span>
        </React.Fragment>
        : null;

    const isConnectedToGreenhouse = props.role.greenhouse_job ?
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={(
                <Popover id="popover-positioned-right">
                    Connected to Greenhouse.
                </Popover>
            )}>
            <img className={classes.AtsIcon} src={greenhouseIcon} alt="greenhouse-icon" />
        </OverlayTrigger>
        : null;

    const isConnectedToSap = props.role.sap_job ?
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={(
                <Popover id="popover-positioned-right">
                    Connected to SAP SuccessFactors.
                </Popover>
            )}>
            <img className={classes.AtsIcon} src={sapIcon} alt="sap-icon" />
        </OverlayTrigger>
        : null;

    const isConnectedToLever = props.role.lever_job ?
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={(
                <Popover id="popover-positioned-right">
                    Connected to Lever.
                </Popover>
            )}>
            <img className={classes.AtsIcon} src={leverIcon} alt="lever-icon" />
        </OverlayTrigger>
        : null;

    const isConnectedToIcims = props.role.icims_job ?
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={(
                <Popover id="popover-positioned-right">
                    Connected to ICIMS.
                </Popover>
            )}>
            <img className={classes.AtsIcon} src={icimsIcon} alt="icims-icon" />
        </OverlayTrigger>
        : null;

    return (
        <div className={buildRowStyles()} data-id={id} onClick={handleRowClicked}>
            <div className={classes.DropDownIndicator}>
                <FontAwesomeIcon className={chevronClass} icon={faChevronDown}/>
            </div>
            <div className={classes.RoleInfoSection}>
                <div className={classes.RoleNameWrapper}>
                    <div className={classes.RoleName}>
                        {name} - {companyName} {interestTalent} {trialIcon} {sourcingIcon} {pendingClosure} {pendingOpen} {isConnectedToApollo} {isConnectedToSap} {isConnectedToGreenhouse} {isConnectedToLever} {isConnectedToIcims}
                    </div>
                    <div className={classes.OrderText}>Order: HRGH-{dealId} {infoTime} {mondayLinkedIcon}</div>
                </div>
                <div className={classes.AdminArea}>
                    {mode === 'success' &&
                        <AdminBubbleCluster
                            defaultAdmin={admin}
                            talentAdmins={talentAdmins}
                        />
                    }
                </div>
                <div className={classes.Status}>
                    {buildRightMessageArea()}
                </div>
            </div>
        </div>
    )
};

export default React.memo(RoleRowItemTop);
