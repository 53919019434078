import React, {useReducer, Fragment, useEffect, useState} from 'react';
import classes from './AddTalentForm.module.css';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {getRoleInfo} from '../../../store/actions';
import moment from "moment-timezone";
import {postRoleTalent} from '../../../utils/services/turboPlus2RestCalls';
import ScheduledTalentSection from './components/ScheduledTalentSection/ScheduledTalentSection';
import AddTalentSection from './components/AddTalentSection/AddTalentSection';
import ReviewedTalentSection from './components/ReviewedTalentSection/ReviewedTalentSection';
import _ from 'lodash'
import {showToastError,showToastSuccess} from '../../../utils/toastMessages';
import {useDispatch} from 'react-redux';
import {FieldArray, Formik} from 'formik';
import * as yup from 'yup';


const BASE_EMPTY_USER = {
    name: '',
    link: '',
    gender: '',
    isDiverse: ''
};

function reducer(state, action) {
    switch (action.type) {
        case 'talentAdded':
            return {
                ...state,
                talent:[BASE_EMPTY_USER]
            }
        default:
            throw new Error();
    }
}

function localDateTimeToTimeZoneUTC (date){
    /**
     * Leaving this long version since its kinda odd code to follow and why I did it this way.
     * if you use the normal ways to convert from someones local timezone to la, there seems to be some
     * converting of the hours behind the scenes. The below works perfect. AO
     */

        //First we get the local time and strip out any location data by exporting to string.
    const localtimeAsString = moment(date).format("YYYY-MM-DD HH:mm:ss");

    //then we create a new data object with moment in "America/Los_Angeles"
    const laTime = moment.tz(localtimeAsString, "America/Los_Angeles");

    //Last we change that laTime to Utc and save!
    return laTime.utc().format("YYYY-MM-DD HH:mm:ss")
}

const FieldArrayContent = ({form, insert, remove, replace, isSchedule, roleId, role}) => {
        const {values, handleChange, handleBlur, errors, touched, isSubmitting, handleReset} = form;

        // Update isDiverse when a admin selects female as a gender.
        useEffect(() => {
            values.talent.forEach((x,i) => {
                if(x.gender === 'female' && x.isDiverse === ""){
                    const updatedData = {...x, isDiverse: "1"};
                    replace(i, updatedData);
                }
            })
        }, [values.talent]);

        const handleAddEmptyItemToForm = () => {
            insert(values.talent.length, {
                name: '',
                link: '',
                gender: '',
                isDiverse: ''
            });
        }

        const handleRemoveItemFromFormByIndex = (index) => {
            if(values.talent.length > 1){
                remove(index);
            } else {
                replace(0, {
                    name: '',
                    link: '',
                    gender: '',
                    isDiverse: ''
                });
            }
        }

        const handleAddReviewedItemToForm = (talent, isAutoSurfacedTalent = false) => {

            const data = {
                id: talent.id ?? null,
                name: talent.name,
                link: talent.link,
                gender: '',
                isDiverse: '',
                is_auto_surfaced: talent.pivot?.is_auto_surfaced,
                is_recommended: talent.pivot?.is_recommended
            }

            // the !isAutoSurfacedTalent check is important so we don't override auto-surface talent
            // while iterating through them
            if(
                //Check if the first item in the index has been modified.
                !values.talent[0].name &&
                !values.talent[0].link &&
                !values.talent[0].gender &&
                !values.talent[0].isDiverse &&
                !isAutoSurfacedTalent
            ){
                replace(0, data);
            } else {
                // Check if talent is already part of the "add talent and send"
                // stack and if they are, skip adding them a second time!
                if (!values.talent.find(x => x.link === talent.link)) {
                    insert(values.talent.length, data);
                }
            }
        }

        // removes zero index in the event of auto-surfaced talent
        // we make sure the first talent doesn't have assigned name to prevent
        // removing talent other than the initial placeholder (empty talent row that is shown by default)
        const handleRemoveZeroIndex = () => {
            if (!values.talent[0].name) {
                remove(0);
            }
        }

        const handleRemoveReviewedItemFromForm = (link) => {
            handleRemoveItemFromFormByIndex(values.talent.findIndex(x => x.link === link))
        }

        return (
            <Fragment>
                <AddTalentSection
                    role={role}
                    roleId={roleId}
                    talent={values.talent}
                    hasAutoSurfacedTalent={values.talent.filter(x => x.is_auto_surfaced)?.length}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    handleAddEmptyItemToForm={handleAddEmptyItemToForm}
                    handleRemoveItemFromForm={handleRemoveItemFromFormByIndex}
                />

                <ReviewedTalentSection
                    roleId={roleId}
                    arrayOfLinksInUse={values.talent.filter(t=>t.link !== "").map(t => t.link)}
                    handleReviewedTalentAddedToAddList={handleAddReviewedItemToForm}
                    handleReviewedTalentRemovedFromAddList={handleRemoveReviewedItemFromForm}
                    handleRemoveZeroIndex={handleRemoveZeroIndex}
                />

                <ScheduledTalentSection selected={values.scheduled} handleChange={handleChange}/>

                <Row className={classes.SubmitRow}>
                    <Col xs={12} lg={3} lgOffset={7}>
                        <Button
                            disabled={isSubmitting}
                            bsStyle="success"
                            block
                            type="submit"
                        >
                            {isSchedule ? "Schedule and Add Talent" : "Add Talent and Send"}
                        </Button>
                    </Col>
                    <Col xs={12} lg={2}>
                        <Button
                            disabled={isSubmitting}
                            bsStyle="danger"
                            onClick={handleReset}
                            block>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }

const AddTalentForm = props => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, {});
    const {role, roleId, isWaiting} = props;

    const handleSubmit = (values, form) => {

        if (isWaiting) {
            showToastError("You can't add any talent if a role is Waiting for Setup", []);
            form.setSubmitting(false)
            return;
        }

        let payLoad = {
            talent: values.talent.map(x => {
                let {id, gender, isDiverse, ...talent} = x;

                if(gender !== '' && gender !== 'select') {
                    talent = {...talent, gender}
                }

                if(isDiverse !== '') {
                    talent = {...talent, is_diverse: isDiverse}
                }

                return talent;
            })
        };

        if(values.scheduled){
            payLoad = {
                ...payLoad,
                scheduled_at: localDateTimeToTimeZoneUTC(values.scheduled)
            }
        }

        postRoleTalent(props.roleId, payLoad)
            .then(() => {
                dispatch({type: 'talentAdded'});
                showToastSuccess("Talent added to role");
                dispatchRedux(getRoleInfo(props.roleId));
                form.resetForm();
            })
            .catch(e => {
                const message = e.message ? e.message: e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)): [];
                showToastError(message, errors);
                form.setSubmitting(false)
            });
    };

    const schema = yup.object().shape({
        talent: yup.array().of(
            yup.object().shape(
                {
                    name: yup
                        .string()
                        .trim('Remove extra spaces.')
                        .strict()
                        .min(3, "Must be over 3 chars.")
                        .max(100, "Must be under 100 chars.")
                        .required("Must be between 3 and 100 chars."),

                    link: yup
                        .string()
                        .trim('Remove extra spaces.')
                        .strict()
                        .url("Must be a valid url")
                        .matches(/^https:\/\/*/, "Must be a secure url(https).")
                        .required("Must be between 3 and 100 chars."),
                }
            )
        )
    });

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={schema}
            initialValues={{
                talent: [BASE_EMPTY_USER],
                scheduled: ''
            }}
        >
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <FieldArray name="talent">
                        {(props) =>
                            <FieldArrayContent
                                {...
                                    {...props, role, roleId,
                                        isSchedule: state.isSchedule}}/>
                        }
                    </FieldArray>
                </Form>
            )}
        </Formik>
    )
};

export default AddTalentForm;