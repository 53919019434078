import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from  './Auth.module.css';
import * as actions from '../../store/actions';
import IconInput from '../../components/Ui/Inputs/IconInput/IconInput';
import Button from './../../components/Ui/Buttons/Button';
import Aux from '../../hoc/AuxWrapper/AuxWrapper';
import {Alert} from 'react-bootstrap';

class Auth extends Component {

    state = {
        email: "",
        password: "",
        newPassword: "",
        emailVerification: '',
        submitDisabled: false
    }

    inputChangeHandler = (event, type) => {
        let updateValue = {};
        switch (type) {
            case "email":
                updateValue = {email: event.target.value}
                break;
            case 'password':
                updateValue = {password: event.target.value}
                break;
            case 'newPassword':
                updateValue = {newPassword: event.target.value}
                break;
            case 'emailVerification':
                updateValue = {emailVerification: event.target.value}
                break;
            default:
                break;
        }

        this.setState(updateValue);
    }

    signInSubmitHandler = event => {

    };

    emailVerificationSubmitHandler = event => {
        event.preventDefault();
        this.props.onEmailVerification(this.props.username, this.state.emailVerification);
    };

    submitForm = (event) => {
        event.preventDefault();
        this.props.onSignInAuth(this.state.email, this.state.password, this.state.newPassword);
    }

    render () {
        let formInputs = null;

        if(!this.props.username){
            formInputs = (
                <Aux>
                    <IconInput
                        required
                        inputLabel="Email"
                        placeholderText=""
                        inputId="email"
                        fontAwesomeClass="fa fa-envelope"
                        inputType="email"
                        inputValue={this.state.email}
                        inputOnChange={(event)=> {this.inputChangeHandler(event, 'email')}} />

                    <IconInput
                        required
                        inputLabel="Password"
                        placeholderText=""
                        inputId="password"
                        fontAwesomeClass="fa fa-key"
                        inputType="password"
                        inputValue={this.state.password}
                        inputOnChange={(event)=> {this.inputChangeHandler(event, 'password')}} />
                </Aux>
            );
        }

        if(this.props.passwordReset) {
            formInputs = (
                <Aux>
                    <IconInput
                        inputLabel="Email"
                        placeholderText=""
                        inputId="email"
                        fontAwesomeClass="fa fa-envelope"
                        inputType="text"
                        inputValue={this.state.email}
                        inputOnChange={(event)=> {this.inputChangeHandler(event, 'email')}} />

                    <IconInput
                        inputLabel="Password"
                        placeholderText=""
                        inputId="password"
                        fontAwesomeClass="fa fa-key"
                        inputType="password"
                        inputValue={this.state.password}
                        inputOnChange={(event)=> {this.inputChangeHandler(event, 'password')}} />

                    <IconInput
                        inputLabel="New Password"
                        placeholderText=""
                        inputId="newPassword"
                        fontAwesomeClass="fa fa-key"
                        inputType="password"
                        inputValue={this.state.newPassword}
                        inputOnChange={(event)=> {this.inputChangeHandler(event, 'newPassword')}} />
                </Aux>
            );
        }

        if(this.props.username && !this.props.userConfirmed){
            formInputs = (
                <Aux>
                    <IconInput
                        inputLabel="Email Code"
                        inputId="email"
                        fontAwesomeClass="fa fa-envelope"
                        inputType="text"
                        inputValue={this.state.emailVerification}
                        inputOnChange={ event => {this.inputChangeHandler(event, 'emailVerification')}} />
                </Aux>
            );
        }

        return (
            <div className={classes.Auth}>
                <h1 className={classes.Header}>Sign into rolebot</h1>
                <div className={classes.FormWrapper}>
                    { this.props.error &&
                        <Alert bsStyle="danger">
                            <div>
                                {this.props.error}
                            </div>
                        </Alert>
                    }
                    <form className={classes.Form} onSubmit={this.submitForm}>
                        {formInputs}
                        <Button disabled={this.state.submitDisabled}  type='submit'>
                            {this.props.loading? 'Loading..': 'Sign in'}
                        </Button>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
        username: state.auth.username,
        userConfirmed: state.auth.userConfirmed,
        loading: state.auth.loading,
        passwordReset: state.auth.passwordReset,
        error: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSignInAuth: (email, password, newPassword) => dispatch(actions.authSignIn(email, password, newPassword)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
