import React, { Fragment, useReducer, useMemo, useEffect } from 'react';
import NewAdvanceTextInput from "../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import { patchCompanyItem, getCompanyContactOutPlansRequest, getCompanyCardInfoRequest, getTypeOfPlan } from '../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFirstRender } from '../../../../../hooks/useFirstRender';
import FormEditButtonArea from "../../../../Ui/Inputs/FormEditButtonArea/FormEditButtonArea";
import NewAdvanceDateTimePicker from "../../../../Ui/Inputs/NewAdvanceDateTimePicker/NewAdvanceDateTimePicker";
import {
    companyNameValidation,
    companyWebsiteValidation,
    companyNotesValidation,
    isSuccessOrNull
} from '../../../../../utils/validation';
import { Col, OverlayTrigger, Popover,FormGroup, Checkbox, FormControl,ControlLabel } from 'react-bootstrap';
import moment from 'moment';
import BootstrapRowColCombo from "../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewAdvanceDropdown from "../../../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import NewAdvanceSliderButton from "../../../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
import NewTitleHeader from "../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { getMondayBoardsDd } from '../../../../../store/actions/roles';
import { SearchIndustry } from '../CompanyIndustrySearch/SearchIndustry';
import useDebounce from "../../../../../hooks/useDebounce";
import {getIndustries} from "../../../../../utils/services/turboPlus2RestCalls";
import classes  from './CompanyInfoForm.module.css';

const init = company => {
    return {
        name: company?.name,
        url: company?.url ? company.url : '',
        notes: company?.notes ? company.notes : '',
        enableContactOut: true,
        enableRocketReach: true,
        forceCollectCard: company?.force_collect_card,
        adminId: company?.admin_id ? company.admin_id.toString() : '',
        inEditMode: false,
        loading: false,
        submitDisabled: true,
        activeRoleLimit: company?.active_role_limit ? company.active_role_limit.toString() : '',
        planStartDate: company?.plan_start_date ? moment.utc(company.plan_start_date).toDate() : null,
        planEndDate: company?.plan_end_date ? moment.utc(company.plan_end_date).toDate() : null,
        contactOutCreditsUsed: company?.contactOutCreditsUsed ? company?.contactOutCreditsUsed : company?.contact_plans && company?.contact_plans?.length ? company.contact_plans[0].credits_used.toString() : '0',
        rocketReachCreditsUsed: company?.rocketReachCreditsUsed ? company?.rocketReachCreditsUsed : company?.rocket_reach_plans && company?.rocket_reach_plans.length ? company.rocket_reach_plans[0].credits_used.toString() : '0',
        card_info: company?.card_info ? company?.card_info : null,
        validation: {
            nameValidState: null,
            urlValidState: null,
            notesValidState: null,
            validateName: false,
            validateUrl: false,
            validateNotes: false,
        },
        typeOfPlan: company?.role_type_id ? company.role_type_id : company.role_type.id,
        defaultMondayBoard: company?.default_monday_board_id ? company.monday_board.id : "",
        defaultPaymentMethod: company?.invoice ? 'inv' : 'cc',
        industry: company?.industry ? company.industry.name : '',
        industry_id: company?.industry ? company.industry.id : '',
        industries: [],
        searchEnabled: true,
        displayIndustriesDropdown: false,
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'nameChanged': {
            const isValid = companyNameValidation(action.payload);
            return {
                ...state,
                name: action.payload,
                validation: {
                    ...state.validation,
                    nameValidState: isValid ? 'success' : 'error',
                    validateName: true
                }
            }
        }
        case 'urlChanged': {
            const isValid = companyWebsiteValidation(action.payload)
            return {
                ...state,
                url: action.payload,
                validation: {
                    ...state.validation,
                    urlValidState: isValid ? 'success' : 'error',
                    validateUrl: true
                }
            }
        }
        case 'notesChanged': {
            const isValid = companyNotesValidation(action.payload);
            return {
                ...state,
                notes: action.payload, validation: {
                    ...state.validation,
                    notesValidState: isValid ? 'success' : 'error',
                    validateNotes: true
                }

            }
        }
        case 'editEnabled':
            return {
                ...state,
                inEditMode: true,
            }
        case 'loading':
            return {
                ...state,
                loading: true
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'activeRoleLimitChanged':
            return {
                ...state,
                activeRoleLimit: action.payload
            }
        case 'planStartDateChanged':
            return {
                ...state,
                planStartDate: action.payload
            }
        case 'planEndDateChanged':
            return {
                ...state,
                planEndDate: action.payload
            }
        case 'accountManagerChanged':
            return {
                ...state,
                adminId: action.payload
            }
        case 'enableContactOutChanged':
            return {
                ...state,
                enableContactOut: action.payload
            }
        case 'enableRocketReachChanged':
            return {
                ...state,
                enableRocketReach: action.payload
            }
        case 'forceCollectCardChanged':
            return {
                ...state,
                forceCollectCard: action.payload
            }
        case 'typeOfPlanChanged':
            return {
                ...state,
                typeOfPlan: action.payload
            }
        case 'mondayBoardChanged':
            return {
                ...state,
                defaultMondayBoard: action.payload
            }
        case 'paymentMethodChanged':
            return {
                ...state,
                defaultPaymentMethod: action.payload
            }
        case 'industryChanged':
            return {
                ...state,
                industry: action.payload
            }
        case 'isIndustryDefaultChanged':
            return {
                ...state,
            }
        case 'SearchValueChanged':
            return {
                ...state,
                industry: action.payload,
                displayIndustriesDropdown: true,
            }
        case 'receivedIndustries':
            return {
                ...state,
                industries: action.payload,
                displayIndustriesDropdown: true,
            };
        case 'handleIndustrySelect':
            return {
                ...state,
                industry: action.payload.name,
                industry_id: action.payload.id,
                displayIndustriesDropdown: false,
                searchEnabled: false,
            }
        case 'enableSearch':
            return {
                ...state,
                searchEnabled: action.payload,
                displayApolloSequencesDropdown: action.payload
            }
        case "clickedOutsideOfDropdown":
            return {
                ...state,
                displayIndustriesDropdown: action.payload.displayIndustriesDropdown
            };
        case 'reset':
            return init(action.payload);
        default:
            throw new Error();
    }
}

const CompanyInfoForm = () => {
    const dispatchRedux = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const admins = useSelector(state => state.globalLists.admins);
    const company = useMemo(() => companies.find(x => x.id === companyId), [companies]);
    const [state, dispatch] = useReducer(reducer, company, init);
    const isFirstRender = useFirstRender();
    const typeOfPlans = useSelector(state => state.roles.typeOfPlans)
    const mondayBoardsDropdown = useSelector(state => state.roles.boards)
    const paymentMethodsDropdown = [{id: 'inv', name: "Invoice"},{id: 'cc', name: "Credit Card"}]
    const searchDebounced = useDebounce(state.industry, 500);

    useEffect(() => {
        if (!isFirstRender) {
            dispatch({ type: 'reset', payload: company });
        }
    }, [company])

    useEffect(() => {
        const { nameValidState, urlValidState, notesValidState } = state.validation;
        if (isSuccessOrNull(nameValidState) && isSuccessOrNull(urlValidState) && isSuccessOrNull(notesValidState)) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [state.validation]);

    useEffect(() => {
        dispatchRedux(getCompanyContactOutPlansRequest(companyId));
        dispatchRedux(getCompanyCardInfoRequest(companyId));
        dispatchRedux(getTypeOfPlan())
        dispatchRedux(getMondayBoardsDd())
    }, [])

    useEffect(() => {
        if (!isFirstRender){
            getIndustries({params: {'search-term': state.industry}})
                .then((response) => {
                    dispatch({type: 'receivedIndustries', payload: response})
                .catch((error) => {
                    console.log(error)
                });
            });
        }
        
    }, [searchDebounced])


    const handleNameChanged = (e) => {
        dispatch({
            type: 'nameChanged',
            payload: e.currentTarget.value
        });
    }

    const handleUrlChanged = (e) => {
        dispatch({
            type: 'urlChanged',
            payload: e.currentTarget.value
        });
    }

    const handleNotesChanged = (e) => {
        dispatch({
            type: 'notesChanged',
            payload: e.currentTarget.value
        });
    }

    const handleAccountManagerChanged = (e) => {
        dispatch({ type: 'accountManagerChanged', payload: e.currentTarget.value });
    }

    const handleEditEnabled = () => {
        dispatch({ type: 'editEnabled' });
    }

    const handleEditCanceled = () => {
        dispatch({ type: 'reset', payload: company });
    }

    const handlePlanStartDateChanged = (value) => {
        dispatch({ type: 'planStartDateChanged', payload: value });
    }

    const handlePlanEndDateChanged = (value) => {
        dispatch({ type: 'planEndDateChanged', payload: value });
    }

    const handleActiveRoleLimitChanged = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            dispatch({ type: 'activeRoleLimitChanged', payload: value });
        }
    }

    const handleEnableContactOutChanged = (value) => {
        dispatch({ type: 'enableContactOutChanged', payload: value });
    }

    const handleEnableRocketReachChanged = (value) => {
        dispatch({ type: 'enableRocketReachChanged', payload: value });
    }

    const handleForceCollectCardChanged = (value) => {
        dispatch({ type: 'forceCollectCardChanged', payload: value });
    }

    const handleTypeOfPlanChanged = (e) => {
        dispatch({ type: 'typeOfPlanChanged', payload: e.currentTarget.value });
    }

    const handleMondayBoardChanged = (e) => {
        dispatch({ type: 'mondayBoardChanged', payload: e.currentTarget.value });
    }

    const handlePaymentMethodChanged = (e) => {
        dispatch({ type: 'paymentMethodChanged', payload: e.currentTarget.value });
    }


    const handleIndustrySelect = (id,name) => {
        dispatch({ type: 'handleIndustrySelect', payload: {id,name} });
    }

    const handleSearchChange = (e) => {
        const searchValue =  e.currentTarget.value;

        dispatch({ type: "SearchValueChanged", payload: searchValue });

        if (searchValue.trim() === "") {
            dispatch({ type: 'handleIndustrySelect', payload: {industry_id:null, name:null} });
        } else {
            dispatch({ type: 'enableSearch', payload: true });
        }
    }

    const handleFocusOnSearchText = () => {
        dispatch({type: "enableSearch", payload:true});
    }

    const handleClickedOutside = () => {
        dispatch({type: "clickedOutsideOfDropdown", payload: {displayIndustriesDropdown:false}});
    }

    const handleSubmitChanges = () => {
        dispatch({ type: 'loading' });

        const payload = {
            name: state.name,
            url: state.url === "" ? null : state.url,
            notes: state.notes === "" ? null : state.notes,
            active_role_limit: state.activeRoleLimit === "" ? null : state.activeRoleLimit,
            plan_start_date: state.planStartDate === "" ? null : state.planStartDate,
            plan_end_date: state.planEndDate === "" ? null : state.planEndDate,
            admin_id: state.adminId ? parseInt(state.adminId, 10) : null,
            enable_contact_out: state.enableContactOut,
            enable_rocket_reach: state.enableRocketReach,
            force_collect_card: state.forceCollectCard,
            role_type_id: parseInt(state.typeOfPlan, 10),
            monday_board_id: state.defaultMondayBoard,
            invoice : state.defaultPaymentMethod === 'inv' ? true : false,
            industry_id: parseInt(state.industry_id, 10)
        };

        let extraData = {
            card_info: state.card_info,
            contactOutCreditsUsed: state.contactOutCreditsUsed,
            rocketReachCreditsUsed: state.rocketReachCreditsUsed
        }

        dispatchRedux(patchCompanyItem(
            company.id,
            payload,
            extraData
        ));
    }

    return <Fragment>
        <FormEditButtonArea
            editDisabled={state.loading}
            inEditMode={state.inEditMode}
            onEditClicked={handleEditEnabled}
            onCancelClicked={handleEditCanceled}
            onSaveClicked={handleSubmitChanges}
            saveDisabled={state.submitDisabled}
        />
        <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Basic Company Info" />
            </Col>
        </BootstrapRowColCombo>
        <BootstrapRowColCombo>
            <Col xs={6}>
                <NewAdvanceTextInput
                    loading={state.loading}
                    onChange={handleNameChanged}
                    value={state.name}
                    disabled={!state.inEditMode}
                    validationState={state.validation.nameValidState}
                    label="Company Name" />
            </Col>
            <Col xs={6}>
                <NewAdvanceTextInput
                    loading={state.loading}
                    onChange={handleUrlChanged}
                    value={state.url}
                    disabled={!state.inEditMode}
                    validationState={state.validation.urlValidState}
                    label="Website URL" />
            </Col>
        </BootstrapRowColCombo>
        <BootstrapRowColCombo>
            <Col xs={12}>
                <NewAdvanceTextInput
                    loading={state.loading}
                    onChange={handleNotesChanged}
                    value={state.notes}
                    disabled={!state.inEditMode}
                    validationState={state.validation.notesValidState}
                    helpText="Max 1000 chars"
                    label="Notes:" />
            </Col>
        </BootstrapRowColCombo>
        <BootstrapRowColCombo>
            <Col xs={12} lg={6}>
                <NewAdvanceDropdown
                    disabled={!state.inEditMode}
                    options={admins.filter(x => x.is_account_manager === true).map(x => ({ id: x.id, name: x.user.name }))}
                    value={state.adminId}
                    onChange={handleAccountManagerChanged}
                    placeholder="Select"
                    label="Account manager" />
            </Col>
            <Col xs={12} lg={6}>
                <FormGroup >
                    <ControlLabel>Company Industry</ControlLabel>
                    <FormControl
                        type="text"
                        value={state.industry}
                        placeholder="Search Industry"
                        disabled={!state.inEditMode}
                        onChange={handleSearchChange}
                        onFocus={handleFocusOnSearchText}
                    />
                    {state.displayIndustriesDropdown && state.searchEnabled &&
                     <SearchIndustry
                        industries={state.industries}
                        handleIndustrySelect={handleIndustrySelect}
                        clickedOutSide={handleClickedOutside}
                    />}
                        
                </FormGroup>
                <div className={classes.industryMessage}>
                    Adding a company industry will make it the default and prioritize that industry for candidate recommendations across all roles
                </div>
            </Col>

        </BootstrapRowColCombo>

        
        <br /><br />

        {/* <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Company Plan" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col xs={6}>
                <NewAdvanceTextInput
                    loading={state.loading}
                    onChange={handleActiveRoleLimitChanged}
                    value={state.activeRoleLimit}
                    disabled={!state.inEditMode}
                    label="Active Role Limit" />
            </Col>
            <Col xs={6}>
                <NewAdvanceDropdown
                    disabled={!state.inEditMode}
                    options={typeOfPlans}
                    value={state.typeOfPlan}
                    onChange={handleTypeOfPlanChanged}
                    label="Default Role Type" />
            </Col>
        </BootstrapRowColCombo>


        <BootstrapRowColCombo>
            <Col xs={6}>
                <NewAdvanceDateTimePicker
                    disabled={!state.inEditMode}
                    label="Plan Start Date"
                    value={state.planStartDate}
                    onChange={handlePlanStartDateChanged}
                />
            </Col>
            <Col xs={6}>
                <NewAdvanceDateTimePicker
                    disabled={!state.inEditMode}
                    value={state.planEndDate}
                    label="Plan End Date"
                    onChange={handlePlanEndDateChanged}
                />
            </Col>
        </BootstrapRowColCombo>

        <br/><br/> */}

        <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Monday Board" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col xs={6}>
                <NewAdvanceDropdown
                    disabled={!state.inEditMode}
                    options={mondayBoardsDropdown}
                    value={state.defaultMondayBoard}
                    onChange={handleMondayBoardChanged}
                    placeholder="-- SELECT --"
                    label="Default Monday Board" />
            </Col>
        </BootstrapRowColCombo>
        <br /><br />

        {/* <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Payment Method" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col xs={6}>
                <NewAdvanceDropdown
                    disabled={!state.inEditMode}
                    options={paymentMethodsDropdown}
                    value={state.defaultPaymentMethod}
                    onChange={handlePaymentMethodChanged}
                    placeholder="-- SELECT --"/>
            </Col>
        </BootstrapRowColCombo>

        {state.defaultPaymentMethod === 'cc' && (<><BootstrapRowColCombo>
            <Col xs={12} lg={4}>
                <NewAdvanceSliderButton
                    value={state.forceCollectCard}
                    onChange={handleForceCollectCardChanged} label={(<>Collect Card
                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="right"
                            overlay={(
                                <Popover id="popover-positioned-right" title="Force Collect Card">
                                    Checking this will force the user to submit a card. Can only be used if <strong style={{ color: "red" }}>ALL</strong> the users added to the role are
                                    missing card data.
                                </Popover>
                            )}>
                            <FontAwesomeIcon style={{ marginLeft: ".5rem" }} icon={faQuestionCircle} />
                        </OverlayTrigger>
                    </>)} disabled={!state.inEditMode} />
            </Col>
        </BootstrapRowColCombo>
        {state.card_info &&
            <BootstrapRowColCombo style={{ backgroundColor: "#e5e5e5", backgroundClip: "content-box" }}>
                <Col lg={12}>
                    <h5 style={{ fontWeight: "bold" }}>Name: <span style={{ marginLeft: 10 }}>{state.card_info ? state.card_info.cardholder_name : ''}</span></h5>
                </Col>
                <Col lg={6}>
                    <h4><FontAwesomeIcon icon={faCreditCard} /><span style={{ marginLeft: 10 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 10 }}>
                        {state.card_info ? state.card_info.card_last_four ? state.card_info.card_last_four.toString().padStart(4, '0') : '' : ''}</span></h4>
                </Col>
                <Col lg={6} style={{ textAlign: "end" }}>
                    <h5><span style={{ fontWeight: "bold" }}>Stripe ID: </span>{state.card_info ? state.card_info.stripe_id : ''}</h5>
                </Col>
            </BootstrapRowColCombo>
        }</>)}

        {state.card_info && <br />} */}

        <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Contact Out Integration" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col xs={12} lg={2}>
                <NewAdvanceSliderButton
                  value={state.enableContactOut}
                  hideSlider={true}
                  onChange={handleEnableContactOutChanged} label="Enabled" disabled={!state.inEditMode} />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo xs={12}>
            <Col xs={12} lg={2}>
                <NewAdvanceTextInput
                  loading={state.loading}
                  onChange={() => { }}
                  value={state.contactOutCreditsUsed}
                  disabled={true}
                  label="Credits Used" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col lg={6}>
                <NewTitleHeader title="Rocket Reach Integration" />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo>
            <Col xs={12} lg={2}>
                <NewAdvanceSliderButton
                  value={state.enableRocketReach}
                  hideSlider={true}
                  onChange={handleEnableRocketReachChanged} label="Enabled" disabled={!state.inEditMode} />
            </Col>
        </BootstrapRowColCombo>

        <BootstrapRowColCombo xs={12}>
            <Col xs={12} lg={2}>
                <NewAdvanceTextInput
                  loading={state.loading}
                  onChange={() => { }}
                  value={state.rocketReachCreditsUsed}
                  disabled={true}
                  label="Credits Used" />
            </Col>
        </BootstrapRowColCombo>
    </Fragment>
};

export default React.memo(CompanyInfoForm);
