import Echo from "laravel-echo";
import Pusher from "pusher-js"; //eslint-disable-line

let OPTIONS = [];

if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === "false" || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
  OPTIONS = {
    broadcaster: "pusher",
    scheme: process.env.REACT_APP_PUSHER_SCHEME,
    wsHost: process.env.REACT_APP_PUSHER_HOST,
    wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
    wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_APP_CLUSTER,
    forceTLS: process.env.REACT_APP_PUSHER_FORCE_TSL === "true",
    encrypted: true,
  };
  //   console.log('Echo options::', OPTIONS);
}

export default new Echo(OPTIONS);
