import React, {useRef, useEffect, useState} from 'react';
import classes from './PinkFilter.module.css';
import useOutsideAreaClick from '../../../hooks/useOutsideAreaClick';
import PinkPopup from "./components/PinkPopup/PinkPopup";
import PropTypes from 'prop-types';

const PinkFilter = (props) => {
    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);
    const [isOpen,setIsOpen] = useState(false);

    useEffect(() => {
        if(clickedOutside && isOpen){
            setIsOpen(false);
        }
    }, [clickedOutside, isOpen])

    const handleButtonCLicked = () => {
        if(isOpen){
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }

    let buttonClass = classes.Button;

    if (!props.isActive && !isOpen) {
        buttonClass = props.isLookalike ? classes.ButtonLookalike : classes.Button;
    } else {
        buttonClass = [props.isLookalike ? classes.ButtonLookalike : classes.Button, classes.active].join(" ");
    }

    if (props.disabled) {
        buttonClass = classes.DisabledButton;
    }

    const leftMargin = `-${props.negativeMargin}px`;

    return (
        <div className={classes.RoleFilterBox} ref={wrapperRef}>
            <div className={buttonClass} onClick={handleButtonCLicked}>
                {props.button}
            </div>
            {isOpen && <PinkPopup style={{left: leftMargin}}>{props.children}</PinkPopup>}
        </div>
    )
};

export default PinkFilter;

PinkFilter.propTypes = {
    isActive: PropTypes.bool,
    button: PropTypes.element.isRequired,
    children: PropTypes.node,
    negativeMargin: PropTypes.number,
    disabled: PropTypes.bool,
    isLookalike: PropTypes.bool
}

PinkFilter.defaultProps = {
    isActive: false,
    negativeMargin: 0,
    disabled: false,
    isLookalike: false
}