import {
    GET_COMPANY_METRICS_REQUEST, GET_COMPANY_METRICS_SUCCESS, GET_ROLE_METRICS_SUCCESS,
} from "./actionsTypes";
import {getCompanyMetrics} from "../../utils/services/turboPlus2RestCalls";


export const getCompanyMetricsRequest = (companyId, payload={}) => {
    return dispatch => {
        dispatch({type: GET_COMPANY_METRICS_REQUEST});
        getCompanyMetrics(companyId, payload)
            .then(response => {
                dispatch({type: GET_COMPANY_METRICS_SUCCESS, payload: response.company_metrics});
                dispatch({type: GET_ROLE_METRICS_SUCCESS, payload: response.role_metrics});
            })
            .catch(e => console.log(e));
    }
}