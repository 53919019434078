import React, {useEffect, useState} from 'react';
import classes from "../../ReviewedTalentSection.module.css";
import {Panel} from "react-bootstrap";
import ReviewTalentHeader from './components/ReviewTalentHeader/ReviewTalentHeader';
import EditTalentProspectForm
    from "../../../../../RoleDeliveryDropDownWrapper/components/ReviewTalentSection/components/TalentProspectItemWrapper/component/EditTalentProspectForm/EditTalentProspectForm";

const ReviewTalentItem = props => {

    const {id} = props.talent;

    const [isOpen, setIsOpen] =useState(false)

    const OnPanelToggle = (isPanelOpen) => {
        setIsOpen(isPanelOpen);
    };

    return (
        <Panel
            id={id.toString()} className={classes.Panel}  onToggle={OnPanelToggle} bsClass={classes.ReviewedTalentSection}>
            <Panel.Heading componentClass={() => <ReviewTalentHeader
                handleReviewedTalentAddedToAddList={props.handleReviewedTalentAddedToAddList}
                handleReviewedTalentRemovedFromAddList={props.handleReviewedTalentRemovedFromAddList}
                talent={props.talent}
                addedToAddTalentForm={props.addedToAddTalentForm}
                mode={props.filter}
                count={props.count}/>
            }/>
            <Panel.Collapse>
                <Panel.Body>
                    <div className={classes.ItemWrapper}>
                        <EditTalentProspectForm
                            isOpen={isOpen} talent={props.talent} updateTalentForReview={props.updateTalentForReview}/>
                    </div>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    )
};

export default ReviewTalentItem;