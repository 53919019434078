/** validation rules */

export const isRoleNameValid = (roleName) => {
    return /^.{3,100}$/.test(roleName);
}

export const isCompanyNameValid = (companyName) => {
    return /^.{3,100}$/.test(companyName);
}

export const isTotalInterviewsValid = (interviewCount) => {
    return /^[0-9]{1,25}$/.test(interviewCount);
}

export const isTotalPriceValid = (interviewPrice) => {
    return /^[0-9]+\.[0-9]{2}$/.test(interviewPrice);
}

export const isEmailValid = (email) => {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

export const isEmailAddedBefore = (email, users) => {
    return users.find(x => x.email === email);
}

export const isNotesValid = (notes) => {
    return /^.{1,7000}/.test(notes);
};

export const isProfileValid = (profile) => {
    return /^https?:\/\/.+\..+/.test(profile);
};

export const hasOneChar = (profile) => {
    return /^.+$/gm.test(profile);
};

export const isNumber = (number) => {
    return /^[0-9]+$/.test(number);
};

export const isDollarAmount = (price) => {
    return /^[0-9]+\.[0-9]{2}$/.test(price);
};

export const isCentsDollarAmount = value => {
    return /^[0-9]{3,}$/.test(value);
};

export const isInvoiceValid = (state) => {
    // pass if is invoice OR force card WITH a billable user id
    return !!(state.invoice || (state.force_card && state.billable_user_id));
};

export const isForceCardValid = (state) => {
    return (state.force_card && state.billable_user_id) || state.invoice;
}


/** validation checks using the rules above */


/** Helpers */
export const inputNullToEmptyString = value => {
    return value === null ? "" : value;
};

