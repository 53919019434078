import React, {} from 'react';
import classes from './PostItView.module.css';

const PostItView = props => {
    return (
        <pre className={classes.PostItView}>
            <div className={classes.RoleHeader}>{props.title}</div>
            {props.children}
        </pre>
    );
};

export default PostItView;