import React, { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';
import RoleRowTopButtonRow from "../RoleRowTopButtonRow/RoleRowTopButtonRow";
import RoleInfoSection from "../RoleInfoSection/RoleInfoSection";
import { Col, Row } from "react-bootstrap";
import PostItView from "../PostItView/PostItView";
import MondayRoleLinker from "../MondayRoleLinker/MondayRoleLinker";
import ApolloRoleLinker from "../ApolloRoleLinker/ApolloRoleLinker";
import DeleteSection from "../DeleteSection/DeleteSection";
import TurboPlus2Loader from "../TurboPlus2Loader/TurboPlus2Loader";
import WaitingForCustomer from "../SpashScreens/WaitingForCustomer/WaitingForCustomer";
import TalentWrapper from "../TalentWrapper/TalentWrapper";
import TalentRowItem from "../TalentRowItem/TalentRowItem";
import EmptyTalentListScreen from "../SpashScreens/EmptyTalentListScreen/EmptyTalentListScreen";
import {
    addUserCard,
    addUserToRole,
    cancelInterview,
    deleteFollowUp,
    deleteTalent, getAdmins,
    getRoleById,
    getRoleSurfaceDates,
    getRoleTalent,
    patchCompanyUser,
    patchRole,
    patchRoleInfo,
    patchRoleProfile,
    patchTalent,
    postAdmin,
    postTalentAdmin,
    refundInterviews,
    removeUserFromRole,
    sendInfoAdminReminders,
    sendTalentAdminReminders
} from "../../../utils/services/turboPlus2RestCalls";
import { showToastError, showToastSuccess } from "../../../utils/toastMessages";
import _ from "lodash";
import ExportTalentModal from "./components/ExportTalentModal/ExportTalentModal";
import useTalentToTypesFilter from "../../../hooks/useTalentToTypesFilter";
import { useDispatch, useSelector } from 'react-redux';
import { getRoleInfo } from '../../../store/actions';
import PlanStatusSection from "../PlanStatusSection/PlanStatusSection";
import moment from 'moment-timezone';
import RoleHistorySection from "../RoleHistorySection/RoleHistorySection";

const DEFAULT_FILTERS = {
    accepted: false,
    interviews: false,
    unavailable: false,
    rejected: false,
    scheduled: false,
    waiting: false,
    skipped: false
};

const init = () => {
    return {
        role: [],
        // searchName: props.searchName,
        isLoading: true,

        admins: [],

        // add talent section
        addTalentLoading: false,
        addTalentError: false,

        // Talent filter status: all, waiting, accepted, notAccepted, scheduled, rejected, interviews
        // Used for the drop down
        talentFilter: 'all',

        // Enabled Filters
        filters: DEFAULT_FILTERS,

        talent: [],
        pendingTalent: [],
        addTalentPanelOpen: false,
        talentRowOpen: null,
        deleteOpen: false,

        // info area
        infoAreaVisible: true,
        infoPanelOpen: false,

        isOnHoldLoading: false,

        roleUpdating: false,

        //exportTalent
        exportTalentModalOpen: false,

        surfaceDates: [],
        talentSurfacedDate: null,

        //history Section
        showHistorySection: false,

        mondayBoardId: null
    };
};

function reducer(state, action) {
    switch (action.type) {
        case 'modeChanged':
            return {
                ...state,
                mode: action.payload
            };
        case 'isLoading':
            return {
                ...state,
                isLoading: true
            };
        case 'roleUpdating':
            return {
                ...state,
                roleUpdating: true
            };
        case 'receivedRole':
            let boardId = action.payload.monday_group?.monday_board?.board_id

            //For turnkey roles, we use the default monday board. If it's sourcing only, null will do.
            if ( !boardId && !action.payload.sourcing_only ) {
                boardId = state.role.company?.monday_board?.board_id;
            }

            return {
                ...state,
                role: action.payload,
                isOnHoldLoading: false,
                mondayBoardId: boardId
            };
        case 'roleUpdated':
            return {
                ...state,
                role: { ...state.role, ...action.payload },
                isOnHoldLoading: false,
                roleUpdating: false
            };
        case 'adminsReturned':
            return {
                ...state,
                admins: action.payload
            };
        case 'roleRoleInfoUpdated':
            return {
                ...state,
                role: { ...state.role, info: action.payload }
            };
        case 'roleProfileUpdated':
            return {
                ...state,
                role: { ...state.role, profiles: action.payload }
            };
        case 'fetchingRole':
            return {
                ...state,
                isLoading: true,
                // talent: [],
            };
        case 'fetchingTalent':
            return {
                ...state,
                isLoading: true,
                addTalentLoading: true
            };
        case 'receivedTalent': {
            // TODO: Move this to the Backend for speed!!
            // if state.searchName we filter those with that name out.
            // const talent = state.searchName === '' ?
            //     action.payload :
            //     action.payload.filter(x => x.name.toLowerCase().includes(state.searchName.toLowerCase()));

            return {
                ...state,
                isLoading: false,
                talent: action.payload,
                addTalentLoading: false,
                addTalentError: false,
                addTalentPanelOpen: false
            };
        }
        case 'receivedPendingTalent':
            return {
                ...state,
                pendingTalent: action.payload
            };
        case 'adminAddedToRole':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'adminMadeDefaultPayer':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'adminUpdated':
            return {
                ...state,
                talent: state.talent.map(x => {
                    if (x.id === action.payload.talentId) {
                        return {
                            ...x,
                            ...action.payload.talent,
                        }
                    }
                    return x;
                })
            };
        case 'userAddedToRole':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'userDataUpdated':
            const users = state.role.users.map(x => x.id === action.payload.id ? action.payload : x);
            return {
                ...state,
                role: {
                    ...state.role,
                    users
                }
            };
        case 'removedRoleUser':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'cardAddedToUser':
            return {
                ...state,
                role: {
                    ...state.role,
                    users: state.role.users.map(x => {
                        if (x.id === action.payload.id) {
                            return action.payload;
                        }
                        return x;
                    })
                }
            };
        case 'talentUpdated':
            return {
                ...state,
                talent: state.talent.map(x => {
                    if (x.id === action.payload.id) {
                        return { ...x, ...action.payload }
                    }
                    return x;
                })
            };
        case 'showInfoPanel':
            return {
                ...state,
                deleteOpen: false,
                infoPanelOpen: true,
                showHistorySection: false,

                // add talent area
                addTalentPanelOpen: false,
            };
        case 'hideInfoPanel':
            return {
                ...state,
                deleteOpen: false,
                infoPanelOpen: false,

                // add talent area
                addTalentPanelOpen: false,
            };
        case 'showAddTalent':
            return {
                ...state,
                deleteOpen: false,

                // add talent area
                addTalentPanelOpen: action.payload,

                // info area
                infoAreaVisible: false,
                infoPanelOpen: false,
            };
        case 'talentRowClicked':
            return {
                ...state,
                deleteOpen: false,
                talentRowOpen: state.talentRowOpen === action.payload ? null : action.payload
            };
        case 'toggleDeleteButton':
            return {
                ...state,
                deleteOpen: !state.deleteOpen
            };
        case 'talentDropdownChanged':
            return {
                ...state,
                talentFilter: action.payload,
                filters: action.payload === 'all' ? DEFAULT_FILTERS : {
                    accepted: false,
                    interviews: false,
                    unavailable: false,
                    rejected: false,
                    scheduled: false,
                    waiting: false,
                    skipped: false,
                    [action.payload]: true
                }
            };
        case 'talentReminderSuccess':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'infoReminderSuccess':
            return {
                ...state,
                role: { ...state.role, ...action.payload }
            };
        case 'onHoldButtonClicked':
            return {
                ...state,
                isOnHoldLoading: true,
                ...action.payload
            };
        case 'showExportModalClicked':
            return {
                ...state,
                exportTalentModalOpen: true
            }
        case 'hideExportModalClicked':
            return {
                ...state,
                exportTalentModalOpen: false
            }
        case 'makeUserBillableReturned':
            return {
                ...state,
                role: {
                    ...state.role,
                    ...action.payload
                }
            }
        case 'surfaceDatesUpdated':
            return {
                ...state,
                surfaceDates: action.payload
            }
        case 'talentSurfaceDateChanged':
            return {
                ...state,
                talentSurfacedDate: action.payload
            }
        case 'talentSurfaceDateReset':
            return {
                ...state,
                talentSurfacedDate: null
            }
        case 'toggleHistoryView':
            return {
                ...state,
                showHistorySection: !state.showHistorySection
            }
        case 'reset':
            return init(action.payload);
        default:
            return state;
    }
}

const RoleSuccessDropDownWrapper = props => {
    const dispatchRedux = useDispatch();
    const mode = useSelector(state => state.siteSettings.mode);
    const searchModeType = useSelector(state => state.rolesSearchSettings.searchModeType);
    const searchText = useSelector(state => state.rolesSearchSettings.searchText);
    const componentActive = useRef(false);

    const { roleId } = props;

    const [state, dispatch] = useReducer(reducer, props, init);

    const talent = useTalentToTypesFilter(state.talent, state.filters);

    useEffect(() => {
        componentActive.current = true;
        return () => {
            componentActive.current = false;
        }
    }, []);

    useEffect(() => {
        if (state.talent.length > 0) {
            getRoleSurfaceDates(roleId)
                .then(response => {
                    if (componentActive.current) {
                        dispatch({ type: 'surfaceDatesUpdated', payload: response.surface_dates })
                    }
                })
                .catch(e => console.log(e));
        }
    }, [state.talent])

    useEffect(() => {
        if (props.rowIsOpen) {
            initRole();
        }
    }, [props.rowIsOpen, mode, state.talentSurfacedDate]);

    // useEffect(() => {
    //     let isMounted = true;
    //     if (props.isOpen) {
    //         console.log("wasted get admins call");
    //         getAdmins()
    //             .then(({ data }) => {
    //                 if (isMounted) {
    //                     dispatch({ type: 'adminsReturned', payload: data.admins });
    //                 }
    //             })
    //             .catch(e => console.log(e));
    //     }
    //     return () => { isMounted = false };
    // }, [props.role]);

    const fetchAdmins = useCallback(() => {
        getAdmins()
            .then(({ data }) => {
                dispatch({ type: 'adminsReturned', payload: data.admins });
            })
            .catch(e => console.log(e));
    }, []);

    const initRole = () => {
        (async () => {
            try {
                dispatch({ type: 'fetchingRole' });
                const { role } = await getRoleById(roleId);
                let params = {};
                if (componentActive.current) {
                    dispatch({ type: 'receivedRole', payload: role });

                    if (mode === 'success') {
                        dispatch({ type: 'fetchingTalent' });

                        params = (searchModeType === 'talent' && searchText !== '') ?
                            { 'search-text': searchText } : {};

                        if (state.talentSurfacedDate) {
                            const startTimeRange = moment(state.talentSurfacedDate);
                            const endTimeRange = moment(state.talentSurfacedDate).add(1, 'd');
                            params['surfaced-date-start'] = startTimeRange.utc().format("YYYY-MM-DD HH:mm:ss");
                            params['surfaced-date-end'] = endTimeRange.utc().format("YYYY-MM-DD HH:mm:ss");
                        }

                        const { talent } = await getRoleTalent(roleId, { params });
                        if (componentActive.current) {
                            dispatch({ type: 'receivedTalent', payload: talent });
                        }
                    }
                }
            } catch (e) {
                handleError(e);
            }
        })();
    };

    const handleMakeUserBillable = (userId) => {
        patchRole(roleId, { billable_user_id: userId })
            .then(({ role }) => {
                dispatch({ type: 'makeUserBillableReturned', payload: role });
            })
            .catch(e => console.log(e))
    }

    const handleInfoReminder = useCallback(() => {
        sendInfoAdminReminders(state.role.id)
            .then(({ role }) => {
                dispatch({ type: 'infoReminderSuccess', payload: role });
                showToastSuccess("Info Reminder has been sent.");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const handleError = (message, errors = []) => {
        if (message === "Upgrade Required") {
            alert("Upgrade Required: Reload the app to get new version");
            // mainContext.showModal(true);
        } else {
            showToastError(message, errors);
        }
    };

    const handleTalentReminder = useCallback(() => {
        sendTalentAdminReminders(state.role.id)
            .then(({ role }) => {
                dispatch({ type: 'talentReminderSuccess', payload: role });
                showToastSuccess("Reminders have been sent.");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const handleToggleShowInfo = useCallback(() => {
        if (state.infoPanelOpen) {
            dispatch({ type: 'hideInfoPanel' });
        } else {
            dispatch({ type: 'showInfoPanel' });
        }
    }, [state.infoPanelOpen]);

    const handleOnHoldClicked = useCallback(() => {
        const payload = { on_hold: !state.role.on_hold };
        dispatch({ type: "onHoldButtonClicked", payload: payload });
        updateRole(payload);
    }, [state.role.on_hold]);

    const updateRole = useCallback((payload) => {
        patchRole(state.role.id, payload)
            .then(() => {
                showToastSuccess("Role has been updated.");
                initRole();
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const handleToggleDeleteButton = useCallback(() => {
        dispatch({ type: 'toggleDeleteButton' });
    }, []);

    const handleTalentDropDownChange = useCallback((e) => {
        dispatch({ type: 'talentDropdownChanged', payload: e.target.value });
    }, []);

    const handleTalentAdminChanged = useCallback((talentId, adminId) => {
        postTalentAdmin(talentId, { roleId: state.role.id, adminId })
            .then(({ talent }) => {
                dispatch({
                    type: 'adminUpdated',
                    payload: { talentId, talent: { ...talent, pivot: { ...talent.pivot, admin_id: adminId } } }
                });
                showToastSuccess("Admin has been saved.");
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const handlePatchTalent = useCallback((roleId, talentId, payload, reload = false, resetLoading = () => {
    }) => {
        patchTalent(roleId, talentId, payload)
            .then(({ talent }) => {
                dispatch({ type: 'talentUpdated', payload: talent });
                if (reload) {
                    initRole();
                }
                showToastSuccess("Talent has been updated.");
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => {
                handleError(e.message);
            })
            .finally(() => {
                resetLoading()
            })
    }, [state.role.id]);

    const handleDeleteTalent = useCallback((talentId, roleId) => {
        deleteTalent(talentId, roleId)
            .then(() => {
                initRole();
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => {
                handleError(e.message);
            });
    }, [state.role.id]);

    const handleDeleteTalentInterviews = useCallback((talentId) => {
        refundInterviews(talentId, state.role.id)
            .then(() => {
                initRole();
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                showToastError(message, errors);
            });
    }, [state.role.id]);

    const handleTalentRowClicked = useCallback((id) => {
        dispatch({ type: 'talentRowClicked', payload: id });
    }, []);

    const removeRoleUser = useCallback((userId) => {
        removeUserFromRole(state.role.id, userId)
            .then(({ role }) => {
                dispatch({ type: 'removedRoleUser', payload: role });
                showToastSuccess("User has been removed.");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const addRoleUser = useCallback((payload) => {
        addUserToRole(state.role.id, payload)
            .then(({ role }) => {
                dispatch({ type: 'userAddedToRole', payload: role });
                showToastSuccess("User has been added.");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const addCardToUser = useCallback((userId, payload) => {
        addUserCard(userId, payload)
            .then(({ user }) => {
                dispatch({ type: 'cardAddedToUser', payload: user });
                showToastSuccess("User Card has been added.");
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, []);

    const postAdminToRole = useCallback((payload) => {
        postAdmin(state.role.id, payload)
            .then(({ role }) => {
                dispatch({ type: 'adminAddedToRole', payload: role });
                dispatchRedux(getRoleInfo(state.role.id));
                showToastSuccess("Admin has been saved.");
                updateRoleTalent();
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const makeAdminDefaultPayment = useCallback((payload) => {
        patchRole(state.role.id, payload)
            .then(({ role }) => {
                showToastSuccess("Admin made default payer");
                dispatch({ type: 'adminMadeDefaultPayer', payload: role });
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const updateRoleInfo = useCallback((payload) => {
        patchRoleInfo(state.role.id, payload)
            .then(({ info }) => {
                showToastSuccess("Role info has been updated.");
                dispatchRedux(getRoleInfo(state.role.id));
                dispatch({ type: 'roleRoleInfoUpdated', payload: info });
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const massUpdateRoleInfo = async payload => {
        dispatch({ type: 'roleUpdating' });
        try {
            const response = await patchRole(state.role.id, payload);
            dispatch({ type: 'roleUpdated', payload: response.role });
            showToastSuccess("Role has been updated.");
            dispatchRedux(getRoleInfo(state.role.id));
        } catch (e) {
            dispatch({ type: 'roleUpdated', payload: state.role });
            const message = e.message ? e.message : e;
            const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
            handleError(message, errors);
        }
    };

    const updateUserInfo = useCallback((userId, payload) => {
        patchCompanyUser(userId, payload)
            .then(({ user }) => {
                dispatch({ type: 'userDataUpdated', payload: user });
            })
            .catch(e => console.log(e));
    }, []);

    const updateRoleProfile = useCallback((profileId, payload) => {
        patchRoleProfile(state.role.id, profileId, payload)
            .then(({ profiles }) => {
                showToastSuccess("Role profile has been updated.");
                dispatch({ type: 'roleProfileUpdated', payload: profiles });
            })
            .catch(e => {
                const message = e.message ? e.message : e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
                handleError(message, errors);
            });
    }, [state.role.id]);

    const deleteFollowUpByPivotId = useCallback((talentId, followUpPivotId) => {
        deleteFollowUp(talentId, followUpPivotId)
            .then(() => {
                initRole();
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => console.log(e));
    }, [state.role.id]);

    const cancelInterviewById = useCallback((interviewId) => {
        cancelInterview(interviewId)
            .then(() => {
                initRole();
                dispatchRedux(getRoleInfo(state.role.id));
            })
            .catch(e => console.log(e))
    }, [state.role.id]);

    const updateRoleTalent = () => {
        getRoleTalent(roleId)
            .then(({ talent }) => {
                dispatch({ type: 'receivedTalent', payload: talent });
            })
            .catch(e => {
                handleError(e);
            });
    };

    const handleShowExportModal = useCallback(() => {
        dispatch({ type: 'showExportModalClicked' });
    }, []);

    const handleHideExportModal = useCallback(() => {
        dispatch({ type: 'hideExportModalClicked' });
    }, []);

    const handleTalentSurfedDateChange = useCallback((date) => {
        dispatch({ type: 'talentSurfaceDateChanged', payload: date });
    }, []);

    const handleClearTalentSurfedDate = useCallback((date) => {
        dispatch({ type: 'talentSurfaceDateReset' });
    }, []);

    const handleOpenHistoryClicked = () => {
        dispatch({ type: 'toggleHistoryView' });
    }

    if (state.isLoading) {
        return <TurboPlus2Loader />;
    }

    return (
        <Fragment>
            <RoleRowTopButtonRow
                mode={mode}
                role={state.role}
                talent={state.talent}
                roleType={props.roleType}
                isLoading={state.isLoading}
                deleteOpen={state.deleteOpen}
                talentFilter={state.talentFilter}
                isOnHoldLoading={state.isOnHoldLoading}
                talentSurfacedDate={state.talentSurfacedDate}
                surfaceDates={state.surfaceDates}
                handleTalentSurfedDateChange={handleTalentSurfedDateChange}
                handleClearTalentSurfedDate={handleClearTalentSurfedDate}
                handleInfoReminder={handleInfoReminder}
                handleTalentReminder={handleTalentReminder}
                handleToggleShowInfo={handleToggleShowInfo}
                handleOnHoldClicked={handleOnHoldClicked}
                handleToggleDeleteButton={handleToggleDeleteButton}
                handleTalentDropDownChange={handleTalentDropDownChange}
                handleShowExportModal={handleShowExportModal}
                onShowHistoryClicked={handleOpenHistoryClicked}
                historyActive={state.showHistorySection}
            />

            <PlanStatusSection customerId={state.role.customer_id} />

            <RoleInfoSection
                role={state.role}
                fetchAdmins={fetchAdmins}
                admins={state.admins}
                isOpen={state.infoPanelOpen}
                roleUpdating={state.roleUpdating}
                updateRole={updateRole}
                addRoleUser={addRoleUser}
                addCardToUser={addCardToUser}
                removeRoleUser={removeRoleUser}
                updateUserInfo={updateUserInfo}
                updateRoleInfo={updateRoleInfo}
                postAdminToRole={postAdminToRole}
                updateRoleProfile={updateRoleProfile}
                massUpdateRoleInfo={massUpdateRoleInfo}
                handleToggleShowInfo={handleToggleShowInfo}
                makeAdminDefaultPayment={makeAdminDefaultPayment}
                makeUserBillable={handleMakeUserBillable}
                initRole={initRole}
            />


            {!state.infoPanelOpen && state.role.info && state.role.info.notes &&
                <Row>
                    <Col xs={12}>
                        <PostItView title="Role Notes:">
                            {state.role.info.notes}
                        </PostItView>
                    </Col>
                </Row>
            }

            {!state.showHistorySection && !state.infoPanelOpen && props.roleType !== 'waiting' &&
                <div>
                    <MondayRoleLinker
                        roleId={roleId}
                        groupId={state.role.monday_group ? state.role.monday_group.group_id : null}
                        boardId={state.mondayBoardId }
                        updateRole={updateRole}
                        initRole={initRole} />
                    <ApolloRoleLinker
                        roleId={roleId}
                        displayNotificationsClearButton={props.displayNotificationsClearButton}
                        apolloSequenceId={state.role.apollo_sequence_id}
                        apolloSequenceName={state.role.apollo_sequence_name}
                        updateRole={updateRole}
                        initRole={initRole} />
                </div>
            }


            {state.showHistorySection && <RoleHistorySection roleId={roleId} />}

            {!state.infoPanelOpen && !state.talent.length &&
                <DeleteSection
                    roleId={roleId}
                    deleteOpen={state.deleteOpen}
                    handleDeleteClicked={handleToggleDeleteButton}
                    handleCancel={handleToggleDeleteButton}
                />
            }

            {!state.infoPanelOpen && (!state.role.info_count || !!state.role.show_info && !state.role.completed) &&
                <WaitingForCustomer waitingForCard={state.role.force_card} />
            }


            {!state.infoPanelOpen && !!state.talent.length && !state.showHistorySection &&
                <TalentWrapper>
                    {talent.map(x => (
                        <TalentRowItem
                            key={x.id}
                            roleId={state.role.id}
                            apollo_clear_notifications={state.role.apollo_clear_notifications}
                            isSourcingOnly={state.role.sourcing_only}
                            isOpen={state.talentRowOpen === x.id}
                            talent={x}
                            followUpList={props.followUpList}
                            handlePatchTalent={handlePatchTalent}
                            handleDeleteTalent={handleDeleteTalent}
                            handleTalentRowClicked={handleTalentRowClicked}
                            handleTalentAdminChanged={handleTalentAdminChanged}
                            deleteFollowUpByPivotId={deleteFollowUpByPivotId}
                            cancelInterviewById={cancelInterviewById}
                            handleDeleteTalentInterviews={handleDeleteTalentInterviews}
                        />
                    ))}
                </TalentWrapper>
            }

            {state.isLoading && <TurboPlus2Loader />}

            {!state.infoPanelOpen && !state.isLoading && !!state.role.info_count && !state.talent.length && (!!state.role.completed || !state.role.show_info) &&
                <EmptyTalentListScreen />
            }

            {state.talent.length !== 0 && state.exportTalentModalOpen && <ExportTalentModal
                roleId={state.role.id}
                show={state.exportTalentModalOpen}
                handleHideExportModal={handleHideExportModal} />}
        </Fragment>
    )
};

export default RoleSuccessDropDownWrapper;
