import React from 'react';
import Checkbox from "react-bootstrap/lib/Checkbox";
import FormGroup from "react-bootstrap/lib/FormGroup";
import classes from './IncludeCheckBoxes.module.css';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const IncludeCheckBoxes = props => {
    return (
        <FormGroup>
            <Checkbox bsClass={classes.CheckBox} checked={props.calendarSurfaced} onChange={props.calendarSurfacedChanged}>
                <span className={classes.Text}>Include surfaced dates</span>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={(
                        <Popover id="popover-positioned-right" title="Surface date">
                            This is the date the candidate was first 'surfaced' on Rolebot, i.e the date it showed up on
                            the platform for the first time. If you include 'surfaced' dates, the calendar filter will
                            include all candidates that were surfaced by Rolebot during the time frame of your choosing.
                        </Popover>
                    )}>
                    <FontAwesomeIcon icon={faQuestionCircle}/>
                </OverlayTrigger>
            </Checkbox>
            <Checkbox bsClass={classes.CheckBox} checked={props.calendarAccepted} onChange={props.calendarAcceptedChanged}>
                <span className={classes.Text}>Include accepted dates</span>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={(
                        <Popover id="popover-positioned-right" title="Accepted date">
                            This is the date the candidates were 'accepted' by the customer. If you include 'accepted'
                            dates, the calendar filter will include all candidates that were accepted by the customer
                            during the time frame of your choosing.
                        </Popover>
                    )}>
                    <FontAwesomeIcon icon={faQuestionCircle}/>
                </OverlayTrigger>
            </Checkbox>
        </FormGroup>
    )
};

export default IncludeCheckBoxes;
