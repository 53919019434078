import React, {useMemo} from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import {
    faCircle,
    faEnvelope,
    faFlag as faFlagSolid,
    faPhone,
    faStar as faStarSolid
} from "@fortawesome/free-solid-svg-icons";
import {faFlag, faStar} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from "./TalentItemRow.module.css";
import moment from "moment";
import {OverlayTrigger} from "react-bootstrap";
import useBuildAcceptedTooltip from "../../../../../../../hooks/useBuildAcceptedTooltip";


const buildStarIcon = (is_reviewed) => {
    let color, icon;
    switch (is_reviewed) {
        case 1:
            color = 'orange';
            icon = faStarSolid;
            break;
        case 2:
            color = '#46BAF9';
            icon = faStarSolid;
            break;
        default:
            color = 'grey';
            icon = faStar;
    }

    return <FontAwesomeIcon style={{color, fontSize: "15px", paddingRight: '4px'}} icon={icon}/>;
};

const buildFlagIcon = (interested_on) => {
    return <FontAwesomeIcon
        style={{color: interested_on ? "green":"grey", fontSize: "15px", paddingRight: '4px'}}
        icon={interested_on ? faFlagSolid : faFlag}/>;
};

const getIconStatus = (talent) => {

    const { outcome_id } = talent.pivot;
    const { accepted, skipped_at, scheduled_at } = talent.pivot;

    //This was added after the BIG push. We need to check that this takes into account
    //Voided, cancelled interviews. Pushing this patch to get another item working that is critical
    const hasInterviews = talent.interviews.length > 0;

    if(skipped_at !== null){
        return classes.SkippedIcon;
    }

    if(scheduled_at && moment.utc(scheduled_at).local().isAfter(moment())){
        return classes.ScheduledAtIcon;
    }

    if(outcome_id){
        return hasInterviews ? classes.InterviewIcon : classes.RefusedIcon;
    }

    if(accepted === true){
        return classes.AcceptedIcon;
    }

    if(accepted === false){
        return classes.RejectedIcon;
    }

    return classes.WaitingIcon;
};

const TalentItemRow = props => {
    const {id, name, mobile_number, email} = props.talent;
    const { accepted, skipped_at, is_reviewed, scheduled_at, surfaced_at, skipped_reason, accepted_on, created_at, interested_on } = props.talent.pivot;

    const tooltip =  useBuildAcceptedTooltip(id, accepted, accepted_on, scheduled_at, surfaced_at, created_at, skipped_at, skipped_reason);

    const flagIcon = buildFlagIcon(interested_on);

    const starIcon = buildStarIcon(is_reviewed);

    const emailIcon = email ? <FontAwesomeIcon style={{paddingRight: '4px'}} icon={faEnvelope}/> : null;

    const phoneIcon = mobile_number ? <FontAwesomeIcon style={{paddingRight: '4px'}} icon={faPhone}/> : null;

    const iconClass = getIconStatus(props.talent);

    const littleStatusIcon = useMemo(() => {
        return (
            <FontAwesomeIcon className={iconClass} icon={faCircle} />
        );
    }, []);

    return (
        <Row style={{borderBottom: "1px solid lightgray"}}>
            <Col lg={8}>
                <div className={classes.Row}>
                    {flagIcon}
                    {starIcon}
                    <span style={{paddingRight: '4px'}}>{name}</span>
                    {emailIcon}
                    {phoneIcon}
                </div>
            </Col>
            <Col lg={4}>
                <div  className={classes.Row} style={{textAlign: "right"}}>
                    Talent Status:
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        {littleStatusIcon}
                    </OverlayTrigger>
                </div>
            </Col>
        </Row>
    )
};

export default TalentItemRow;

TalentItemRow.propTypes = {};

TalentItemRow.defaultProps = {};
