import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import AddCompanyHeader from "../AddCompanySection/Components/AddCompanyHeader/AddCompanyHeader";
import AddRoleForm from "../AddRoleForm/AddRoleForm";
import FormPanel from "../FormPanel/FormPanel";
import GreyScrollBoxArea from "../GreyScrollBoxArea/GreyScrollBoxArea";

const AddRoleSection = () => {
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = companies.find(x => companyId === x.id);

    return (
        <Fragment>
            <AddCompanyHeader title={`Create Roles For ${company.name}`}>
                These Users will be connected to {company.name}. Add as many as you’d like.
            </AddCompanyHeader>
            <GreyScrollBoxArea>
                <FormPanel title="Create New Role">
                    <AddRoleForm company={company}/>
                </FormPanel>
            </GreyScrollBoxArea>
        </Fragment>
    )
};

export default AddRoleSection;