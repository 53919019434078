import React, {useReducer, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Alert, FormGroup, Row} from "react-bootstrap";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardFormArea from "./Components/CardFormArea/CardFormArea";
import {getPaymentIntent} from '../../../../../../../../../../../utils/services/turboPlus2RestCalls';
import Skeleton from 'react-loading-skeleton';
import NewAdvanceTextInput from "../../../../../../../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";

const init = (props) => {
    return {
        email: props.user.email,
        name: '',
        intent: null,
        loading: false,
        complete: false,
        error: null
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                loading: true
            }
        case 'intentReturned':
            return {
                ...state,
                loading: false,
                intent: action.payload
            }
        case 'ccInputUpdated':
            return {
                ...state,
                ...action.payload
            }
        case 'cancelCardCollect':
            return init();
        case 'nameChanged':
            return {
                ...state,
                name: action.payload
            }
        default:
            throw new Error();
    }
}

const CreditCardSection = props => {
    const [state, dispatch] = useReducer(reducer, props, init);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        let isMounted = true;
        dispatch({type: 'loading'});
        getPaymentIntent(props.user.id)
            .then(({intent}) => {
                if(isMounted){
                    dispatch({type: 'intentReturned', payload: intent});
                }
            })
            .catch(e => console.log(e));
        return () => { isMounted = false };
    }, []);

    const handleCancelCard = () => {
        props.cancel();
    }

    const handleCardInputChanged = (payload) => {
        dispatch({type: 'ccInputUpdated', payload})
    }


    const handleCardNameChanged = (e) => {
        dispatch({type: 'nameChanged', payload: e.currentTarget.value})
    }

    const handleSubmitCard = async (event) => {
        event.preventDefault();
        const stripeResult = await stripe.confirmCardSetup(state.intent.client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: state.name,
                },
            }
        });
        props.submit(stripeResult.setupIntent);
    }

    return (
        <form onSubmit={handleSubmitCard}>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            {state.loading ? <Skeleton height={30} style={{margin: '15px 0'}}/> :
                                <NewAdvanceTextInput
                                    required
                                    value={state.name}
                                    onChange={handleCardNameChanged}
                                    label="Name On Card"
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {state.loading ? <Skeleton height={30} style={{margin: '15px 0'}}/> : <CardFormArea onChange={handleCardInputChanged}/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} xsOffset={6}>
                            {state.loading ? <Skeleton height={30}/> :
                                <Button
                                    disabled={!state.complete || state.loading}
                                    type="submit"
                                    bsStyle="success" block>
                                    Add Credit Card
                                </Button>
                            }
                        </Col>
                        <Col xs={3}>
                            {state.loading ? <Skeleton height={30}/> :
                                <Button
                                    disabled={state.loading}
                                    onClick={handleCancelCard}
                                    bsStyle="danger"
                                    block>Cancel</Button>
                            }
                        </Col>
                    </Row>
                </FormGroup>
                {state.error && (
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="danger">
                                {state.error.message}
                            </Alert>
                        </Col>
                    </Row>
                )}
        </form>
    );
};

export default CreditCardSection;

CreditCardSection.propTypes = {
    user: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired
};

CreditCardSection.defaultProps = {};