import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import classes from './NewAdvanceTextInput.module.css';

const NewAdvanceTextInput = props => {
    const {
        controlId,
        label,
        helpText,
        componentClass,
        validationState,
        placeholder,
        type,
        required,
        disabled,
        value,
        onChange,
        loading
    } = props;

    return (
        <FormGroup controlId={controlId} validationState={validationState}>
            <ControlLabel className={classes.Label}>{label}</ControlLabel>
            {
                loading ?
                    <Skeleton height={34}/> :
                    <FormControl
                        type={type}
                        componentClass={componentClass}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                    />
            }
            <HelpBlock>{helpText}</HelpBlock>
        </FormGroup>
    )
};

export default React.memo(NewAdvanceTextInput);

NewAdvanceTextInput.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    helpText: PropTypes.string,
    componentClass: PropTypes.oneOf(['input', 'textarea']),
    validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool

};

NewAdvanceTextInput.defaultProps = {
    componentClass: 'input',
    validationState: null,
    placeholder: '',
    type: 'text',
    required: false,
    loading: false,
    disabled: false
};