import React from 'react';
import classes from './ArchiveLeftMenuSection.module.css';
import PropTypes from 'prop-types';
import {faArchive} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Switch from "react-switch";

const ArchiveLeftMenuSection = ({showArchived, showArchivedClicked}) => {
    return (
        <div className={classes.ArchiveLeftMenuSection}>
            <FontAwesomeIcon className={classes.Icon} icon={faArchive}/>
                Archive
            <Switch height={18} width={35} onChange={showArchivedClicked} checked={showArchived}/>
        </div>
    )
};

export default ArchiveLeftMenuSection;

ArchiveLeftMenuSection.propTypes = {
    showArchivedClicked: PropTypes.func.isRequired,
    showArchived: PropTypes.bool.isRequired
};

ArchiveLeftMenuSection.defaultProps = {};