import React, {useReducer, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import FormEditButtonArea from "../../../../../../Ui/Inputs/FormEditButtonArea/FormEditButtonArea";
import {Button, Checkbox, Col, Row} from "react-bootstrap";
import {useDispatch} from 'react-redux'
import TopBackButtonSection from "./Components/TopBackButtonSection/TopBackButtonSection";
import UserProfileHeader from "./Components/UserProfileHeader/UserProfileHeader";
import {
    patchCompanyUsersRequest,
    deleteCompanyUserPaymentMethodRequest,
    patchCompanyUserSuccessAction,
    getCompanyUserRequest
} from '../../../../../../../store/actions';
import {getObjectDiff} from '../../../../../../../utils/misc';
import NewTitleHeader from "../../../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import AddUserButtonSection from "../AddUserButtonSection/AddUserButtonSection";
import BillingInfoItem from "./Components/BillingInfoItem/BillingInfoItem";
import AddCreditCardArea from "../CompanyUserItem/Components/AddCreditCardArea/AddCreditCardArea";
import {patchCompanyUserPaymentMethodRequest} from "../../../../../../../store/actions/companies";
import NewUserFormArea from "../NewUserFormArea/NewUserFormArea";
import {
    formatPhoneNumber,
    isSuccessOrNull, stripeOutNonDigits,
    userNameValidation, userPhoneValidation,
    userTitleValidation
} from "../../../../../../../utils/validation";
import {addCardToUser} from "../../../../../../../utils/services/turboPlus2RestCalls";
import classes from "../../../../../RolesHeader/RolesHeader.module.css";
import AddUserToRolesModal from "../../../../../../Modals/AddUserToRolesModal/AddUserToRolesModal";

const init = (user) => {
    return {
        user,
        editMode: false,
        showCreditForm: false,
        cardIdOpen: null,


        //validation Area
        submitDisabled: true,
        showAddToRolesModal: false,
        validation: {
            nameValidState: null,
            titleValidState: null,
            phoneValidState: null,

            validateName: false,
            validateTitle: false,
            validatePhone: false
        }
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'nameChanged': {
            const isValid = userNameValidation(action.payload);
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload,
                },
                validation: {
                    ...state.validation,
                    nameValidState: isValid ? 'success' : 'error',
                    validateName: true
                }
            }
        }
        case 'emailChanged':
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload
                }
            }
        case 'titleChanged': {
            const isValid = userTitleValidation(action.payload);
            return {
                ...state,
                user: {
                    ...state.user,
                    title: action.payload
                },
                validation: {
                    ...state.validation,
                    titleValidState: isValid ? 'success' : 'error',
                    validateTitle: true
                }
            }
        }
        case 'phoneChanged': {
            const isValid = userPhoneValidation(action.payload);
            return {
                ...state,
                user: {
                    ...state.user,
                    phone: action.payload
                },
                validation: {
                    ...state.validation,
                    phoneValidState: isValid ? 'success' : 'error',
                    validatePhone: true
                }
            }
        }
        case 'allowNotificationsChanged':
            return {
                ...state,
                user: {
                    ...state.user,
                    allow_notifications: action.payload
                }
            }
        case 'isAdminChanged':
            return {
                ...state,
                user: {
                    ...state.user,
                    customer_user: {
                        ...state.user.customer_user,
                        is_admin: action.payload
                    }
                }
            }
        case 'editModeEnabled':
            return {
                ...state,
                editMode: true
            }
        case 'editModeCanceled':
            return init(action.payload);
        case 'showCreditCardForm':
            return {
                ...state,
                showCreditForm: true
            }
        case 'hideCreditCardForm':
            return {
                ...state,
                showCreditForm: false
            }
        case 'showCardItemMenu':
            return {
                ...state,
                cardIdOpen: action.payload
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'cardAdded':
            return {
                ...state,
                showCreditForm: false
            }
        case 'userSubmitted':
            return {
                ...state,
                editMode: false,
                validation: {
                    nameValidState: null,
                    titleValidState: null,
                    phoneValidState: null,

                    validateName: false,
                    validateTitle: false,
                    validatePhone: false
                }
            }
        case 'showAddToRolesModal':
            return {
                ...state,
                showAddToRolesModal: true
            }
        case 'closeModals':
            return {
                ...state,
                showAddToRolesModal: false
            }
        default:
            throw new Error();
    }
}

const EditUserProfile = ({user, companyRoles, companyId, backClicked,  userId}) => {
    const dispatchRedux = useDispatch();

    const defaultId = user?.default_payment_method ? user?.default_payment_method?.id : null;

    const [state, dispatch] = useReducer(reducer, user, init);

    useEffect(() => {
        dispatchRedux(
            getCompanyUserRequest(companyId, user.id, {
                params: {
                    'get-payment-info': 1
                }})
        );
    },[])

    useEffect(() => {
        const {nameValidState, titleValidState, phoneValidState} = state.validation;
        if(
            isSuccessOrNull(nameValidState) &&
            isSuccessOrNull(titleValidState) &&
            isSuccessOrNull(phoneValidState)
        ){
            dispatch({type: 'enableSubmitButton'});
        } else {
            dispatch({type: 'disableSubmitButton'});
        }
    },[state.validation]);

    const handleEditClicked = useCallback(() => {
        dispatch({type: 'editModeEnabled'});
    },[]);

    const handleEditCancel = useCallback(() => {
        dispatch({type: 'editModeCanceled', payload: user});
    },[]);

    const handleNameChanged = useCallback((e) => {
        dispatch({type: 'nameChanged', payload: e.currentTarget.value});
    }, []);

    const handleEmailChanged = useCallback((e) => {
        dispatch({type: 'emailChanged', payload: e.currentTarget.value});
    }, []);

    const handleTitleChanged = useCallback((e) => {
        dispatch({type: 'titleChanged', payload: e.currentTarget.value});
    },[]);

    const handlePhoneChanged = useCallback((e) => {
        dispatch({type: 'phoneChanged', payload: stripeOutNonDigits(e.currentTarget.value)});
    },[]);

    const handleAllowNotificationsChanged = useCallback((value) => {
        dispatch({type: 'allowNotificationsChanged', payload: value});
    },[]);

    const handleIsAdminChanged = useCallback((value) => {
        dispatch({type: 'isAdminChanged', payload: value});
    },[]);

    const handleShowCreditForm = () => {
        dispatch({type: 'showCreditCardForm'});
    }

    const handleCancelCardForm = () => {
        dispatch({type: 'hideCreditCardForm'});
    }

    const handleSaveClicked = () => {
        const payload = getObjectDiff(user, state.user);

        if(payload.customer_user && payload.customer_user.is_admin !== undefined){
            payload['is-admin'] = payload.customer_user.is_admin;
            delete payload.customer_user;
        }

        dispatch({type:'userSubmitted'});
        dispatchRedux(patchCompanyUsersRequest(companyId, userId, payload ));
    };

    const handleCardMenuClicked = (cardId) => {
        dispatch({type: 'showCardItemMenu', payload: cardId === state.cardIdOpen ? null : cardId});
    }

    const handleRemoveCardFromRole = (paymentMethodId) => {
        dispatchRedux(deleteCompanyUserPaymentMethodRequest(companyId, user.id, paymentMethodId));
    }

    const handleMakeCardDefault = (paymentMethodId) => {
        dispatchRedux(patchCompanyUserPaymentMethodRequest(companyId, user.id, paymentMethodId, {idDefault: true}))
    }

    const handleCardSubmit = async (intent) => {
        const response = await addCardToUser(userId, {intent});
        dispatchRedux(patchCompanyUserSuccessAction(companyId, response.user));
        dispatch({type: 'cardAdded'})
    }

    const handleAddToRolesClicked = () => {
        dispatch({type: 'showAddToRolesModal'});
    }

    const handleCancelModal = () => {
        dispatch({type: 'closeModals'});
    }

    const handleExportCompaniesSubmit = () => {
        return true;
    }

    return (
        <Row>
            <Col xs={12}>
                <TopBackButtonSection onClick={backClicked}/>

                <UserProfileHeader name={state.user.name} title={state.user.title} email={state.user.email}/>


                <FormEditButtonArea
                    inEditMode={state.editMode}
                    onSaveClicked={handleSaveClicked}
                    onCancelClicked={handleEditCancel}
                    onEditClicked={handleEditClicked}
                    saveDisabled={state.submitDisabled}/>

                <NewUserFormArea
                    name={state.user.name}
                    nameDisabled={!state.editMode}
                    onNameChanged={handleNameChanged}
                    nameValidationState={state.validation.nameValidState}

                    isAdmin={!!state.user.customer_user.is_admin}
                    onIsAdminChanged={handleIsAdminChanged}


                    email={state.user.email}
                    emailDisabled={true}
                    onEmailChanged={handleEmailChanged}


                    title={state.user.title}
                    titleDisabled={!state.editMode}
                    onTitleChanged={handleTitleChanged}
                    titleValidationState={state.validation.titleValidState}

                    phone={state.user.phone ? formatPhoneNumber(state.user.phone) : ""}
                    phoneDisabled={!state.editMode}
                    onPhoneChanged={handlePhoneChanged}
                    phoneValidationState={state.validation.phoneValidState}

                    allowNotifications={state.user.allow_notifications}
                    allowNotificationsDisabled={!state.editMode}
                    onAllowNotificationsChanged={handleAllowNotificationsChanged}

                    inEditMode={!state.editMode}
                    disableIsAdmin={false}
                />

                {/*<Row>*/}
                {/*    <Col xs={6}>*/}
                {/*        <NewTitleHeader title="Billing Info"/>*/}
                {/*    </Col>*/}
                {/*    <Col xs={6}>*/}
                {/*        <div style={{textAlign: 'right'}}>*/}
                {/*            <AddUserButtonSection isActive={state.showCreditForm} addClicked={handleShowCreditForm} />*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}


                {state.showCreditForm &&
                <AddCreditCardArea
                    companyId={companyId} user={user} cancel={handleCancelCardForm} submit={handleCardSubmit}/>}

                {user?.payment_methods && user?.payment_methods.map(x =>
                    <BillingInfoItem
                        key={x.id}
                        card={x}
                        userId={userId}
                        isDefault={defaultId === x.id}
                        menuOpenClicked={handleCardMenuClicked}
                        menuOpen={x.id === state.cardIdOpen}
                        removeCard={handleRemoveCardFromRole}
                        makeDefault={handleMakeCardDefault}
                    />
                )}

                <Button
                    style={{width: 100, marginLeft: 10}}
                    bsStyle="default"
                    block
                    mode="dangerConfirmation"
                    onClick={() => handleAddToRolesClicked()}
                >
                    Add to roles
                </Button>
            </Col>

            {state.showAddToRolesModal &&
                <AddUserToRolesModal show={state.showAddToRolesModal} onHide={() => handleCancelModal()}
                                   user={user}
                                   companyRoles={companyRoles} />
            }
        </Row>
    )
};

export default React.memo(EditUserProfile);

EditUserProfile.propTypes = {
    userId: PropTypes.number.isRequired,
    backClicked: PropTypes.func.isRequired,
};

EditUserProfile.defaultProps = {};