import React from 'react';
import {Panel} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from "../../../AddTalentForm/components/ReviewedTalentSection/ReviewedTalentSection.module.css";
import FormGreyWrapper from "../../../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";

const TalentProspectsFoldingContainer = props => {

    return (
        <FormGreyWrapper>
            <Panel
                expanded={props.expanded}
                onToggle={props.onToggle}
                defaultExpanded={false}
                bsClass={classes.ReviewedTalentSection}>
                <Panel.Heading componentClass={props.headerComponent}/>
                <Panel.Collapse>
                    <Panel.Body>
                        {props.children}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        </FormGreyWrapper>
    )
};

export default TalentProspectsFoldingContainer;

TalentProspectsFoldingContainer.propTypes = {
    headerComponent: PropTypes.func.isRequired,
    onToggle: PropTypes.func

};

TalentProspectsFoldingContainer.defaultProps = {
    onToggle: () => {}
};