import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, FormControl, Form, FormGroup, ControlLabel, Checkbox } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from './AddUserToRolesModal.module.css';
import {addUserToRole, addUserToRoles, getUserRoles} from "../../../utils/services/turboPlus2RestCalls";
import {showToastError, showToastSuccess} from "../../../utils/toastMessages";

const AddUserToRolesModal = (props) => {
    const [allRolesAreSelected, setAllRolesAreSelected] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedRolesType, setSelectedRolesType] = useState("0");
    const [selectedRoleIds, setSelectedRoleIds] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userRoles, setUserRoles] = useState([])

    useEffect(() => {
        getUserRoles(props.user.id).then((res) => {
            setUserRoles(res)
            console.log(userRoles)
        })
    }, [])

    useEffect(() => {
        // I know the default value in state is set to "0". I'm setting it to "1" explicitly so my
        // component re-renders and roles get filtered to show "Active" roles only - N.S.
        setSelectedRolesType("1");
    }, []);

    useEffect(() => {
        if (allRolesAreSelected) {
            let selectedRoleIds = filteredRoles.filter(x => !x.already_added).map(x => x.id);

            setSelectedRoleIds(selectedRoleIds);
        } else {
            setSelectedRoleIds([]);
        }
    }, [allRolesAreSelected]);


    const handleCheckboxChange = (roleId) => {
        if (selectedRoleIds.includes(roleId)) {
            // Remove the roleId if already selected
            setSelectedRoleIds(selectedRoleIds.filter((id) => id !== roleId));
        } else {
            // Add the roleId if not selected
            setSelectedRoleIds([...selectedRoleIds, roleId]);
        }
    };

    const transformCompanyRoles = (roles) => {

        //TODO:instead of props.user.roles we should use data from a request
        const rolesIds = userRoles?.map((role) => role.id);

        return roles.map((role) => ({
            ...role,
            already_added: rolesIds.includes(role.id),
        }));
    }

    useEffect(() => {

        if ( userRoles.length == 0 ) return
        
        // Filter roles based on search text and roles type
        const filtered = props.companyRoles.filter((role) => {
            const nameMatches = role.name.toLowerCase().includes(searchText.toLowerCase());

            if (selectedRolesType === "0") {
                // Display all roles when selectedRolesType is empty
                return nameMatches;
            } else if (selectedRolesType === "1" && !role.completed) {
                // Display active roles when selectedRolesType is "1"
                return nameMatches;
            } else if (selectedRolesType === "2" && role.completed) {
                // Display completed roles when selectedRolesType is "2"
                return nameMatches;
            }

            return false; // Exclude other cases
        });

        setFilteredRoles(transformCompanyRoles(filtered));
    }, [searchText, selectedRolesType, props.companyRoles, userRoles]);

    const searchValueChanged = (e) => {
        setSearchText(e.target.value);
    };

    const selectedRolesTypeChanged = (e) => {
        setSelectedRolesType(e.currentTarget.value);
    };

    useEffect(() => {

        const updatedRoles = transformCompanyRoles(props.companyRoles);

        setFilteredRoles(updatedRoles); // Update filtered roles with already_added
    }, [props.user, props.companyRoles]);

    const handleAddExistingUserToMultipleRoles = () => {
        setIsLoading(true);
        let errorToastIsAlreadyShown = false;

        // Create a copy of the filteredRoles array to work with
        const updatedRoles = [...filteredRoles];

        addUserToRoles({user_id: props.user.id, roles: selectedRoleIds}).then( res => {

            selectedRoleIds.map( roleId => {
                const roleToChange = updatedRoles.find(x => x.id === roleId);

                if (roleToChange) {
                    roleToChange.already_added = true;
                }
            })

            if (!errorToastIsAlreadyShown) {
                // Set the updatedRoles array back into state
                setFilteredRoles(updatedRoles);
                setSelectedRoleIds([]);
                showToastSuccess("User added to role(s) successfully");
                props.onHide(); // Close the modal once after all roles are added
            }

            setIsLoading(false);

        }).catch( e => {
            console.log(e);
            if (!errorToastIsAlreadyShown) {
                showToastError("We could not add the user to the selected roles", []);
            }

            errorToastIsAlreadyShown = true;
        })

    };

    return (
        <Modal show={props.show} onHide={props.onHide} backdrop="static" dialogClassName={classes.ReactModal}>
            <Modal.Header className={classes.Header} closeButton>
                <Modal.Title className={classes.Title}>
                    Add user to roles
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.Body}>
                <Row>
                    <Col xs={12} lg={12}>
                        <p className={classes.SubHeading}>Select the roles you'd like to add <strong>{props.user.name}</strong> to</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={8}>
                        <FormControl
                            className={classes.Input}
                            value={searchText}
                            onChange={searchValueChanged}
                            type="text"
                            placeholder='Search for a role...' />
                    </Col>
                    <Col xs={12} lg={4}>
                        <div style={{textAlign: "right"}}>
                            <Form inline className={classes.RolesTypeDropDown}>
                                <FormGroup controlId="rolesTypeSelect">
                                    <FormControl
                                        className={classes.Input}
                                        componentClass="select"
                                        placeholder="select"
                                        onChange={selectedRolesTypeChanged}
                                        value={selectedRolesType}>
                                        <option value="0">All roles</option>
                                        <option value="1">Active roles</option>
                                        <option value="2">Completed roles</option>
                                    </FormControl>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <div className={classes.RolesContainer}>
                    {filteredRoles.length > 0 ? (
                       <Row>
                           <Col xs={1}>
                               <Checkbox
                                   class={classes.Checkbox}
                                   onChange={() => setAllRolesAreSelected(!allRolesAreSelected)}
                                   checked={allRolesAreSelected}
                               >
                               </Checkbox>
                           </Col>
                           <Col xs={7}>
                               <Row>
                                   <Col className={classes.RoleName} xs={12}>Select all roles</Col>
                                   <Col xs={12}></Col>
                               </Row>
                           </Col>
                           <Col xs={4}></Col>
                       </Row>
                    ) : null}
                    {filteredRoles.length > 0 ? (
                        filteredRoles.map((x) => (
                            <Row className={classes.RoleRow} key={x.id}>
                                <Col xs={1}>
                                    <Checkbox
                                        className={classes.Checkbox}
                                        onChange={() => handleCheckboxChange(x.id)}
                                        checked={selectedRoleIds.includes(x.id) || x.already_added}
                                        disabled={x.already_added}
                                    >
                                    </Checkbox>
                                </Col>
                                <Col xs={7}>
                                    <Row>
                                        <Col className={x.already_added ? classes.RoleNameDisabled : classes.RoleName} xs={12}>{x.name}</Col>
                                        <Col className={classes.RoleOrderNumber} xs={12}>{`Order: HRGH-${x.id?.toString()?.padStart(6, '0')}`}</Col>
                                    </Row>
                                </Col>
                                <Col className={classes.RoleStatus} xs={4}>{x.already_added ? 'Already on this role' : x.completed ? 'Completed' : ''}</Col>
                            </Row>
                        ))
                    ) : (
                        <p>No roles match your criteria.</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className={classes.Footer}>
                <Row>

                </Row>
                <Row>
                    <Col xs={12} lg={4}>
                        <p style={{marginTop: 5, textAlign: "initial", visibility: selectedRoleIds.length ? "inherit" : "hidden"}}>{selectedRoleIds.length + " role(s) selected"}</p>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Button
                            disabled={props.loading}
                            block
                            mode="neutral"
                            onClick={props.onHide}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Button
                            bsStyle="success"
                            disabled={isLoading || !selectedRoleIds.length}
                            block
                            mode="dangerConfirmation"
                            onClick={() => handleAddExistingUserToMultipleRoles()}
                        >
                            Add to Roles
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
};

export default AddUserToRolesModal;

AddUserToRolesModal.propTypes = {
    user: PropTypes.object.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

AddUserToRolesModal.defaultProps = {
    show: false,
    loading: false
};
