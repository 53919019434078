import React from 'react';
import {GiBananaBunch} from 'react-icons/gi';
import {AiOutlineGithub, AiOutlinePhone} from 'react-icons/ai';
import {HiOutlineMail} from 'react-icons/hi';
import classes from './ContactOutTag.module.css';
import PropTypes from 'prop-types';

const ContactOutTag = props => {

    let icon;
    switch (props.type) {
        case 'phone':
            icon = <AiOutlinePhone className={classes.Icon}/>;
            break;
        case 'email':
            icon = <HiOutlineMail className={classes.Icon}/>;
            break;
        case 'work_email':
            icon = <HiOutlineMail className={classes.Icon}/>;
            break;
        case 'personal_email':
            icon = <HiOutlineMail className={classes.Icon}/>;
            break;
        case 'github':
            icon = <AiOutlineGithub className={classes.Icon}/>;
            break;
        default:
            icon = <GiBananaBunch className={classes.Icon}/>;
    }

    return (
        <div className={classes.ContactOutTag}>{icon} {props.value}</div>
    )
};

export default ContactOutTag;

ContactOutTag.propTypes = {
    type: PropTypes.oneOf(['phone','email','github','work_email', 'personal_email']),
    value: PropTypes.string.isRequired
};

ContactOutTag.defaultProps = {};

/**
 Github: AiOutlineGithub
 Email: HiOutlineMail
 Phone: AiOutlinePhone
 */