export const setMode = (mode) => {
    return {
        type: 'APP_SETTINGS_UPDATE_MODE',
        payload: mode
    };
}

export const openSideDrawer = () => {
    return {
        type: 'ROLES_SEARCH_SIDE_DRAWER_OPENED',
    };
}

export const closeSideDrawer = () => {
    return {
        type: 'ROLES_SEARCH_SIDE_DRAWER_CLOSED',
    };
}

export const setCSVExportPreference = (preference) => {
    return {
        type: 'APP_SETTINGS_UPDATE_CSV_PREFERENCE',
        payload: preference
    };
}

export const saveCSVExportPreferences = () => {
    return {
        type: 'APP_SETTINGS_SAVE_CSV_PREFERENCES',
    };
};

export const resetCSVPreferences = () => {
    return {
        type: 'APP_SETTINGS_RESET_CSV_PREFERENCES'
    }
};