import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import classes from './TalentForReviewDownArrow.module.css';

const TalentForReviewDownArrow = props => {

    const downArrowStyles = props.isOpen ? [classes.DownArrow, classes.DownArrowActive].join(' ') : classes.DownArrow;

    return (
        <div className={classes.HeaderOpenIndicator}>
            <FontAwesomeIcon className={downArrowStyles} icon={faChevronDown}/>
        </div>
    )
};

export default TalentForReviewDownArrow;

TalentForReviewDownArrow.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

TalentForReviewDownArrow.defaultProps = {};