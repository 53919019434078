import React from 'react';
import PropTypes from 'prop-types';
import classes from "./SmallPercentBox.module.css";
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';

const SmallPercentBox = props => {

    const data = [
        { name: 'Group A', value: props.percent, color: props.primaryColor},
        { name: 'Group B', value: 100 - props.percent, color: props.SecondaryColor }
    ];

    return (
        <div className={classes.SmallPercentBox}>
            <div style={{color: props.iconColor}} className={classes.Icon}>{props.icon}</div>
            <div className={classes.PercentWrapper}>
                <div className={classes.CenterPercentage}>{props.percent}%</div>
                <ResponsiveContainer width="100%" height={160}>
                    <PieChart>
                        <Pie
                            dataKey="value"
                            data={data}
                            innerRadius={55}
                            outerRadius={65}
                            startAngle={90}
                            endAngle={-270}
                            paddingAngle={1}
                        >
                            {data.map((x, index) => <Cell key={index} fill={x.color}/>)}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className={classes.Title}>
                {props.title}
            </div>
            <div className={classes.StatsWrapper}>
                <div>
                    {props.subTitleOne}: {props.subTitleOneValue}
                </div>
                <div>
                    {props.subTitleTwo}: {props.subTitleTwoValue}
                </div>
            </div>
        </div>
    )
};

export default SmallPercentBox;

SmallPercentBox.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    iconColor: PropTypes.string,
    percent: PropTypes.number.isRequired,
    subTitleOne: PropTypes.string.isRequired,
    subTitleOneValue: PropTypes.number.isRequired,
    subTitleTwo: PropTypes.string.isRequired,
    subTitleTwoValue: PropTypes.number.isRequired,
    primaryColor: PropTypes.string.isRequired,
    SecondaryColor: PropTypes.string
};

SmallPercentBox.defaultProps = {
    color: '#000000',
    subTitleOneValue: 0,
    subTitleTwoValue: 0,
    SecondaryColor: '#dfe1e1',
    iconColor: '#000000'
};