import React from 'react';
import {ButtonToolbar} from "react-bootstrap";
import ButtonLink from "./ButtonLink/ButtonLink";

const RoleTypeSelector = () => {
    return (
        <ButtonToolbar>
            {/*<ButtonLink type='all' text="All Roles"/>*/}
            <ButtonLink type='active' text="Active"/>
            <ButtonLink type='waiting' text="Waiting"/>
            <ButtonLink type='completed' text="Completed"/>
            <ButtonLink type='vault' text="Vault"/>
        </ButtonToolbar>
    )
};

export default React.memo(RoleTypeSelector);