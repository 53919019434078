import React from 'react';
import {FormGroup, FormControl, ControlLabel, Form, Col, Row} from "react-bootstrap";
import classes from './AdminDropDown.module.css';

const AdminDropDown = props => {
    // Need to check that the id value supplied is still in the admin list.
    const value = props.admins.filter(x => x.is_account_manager === false).map(x => x.id).includes(props.value) ? props.value : '';

    return (
        <Row>
            <Col xs={12} lg={4} lgOffset={8}>
                <div style={{textAlign: "right"}}>
                    <Form inline className={classes.AdminDropDown}>
                        <FormGroup controlId="adminSelect">
                            <ControlLabel>{props.title}</ControlLabel> {' '}
                            <FormControl
                                componentClass="select"
                                placeholder="select"
                                onChange={props.handledAdminChanged}
                                value={value}>
                                <option value="">select</option>
                                {props.admins.filter(x => x.is_account_manager === false).map(x => <option key={x.id} value={x.id}>{x.user.name}</option>)}
                            </FormControl>
                        </FormGroup>
                    </Form>
                </div>
            </Col>
        </Row>
    )
};

export default AdminDropDown;