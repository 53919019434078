import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classes from './FilterTypePopUp.module.css';
import Overlay from "react-bootstrap/lib/Overlay";
import Popup from "./components/Popup/Popup";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faChevronDown} from "@fortawesome/free-solid-svg-icons";

const FilterTypePopUp = props => {
    const container = useRef(null);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(() => {
        setFilterCount(
            Object.values(props.filters)
            .filter(x => x === true)
            .length
        );
    }, [props.filters]);

    const handleButtonClicked = (e) => {
        e.stopPropagation();
        props.talentFilterToggle();
    };

    const arrowStyles = props.talentFilterOpen ? [classes.Arrow,classes.DownArrow].join(" ") : classes.Arrow;

    return (
        <div>
            <div className={classes.FilterButton} onClick={handleButtonClicked}>
                <FontAwesomeIcon icon={faFilter}/>
                <span>
                    <span className={classes.FilterText}>Filter by Status</span>
                    ({filterCount})
                </span>
                <FontAwesomeIcon className={arrowStyles} icon={faChevronDown}/>
            </div>
            <div className={classes.PopUpWrapper} ref={container}>
                <Overlay placment="bottom" show={props.talentFilterOpen} container={container.current} animation={false}>
                    <Popup filters={props.filters} filterChanged={props.filterChanged} reset={props.reset}/>
                </Overlay>
            </div>
        </div>
    )
};

export default React.memo(FilterTypePopUp);

FilterTypePopUp.propTypes = {
    filters: PropTypes.object.isRequired,
    filterChanged: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    talentFilterOpen: PropTypes.bool.isRequired,
    talentFilterToggle: PropTypes.func.isRequired
};

FilterTypePopUp.defaultProps = {};