import React, {useEffect, useReducer, useCallback} from 'react';
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import classes from './ExportTalentModal.module.css';
import {getRoleTalent} from '../../../../../utils/services/turboPlus2RestCalls';
import ModalHeader from "./components/ModalHeader/ModalHeader";
import useTalentToTypesFilter from "../../../../../hooks/useTalentToTypesFilter";
import useExportCSV from "../../../../../hooks/useExportCSV";
import TalentArea from "./components/TalentArea/TalentArea";
import {useDispatch, useSelector} from 'react-redux';
import {setCSVExportPreference, saveCSVExportPreferences, resetCSVPreferences} from '../../../../../store/actions';

const DEFAULT_FILTERS = {
    accepted: false,
    interviews: false,
    unavailable: false,
    rejected: false,
    scheduled: false,
    waiting: false,
    skipped: false
};

function init(props) {
    return {
        roleId: props.roleId,

        loading: true,

        // Talent returned and used to refresh export talent data.
        talent: [],

        // Enabled Filters
        filters: DEFAULT_FILTERS,

        talentFilterOpen: false,

        calendarFilterOpen: false,

        calendarFilterDate: null,

        calendarIncludeSurfed: true,

        calendarIncludeAccepted: true,

        preferenceOpen: false,
    };
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'fetchingTalent':
            return {
                ...state,
                loading: true
            };
        case 'talentReturned':
            return {
                ...state,
                talent: action.payload,
                loading: false
            };
        case 'filterChanged':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload]: !state.filters[action.payload]
                }
            }
        case 'filterReset':
            return {
                ...state,
                filters: DEFAULT_FILTERS
            }
        case 'talentFilterToggled':
            return {
                ...state,
                talentFilterOpen: !state.talentFilterOpen,
                calendarFilterOpen: false
            }
        case 'talentCalendarFilterToggled':
            return {
                ...state,
                talentFilterOpen: false,
                calendarFilterOpen: !state.calendarFilterOpen
            }
        case 'calendarDateRangeSupplied':
            return {
                ...state,
                calendarFilterDate: action.payload,
            };
        case 'calendarSurfacedChanged':
            return {
                ...state,
                calendarIncludeSurfed: !state.calendarIncludeSurfed
            }
        case 'calendarAcceptedChanged':
            return {
                ...state,
                calendarIncludeAccepted: !state.calendarIncludeAccepted
            }
        case 'preferencesButtonClicked':
            return {
                ...state,
                preferenceOpen: !state.preferenceOpen
            }
        default:
            throw new Error();
    }
}

const ExportTalentModal = props => {
    const dispatchX = useDispatch();
    const csvExportSettings = useSelector(state => state.siteSettings.csvExportSettings);
    const outcomes = useSelector(state => state.globalLists.outcomes);

    const [state, dispatch] = useReducer(reducer, props, init);

    useEffect(() => {
        if(props.show){
            let params = {};

            params = state.calendarFilterDate ? {
                ...params,
                after: state.calendarFilterDate[0],
                before: state.calendarFilterDate[1]
            } : params;

            if(state.calendarFilterDate){
                params = state.calendarIncludeSurfed ? {
                    ...params,
                    calendarIncludeSurfed: 1
                } : params;

                params = state.calendarIncludeAccepted ? {
                    ...params,
                    calendarIncludeAccepted: 1
                } : params;
            }

            dispatch({type: 'fetchingTalent'});

            getRoleTalent(props.roleId, {params})
                .then(({talent}) => {
                    dispatch({type: "talentReturned", payload: talent})
                })
                .catch(e => console.log(e));
        }
    }, [
        props.show,
        state.calendarFilterDate,
        state.calendarIncludeSurfed,
        state.calendarIncludeAccepted
    ]);

    const talent = useTalentToTypesFilter(state.talent, state.filters);

    const handleModalClicked = () => {
        if(state.talentFilterOpen){
            handleTalentFilterToggle();
        }

        if(state.calendarFilterOpen){
            handleCalendarFilterToggle();
        }
    };

    const handleTalentFilterToggle = useCallback(() => {
        dispatch({type: 'talentFilterToggled'});
    }, [])

    const handleCalendarFilterToggle = useCallback(() => {
        dispatch({type: 'talentCalendarFilterToggled'});
    }, [])

    const handleFilterChanged = useCallback( (filter) => {
        dispatch({type: 'filterChanged', payload: filter});
    }, []);

    const handleResetFilter = useCallback(() => {
        dispatch({type: 'filterReset'});
    },[]);

    const handleCalendarRangeChanged = useCallback((calendarDay) => {
        dispatch({type: 'calendarDateRangeSupplied', payload: calendarDay});
    }, []);

    const handleCalendarSurfacedChanged = useCallback(() => {
        dispatch({type: 'calendarSurfacedChanged'});
    }, []);

    const handleCalendarAcceptedChanged = useCallback(() => {
        dispatch({type: 'calendarAcceptedChanged'});
    }, []);

    const handlePreferenceButtonClicked = () => {
        dispatch({type: 'preferencesButtonClicked'});
    };

    const handlePreferenceChanged = (preference = {}) => {
        dispatchX(setCSVExportPreference(preference));
    };

    const handlePreferenceSaved = () => {
        dispatchX(saveCSVExportPreferences());
    };

    const handleResetPreferences = () => {
        dispatchX(resetCSVPreferences());
    };

    const handleSaveCSV = () => {
        useExportCSV(state.roleId, talent, csvExportSettings, outcomes);
    };

    if(props.show){
        return (
            <Modal show={props.show} size="lg" bsClass={classes.ModalBackground} animation={false}>
                <div className={classes.ModalWrapper} onClick={handleModalClicked}>
                    <div className={classes.Modal}>
                        <ModalHeader
                            talentFilterOpen={state.talentFilterOpen}
                            talentFilterToggle={handleTalentFilterToggle}
                            calendarFilterOpen={state.calendarFilterOpen}
                            calendarFilterToggle={handleCalendarFilterToggle}
                            calendarFilterDate={state.calendarFilterDate}
                            calendarRangeChanged={handleCalendarRangeChanged}
                            calendarAccepted={state.calendarIncludeAccepted}
                            calendarSurfaced={state.calendarIncludeSurfed}
                            calendarSurfacedChanged={handleCalendarSurfacedChanged}
                            calendarAcceptedChanged={handleCalendarAcceptedChanged}
                            handlePreferenceButtonClicked={handlePreferenceButtonClicked}
                            filters={state.filters}
                            filterChanged={handleFilterChanged}
                            reset={handleResetFilter}
                            preferenceOpen={state.preferenceOpen}
                        />
                        <TalentArea
                            preferences={csvExportSettings}
                            talent={talent}
                            loading={state.loading}
                            preferenceOpen={state.preferenceOpen}
                            preferenceChanged={handlePreferenceChanged}
                            preferenceSaved={handlePreferenceSaved}
                            handleResetPreferences={handleResetPreferences}
                        />
                        <Row className={classes.Footer}>
                            <Col lg={4}>
                                <span className={classes.CounterButton}>{talent.length}</span> Items ready for export
                            </Col>
                            <Col lg={2} lgOffset={4}>
                                {!state.preferenceOpen && <Button bsStyle="danger" block onClick={props.handleHideExportModal}>Cancel</Button>}
                            </Col>
                            <Col lg={2}>
                                {!state.preferenceOpen && <Button bsStyle="success" block onClick={handleSaveCSV}>Download</Button>}
                            </Col>
                        </Row>

                    </div>
                </div>
            </Modal>
        )
    } else {
        return null;
    }
};

export default ExportTalentModal;

ExportTalentModal.propTypes = {};

ExportTalentModal.defaultProps = {};