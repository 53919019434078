import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import classes from './FilterSection.module.css';

const FilterSection = props => {
    return (
        <div className={classes.FilterSection}>
            <Row>
                <Col xs={2}>
                    <div className={classes.Title}>
                        {props.title}
                    </div>
                </Col>
                <Col xs={10}>
                    {props.children}
                </Col>
            </Row>
        </div>
    )
};

export default FilterSection;

FilterSection.propTypes = {
    title: PropTypes.string.isRequired
};

FilterSection.defaultProps = {};