import React, {useEffect, useReducer} from 'react';
import {
    Grid,
    Table,
    Button,
    Form,
    FormControl,
    FormGroup,
    Row,
    Col,
    ButtonGroup,
    Pager,
    Modal
} from 'react-bootstrap';
import {getCharges, patchCharges, patchRoleInfo} from '../../utils/services/turboPlus2RestCalls';
import BillingTableRow from './BillingTableRow/BillingTableRow';
import BillingNotesSection from './BillingNotesSection/BillingNotesSection';
import classes from './Billing.module.css';

function reducer(state, action) {
    switch (action.type) {
        case 'chargesLoading':
            return {
                ...state,
                charges: [],
                chargesLoading: true
            };
        case 'chargesReceived':
            return {
                ...state,
                charges: action.payload.charges.data,
                chargesLoading: false,
                current_page: action.payload.charges.current_page,
                last_page: action.payload.charges.last_page,
                roleFilterSubmitting: false
            };
        case 'chargeSubmitting':
            return {
                ...state,
                chargeSubmitting: true
            };
        case 'chargeUpdated':
            return {
                ...state,
                charges: state.chargeType === "pending" ?
                    state.charges.filter(x => action.payload.id !== x.id) :
                    state.charges.map(x => x.id === action.payload.id ? action.payload : x),
                chargeSubmitting: false,
                chargeInEditMode: false,
            };
        case 'pendingClicked':
            return {
                ...state,
                chargeType: "pending",
                charges: [],
                current_page: 1,
                chargeInEditMode: false,
            };
        case 'processedClicked':
            return {
                ...state,
                chargeType: "processed",
                charges: [],
                current_page: 1,
                chargeInEditMode: false,
            };
        case 'nextPageClicked':
            return {
                ...state,
                current_page: ++state.current_page,
            };
        case 'lastPageClicked':
            return {
                ...state,
                current_page: --state.current_page,
            };
        case 'roleFilterIdsUpdated':
            return {
                ...state,
                roleFilterIds: action.payload,
                current_page: 1,
                chargeInEditMode: false,
            };
        case 'roleCompanyNameFilterChanged':
            return {
                ...state,
                companyName: action.payload,
                current_page: 1,
                chargeInEditMode: false,
            };
        case 'cancelClicked':
            return {
                ...state,
                roleFilterIds: "",
                companyName: "",
                triggerReload: ++state.triggerReload
            };
        case 'showModalClicked': {
            const role = state.charges.find(x => x.id === action.payload).role;
            return {
                ...state,
                showNotesModal: true,
                roleNotes: role.info.notes,
                billingNotes: role.info.billing_notes ? role.info.billing_notes  : "" ,
                modalRoleId: role.id
            };
        }
        case 'hideModalClicked':
            return {
              ...state,
                showNotesModal: false,
                roleNotes: "",
                billingNotes: '',
                notesLoading: false,
                modalRoleId: null
            };
        case 'billingNotesSubmitted':
            return {
                ...state,
                notesLoading: true
            }
        case 'billingNotesChanged':
            return {
                ...state,
                billingNotes: action.payload
            };
        case 'billingNotesUpdated': {
            const {roleId, info} = action.payload;

            //We need to update all the charges that use this role id since they share the same info.
            const charges = state.charges.map(charge => {
                if(charge.role.id === roleId){
                    return {
                        ...charge,
                        role: {
                            ...charge.role,
                            info
                        }
                    }
                }
                return charge;
            })

            return {
                ...state,
                charges,
                showNotesModal: false,
                roleNotes: "",
                billingNotes: '',
                notesLoading: false,
                modalRoleId: null
            };
        }
        default:
            throw new Error();
    }
}

const Billing = () => {
    const [state, dispatch] = useReducer(reducer, {
        chargeType: "pending", // pending or processed
        charges: [],
        chargesLoading: false,
        chargeSubmitting: false,
        chargeInEditMode: false,

        //filter
        roleFilterIds: "",
        companyName: "",

        //pagination
        current_page: 1,
        last_page: 1,
        per_page: 50,

        //notes modal
        showNotesModal: false,
        modalRoleId: null,
        roleNotes: '',
        billingNotes: '',
        notesLoading: false,

        triggerReload: 0 //increment this to reload data after state changed.
    });

    useEffect(() => {
        getChargesAsync();
    }, [state.chargeType, state.current_page, state.triggerReload]);

    const getChargesAsync = async () => {
        dispatch({type: 'chargesLoading'});

        let payload = {
            page: state.current_page,
            items: state.per_page
        };

        if (state.chargeType === "pending") {
            payload = {
                ...payload,
                paid: false,
            }
        }

        if (state.chargeType === "processed") {
            payload = {
                ...payload,
                paid: true,
            }
        }

        if (state.roleFilterIds !== '') {
            payload = {
                ...payload,
                ids: state.roleFilterIds,
            }
        }

        if (state.companyName !== '') {
            payload = {
                ...payload,
                company: state.companyName,
            }
        }

        try {
            const response = await getCharges(payload);
            dispatch({type: "chargesReceived", payload: response});
        } catch (e) {
            console.log("Error", e);
        }
    };

    const handleRoleIdFilterChanged = (e) => {
        const string = e.target.value;

        if (/^([0-9],?)*$/.test(string)) {
            dispatch({type: 'roleFilterIdsUpdated', payload: string});
        }
    };

    const handleCompanyNameFilterChanged = (e) => {
        const string = e.target.value;
        dispatch({type: 'roleCompanyNameFilterChanged', payload: string});
    };

    const handleFilterClicked = (e) => {
        e.preventDefault();
        getChargesAsync();
    };

    const updateCharge = async (id, value) => {
        dispatch({type: "chargeSubmitting"});
        try {
            const response = await patchCharges(id, {paid: value});
            dispatch({type: 'chargeUpdated', payload: response.charge});
        } catch (e) {
            console.log(e);
        }
    };

    const handleCancelClicked = () => {
        dispatch({type: 'cancelClicked'});
    };

    const handleShowNotesModal = chargeId => {
        dispatch({type: 'showModalClicked', payload: chargeId});
    };

    const handleHideNotesModal = () => {
        if(state.notesLoading){
            return;
        }
        dispatch({type: 'hideModalClicked'});
    };

    const handleNotesChanged = e => {
        dispatch({type: 'billingNotesChanged', payload: e.currentTarget.value});
    };

    const handleFormSubmit = e => {
        e.preventDefault();
        dispatch({type: 'billingNotesSubmitted'});

        patchRoleInfo(state.modalRoleId, {billing_notes: state.billingNotes})
            .then(response => {
                dispatch({type: 'billingNotesUpdated', payload: {roleId: state.modalRoleId, info: response.info}});
            })
            .catch(e => console.log(e));
    };

    return (
        <Grid>
            <Row>
                <Col xs={12} style={{textAlign: "center", marginBottom: "15px", marginTop: "0"}}>
                    <h1>Accounting</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{marginBottom: "15px"}}>
                    <ButtonGroup justified>
                        <Button
                            onClick={() => dispatch({type: 'pendingClicked'})}
                            href="#"
                            disabled={state.chargeType === 'pending'}>Pending</Button>
                        <Button
                            onClick={() => dispatch({type: 'processedClicked'})}
                            href="#"
                            disabled={state.chargeType === 'processed'}>Processed</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Form style={{marginBottom: "15px"}} onSubmit={handleFilterClicked}>
                    <Col xs={12} lg={4} lgOffset={4}>
                        <FormGroup controlId="formInlineName">
                            <FormControl
                                onChange={handleCompanyNameFilterChanged}
                                value={state.companyName}
                                type="text"
                                placeholder="Company Name"/>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={2} >
                        <FormGroup controlId="formInlineName">
                            <FormControl
                                onChange={handleRoleIdFilterChanged}
                                value={state.roleFilterIds}
                                type="text"
                                placeholder="Role Ids: 1,23,45"/>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={2}>
                        <FormGroup controlId="formInlineName" style={{display: "flex"}}>
                            <Button type="submit" style={{flex: '1', marginRight: "5px"}}>Filter</Button>
                            <Button type="button" onClick={handleCancelClicked}>Reset</Button>
                        </FormGroup>
                    </Col>
                </Form>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table bordered hover condensed responsive>
                        <thead>
                        <tr>
                            <td>Type</td>
                            <td>Notes</td>
                            <td>Company</td>
                            <td>Role</td>
                            <td>Talent</td>
                            <td>Role talent cost</td>
                            <td>Date Completed</td>
                            <td>Amount Charged</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.charges.map(
                                x => <BillingTableRow
                                    key={x.id}
                                    charge={x}
                                    handleSaveClicked={updateCharge}
                                    handleShowNotesModal={handleShowNotesModal}/>)
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {
                        state.last_page !== 1 ? (
                            <Pager>
                                <Pager.Item
                                    disabled={state.current_page === 1}
                                    previous
                                    href="#"
                                    onClick={() => dispatch({type: 'lastPageClicked'})}>
                                    &larr; Previous Page
                                </Pager.Item>
                                <Pager.Item
                                    disabled={state.current_page === state.last_page}
                                    next
                                    onClick={() => dispatch({type: 'nextPageClicked'})}>
                                    Next Page &rarr;
                                </Pager.Item>
                            </Pager>
                        ) : null
                    }
                </Col>
            </Row>
           <Modal show={state.showNotesModal} onHide={handleHideNotesModal} bsSize="lg">
               <Modal.Header closeButton>
               </Modal.Header>
               <Modal.Body>
                   <BillingNotesSection title="Role Notes">
                       <pre className={classes.Pre}>
                           {state.roleNotes}
                       </pre>
                   </BillingNotesSection>

                   <BillingNotesSection title="Billing Notes">
                       <Form onSubmit={handleFormSubmit}>
                           <Row>
                               <Col xs={12}>
                                   <FormGroup>
                                       <FormControl value={state.billingNotes} onChange={handleNotesChanged} disabled={state.notesLoading} componentClass="textarea" rows="6"/>
                                   </FormGroup>
                               </Col>
                           </Row>
                           <Row>
                                <Col xs={12} lg={2} lgOffset={8} onClick={handleHideNotesModal}>
                                    <Button disabled={state.notesLoading} block>Cancel</Button>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <Button disabled={state.notesLoading} type="submit" bsStyle="success" block>Save</Button>
                                </Col>
                            </Row>
                       </Form>
                   </BillingNotesSection>
               </Modal.Body>
           </Modal>
        </Grid>
    );
};

export default Billing;