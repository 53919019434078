import React from 'react';
import classes from './PinkPopup.module.css';

const PinkPopup = props => {
    return (
        <div className={classes.PopUp} style={props.style}>
            {props.children}
        </div>
    )
};

export default PinkPopup;