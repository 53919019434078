import React from 'react';
import PropTypes from 'prop-types';
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import TalentItemRow from "../TalentItemRow/TalentItemRow";
import TalentListOuterWrapper from "../TalentListOuterWrapper/TalentListOuterWrapper";
import NoTalentMessage from "./components/NoTalentMessage/NoTalentMessage";
import Preferences from "../Preferences/Preferences";

const TalentArea = ({loading, talent, preferenceOpen, preferences, preferenceChanged, preferenceSaved, handleResetPreferences}) => {
    const showTalentList = !loading && !preferenceOpen && talent.length !== 0;
    const showNoTalentMessage = !loading && talent.length === 0;

    return (
        <TalentListOuterWrapper>
            <LoadingWrapper loading={loading}/>
            {showTalentList ? talent.map(x => <TalentItemRow key={x.id} talent={x}/>) : null}
            {showNoTalentMessage && <NoTalentMessage/>}
            <Preferences
                show={preferenceOpen}
                preferences={preferences}
                onChange={preferenceChanged}
                preferenceSaved={preferenceSaved}
                handleResetPreferences={handleResetPreferences}
            />
        </TalentListOuterWrapper>
    )
};

export default React.memo(TalentArea);

TalentArea.propTypes = {
    loading: PropTypes.bool.isRequired,
    talent: PropTypes.array.isRequired,
    preferenceOpen: PropTypes.bool.isRequired,
    preferenceSaved: PropTypes.func.isRequired
};

TalentArea.defaultProps = {};