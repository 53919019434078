import React from 'react';
import PropTypes from 'prop-types';
import {Col, Popover, Row} from "react-bootstrap";
import NewAdvanceTextInput from "../../../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import NewAdvanceSliderButton from "../../../../../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
const NewUserFormArea = (
    {
        isAdmin,
        onNameChanged,
        nameValidationState,
        onTitleChanged,
        titleValidationState,
        onEmailChanged,
        onPhoneChanged,
        phoneValidationState,
        onIsAdminChanged,
        onAllowNotificationsChanged,
        name,
        title,
        phone,
        email,
        allowNotifications,
        emailDisabled,
        nameDisabled,
        phoneDisabled,
        titleDisabled,
        allowNotificationsDisabled,
        inEditMode,
        disableIsAdmin
    }) => {

    const adminRightsPopover = (
        <Popover id="admin-rights-popover" title="Grant Admin rights">
            <span>
                Turning on “Admin rights” will grant this user  access to <span style={{fontWeight: "bold"}}>all</span>
                of the company’s roles, including additional information about the company and its roles that is not
                available for non admin users.!!!!
            </span>
        </Popover>
    );

    return (
        <div style={{marginBottom: '20px'}}>
            <Row>
                <Col xs={12}>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            disabled={nameDisabled}
                            validationState={nameValidationState}
                            label="Name"
                            value={name}
                            onChange={onNameChanged}
                        />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            disabled={titleDisabled}
                            validationState={titleValidationState}
                            label="Title" value={title}
                            onChange={onTitleChanged}
                        />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            disabled={emailDisabled}
                            label="Email"
                            value={email}
                            onChange={onEmailChanged}
                        />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            disabled={phoneDisabled}
                            validationState={phoneValidationState}
                            xs={6} label="Phone Number"
                            value={phone}
                            onChange={onPhoneChanged}
                        />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceSliderButton
                            disabled={allowNotificationsDisabled}
                            label="Allow Notifications?"
                            value={allowNotifications}
                            onChange={onAllowNotificationsChanged}
                        />
                    </Col>
                    {!disableIsAdmin &&
                    <Col xs={6}>
                        <NewAdvanceSliderButton
                            popOver={adminRightsPopover}
                            disabled={inEditMode}
                            label="Grant Admin rights?"
                            value={isAdmin}
                            onChange={onIsAdminChanged}
                        />
                    </Col>
                    }
                </Col>
            </Row>
        </div>
    )
};

export default NewUserFormArea;

NewUserFormArea.propTypes = {
    name: PropTypes.string,
    nameValidationState: PropTypes.string,
    nameDisabled: PropTypes.bool,
    email: PropTypes.string,
    emailDisabled: PropTypes.bool,
    title: PropTypes.string,
    titleValidationState: PropTypes.string,
    titleDisabled: PropTypes.bool,
    phone: PropTypes.string,
    phoneValidationState: PropTypes.string,
    phoneDisabled: PropTypes.bool,
    allowNotifications: PropTypes.bool,
    allowNotificationsDisabled: PropTypes.bool,
    onNameChanged: PropTypes.func.isRequired,
    onTitleChanged: PropTypes.func.isRequired,
    onEmailChanged: PropTypes.func.isRequired,
    onPhoneChanged: PropTypes.func.isRequired,
    onAllowNotificationsChanged: PropTypes.func.isRequired,
    inEditMode: PropTypes.bool,
    disableIsAdmin: PropTypes.bool
};

NewUserFormArea.defaultProps = {
    disableIsAdmin: true
};
