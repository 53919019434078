import React from 'react';
import PropTypes from 'prop-types';
import LittleIconWrapper from "../../../TalentProspectItemWrapper/component/LittleIconWrapper/LittleIconWrapper";
import {faCheck, faMinus, faTimes} from "@fortawesome/free-solid-svg-icons";
import classes from './IconLists.module.css';

const IconList = props => {
    return (
        <div className={classes.IconList}>
            {props.showWaiting &&
                <div className={classes.IconWrapper}>
                    <LittleIconWrapper containerStyle={{ border: 'solid 2px #969696'}} type="empty" active={true} color="#EFEFEF" onClick={() => {}} icon={null}/>
                    <span className={classes.Count}>{props.stats.waiting}</span>
                </div>
            }
            <div className={classes.IconWrapper}>
                <LittleIconWrapper type="prospect_approved" active={true} color="green" onClick={() => {}} icon={faCheck}/>
                <span className={classes.Count}>{props.stats.prospect_approved}</span>
            </div>

            <div className={classes.IconWrapper}>
                <LittleIconWrapper type="saved" active={true} color="gold" onClick={() => {}} icon={faMinus}/>
                <span className={classes.Count}>{props.stats.saved}</span>
            </div>
            {props.showDeclined &&
                <div className={classes.IconWrapper}>
                    <LittleIconWrapper type="declined" active={true} color="red" onClick={() => {}} icon={faTimes}/>
                    <span className={classes.Count}>{props.stats.declined}</span>
                </div>
            }
        </div>
    )
};

export default IconList;

IconList.propTypes = {
    showWaiting: PropTypes.bool,
    showDeclined: PropTypes.bool
};

IconList.defaultProps = {
    showWaiting: true,
    showDeclined: true
};