import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import classes from './RoleBillingItem.module.css';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const RoleBillingItem = props => {

    const icon = props.isInvoice ? (
        <FontAwesomeIcon icon={faFileInvoice}/>
    ) : (
        <a href={props.url} target="_blank">
            <FontAwesomeIcon icon={faCreditCard}/>
        </a>
    );

    const tooltipMessage = props.isInvoice ? "Invoice Role" :"Card Role";

    return (
        <div className={classes.RoleBillingItem}>
            <OverlayTrigger placement="top" overlay={<Tooltip id={"billingToolti" + props.id}>{tooltipMessage}</Tooltip>}>
                {icon}
            </OverlayTrigger>
        </div>
    );
};

export default RoleBillingItem;