import React, {Fragment, useState, useReducer, useEffect} from 'react';
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import {Col, InputGroup, FormControl} from "react-bootstrap";
import RolesHeader from "../RolesHeader/RolesHeader";
import TabItem from "../TabsSection/Components/TabItem/TabItem";
import TabsSection from "../TabsSection/TabsSection";
import GeneralMetrics from "./Components/GeneralMetrics/GeneralMetrics";
import classes from "./MetricsSection.module.css";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MetricsRolesDropdown from "./Components/MetricsRolesDropdown/MetricsRolesDropdown";
import {getCompanyMetricsRequest} from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import RoleMetricsFilters from "./Components/RoleMetricsFilters/RoleMetricsFilters";
import MetricsDetail from "./Components/MetricsDetail/MetricsDetail";

function reducer(state, action) {
    switch (action.type) {
        case 'roleIdChanged':
            return {
                ...state,
                roleId: action.payload,
            };
        case 'rolesFilterChanged':
            return {
                ...state,
                rolesFilter: action.payload,
            };
        default:
            throw new Error();
    }
}

const MetricsSection = () => {
    const reduxDispatch = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const company_metrics = useSelector(state => state.metrics.company_metrics);
    const role_metrics = useSelector(state => state.metrics.role_metrics);
    const [dropdownRolesToShow, setDropdownRolesToShow] = useState([]);
    const [metricsToShow, setMetricsToShow] = useState({});
    const [state, dispatch] = useReducer(reducer, {
        roleId: '0',
        rolesFilter: 'all',
    });
    const [currentTab, setCurrentTab] = useState('general');
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [showMetricsSearch, setShowMetricsSearch] = useState(false);
    const [query, setQuery] = useState('');


    // This calculates and sets metrics data roles per role status when we have one set in the
    // role status filter
    const calculateFilteredRoleMetrics = (roles) => {
        return roles.reduce((combinedMetrics, role) => {
            combinedMetrics.talent_accepted_count += role.talent_accepted_count ?? 0;
            combinedMetrics.talent_commute_distance_count += role.talent_commute_distance_count ?? 0;
            combinedMetrics.talent_comp_differential_count += role.talent_comp_differential_count ?? 0;
            combinedMetrics.talent_diverse_accepted_count += role.talent_diverse_accepted_count ?? 0;
            combinedMetrics.talent_diverse_count += role.talent_diverse_count ?? 0;
            combinedMetrics.talent_diverse_reviewed += role.talent_diverse_reviewed ?? 0;
            combinedMetrics.talent_happy_in_role_count += role.talent_happy_in_role_count ?? 0;
            combinedMetrics.talent_in_progress_count += role.talent_in_progress_count ?? 0;
            combinedMetrics.talent_interested_count += role.talent_interested_count ?? 0;
            combinedMetrics.talent_interview_count += role.talent_interview_count ?? 0;
            combinedMetrics.talent_not_interested_count += role.talent_not_interested_count ?? 0;
            combinedMetrics.talent_seeking_remote_work_count += role.talent_seeking_remote_work_count ?? 0;
            combinedMetrics.talent_seeking_hybrid_work_count += role.talent_seeking_hybrid_work_count ?? 0;
            combinedMetrics.talent_requires_visa_sponsorship_count += role.talent_requires_visa_sponsorship_count ?? 0;
            combinedMetrics.talent_not_open_to_relocation_count += role.talent_not_open_to_relocation_count ?? 0;
            combinedMetrics.talent_previously_contacted_count += role.talent_previously_contacted_count ?? 0;
            combinedMetrics.talent_profile_error_count += role.talent_profile_error_count ?? 0;
            combinedMetrics.talent_refused_count += role.talent_refused_count ?? 0;
            combinedMetrics.talent_rejected_count += role.talent_rejected_count ?? 0;
            combinedMetrics.talent_reviewed += role.talent_reviewed ?? 0;
            combinedMetrics.talent_scheduled_at_count += role.talent_scheduled_at_count ?? 0;
            combinedMetrics.talent_skipped_count += role.talent_skipped_count ?? 0;
            combinedMetrics.talent_surfaced += role.talent_surfaced ?? 0;
            combinedMetrics.talent_waiting_count += role.talent_waiting_count ?? 0;
            return combinedMetrics;
        }, {
            talent_accepted_count: 0,
            talent_commute_distance_count: 0,
            talent_comp_differential_count: 0,
            talent_diverse_accepted_count: 0,
            talent_diverse_count: 0,
            talent_diverse_reviewed: 0,
            talent_happy_in_role_count: 0,
            talent_in_progress_count: 0,
            talent_interested_count: 0,
            talent_interview_count: 0,
            talent_not_interested_count: 0,
            talent_seeking_remote_work_count: 0,
            talent_seeking_hybrid_work_count: 0,
            talent_requires_visa_sponsorship_count: 0,
            talent_not_open_to_relocation_count: 0,
            talent_previously_contacted_count: 0,
            talent_profile_error_count: 0,
            talent_refused_count: 0,
            talent_rejected_count: 0,
            talent_reviewed: 0,
            talent_scheduled_at_count: 0,
            talent_skipped_count: 0,
            talent_surfaced: 0,
            talent_waiting_count: 0,
        });
    };

    useEffect(() => {
        reduxDispatch(getCompanyMetricsRequest(companyId));
    }, [companyId])

    const handleTabSelected = (key) => {
        setCurrentTab(key);
    }

    const handleMetricsSearchFocus = () => {
        setShowMetricsSearch(!showMetricsSearch);
    };

    const clearQuery = () => {
        setQuery('');
        dispatch({ type: 'roleIdChanged', payload: '0' });
    };

    const handleCloseDropdown = () => {
        setShowMetricsSearch(false);
    };

    const handleSearchSelected = (value) => {
        if (isFirstRender) {
            setIsFirstRender(false);
        }
        setQuery(value.name);
        setShowMetricsSearch(false);
        dispatch({ type: 'roleIdChanged', payload: value.id });
    };

    const syncMetricsDataWithRolesFilters = () => {
        if (state.rolesFilter === 'active-roles') {
            let activeRoles = role_metrics.filter((x) => !x.completed);
            setDropdownRolesToShow(activeRoles);
            setMetricsToShow(calculateFilteredRoleMetrics(activeRoles));
        } else if (state.rolesFilter === 'completed-roles') {
            let completedRoles = role_metrics.filter((x) => x.completed);
            setDropdownRolesToShow(completedRoles);
            setMetricsToShow(calculateFilteredRoleMetrics(completedRoles));
        } else {
            setDropdownRolesToShow(role_metrics);
            setMetricsToShow(company_metrics);
        }
    };

    useEffect(() => {
        if (state.roleId && !isFirstRender) {
            let role = role_metrics.find((x) => x.id === Number(state.roleId));

            if (role) {
                setMetricsToShow(role);
            } else {
                if (state.rolesFilter) {
                    syncMetricsDataWithRolesFilters();
                } else {
                    setMetricsToShow(company_metrics);
                    setDropdownRolesToShow(role_metrics);
                }
            }
        }
        // eslint-disable-next-line
    }, [state.roleId]);

    useEffect(() => {
        if (state.rolesFilter && !isFirstRender) {
            syncMetricsDataWithRolesFilters();
        }
        // eslint-disable-next-line
    }, [state.rolesFilter]);

    useEffect(() => {
        if (company_metrics && role_metrics) {
            setMetricsToShow(company_metrics);
            setDropdownRolesToShow(role_metrics);
        }
    }, [company_metrics, role_metrics]);

    const handleChangeFilter = (value) => {
        if (isFirstRender) {
            setIsFirstRender(false);
        }
        setQuery('');
        dispatch({ type: 'roleIdChanged', payload: '0' });
        dispatch({ type: 'rolesFilterChanged', payload: value });
    };

    return (
        <Fragment>
            <BootstrapRowColCombo>
                <Col xs={12}>
                    <RolesHeader />
                </Col>
            </BootstrapRowColCombo>
            <BootstrapRowColCombo>
                <Col md={8}>
                    <InputGroup>
                        <FormControl
                            className={classes.RoleSelectInput}
                            onFocus={() => handleMetricsSearchFocus()}
                            type="text"
                            placeholder="Search by Role"
                            value={query}
                            onClick={() => {
                                if (!showMetricsSearch) {
                                    setShowMetricsSearch(true);
                                }
                            }}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        {query && (
                            <FontAwesomeIcon className={classes.CloseIcon} icon={faTimes} onClick={clearQuery}/>
                        )}
                        <MetricsRolesDropdown
                            closeDropdown={handleCloseDropdown}
                            rowSelected={handleSearchSelected}
                            show={showMetricsSearch}
                            needle={query}
                            dataToshow={dropdownRolesToShow}
                            filter={state.rolesFilter}
                        />
                    </InputGroup>
                </Col>
                <Col md={4} >
                    <RoleMetricsFilters onFilterChange={(val) => handleChangeFilter(val)} />
                </Col>
            </BootstrapRowColCombo>
            <TabsSection activeKey={currentTab} handleTabSelected={handleTabSelected}>
                <TabItem eventKey="general" title="">
                    {state.roleId !== '0' && (
                        <>
                            <MetricsDetail data={role_metrics} roleId={state.roleId} />
                        </>
                    )}

                    {query === '' && (state.rolesFilter === 'active-roles' || state.rolesFilter === 'completed-roles') && (
                        <div style={{ color: '#242424', fontFamily: 'Helvetica', fontSize: '16px', margin: '0 0 16px 4px' }}>
                            Displaying '{state.rolesFilter === 'active-roles' ? 'Active Roles' : 'Completed Roles'}'
                        </div>
                    )}
                    <GeneralMetrics metrics={metricsToShow} />
                </TabItem>
            </TabsSection>

        </Fragment>
    )
};

export default MetricsSection;