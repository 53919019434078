import React, {useEffect, useRef} from 'react';
import classes from './SearchIndustry.module.css';
import useOutsideAreaClick from "../../../../../hooks/useOutsideAreaClick";

const SearchIndustry = (props) => {
    const wrapperRef = useRef(null);

    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if (clickedOutside)
            props.clickedOutSide();
    }, [clickedOutside]);

    return (
        <div className={classes.Wrapper} ref={wrapperRef}>
            { props.industries.length > 0 ?
                <div className={classes.DropDown}>
                    {props.industries.map((x) => (
                        <div className={classes.IndustryItem} onClick={() => props.handleIndustrySelect(x.id, x.name)} key={x.id}>{ x.name }</div>
                    ))}
            </div>
             : <div className={classes.NoResultsItem}>No results found</div> }
        </div>
    )
};

export {SearchIndustry};
