import React, {useState} from 'react';
import classes from './TalentInterviewItem.module.css';
import {Col, Button} from "react-bootstrap";
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import moment from "moment-timezone";

const formatTimeWithZone = (date) => {
    const time = moment.utc(date).local().format("LLL");

    // get the users timezone.
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();

    return `${time} (${timezone}) `
};

const TalentInterviewItem = props => {
    const [loading, setLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const cancelButtonClicked = () => {
        setShowCancelModal(true);
    };

    const handleCancel = () => {
        setShowCancelModal(false);
    };

    const handleCancelConfirm = () => {
        setLoading(true);
        props.handleInterviewCanceled(props.interview.id);
    };

    const time = formatTimeWithZone(props.interview.interview_at);

    return (
        <div>
            {showCancelModal ? (
                <ConfirmationModal
                    show={showCancelModal}
                    disabled={loading}
                    handleCancel={handleCancel}
                    handleConfirm={handleCancelConfirm}>
                    <h2>Cancel this {time} interview?</h2>
                    <p>This will NOT refund any payments and you are free to make more interviews.</p>
                </ConfirmationModal>
            ) : null}
            <Col xs={12} className={classes.TalentInterviewItem}>
                <Col lg={10}>
                    {time}
                    {(props.interview.cronofy_event_id || props.interview.event) && (
                        <span className={classes.CronofySpan}> Cronofy</span>
                    )}
                </Col>
                <Col lg={2}>
                    {props.interview.cancelled_at || props.interview.voided_at ? (
                        <div style={{textAlign: "center", fontWeight: "bold" , textTransform: "uppercase"}}>
                            {props.interview.voided_at ? "Voided" : "Cancelled"}
                        </div>
                    ) : (
                        <Button bsSize="xsmall" block onClick={cancelButtonClicked}>Cancel Interview</Button>
                    )}

                </Col>
            </Col>
        </div>
    )
};

export default TalentInterviewItem;
