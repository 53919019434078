import React, {Fragment} from 'react';
import classes from './AddCompanyHeader.module.css';
import {Col, Row} from "react-bootstrap";

const AddCompanyHeader = ({title, children}) => {
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <h1 className={classes.Header}>{title}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className={classes.Title}>
                        {children}
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
};

export default AddCompanyHeader;