import React from 'react';
import PropTypes from 'prop-types';
import classes from './FormPanel.module.css';
import {Col, Row} from "react-bootstrap";

const FormPanel = ({title, children}) => {
    return (
        <div className={classes.Panel}>
            <div className={classes.Heading}>
                {title}
            </div>
            <div className={classes.Body}>
                <Row>
                    <Col xs={12}>
                        <Col xs={12}>
                            {children}
                        </Col>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default FormPanel;

FormPanel.propTypes = {
    title: PropTypes.string.isRequired
};

FormPanel.defaultProps = {};