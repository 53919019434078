import React from 'react';
import classes from './TalentRowItemTop.module.css';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiFillExclamationCircle } from 'react-icons/ai';
import { faChevronDown, faCircle, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import useBuildAcceptedTooltip from '../../../hooks/useBuildAcceptedTooltip';
import TalentInterestedFlag from '../TalentInterestedFlag/TalentInterestedFlag';
import TalentReviewedStar from '../TalentReviewedStar/TalentReviewedStar';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import apolloIcon from './../../../assets/images/apollo-icon.png';

const TalentRowItemTop = (props) => {
    const mode = useSelector(state => state.siteSettings.mode);
    const outcomes = useSelector(state => state.globalLists.outcomes);
    
    const {
        id,
        name,
        link,
        email,
        mobile_number,
        alt_number,
        follow_ups,
        apollo_contact_id,
        // contact_out,
        // rocket_reach,
    } = props.talent;

    const {
        accepted,
        accepted_on,
        is_reviewed,
        skipped_at,
        skipped_reason,
        outcome_id,
        interested_on,
        created_at,
        scheduled_at,
        surfaced_at,
        calendar_link,
        apollo_sync_failed,
        is_recommended
    } = props.talent.pivot

    const apollo_clear_notifications = props.apollo_clear_notifications;

    const hasFollowUps = !!follow_ups.length;

    // const hasContactOut = !!contact_out.length;
    // const hasRocketReach = !!rocket_reach.length;

    const chevronClass = props.isOpen ?
        [classes.ChevronIcon, classes.ChevronIconActive].join(' ') :
        classes.ChevronIcon;

    const talentRowStyles = props.isOpen ?
        [classes.TalentRowItemTop, classes.TalentRowItemTopSelected].join(' ') :
        classes.TalentRowItemTop;

    const getIconStatus = () => {
        if (skipped_at !== null) {
            return classes.SkippedIcon;
        }

        if (scheduled_at && moment.utc(scheduled_at).local().isAfter(moment())) {
            return classes.ScheduledAtIcon;
        }

        if (outcome_id) {
            return outcome_id === 6 ? classes.InterviewIcon : classes.RefusedIcon;
        }

        if (accepted === true) {
            return classes.AcceptedIcon;
        }

        if (accepted === false) {
            return classes.RejectedIcon;
        }

        return classes.WaitingIcon;
    };

    let message = '';

    // let personal_email_contact_out = '';
    // let phone_contact_out = '';
    // let personal_email_rocket_reach = '';
    // let phone_rocket_reach = '';

    if (hasFollowUps) {
        const followUp = follow_ups[0];
        message = <span style={{ color: 'grey' }}>
            Follow Up: {followUp.abbr + ' ' + moment.utc(followUp.pivot.follow_up_at).local().format('M/D/YY')}
        </span>;
    }

    if (outcome_id) {
        message = <span>Outcome: {outcomes.find(x => x.id === outcome_id).name}</span>;
    }


    // if (hasContactOut) {
    //     personal_email_contact_out = !!contact_out.find(x => x.type === 'personal_email');
    //     phone_contact_out = !!contact_out.find(x => x.type === 'phone');
    // }
    // if (hasRocketReach) {
    //     personal_email_rocket_reach = !!rocket_reach.find(x => x.type === 'personal_email');
    //     phone_rocket_reach = !!rocket_reach.find(x => x.type === 'mobile');
    // }   

    const tooltip = useBuildAcceptedTooltip(id, accepted, accepted_on, scheduled_at, surfaced_at, created_at, skipped_at, skipped_reason);

    //this should added on lines phoneIcon and emailIcon when contact out and rocket reach are fixed
    // || phone_rocket_reach || phone_contact_out
    // || personal_email_rocket_reach || personal_email_contact_out

    const phoneIcon = alt_number || mobile_number  ? <FontAwesomeIcon className={classes.NameRowIcons} icon={faPhone} /> : null;
    const emailIcon = email  ? <FontAwesomeIcon className={classes.NameRowIcons} icon={faEnvelope} /> : null;    
    const apolloSyncFailed = apollo_sync_failed && apollo_clear_notifications === 0 ? <React.Fragment><img className={classes.ApolloIcon} src={apolloIcon} alt="apollo-icon" /><AiFillExclamationCircle className={classes.ApolloSyncFailedIcon} /></React.Fragment> : null;
    const isRecommendedBadge = is_recommended ? <React.Fragment><span className={classes.RecommendedTalentBadge}>AI</span></React.Fragment> : null;

    const adminToolTip = (key) => {
        const id = "talentRowAdminTooltip" + key;
        return (
            <Tooltip id={id}>
                Admin assigned to talent
            </Tooltip>
        );
    };

    return (
        <div className={talentRowStyles} onClick={() => props.handleTalentRowClicked(id)}>
            <div className={classes.TalentInfoWrapper}>
                <div className={classes.TalentInfoRow}>
                    {mode === 'success' &&
                        <TalentInterestedFlag interestedOn={interested_on} handleFlagClicked={props.handleInterestedClicked} />
                    }
                    {mode === 'success' &&
                        <TalentReviewedStar reviewed={is_reviewed} handleFlagClicked={props.handleReviewedClicked} />
                    }
                    <a className={classes.Link} href={link} target="_blank">
                        {name}{phoneIcon}{emailIcon}{apolloSyncFailed}
                    </a>
                    {isRecommendedBadge}
                    {
                        props.connectedToApollo && (
                            <a className={classes.Link} href={props.connectedToApollo && `https://app.apollo.io/#/contacts/${apollo_contact_id}`} target="_blank">
                                <img className={classes.ApolloIcon} src={apolloIcon} alt="apollo-icon" />
                            </a>)
                    }

                </div>
                <div className={classes.TalentMessageRow}>
                    {calendar_link && !outcome_id && <span>Calendar email sent <br /></span>}
                    {message}
                </div>
                <div className={classes.TalentStatusRow}>
                    {mode === 'success' &&
                        <div className={classes.statusItem}>
                            <OverlayTrigger placement="top" overlay={adminToolTip(id)}>
                                <span>
                                    {props.admin}
                                </span>
                            </OverlayTrigger>
                        </div>
                    }
                    <div>
                        Talent Status:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <FontAwesomeIcon className={getIconStatus()} icon={faCircle} />
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            <div className={classes.DropDownIndicatorWrapper}>
                <span className={classes.DropDownIndicator}>
                    <FontAwesomeIcon className={chevronClass} icon={faChevronDown} />
                </span>
            </div>
        </div>
    )
};

TalentRowItemTop.propTypes = {
    connectedToApollo: PropTypes.bool,
}

TalentRowItemTop.defaultProps = {
    connectedToApollo: false
}


function shouldUpdate(prevProps, nextProps) {
    return prevProps.isOpen === nextProps.isOpen && prevProps.talent === nextProps.talent;
}

export default React.memo(TalentRowItemTop, shouldUpdate);
