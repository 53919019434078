import React from 'react';
import classes from './PopupWrapper.module.css';

const PopupWrapper = props => {

    const clickBlocker = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={classes.PopUp} onClick={clickBlocker}>{props.children}</div>
    )
};

export default PopupWrapper;