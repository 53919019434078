import { useState, useEffect } from "react";
import moment from "moment";

const isWaitingTalent = (talent) => {
  return talent.pivot.accepted === null;
};

const isScheduledTalent = (talent) => {
  return (
    talent.pivot.scheduled_at != null &&
    moment.utc(talent.pivot.scheduled_at).isAfter()
  );
};

const isRejectedTalent = (talent) => {
  return talent.pivot.accepted === false;
};

const isInterviewTalent = (talent) => {
  const interviewsFound = talent.interviews.filter(
    ({ voided_at }) => voided_at === null && talent.pivot.outcome_id === 6
  );
  return interviewsFound.length !== 0;
};

const isSkippedTalent = (talent) => {
  return talent.pivot.skipped_at !== null;
};

const isNotAccepted = (talent) => {
  //using outcome_id for now but maybe the back end should return the model relation
  const hasInterviews = talent.interviews.length > 0;
  return (
    (!!talent.pivot.outcome_id && !hasInterviews) ||
    (talent.pivot.outcome_id && talent.pivot.outcome_id !== 6)
  );
};

const buildTalentData = (talentToFilter, filters) => {
  const hasFilters = Object.values(filters).includes(true);

  const {
    accepted,
    unavailable,
    interviews,
    skipped,
    rejected,
    waiting,
    scheduled,
  } = filters;

  let acceptedTalent = []; // green dots
  let interviewTalent = []; // gold dots
  let notAcceptedTalent = []; // purple dots
  let rejectedTalent = []; // red dots
  let scheduledTalent = []; // blue dots
  let talentWaiting = []; // grey dots
  let skippedTalent = []; // darkgrey dots

  /* The order of these are very important. It helps keep our checks simple.*/
  talentToFilter.map((x) => {
    switch (true) {
      case isRejectedTalent(x):
        rejectedTalent = [...rejectedTalent, x];

        break;
      case isSkippedTalent(x):
        skippedTalent = [...skippedTalent, x];

        break;
      case isScheduledTalent(x):
        scheduledTalent = [...scheduledTalent, x];

        break;
      case isWaitingTalent(x):
        talentWaiting = [...talentWaiting, x];

        break;
      case isInterviewTalent(x):
        interviewTalent = [...interviewTalent, x];

        break;
      case isNotAccepted(x):
        notAcceptedTalent = [...notAcceptedTalent, x];

        break;
      default:
        acceptedTalent = [...acceptedTalent, x];
    }
    return x;
  });

  let talent = [];

  if (hasFilters) {
    if (accepted) {
      talent = talent.concat(acceptedTalent);
    }

    if (interviews) {
      talent = talent.concat(interviewTalent);
    }

    if (unavailable) {
      talent = talent.concat(notAcceptedTalent);
    }

    if (rejected) {
      talent = talent.concat(rejectedTalent);
    }

    if (scheduled) {
      talent = talent.concat(scheduledTalent);
    }

    if (waiting) {
      talent = talent.concat(talentWaiting);
    }

    if (skipped) {
      talent = talent.concat(skippedTalent);
    }
  } else {
    talent = [
      ...acceptedTalent,
      ...interviewTalent,
      ...notAcceptedTalent,
      ...rejectedTalent,
      ...scheduledTalent,
      ...talentWaiting,
      ...skippedTalent,
    ];
  }
  return talent;
};

const useTalentToTypesFilter = (talentToFilter, filters) => {
  const [talent, setTalent] = useState([]);

  useEffect(() => {
    setTalent(buildTalentData(talentToFilter, filters));
  }, [talentToFilter, filters]);

  return talent;
};

export default useTalentToTypesFilter;
