
/**
 * Validation for Company info
 */
export const companyNameValidation = value => {
    return /^.{1,100}$/.test(value);
}

export const companyWebsiteValidation = value => {
    return /^https?:\/\/.{1,192}$/.test(value) || value === '';
}

export const companyNotesValidation = value => {
    return /^.{1,1000}$/.test(value) || value === '';
}


/**
 * Validation for User info
 */

export const userNameValidation = value => {
    return /^.{1,100}$/.test(value);
}

export const userTitleValidation = value => {
    return /^.{1,100}$/.test(value) || value === '';
}

export const userEmailValidation = value => {
    return /^.{1,100}$/.test(value);
}

export const userPhoneValidation = value => {
    return /^[0-9]{10,12}$/.test(value) || value === '';
}


/**
 * SimpleValidations
 */

export const isInteger = value => /^[0-9]+$/.test(value);

export const stripeOutNonDigits = value => value.replace(/\D/g, '');

export const formatPhoneNumber = value => {
    const result = value.match(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/);
    if (result) {
        const [, int, area, firstThree, lastFour] = result;
        return !int ? `(${area}) ${firstThree}-${lastFour}` : `+${int} (${area}) ${firstThree}-${lastFour}`;
    }
    return value;
}

export const phoneAddPlusOnSubmit = value => {
    return /^[0-9]{10}$/.test(value) ? `+1${value}` : `+${value}`;
}

export const formatCurrencyFromDollarAmount = value => {
    return value.replace(/[^0-9.]/g, '') * 100;
}

export const formatCurrencyToDollarAmount = value => {
    const price = value / 100;
    return `$${price}`
}

export const isDollarAmount = (price) => {
    return /^[0-9]+\.[0-9]{2}$/.test(price);
};

export const isNumber = value => {
    return /^[0-9]+$/.test(value);
}



/**
 * Simple input checks
 */
export const isSuccessOrNull = validationState => validationState === 'success' || validationState === null;

export const isSuccess = validationState => validationState === 'success';

export const isSourcingOnly = roleType => {
    if (roleType === true) return true;
    if (roleType === false) return false;

    if (roleType == 'Sourcing Only' || roleType == 'Hybrid (Sourcing & Turnkey)') {
        return true
    }
    if (roleType === 1 || roleType === 3) {
        return true
    }

    return false
}