import React from 'react';

const ToastMessageBox = props => {
    const errors = props.errors ? props.errors.map((x,i) => <div key={i}>{x}</div>) : null;
    return (
        <div>
            <div style={{fontWeight: "bold", fontSize: "18px"}}>{props.message}</div>
            {errors}
        </div>
    )
};

export default ToastMessageBox;