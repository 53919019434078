import React from 'react';
import PropTypes from 'prop-types';
import GreyScrollBoxArea from "../../../GreyScrollBoxArea/GreyScrollBoxArea";
import {Tab} from "react-bootstrap";

const TabItem = props => {

    return (
        <Tab eventKey={props.eventKey} title={props.title}>
            <GreyScrollBoxArea>
                {props.children}
            </GreyScrollBoxArea>
        </Tab>
    )
};

export default TabItem;

TabItem.propTypes = {
    eventKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

TabItem.defaultProps = {};