import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row, Well} from "react-bootstrap";
import {useSelector} from 'react-redux';
import ProfileInputComponent from "../ProfileInputComponent/ProfileInputComponent";

const AdminProfileSection = props => {
    const mode = useSelector(state => state.siteSettings.mode);

    return (
        <Well bsSize="large">
            <Row>
                <Col xs={12} lg={6}>
                    <ProfileInputComponent
                        validationState={props.profileOneValidationState}
                        label="Profile Link 1"
                        value={props.profileOne}
                        disabled={true}
                        required
                        onChange={props.handleProfileOneChange}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <ProfileInputComponent
                        validationState={props.profileTwoValidationState}
                        label="Profile Link 2"
                        value={props.profileTwo}
                        disabled={true}
                        required
                        onChange={props.handleProfileTwoChange}
                    />
                </Col>
            </Row>

            {mode === 'delivery' ? (
                <Row>
                    <Col  xs={12}>
                        <FormGroup controlId="industry" validationState={props.industryValidation}>
                            <ControlLabel>Industry</ControlLabel>
                            <FormControl
                                value={props.industry === null ? "" : props.industry}
                                onChange={props.handleIndustryChange}
                                type="text"/>
                            <FormControl.Feedback/>
                            <HelpBlock>Used by Talent Delivery ONLY</HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
            ) : null}

        </Well>
    );
};

export default AdminProfileSection;
