import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button, Panel} from "react-bootstrap";
import TalentProspectItemWrapper from "../TalentProspectItemWrapper/TalentProspectItemWrapper";
import classes from './TalentForReviewForm.module.css';
import usePagination from '../../../../../../../hooks/usePagination';
import IconList from './components/IconList/IconList';
import TalentForReviewFilter from '../../../TalentProspectsFoldingContainer/components/TalentForReviewFilter/TalentForReviewFilter';
import TalentProspectsFoldingContainer from '../../../TalentProspectsFoldingContainer/TalentProspectsFoldingContainer';
import TalentForReviewDownArrow
    from "../../../TalentProspectsFoldingContainer/components/TalentForReviewDownArrow/TalentForReviewDownArrow";
import TalentWrapperComingSoonMessage from "../../../TalentWrapperComingSoonMessage/TalentWrapperComingSoonMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";

const TalentForReviewForm = props => {
    const [isOpen, setIsOpen] = useState(false);

    const [pagination] = usePagination(props.page, props.lastPage, props.talentForReviewPageChanged);

    const openAllLinksInNewWindowsClicked = () => {
        props.talent.forEach(x => {
            window.open(x.link);
        });
    };

    const topHeader = () => {
        return (
            <Panel.Title toggle style={{marginBottom: '10px'}}>
                <Row>
                    <Col xs={6}>
                        <div className={classes.PanelTitle}>
                            Talent for Review
                        </div>
                    </Col>
                    <Col xs={3} xsOffset={3}>
                        <div className={classes.HeaderIconsWrapper}>
                            <div className={classes.OptionsWrapper}>
                                <IconList stats={props.stats}/>
                            </div>
                            <TalentForReviewDownArrow isOpen={isOpen}/>
                        </div>
                    </Col>
                </Row>
            </Panel.Title>
        );
    };

    return (
        <TalentProspectsFoldingContainer expanded={isOpen} onToggle={() => setIsOpen(!isOpen)} headerComponent={topHeader}>
            <Row>
                <Col lg={1}>
                    <Button bsSize="small" block disabled={props.loading} onClick={props.onRefreshTalent}>
                        <FontAwesomeIcon className={props.loading ? classes.SpinnerLoading : classes.Spinner} icon={faSync}/>
                    </Button>
                </Col>
                <Col lg={3} lgOffset={8}>
                    <TalentForReviewFilter filter={props.filter} talentForReviewFilterChanged={props.talentForReviewFilterChanged}>
                        <option value="waiting">Talent for review</option>
                        <option value="prospect_approved">Approved</option>
                        <option value="saved">Saved for later</option>
                        <option value="declined">Declined</option>
                        <option value="all">All Talent</option>
                    </TalentForReviewFilter>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className={classes.TalentForReviewForm}>
                        {/* Don't show auto surfaced talent in the review tabs */}
                        {props.talent.filter(x => !x.pivot.is_auto_surfaced).length === 0 && <TalentWrapperComingSoonMessage/>}
                        {props.talent.filter(x => !x.pivot.is_auto_surfaced).map(x => <TalentProspectItemWrapper
                            key={x.id}
                            talent={x}
                            updateTalentForReview={props.updateTalentForReview}
                            talentForeReviewStatusUpdated={props.talentForeReviewStatusUpdated}
                        />)}
                    </div>
                </Col>

                <Col xs={12}>
                    <Row style={{marginBottom: '10px'}}>
                        <Col xs={6} xsOffset={4}>
                            <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                {pagination}
                            </div>
                        </Col>
                        <Col xs={2}>
                            <Button onClick={openAllLinksInNewWindowsClicked} block>Open All</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TalentProspectsFoldingContainer>
    );
};

export default TalentForReviewForm;

TalentForReviewForm.propTypes = {
    talent: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onRefreshTalent: PropTypes.func.isRequired
};

TalentForReviewForm.defaultProps = {};