import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classes from './RoleInfoSection.module.css';
import { Row, Col, Button, Form } from 'react-bootstrap';
import AdminUserForm from '../AdminFormComponents/AdminUserForm/AdminUserForm';
import AdminDropDown from '../AdminDropDown/AdminDropDown';
import { getAdmins } from '../../../utils/services/turboPlus2RestCalls';
import AdminCoreSection from "../AdminFormComponents/AdminCoreSection/AdminCoreSection";
import AdminProfileSection from '../AdminFormComponents/AdminProfileSection/AdminProfileSection';
import AdminNotesSection from '../AdminFormComponents/AdminNotesSection/AdminNotesSection';
import AdminInfoSection from '../AdminFormComponents/AdminInfoSection/AdminInfoSection';
import AdminAdvanceSection from "../AdminFormComponents/AdminAdvanceSection/AdminAdvanceSection";
import { isEqual, isObject } from 'lodash';
import {
    isRoleNameValid,
    isCompanyNameValid,
    isTotalInterviewsValid,
    isTotalPriceValid,
    isCentsDollarAmount,
    isProfileValid,
    hasOneChar,
    isInvoiceValid,
    isForceCardValid
} from '../AdminFormComponents/AdminFormValidation/AdminFormValidation';
import AdminOptionsSection from "../AdminFormComponents/AdminOptionsSection/AdminOptionsSection";
import { useSelector } from 'react-redux';
import AdminTemplateSection from '../AdminFormComponents/AdminTemplateSection/AdminTemplateSection';

const initState = props => {
    return {
        email: "",
        addUserLoading: false,
        forceValidateForm: false,
        itemInEditMode: null,
        itemIsLoading: false,
        disableSubmit: true,
        admins: props.admins,
        adminDropDownDefaultValue: props.role.admin ? props.role.admin.id : "",
        nameModified: false,
        companyModified: false,
        interviewsModified: false,
        priceModified: false,
        notesModified: false,
        profileOneModified: false,
        profileTwoModified: false,
        interviewModified: false,
        interviewTitleModified: false,
        calendlyLinkModified: false,
        targetSalaryModified: false,
        visaSponsorshipModified: false,
        bonusOfferedModified: false,
        equityOfferedModified: false,
        jDLinkModified: false,
        offerRelocationModified: false,
        willReportToModified: false,
        reportToTitleModified: false,
        teamSizeModified: false,
        officeLocationsModified: false,
        environmentModified: false,
        sellOpportunityModified: false,

        nameIsValid: true,
        companyIsValid: true,
        interviewsValid: true,
        priceValid: true,
        invoiceValid: true,
        forceCardValid: true,
        profileOneValid: true,
        profileTwoValid: true,
        interviewerValid: true,
        interviewTitleValid: true,
        calendlyLinkValid: true,
        targetSalaryValid: true,
        willReportToValid: true,
        reportToTitleValid: true,
        teamSizeValid: true,
        officeLocationsValid: true,
        jDLinkValid: true,
        environmentValid: true,
        sellOpportunityValid: true,
        industryIsValid: true,

        roleNameValidateState: null,
        companyValidateState: null,
        totalInterviewsValidateState: null,
        pricePerInterviewValidateState: null,
        isInvoiceValidateState: null,
        notesValidationState: null,
        profileOneValidationState: null,
        profileTwoValidationState: null,
        interviewValidationState: null,
        interviewTitleValidationState: null,
        calendlyLinkValidationState: null,
        targetSalaryValidationState: null,
        visaSponsorshipValidationState: null,
        bonusOfferedValidationState: null,
        equityOfferedValidation: null,
        offerRelocationValidation: null,
        willReportToValidation: null,
        reportToTitleValidation: null,
        teamSizeValidation: null,
        officeLocationsValidation: null,
        jDLinkValidation: null,
        environmentValidation: null,
        sellOpportunityValidation: null,
        industryValidation: null,
        isForceCardValidateState: null,

        validateCheckInvoice: false,
        validateCheckForceCard: false,

        formHasInfo: !!props.role.info,
        //Saving this during the ats greenhouse add. Remove this if the coast is clear!!
        //formHasInfo: !!props.role.info && props.role.profiles.length > 0,
        role: {
            ...props.role,
            info: {
                ...props.role.info,
                notes: (props.role.info && props.role.info.notes ? props.role.info.notes : "")
            },
        },

    }
};
const isFormValid = (state) => {
    return isRoleNameValid(state.role.name) &&
        isCompanyNameValid(state.role.company) &&
        isTotalInterviewsValid(state.role.interviews) &&
        isTotalPriceValid(state.role.price);
};
const infoReducer = (state, action) => {

    switch (action.type) {
        case 'setEmailValue':
            return {
                ...state,
                email: action.payload
            };
        case 'setItemInEditMode':
            return {
                ...state,
                itemInEditMode: action.payload
            };
        case 'setItemLoading':
            return {
                ...state,
                itemIsLoading: action.payload
            };
        case 'nameValueChanged': {
            const nameIsValid = hasOneChar(action.payload);

            return {
                ...state,
                nameModified: true,
                nameIsValid,
                roleNameValidateState: nameIsValid ? "success" : "error",
                role: { ...state.role, name: action.payload }
            };
        }
        case 'companyValueChanged': {
            const companyIsValid = isCompanyNameValid(action.payload);
            return {
                ...state,
                companyModified: true,
                companyIsValid,
                companyValidateState: companyIsValid ? "success" : "error",
                role: { ...state.role, company: action.payload }
            };
        }
        case 'setProfile0': {
            const profileOneValid = isProfileValid(action.payload);

            return {
                ...state,
                profileOneModified: true,
                profileOneValid,
                profileOneValidationState: profileOneValid ? "success" : "error",
                role: {
                    ...state.role,
                    talent: state.role.talent.map((x, i) => {
                        return i === 0 ? ({
                            ...x,
                            link: action.payload.replace(/\/+$/, '')
                        }) : x;
                    })
                }

            };
        }
        case 'setProfile1': {
            const profileTwoValid = isProfileValid(action.payload);
            return {
                ...state,
                profileTwoModified: true,
                profileTwoValid,
                profileTwoValidationState: profileTwoValid ? "success" : "error",
                role: {
                    ...state.role,
                    talent: state.role.talent.map((x, i) => {
                        return i === 1 ? ({
                            ...x,
                            link: action.payload.replace(/\/+$/, '')
                        }) : x;
                    })
                }

            };
        }
        case 'interviewerValueChanged': {
            const interviewerValid = hasOneChar(action.payload);
            return {
                ...state,
                interviewModified: true,
                interviewerValid,
                interviewValidationState: interviewerValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        interviewer: action.payload
                    }
                }
            };
        }
        case 'setInterviewerTitleValue': {
            const interviewTitleValid = hasOneChar(action.payload);

            return {
                ...state,
                interviewTitleModified: true,
                interviewTitleValid,
                interviewTitleValidationState: interviewTitleValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        interviewer_title: action.payload
                    }
                }
            };
        }
        case 'setCalendlyLinkValue': {
            const calendlyLinkValid = action.payload === '' || hasOneChar(action.payload);
            return {
                ...state,
                calendlyLinkModified: true,
                calendlyLinkValid,
                calendlyLinkValidationState: calendlyLinkValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        calendly_link: action.payload
                    }
                }
            };
        }
        case 'setTargetSalaryValue': {
            const targetSalaryValid = hasOneChar(action.payload);
            return {
                ...state,
                targetSalaryModified: true,
                targetSalaryValid,
                targetSalaryValidationState: targetSalaryValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        target_salary: action.payload
                    }
                }
            };
        }
        case 'setJdLinkValue': {
            const jDLinkValid = hasOneChar(action.payload);
            return {
                ...state,
                jDLinkModified: true,
                jDLinkValid,
                jDLinkValidation: jDLinkValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        jd_link: action.payload
                    }
                }
            };
        }
        case 'setReportToValue': {
            const willReportToValid = hasOneChar(action.payload);
            return {
                ...state,
                willReportToModified: true,
                willReportToValid,
                willReportToValidation: willReportToValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        report_to: action.payload
                    }
                }
            };
        }
        case 'setReportToTitleValue': {
            const reportToTitleValid = hasOneChar(action.payload);
            return {
                ...state,
                reportToTitleModified: true,
                reportToTitleValid,
                reportToTitleValidation: reportToTitleValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        report_to_title: action.payload
                    }
                }
            };
        }
        case 'setTeamSizeValue': {
            const teamSizeValid = hasOneChar(action.payload);
            return {
                ...state,
                teamSizeModified: true,
                teamSizeValid,
                teamSizeValidation: teamSizeValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        team_size: action.payload
                    }
                }
            };
        }
        case 'setOfficeLocationsValue': {
            const officeLocationsValid = hasOneChar(action.payload);
            return {
                ...state,
                officeLocationsModified: true,
                officeLocationsValid,
                officeLocationsValidation: officeLocationsValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        office_locations: action.payload
                    }
                }
            };
        }
        case 'setSellOpportunityValue': {
            const sellOpportunityValid = hasOneChar(action.payload)
            return {
                ...state,
                sellOpportunityModified: true,
                sellOpportunityValid,
                sellOpportunityValidation: sellOpportunityValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        sell_opportunity: action.payload
                    }
                }
            };
        }
        case 'notesValueChanged':
            return {
                ...state,
                notesModified: true,
                notesValidationState: "success",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        notes: action.payload
                    }
                }
            };
        case 'setBonusOfferedValue':
            return {
                ...state,
                bonusOfferedModified: true,
                bonusOfferedValidationState: 'success',
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        bonus_offered: action.payload
                    }
                }
            };
        case 'setEquityOfferedValue':
            return {
                ...state,
                equityOfferedModified: true,
                equityOfferedValidation: 'success',
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        equity_offered: action.payload
                    }
                }
            };
        case 'setVisaSponsorshipValue':
            return {
                ...state,
                visaSponsorshipModified: true,
                visaSponsorshipValidationState: 'success',
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        visa_sponsorship: action.payload
                    }
                }
            };
        case 'setEnvironmentValue': {
            const environmentValid = hasOneChar(action.payload);
            return {
                ...state,
                environmentModified: true,
                environmentValid,
                environmentValidation: environmentValid ? "success" : "error",
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        environment: action.payload
                    }
                }
            };
        }
        case 'setOfferRelocationValue':
            return {
                ...state,
                offerRelocationModified: true,
                offerRelocationValidation: 'success',
                role: {
                    ...state.role,
                    info: {
                        ...state.role.info,
                        offer_relocation: action.payload
                    }
                }
            };
        case 'interviewsValueChanged': {
            const interviewsValid = isTotalInterviewsValid(action.payload);
            return {
                ...state,
                interviewsModified: true,
                interviewsValid,
                totalInterviewsValidateState: interviewsValid ? "success" : "error",
                role: { ...state.role, interviews: action.payload }
            };
        }
        case 'setForceCard': {
            return {
                ...state,
                validateCheckForceCard: true,
                validateCheckInvoice: true, // make the invoice check itself as well
                role: { ...state.role, force_card: action.payload }
            };
        }
        case 'validateCheckForceCard': {
            const forceCardValid = isForceCardValid(state.role)
            return {
                ...state,
                validateCheckForceCard: false,
                forceCardValid,
                isForceCardValidateState: forceCardValid ? null : "error",
            };
        }
        case 'priceValueChanged': {
            const priceValid = action.payload === 0 || isCentsDollarAmount(action.payload);
            return {
                ...state,
                priceModified: true,
                priceValid,
                pricePerInterviewValidateState: priceValid ? "success" : "error",
                role: { ...state.role, price: action.payload }
            };
        }
        case 'invoiceValueChanged': {
            return {
                ...state,
                validateCheckInvoice: true,
                validateCheckForceCard: true, // make force card check itself as well
                role: { ...state.role, invoice: action.payload }
            };
        }
        case 'isTrialValueChanged': {
            return {
                ...state,
                role: { ...state.role, is_trial: action.payload }
            };
        }
        case 'isSourcingOnlyValueChanged':
            return {
                ...state,
                role: { ...state.role, sourcing_only: action.payload }
            };
        case 'cardMessageChanged':
            return {
                ...state,
                role: { ...state.role, card_message_id: action.payload }
            };
        case 'setVaultValue':
            return {
                ...state,
                role: { ...state.role, vault: action.payload }
            };
        case 'setAddUserLoading':
            return {
                ...state,
                addUserLoading: action.payload
            };
        case 'validateForm':
            return {
                ...state,
                forceValidateForm: false,
                disableSubmit: !isFormValid(state)
            }
        case 'validateCheckInvoice':
            const invoiceValid = isInvoiceValid(state.role);
            return {
                ...state,
                validateCheckInvoice: false,
                invoiceValid,
                isInvoiceValidateState: invoiceValid ? null : "error"
            };
        case 'industryChanged':
            return {
                ...state,
                industryIsValid: true,
                industryValidation: "success",
                role: { ...state.role, industry: action.payload }
            };
        case 'handleOnHoldChanged':
            return {
                ...state,
                role: {
                    ...state.role,
                    on_hold: action.payload
                }
            }
        case 'handleRoleCompletedChanged':
            return {
                ...state,
                role: {
                    ...state.role,
                    completed: action.payload
                }
            }
        case 'reset':
            return initState(action.payload);
        default:
            throw new Error();
    }
};

const RoleInfoSection = props => {
    const mode = useSelector(state => state.siteSettings.mode);
    const [state, dispatch] = useReducer(infoReducer, props, initState);
    const isFirstRun = useRef(true);
    const hasNotesToShow = state.role.info !== null && state.role.talent.length > 0

    useEffect(() => {
        if (isFirstRun.current) {
            return;
        }
        dispatch({ type: 'reset', payload: props })
    }, [props.role]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        dispatch({ type: 'validateForm' });
    }, [state.role]);

    // Get Admins
    useEffect(() => {
        let isMounted = true;
        if (props.isOpen) {
            props.fetchAdmins();
        }
        return () => { isMounted = false };
    }, [props.isOpen]);

    useEffect(() => {
        if (state.validateCheckInvoice) {
            dispatch({ type: 'validateCheckInvoice' });
        }
    }, [state.validateCheckInvoice]);

    useEffect(() => {
        if (state.validateCheckForceCard) {
            dispatch({ type: 'validateCheckForceCard' });
        }
    }, [state.validateCheckForceCard]);


    const handleRoleNameChanged = useCallback((e) => {
        dispatch({ type: 'nameValueChanged', payload: e.currentTarget.value });
    }, []);

    const handleCompanyChanged = useCallback((e) => {
        dispatch({ type: 'companyValueChanged', payload: e.currentTarget.value });
    }, []);

    const handleInterviewsChanged = useCallback((e) => {
        dispatch({ type: 'interviewsValueChanged', payload: e.currentTarget.value });
    }, []);

    const handlePriceChanged = useCallback((e) => {
        dispatch({ type: 'priceValueChanged', payload: e });
    }, []);

    const handleIsInvoiceChanged = useCallback((e) => {
        dispatch({ type: 'invoiceValueChanged', payload: e });
    }, []);

    const handleIsTrialChanged = useCallback((e) => {
        dispatch({ type: 'isTrialValueChanged', payload: e });
    }, []);

    const handleIsSourcingOnlyChanged = useCallback((e) => {
        dispatch({ type: 'isSourcingOnlyValueChanged', payload: e });
    }, []);

    const handleIsVaultChanged = useCallback((e) => {
        dispatch({ type: 'setVaultValue', payload: e });
    }, []);

    const handleForceCardChanged = useCallback((e) => {
        dispatch({ type: 'setForceCard', payload: e });
    }, []);

    const handleCardMessageChanged = useCallback((e) => {
        const value = e.currentTarget.value === "" ? null : parseInt(e.currentTarget.value, 10);
        dispatch({ type: 'cardMessageChanged', payload: value });
    }, []);

    const handleProfileOneChange = useCallback((e) => {
        dispatch({ type: 'setProfile0', payload: e.currentTarget.value });
    }, []);

    const handleProfileTwoChange = useCallback((e) => {
        dispatch({ type: 'setProfile1', payload: e.currentTarget.value });
    }, []);

    const handleNotesChanged = useCallback((e) => {
        dispatch({ type: 'notesValueChanged', payload: e.currentTarget.value });
    }, []);

    // const handleInterviewChanged = useCallback((e) => {
    //     dispatch({type: 'interviewerValueChanged', payload: e.currentTarget.value});
    // },[]);
    //
    // const handleInterviewTitleChanged = useCallback((e) => {
    //     dispatch({type: 'setInterviewerTitleValue', payload: e.currentTarget.value});
    // },[]);
    //
    // const handleCalendlyLinkChanged = useCallback((e) => {
    //     dispatch({type: 'setCalendlyLinkValue', payload: e.currentTarget.value});
    // },[]);

    const handleTargetSalaryChanged = useCallback((e) => {
        dispatch({ type: 'setTargetSalaryValue', payload: e.currentTarget.value });
    }, []);

    const handleVisaSponsorshipChanged = useCallback((e) => {
        dispatch({ type: 'setVisaSponsorshipValue', payload: e });
    }, []);

    const handleBonusOfferedChanged = useCallback((e) => {
        dispatch({ type: 'setBonusOfferedValue', payload: e });
    }, []);

    const handleEquityOfferedChanged = useCallback((e) => {
        dispatch({ type: 'setEquityOfferedValue', payload: e });
    }, []);

    const handleJDChanged = useCallback((e) => {
        dispatch({ type: 'setJdLinkValue', payload: e.currentTarget.value });
    }, []);

    // const handleReportToChanged = useCallback((e) => {
    //     dispatch({type: 'setReportToValue', payload: e.currentTarget.value});
    // },[]);
    //
    // const handleReportToTitleChanged = useCallback((e) => {
    //     dispatch({type: 'setReportToTitleValue', payload: e.currentTarget.value});
    // },[]);

    const handleTeamSizeChanged = useCallback((e) => {
        dispatch({ type: 'setTeamSizeValue', payload: e.currentTarget.value });
    }, []);

    const handleOfficeLocationsChanged = useCallback((e) => {
        dispatch({ type: 'setOfficeLocationsValue', payload: e.currentTarget.value });
    }, []);

    const handleOfferRelocationChanged = useCallback((e) => {
        dispatch({ type: 'setOfferRelocationValue', payload: e });
    }, []);

    const handleEnvironmentChanged = useCallback((e) => {
        dispatch({ type: 'setEnvironmentValue', payload: e.currentTarget.value });
    }, []);

    const handleOpportunityChanged = useCallback((e) => {
        dispatch({ type: 'setSellOpportunityValue', payload: e.currentTarget.value });
    }, []);

    const handleOnHoldChanged = useCallback((e) => {
        dispatch({ type: 'handleOnHoldChanged', payload: e });
    });

    const handleRoleCompletedChanged = useCallback((e) => {
        dispatch({ type: 'handleRoleCompletedChanged', payload: e });
    });

    const getDifferenceInRoleStates = (updatedState, oldState) => {
        const isArray = Array.isArray(updatedState);
        const stateDiff = isArray ? [] : {};
        
        if (!oldState.info) {
            oldState.info = { notes: "" }
        }

        const oldIdealTalents = oldState.talent ? oldState.talent : [];
        const newIdealTalents = updatedState.talent ? updatedState.talent : [];

        var zippedTalents = oldIdealTalents.map(function(e, i) {
            return [e, newIdealTalents[i]];
        });

        stateDiff['ideal_profiles'] = [];

        zippedTalents.map((talent, index) => {
            if (talent[0] && talent[1]) {
                if (talent[0].link !== talent[1].link) {
                    stateDiff['ideal_profiles'].push({ id: null, 'link': talent[1].link })
                } else {
                    stateDiff['ideal_profiles'].push({ id: talent[0].id, 'link': talent[0].link })
                }
            }
            return talent;
        });

        for (const key of Object.keys(updatedState)) {
            if ( key !== "talent" && !isEqual(updatedState[key], oldState[key])) {
                if (isObject(updatedState[key])) {
                    stateDiff[key] = getDifferenceInRoleStates(updatedState[key], oldState[key]);
                } else {
                    stateDiff.id = updatedState.id;
                    stateDiff[key] = updatedState[key]
                }
            }
        }

        return isArray ? stateDiff.filter(() => true) : stateDiff;
    }

    const handleIndustryChange = useCallback((e) => {
        dispatch({ type: 'industryChanged', payload: e.currentTarget.value });
    }, []);

    const handleFormResetButtonClicked = useCallback(() => {
        dispatch({ type: 'reset', payload: props })
    }, []);

    const isFormReady = () => {
        const {
            nameIsValid,
            companyIsValid,
            interviewsValid,
            priceValid,
            invoiceValid,
            forceCardValid,
            profileOneValid,
            profileTwoValid,
            interviewerValid,
            interviewTitleValid,
            calendlyLinkValid,
            targetSalaryValid,
            willReportToValid,
            reportToTitleValid,
            teamSizeValid,
            officeLocationsValid,
            jDLinkValid,
            environmentValid,
            sellOpportunityValid
        } = state;

        if (state.formHasInfo) {
            return nameIsValid && companyIsValid && interviewsValid && priceValid && invoiceValid && forceCardValid &&
                profileOneValid && profileTwoValid && interviewerValid && interviewTitleValid &&
                calendlyLinkValid && targetSalaryValid && willReportToValid && reportToTitleValid &&
                teamSizeValid && officeLocationsValid && jDLinkValid && environmentValid && sellOpportunityValid;
        } else {
            return nameIsValid && companyIsValid && interviewsValid && priceValid && invoiceValid;
        }
    };

    const handledAdminChanged = useCallback((e) => {
        let adminId = null;
        if (e.currentTarget.value !== '') {
            adminId = parseInt(e.currentTarget.value, 10);
        }
        if(adminId != null) {
            props.postAdminToRole({adminId: adminId});
        }
    }, []);

    const handleSubmitClicked = (e) => {
        e.preventDefault();
        props.massUpdateRoleInfo(getDifferenceInRoleStates(state.role, props.role));

    };

    const interviewer = state.formHasInfo && state.role.info.contacts ? state.role.info.contacts.find(x => x.role_contact_info.type === "interviewer") : null;
    const reportTo = state.formHasInfo && state.role.info.contacts ? state.role.info.contacts.find(x => x.role_contact_info.type === "report_to") : null;

    return (
        <Row>
            {props.isOpen &&
                <Col xs={12}>
                    {mode === 'success' && props.admins && !!props.admins.length &&
                        <AdminDropDown
                            title="Default Role Admin"
                            value={state.adminDropDownDefaultValue}
                            admins={props.admins}
                            handledAdminChanged={handledAdminChanged}
                        />
                    }
                    <Form onSubmit={handleSubmitClicked}>
                        <AdminCoreSection
                            roleNameValidateState={state.roleNameValidateState}
                            roleNameValue={state.role.name}
                            roleNameOnChange={handleRoleNameChanged}
                            companyValidateState={state.companyValidateState}
                            companyNameValue={state.role.company}
                            companyNameOnChange={handleCompanyChanged}
                            totalInterviewsValidateState={state.totalInterviewsValidateState}
                            totalInterviewsValue={state.role.interviews}
                            totalInterviewsOnChange={handleInterviewsChanged}
                            pricePerInterviewValidateState={state.pricePerInterviewValidateState}
                            pricePerInterviewValue={state.role.price}
                            pricePerInterviewOnChange={handlePriceChanged}
                        />

                        <AdminNotesSection
                            notesValue={state.role.info.notes}
                            notesValidationState={state.notesValidationState}
                            handleNotesChanged={handleNotesChanged}
                        />

                        {state.formHasInfo && hasNotesToShow && (
                            <AdminProfileSection
                                profileOne={state.role.talent[0] ? state.role.talent[0].link : ""}
                                profileTwo={state.role.talent[1] ? state.role.talent[1].link : ""}
                                profileOneDisabled={!state.role.talent[0]}
                                profileTwoDisabled={!state.role.talent[1]}
                                profileOneValidationState={state.profileOneValidationState}
                                profileTwoValidationState={state.profileTwoValidationState}
                                handleProfileOneChange={handleProfileOneChange}
                                handleProfileTwoChange={handleProfileTwoChange}
                                industry={state.role.industry}
                                industryValidation={state.industryValidation}
                                handleIndustryChange={handleIndustryChange}
                            />)}

                        {state.formHasInfo && hasNotesToShow && (
                            <AdminInfoSection
                                sourcingOnly={state.role.sourcing_only}
                                interviewer={interviewer ? interviewer.name : ''}
                                interviewer_title={interviewer ? interviewer.title : ''}
                                calendly_link={interviewer ? interviewer.calendly_link : ''}
                                target_salary={state.role.info.target_salary}
                                targetSalaryValidationState={state.targetSalaryValidationState}
                                handleTargetSalaryChanged={handleTargetSalaryChanged}

                                visaSponsorship={state.role.info.visa_sponsorship}
                                visaSponsorshipValidationState={state.visaSponsorshipValidationState}
                                handleVisaSponsorshipChanged={handleVisaSponsorshipChanged}

                                bonus_offered={state.role.info.bonus_offered}
                                bonusOfferedValidationState={state.bonusOfferedValidationState}
                                handleBonusOfferedChanged={handleBonusOfferedChanged}
                                equity_offered={state.role.info.equity_offered}
                                equityOfferedValidation={state.equityOfferedValidation}
                                handleEquityOfferedChanged={handleEquityOfferedChanged}
                                offer_relocation={state.role.info.offer_relocation}
                                offerRelocationValidation={state.offerRelocationValidation}
                                handleOfferRelocationChanged={handleOfferRelocationChanged}
                                report_to={reportTo ? reportTo.name : ''}
                                willReportToValidation={state.willReportToValidation}
                                report_to_title={reportTo ? reportTo.title : ''}
                                team_size={state.role.info.team_size}
                                teamSizeValidation={state.teamSizeValidation}
                                handleTeamSizeChanged={handleTeamSizeChanged}
                                officeLocationsValidation={state.officeLocationsValidation}
                                handleOfficeLocationsChanged={handleOfficeLocationsChanged}
                                jd_link={state.role.info.jd_link ? state.role.info.jd_link : ""}
                                jDLinkValidation={state.jDLinkValidation}
                                handleJDChanged={handleJDChanged}
                                environment={state.role.info.environment === 'blended' ? 'Hybrid' : state.role.info.environment}
                                environmentValidation={state.environmentValidation}
                                handleEnvironmentChanged={handleEnvironmentChanged}
                                sell_opportunity={state.role.info.sell_opportunity}
                                sellOpportunityValidation={state.sellOpportunityValidation}
                                handleOpportunityChanged={handleOpportunityChanged}
                                offices={state.role.info.offices}
                                locations={state.role.info.locations}
                                specific_location={state.role.info.specific_location}
                                office_locations={state.role.info.office_locations}
                            />
                        )}

                        <AdminTemplateSection template={state.role.apollo_email_template} role={state.role}/>
                        {mode === 'success' &&
                            <AdminOptionsSection
                                isTrialValue={state.role.is_trial}
                                isTrialOnChange={handleIsTrialChanged}

                                isInvoiceValue={state.role.invoice}
                                isInvoiceOnChange={handleIsInvoiceChanged}
                                isInvoiceValidateState={state.isInvoiceValidateState}


                                isSourcingOnly={state.role.sourcing_only}
                                isSourcingOnlyOnChange={handleIsSourcingOnlyChanged}

                                isVaultValue={state.role.vault}
                                isVaultOnChange={handleIsVaultChanged}

                                isForceCardValue={state.role.force_card}
                                isForceCardOnChange={handleForceCardChanged}
                                isForceCardValidateState={state.isForceCardValidateState}
                                showCardMessage={state.role.force_card}
                                selectedCardMessageValue={state.role.card_message_id}
                                handleCardMessageChanged={handleCardMessageChanged} />
                        }

                        <AdminAdvanceSection
                            onHold={state.role.on_hold}
                            completedRole={state.role.completed}
                            completedRoleChanged={handleRoleCompletedChanged}
                            OnHoldChanged={handleOnHoldChanged} />

                        {mode === 'success' &&
                            <AdminUserForm
                                customerId={state.role.customer_id}
                                roleId={state.role.id}
                                billableUserId={state.role.billable_user_id}
                                makeUserBillable={props.makeUserBillable}
                            />
                        }

                        <Row style={{ marginBottom: "15px" }}>
                            <Col xs={12} lg={2} lgOffset={8}>
                                <Button
                                    className={classes.Buttons}
                                    disabled={props.roleUpdating || !isFormReady()}
                                    bsStyle="success"
                                    block
                                    type="submit">
                                    {props.roleUpdating ? "Loading.." : "Update Order"}
                                </Button>
                            </Col>
                            <Col xs={12} lg={2}>
                                <Button
                                    className={classes.Buttons}
                                    block
                                    disabled={props.roleUpdating}
                                    onClick={handleFormResetButtonClicked}>Reset</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            }

        </Row>
    )
};

export default RoleInfoSection;

RoleInfoSection.propTypes = {
    massUpdateRoleInfo: PropTypes.func.isRequired
};
RoleInfoSection.defaultProps = {

};
