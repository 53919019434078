import React, {useRef, useEffect} from 'react';
import classes from './HeaderOptionsButton.module.css';
import useOutsideAreaClick from "../../../hooks/useOutsideAreaClick";
import PropTypes from 'prop-types';
import {IoEllipsisHorizontalSharp} from 'react-icons/io5'


const HeaderOptionsButton = (props) => {
    const wrapperRef = useRef(null);

    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if (clickedOutside)
            props.clickedOutSide();
    }, [clickedOutside]);

    return (
        <div className={classes.HeaderOptionsButtonWrapper} ref={wrapperRef}>
            <div className={classes.HeaderOptionsButton} onClick={props.menuClicked}>
                <IoEllipsisHorizontalSharp/>
            </div>
            <div className={classes.PopUpWrapper}>
                {props.isOpen &&
                    <div className={classes.PopUp}>
                        {props.children}
                    </div>
                }
            </div>
        </div>
    )
};

export default HeaderOptionsButton;

HeaderOptionsButton.propTypes = {
    menuClicked: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    clickedOutSide: PropTypes.func
};

HeaderOptionsButton.defaultProps = {
    isOpen: false,
    clickedOutSide: () => {}
};