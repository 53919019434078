import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from "react-bootstrap";
import classes from "../../EditUserProfile.module.css";

const TopBackButtonSection = ({onClick}) => {
    return (
        <Row className={classes.TopBackButtonSection}>
            <Col xs={3}>
                <Button className={classes.BackButton} block bsStyle="link" onClick={onClick}>
                    &lt; Back to Users
                </Button>
            </Col>
        </Row>
    )
}

export default React.memo(TopBackButtonSection);

TopBackButtonSection.propTypes = {
    onClick: PropTypes.func.isRequired
};

TopBackButtonSection.defaultProps = {};