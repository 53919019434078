import {
    LEFT_MENU_COMPANY_CLICKED,
    LEFT_MENU_MODE_RESET,
    LEFT_MENU_SUB_MENU_ITEM_CLICKED,
    LEFT_MENU_ROLES_REQUESTS_CLICKED,
    LEFT_MENU_ADD_COMPANY_CLICKED,
    LEFT_MENU_ARCHIVED_CLICKED,
    REFRESH_ROWS,
    ROLE_TYPE_CHANGED,
    ROLES_SEARCH_ADMIN_CHANGED,
    ROLES_SEARCH_PAGE_CHANGED,
    ROLES_SEARCH_TYPE_CHANGED,
    ROLES_SEARCH_VALUE_CHANGED,
    ROLES_SEARCH_VALUE_RESET,
    SET_SEARCH_ORDER,
    SET_SEARCH_ORDER_TYPE, RESET_SEARCH_ORDER
} from "./actionsTypes";

export const roleSearchAdminChanged = (searchAdminId) => {
    return {
        type: ROLES_SEARCH_ADMIN_CHANGED,
        payload: searchAdminId
    };
}

export const roleSearchTypeChanged = (value) => {
    return {
        type: ROLES_SEARCH_TYPE_CHANGED,
        payload: value
    };
}

export const roleSearchValueChanged = (value) => {
    return {
        type: ROLES_SEARCH_VALUE_CHANGED,
        payload: value
    };
}

export const roleSearchValueReset = () => {
    return {
        type: ROLES_SEARCH_VALUE_RESET
    };
}

export const roleSearchPageChanged = (page) => {
    return {
        type: ROLES_SEARCH_PAGE_CHANGED,
        payload: page
    };
}

export const roleTypeChanged = (type) => {
    return {
        type: ROLE_TYPE_CHANGED,
        payload: type
    };
}

export const refreshRoles = () => {
    return {
        type: REFRESH_ROWS,
    }
};

export const leftMenuCompanySelected = (companyId) => {
    return {
        type: LEFT_MENU_COMPANY_CLICKED,
        payload: companyId
    }
}

export const leftMenuModeSelected = (mode) => {
    return {
        type: LEFT_MENU_SUB_MENU_ITEM_CLICKED,
        payload: mode
    }
};

export const leftMenuReset = () => {
    return {
        type: LEFT_MENU_MODE_RESET
    }
};

export const AddCompanyMenuClicked = () => {
    return {
        type: LEFT_MENU_ADD_COMPANY_CLICKED
    }
};

export const roleRequestsMenuClicked = () => {
    return {
        type: LEFT_MENU_ROLES_REQUESTS_CLICKED
    }
};

export const toggleIsArchived = () => {
    return {
        type: LEFT_MENU_ARCHIVED_CLICKED
    }
}

export const setSearchSortOrder = (order) => {
    return {
        type: SET_SEARCH_ORDER,
        payload: order
    }
}

export const setSearchType = (type) => {
    return {
        type: SET_SEARCH_ORDER_TYPE,
        payload: type
    }
}

export const resetSearchOrder = () => {
    return {
        type: RESET_SEARCH_ORDER,
    }
}