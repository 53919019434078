import * as actionTypes from  '../actions/actionsTypes';

const POPUP_FILTERS = {
    sourcingPriority: false,
    lowerPriority: false,
    onHold: false,
    talentWaiting: false,
    isTurnkey: false,
    isTrial: false,
    sourcingOnly: false,
    filterPopUpCount: 0,
    isReviewed: false
}

export const initialState = {
    ...POPUP_FILTERS,
};

const saveSettings = (state) => {
    localStorage.setItem('filters', JSON.stringify(state))
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.POPUP_FILTER_CHANGE_REQUEST: {
            const newState = {
                ...state,
                [action.payload]: !state[action.payload],
                filterPopUpCount: !state[action.payload] ? state.filterPopUpCount + 1 : state.filterPopUpCount - 1
            }
            saveSettings(newState);
            return newState;
        }
        case actionTypes.POPUP_FILTERS_RESET:
            saveSettings(initialState);
            return {
                ...state,
                ...POPUP_FILTERS
            }
        default:
            return state;
    }
}

export default reducer;