import React from "react";
import { FormControl } from "react-bootstrap";
import classes from "./DropDownTalentStatusRow.module.css";

const DropDownTalentStatusRow = (props) => {
  return (
    <div className={classes.DropDownTalentStatusRow}>
      <FormControl
        componentClass="select"
        value={props.value}
        onChange={props.handleTalentDropDownChange}
      >
        <option value="all">Filter Status</option>
        {props.talent_in_progress_count && (
          <option value="accepted">Accepted</option>
        )}
        {props.talent_interview_count && (
          <option value="interviews">Interviews</option>
        )}
        {props.talent_refused_count && (
          <option value="unavailable">Unavailable</option>
        )}
        {props.talent_scheduled_at_count && (
          <option value="scheduled">Scheduled</option>
        )}
        {props.talent_waiting_count && <option value="waiting">Waiting</option>}
        {props.talent_rejected_count && (
          <option value="rejected">Rejected</option>
        )}
        {props.talent_skipped_count && <option value="skipped">Skipped</option>}
      </FormControl>
    </div>
  );
};
export default DropDownTalentStatusRow;
