import React, { useState } from "react";
import classes from "./AdminMustHaveFeedback.module.css";
import {
  Col,
  ControlLabel,
  FormGroup,
  Row,
  Well,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
import MustHaveTagItem from "./Component/MustHaveTagItem/MustHaveTagItem";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import FeedbackRow from "./Component/FeedbackRow/FeedbackRow";

const helpTagsPopOver = (
  <Popover id="help-tag popover">
    These ‘Must Haves’ are skills, traits or qualities candidates must possess
    to be a good fit for the role, that were shared and requested by the
    customer.
  </Popover>
);

const helpTag = (
  <OverlayTrigger
    trigger={["focus", "hover"]}
    placement="top"
    overlay={helpTagsPopOver}
  >
    <BsFillQuestionCircleFill className={classes.HelpIcon} />
  </OverlayTrigger>
);

const helpFeedbackPopOver = (
  <Popover id="help-feedback-popover">
    This is feedback shared by the customer regarding their likes and/or
    dislikes about the candidates surfaced to date, on this specific role.
  </Popover>
);

const helpFeedbackTag = (
  <OverlayTrigger
    trigger={["focus", "hover"]}
    placement="top"
    overlay={helpFeedbackPopOver}
  >
    <BsFillQuestionCircleFill className={classes.HelpIcon} />
  </OverlayTrigger>
);

const AdminMustHaveFeedback = (props) => {
  const [showMoreFeedbacks, setShowMoreFeedbacks] = useState(false);
  return (
    
      <Row style={{marginTop: '2rem'}}>
        <Col xs={12}>
          <FormGroup>
            <ControlLabel>
              Customer feedback on the candidates surfaced to date
              {helpFeedbackTag}
            </ControlLabel>

            {props?.feedback?.feedback.length > 0 && (
              <>
                <div
                  style={
                    showMoreFeedbacks
                      ? {
                          height: "330px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }
                      : props?.feedback?.feedback.length > 3
                      ? {
                          height: "290px",
                          overflow: "hidden",
                        }
                      : { overflow: "hidden" }
                  }
                >
                  {props?.feedback?.feedback.map((e) => (
                    <FeedbackRow feedbackData={e} />
                  ))}
                </div>
                {props?.feedback?.feedback.length > 3 && (
                  <Button
                    style={{ width: "195px", marginTop: "10px" }}
                    bsSize="default"
                    onClick={() => setShowMoreFeedbacks(!showMoreFeedbacks)}
                  >
                    {showMoreFeedbacks
                      ? "Show less comments"
                      : "Show more comments"}
                  </Button>
                )}
              </>
            )}
          </FormGroup>
        </Col>
      </Row>
    
  );
};

export default AdminMustHaveFeedback;

// AdminMustHaveFeedback.propTypes = {};
//
// AdminMustHaveFeedback.defaultProps = {};
