import React from 'react';
import classes from './InputAreaDivider.module.css';

const InputAreaDivider = () => {
    return (
        <div className={classes.InputAreaDividerWrapper}>
            <div className={classes.InputAreaDivider}/>
        </div>
    )
};

export default InputAreaDivider;