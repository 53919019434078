import React from 'react';
import classes from './SmallMetricItem.module.css';
import PropTypes from 'prop-types';


const SmallMetricItem = props => {
    return (
        <div className={classes.SmallMetricItem}>
            <div className={classes.IconWrapper} style={{color: props.iconColor}}>
                {props.icon}
            </div>
            <div className={classes.Title}>
                {props.title}
            </div>
            <div className={classes.Value}>
                {props.value}
            </div>
        </div>
    )
};

export default SmallMetricItem;

SmallMetricItem.propTypes = {
    iconColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    icon: PropTypes.object.isRequired
};

SmallMetricItem.defaultProps = {
    value: 0,
    iconColor: '#000000'
};