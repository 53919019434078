import React, {Fragment, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {Well} from "react-bootstrap";
import BootstrapRowColCombo from "../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewTitleHeader from "../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import RoleUserItem from "../../AccountSection/Components/CompanyUsersForm/Components/RoleUserItem/RoleUserItem";
import NewUserForm from "../../AccountSection/Components/CompanyUsersForm/Components/NewUserForm/NewUserForm";
import AddUsersSearch
    from "../../AccountSection/Components/CompanyUsersForm/Components/AddUsersSearch/AddUsersSearch";
import {useSelector, useDispatch} from "react-redux";
import {validateEmail} from "../../../../utils/misc";
import useDebounce from "../../../../hooks/useDebounce";
import {useFirstRender} from "../../../../hooks/useFirstRender";
import {
    getUsers,
    addUserToRole,
    removeUserFromRole,
    getRoleUsers,
    setUserAsInterviewer
} from "../../../../utils/services/turboPlus2RestCalls";
import {getCompanyUsersRequest} from '../../../../store/actions';
import _ from "lodash";
import {showToastError} from "../../../../utils/toastMessages";

const filterUsersAlreadyAddedById = (haystack, needles) => {
    const ids = needles.map(x => x.id);
    return haystack.filter(x => !ids.includes(x.id));
}

const initialState = (props) => {
    return {
        showUserProfile: false,
        showUsersList: true,
        showAddUserSearchForm: true,
        showAddUserSearchButton: true,
        showAddUserForm: false,

        companyUsers: [],


        searchValue: '',
        isEmail: false,
        loading: false,
        searchResults: [],

        showAddNewUserSection: false,

        //RoleInfo
        roleName: "",
        price: "",
        interviews: "",
        isInvoice: false,
        isTrial: false,
        collectCard: false,
        sourcingOnly: false,
        users: [],

        //NewUserItems
        email: '',

        userMenuIdOpen: null
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'SearchValueChanged':
            return {
                ...state,
                searchValue: action.payload,
                loading: true,
                isEmail: validateEmail(action.payload),
                searchResults: action.payload === "" ?
                    filterUsersAlreadyAddedById(state.companyUsers, state.users) :
                    state.searchResults
            }
        case 'searchResultsReturned':
            return {
                ...state,
                loading: false,
                searchResults: filterUsersAlreadyAddedById(action.payload.data, state.users),
                showAddNewUserSection: state.isEmail && action.payload.data.length === 0
            }
        case 'startedUsersSearch':
            return {
                ...state,
                loading: true,
                searchResults: [],
            }
        case 'roleNameChanged':
            return {
                ...state,
                roleName: action.payload
            }
        case 'isInvoiceChanged':
            return {
                ...state,
                isInvoice: action.payload,
            }
        case 'isTrailChanged':
            return {
                ...state,
                isTrial: action.payload,
            }
        case 'isCollectCardChanged':
            return {
                ...state,
                collectCard: action.payload,
            }
        case 'isSourcingChanged':
            return {
                ...state,
                sourcingOnly: action.payload,
            }
        case 'isPriceChanged':
            return {
                ...state,
                price: action.payload
            }
        case 'isInterviewsChanged':
            return {
                ...state,
                interviews: action.payload
            }
        case 'searchInputFocused':
            return {
                ...state,
                searchResults: state.searchValue === "" ?
                    filterUsersAlreadyAddedById(state.companyUsers, state.users) : state.searchResults
            }
        case 'companyUsersUpdated':
            return {
                ...state,
                companyUsers: action.payload
            }
        case 'existingCompanyUserAddedToRole': {
            const users = [
                action.payload,
                ...state.users
            ];

            return {
                ...state,
                loading: false,
                users,
                searchResults: [],
                searchValue: '',
                email: ''
            }
        }
        case 'newCompanyUserAddedToRole': {
            const users = [
                action.payload,
                ...state.users
            ]
            return {
                ...state,
                users,
                searchResults: [],
                showAddUserForm: false,
                showAddNewUserSection: false,
                showAddUserSearchForm: true,
                searchValue: '',
                email: ''
            }
        }
        case 'showCreateNewUserForm':
            return {
                ...state,
                showAddUserSearchButton: false,
                showAddUserForm: true,
                showAddUserSearchForm: false,
                email: action.payload
            }
        case 'cancelCreateNewUserForm':
            return {
                ...state,
                showAddUserForm: false,
                showAddUserSearchForm: true,
                showAddNewUserSection: false
            }
        case 'userMenuClicked':
            return {
                ...state,
                userMenuIdOpen: action.payload
            }
        case 'newUserAddedToRolebot':
            return {
                ...state,
                showAddUserSearchForm: true,
                showAddUserForm: false,
                showAddNewUserSection: false,
                users: [action.payload,...state.users],
                searchResults: [],
                email: '',
                searchValue: ''
            }
        case 'removingUserFromRole':
            return {
                ...state,
                loading: true
            }
        case 'settingUserAsInterviewer':
            return {
                ...state,
                loading: true
            }
        case 'userRemovedFromRole':
            return {
                ...state,
                loading: false,
                users: state.users.filter(x => x.id !== action.payload)
            }
        case 'userSetAsInterviewer':
            state.users.map(x => x.pivot ? x.pivot.is_interviewer = 0 : x);
            let interviewerToChange = state.users.find(x => x.id === action.payload);
            let index = state.users.indexOf(interviewerToChange);
            state.users[index] = {...state.users[index], pivot: {...state.users[index].pivot, is_interviewer: 1}};
            return {
                ...state,
                loading: false,
            }
        case 'addingExistingUserToRole':
            return {
                ...state,
                loading: true
            }
        case 'roleUsersReturned':
            return {
                ...state,
                users: action.payload
            }
        default:
            throw new Error();
    }
}

const AdminUserForm = props => {
    const dispatchRedux = useDispatch();
    const customerId = props.customerId;
    const companies = useSelector(state => state.companies.data);
    const companyUsers = companies.find(x => x.id === props.customerId).users;
    const [state, dispatch] = useReducer(reducer, props, initialState);
    const searchDebounced = useDebounce(state.searchValue, 1000);
    const isFirstRender = useFirstRender();

    useEffect(() => {
        dispatchRedux(getCompanyUsersRequest(customerId));
    }, [customerId]);

    useEffect(() => {
        getRoleUsers(props.roleId, {params: {'get-payment-info': 1}}).then(({users}) => {
            dispatch({type: 'roleUsersReturned', payload: users});
        }).catch(e => console.log(e));
    },[]);

    useEffect(() => {
        dispatch({type: 'companyUsersUpdated', payload: companyUsers})
    }, [companyUsers]);

    useEffect(() => {
        let isMounted = true;
        if(!isFirstRender && searchDebounced !== ''){

            dispatch({type: 'startedUsersSearch'})

            let params = {
                'page': 1,
                'per_page' : 50,
                'customer_id': customerId
            };

            if(state.isEmail){
                params['email'] = searchDebounced;
            } else {
                params['search'] = searchDebounced;
            }

            getUsers({params})
                .then(({data}) => {
                    if(isMounted){
                        dispatch({type: 'searchResultsReturned', payload: data});
                    }
                })
                .catch(e => console.log);
        }
        return () => { isMounted = false };
    }, [searchDebounced]);

    const handleSearchInputFocused = () => {
        dispatch({type: 'searchInputFocused'})
    }

    const handleSearchValueChanged = (e) => {
        dispatch({type: "SearchValueChanged", payload: e.currentTarget.value});
    }

    const handleCancelCreateNewUser = () => {
        dispatch({type: 'cancelCreateNewUserForm'});
    }

    const handleAddUserClicked = () => {
        handleShowCreateNewUserForm(state.searchValue);
    }

    const handleShowCreateNewUserForm = (email) => {
        dispatch({type: 'showCreateNewUserForm', payload: email});
    }

    const handleAddExistingUserToRole = (id) => {
        dispatch({type: 'addingExistingUserToRole'});
        addUserToRole(props.roleId, {id})
            .then((res) => {
                dispatch({type: 'existingCompanyUserAddedToRole', payload: res.user});
            })
            .catch(e => console.log(e))
    }

    const handleTalentMenuClicked = (id) => {
        const itemId = state.userMenuIdOpen === id ? null : id;
        dispatch({type: 'userMenuClicked', payload: itemId});
    }

    const handleRemoveUser = (userId) => {
        dispatch({type: 'removingUserFromRole'});
        removeUserFromRole(props.roleId, userId)
            .then(() => {
                dispatch({type: 'userRemovedFromRole', payload: userId});
            })
            .catch(e => console.log(e));
    }

    const handleSetUserAsInterviewer = (userId) => {
        dispatch({type: 'settingUserAsInterviewer'});
        setUserAsInterviewer(props.roleId, userId, {make_interviewer: 1})
            .then(() => {
                dispatch({type: 'userSetAsInterviewer', payload: userId});
            })
            .catch(e => console.log(e));
    }

    const handleNewUserSubmit = (payload) => {
        addUserToRole(props.roleId, payload)
            .then(({user}) => {
                dispatch({type: 'newCompanyUserAddedToRole', payload: user});
            })
            .catch(e => {
                const message = e.message ? e.message: e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)): [];
                showToastError(message, errors);
            });
    }

    return (
        <Well bsSize="large">
            {state.showAddUserSearchForm &&
                <Fragment>
                    <BootstrapRowColCombo>
                        <NewTitleHeader title="Add Admins to Role"/>
                    </BootstrapRowColCombo>
                    <AddUsersSearch
                        label="Enter User's Name or Email Address"
                        loading={state.loading}
                        searchResults={state.searchResults}
                        searchValue={state.searchValue}
                        handleSearchValueChanged={handleSearchValueChanged}
                        showNewAddUserSection={state.showAddNewUserSection}
                        onAddNewUserButtonClicked={handleAddUserClicked}
                        onAddExistingUserClicked={handleAddExistingUserToRole}
                        handleAddUserClicked={handleNewUserSubmit}
                        onSearchFocused={handleSearchInputFocused}
                    />
                </Fragment>
            }

            {/*User Add Form*/}
            {state.showAddUserForm &&
                <NewUserForm
                    onCancelClicked={handleCancelCreateNewUser}
                    email={state.email}
                    onSubmit={handleNewUserSubmit}
                    disableIsAdmin={true}
                />
            }

            {/*List users tied to this role*/}
            {state.users.length > 0 &&
            <Fragment>
                <BootstrapRowColCombo>
                    <NewTitleHeader title='Role Admin List'/>
                </BootstrapRowColCombo>
                {state.users.map(x =>
                    <RoleUserItem
                        key={x.id}
                        user={x}
                        menuOpen={state.userMenuIdOpen === x.id}
                        menuClicked={handleTalentMenuClicked}
                        removeUserClicked={handleRemoveUser}
                        setuserAsInterviewerClicked={handleSetUserAsInterviewer}
                        isInterviewer={x.pivot ? x.pivot.is_interviewer : 0}
                        cronofyCalendarId={x.cronofy ? x.cronofy.calendar_id : null}
                        isBillable={x.id === props.billableUserId}
                        makeBillable={props.makeUserBillable}
                    />)}
            </Fragment>
            }
        </Well>
    )
};

AdminUserForm.propTypes = {
    roleId: PropTypes.number.isRequired,
    billableUserId: PropTypes.number
};

const stateIdentical = (prev, next) => {
    return (prev.email === next.email) &&
        (prev.defaultCardId === next.defaultCardId) &&
        (prev.users === next.users) && (prev.billableUserId === next.billableUserId);
};

export default React.memo(AdminUserForm, stateIdentical);

