import React, {useCallback} from 'react';
import {Col, Form, Row, FormGroup, FormControl, Checkbox} from "react-bootstrap";
import RolesFilterInput from "../RolesFilterInput/RolesFilterInput";
import classes from './RoleFilterSearchRow.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {
    popupFilterChangeRequest,
    popupFilterResetRequest,
    roleSearchAdminChanged,
    setSearchType,
    setSearchSortOrder,
    resetSearchOrder
} from '../../../store/actions';
import PinkFilter from "../PinkFilter/PinkFilter";
import PinkButton from "../PinkFilter/components/PinkButton/PinkButton";
import FilterTopSection from "./components/Popup/components/FilterTopSection/FilterTopSection";
import FilterSection from "./components/Popup/components/FilterSection/FilterSection";
import Divider from "./components/Popup/components/Divider/Divider";

const RoleFilterSearchRow = () => {
    const dispatch = useDispatch();
    const mode = useSelector(state => state.siteSettings.mode);
    const admins = useSelector(state => state.globalLists.admins);
    const searchAdminId = useSelector(state => state.rolesSearchSettings.searchAdminId);
    const sortType = useSelector(state => state.rolesSearchSettings.sortType);
    const sortOrder = useSelector(state => state.rolesSearchSettings.sortOrder);
    const filterPopUpCount = useSelector(state => state.filters.filterPopUpCount);
    const sourcingPriority = useSelector(state => state.filters.sourcingPriority);
    const lowerPriority = useSelector(state => state.filters.lowerPriority);
    const onHold = useSelector(state => state.filters.onHold);
    const talentWaiting = useSelector(state => state.filters.talentWaiting);
    const isTurnkey = useSelector(state => state.filters.isTurnkey);
    const isTrial = useSelector(state => state.filters.isTrial);
    const sourcingOnly = useSelector(state => state.filters.sourcingOnly);
    const isReviewed = useSelector(state => state.filters.isReviewed);

    const adminChanged = (e) => {
        dispatch(roleSearchAdminChanged(e.target.value));
    };

    const handleSearchSubmit = useCallback((e) => {
        e.preventDefault();
    }, []);

    const searchOrderActive = sortType !== 'default' || sortOrder !== 'desc';

    const filterButton = (
        <PinkButton icon="funnel" label="Filter" count={filterPopUpCount}/>
    );

    const sortButton = (
        <PinkButton icon="sort" label="Sort" count={searchOrderActive?1:0}/>
    );

    return (
        <Form className={classes.Form} onSubmit={handleSearchSubmit}>
            <Row>
                <Col sm={12} lg={6}>
                    <RolesFilterInput/>
                </Col>
                <Col xs={12} lg={2}>
                    <PinkFilter button={filterButton} isActive={filterPopUpCount > 0} negativeMargin={100}>
                        <div className={classes.Popup}>
                            <div style={{minWidth: '500px'}}>
                                <FilterTopSection title="Filters" onClearAllClicked={() => dispatch(popupFilterResetRequest())}/>
                                <FilterSection title="Status">
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('sourcingPriority'))} checked={sourcingPriority}>
                                                Sourcing Priority
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('lowerPriority'))} checked={lowerPriority}>
                                                Lower Priority
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('onHold'))} checked={onHold}>
                                                On Hold
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('talentWaiting'))} checked={talentWaiting}>
                                                Talent waiting
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('isReviewed'))} checked={isReviewed}>
                                                Reviewed by customer
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                </FilterSection>
                                <Divider/>
                                <FilterSection title="Type">
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('isTurnkey'))} checked={isTurnkey}>
                                                Turnkey
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('isTrial'))} checked={isTrial}>
                                                Trial
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className={classes.FormGroup}>
                                            <Checkbox className={classes.Checkbox} onChange={() => dispatch(popupFilterChangeRequest('sourcingOnly'))} checked={sourcingOnly}>
                                                Sourcing Only
                                            </Checkbox>
                                        </FormGroup>
                                    </Col>
                                </FilterSection>
                            </div>
                        </div>
                    </PinkFilter>
                </Col>
                <Col xs={12} lg={2}>
                    <PinkFilter button={sortButton} isActive={searchOrderActive} negativeMargin={245}>
                        <div className={classes.Popup}>
                            <div style={{minWidth: '500px'}}>
                                <FilterTopSection title="Sort Order" onClearAllClicked={() => dispatch(resetSearchOrder())}/>
                                <FilterSection title="Sort by">
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className={classes.FormGroup}>
                                                <FormControl componentClass="select" value={sortType} className={classes.Dropdown}
                                                             onChange={(e) => dispatch(setSearchType(e.currentTarget.value))}>
                                                    <option value="default">Role Creation Date</option>
                                                    <option value="info-date">Filled Info Date</option>
                                                    <option value="date-reviewed">Date & Time Reviewed</option>
                                                    <option value="sourcing-accuracy">Sourcing Accuracy</option>
                                                    <option value="interview-number">Interview Number</option>
                                                </FormControl>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className={classes.FormGroup}>
                                                <FormControl componentClass="select" value={sortOrder} className={classes.Dropdown}
                                                             onChange={(e)=>dispatch(setSearchSortOrder(e.currentTarget.value))}>
                                                    <option value="desc">Descending</option>
                                                    <option value="asc">Ascending</option>
                                                </FormControl>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className={classes.BottomText}>
                                        Date & Time Reviewed sorts roles by the date & time in which a customer reviewed talent
                                    </div>
                                </FilterSection>
                            </div>
                        </div>
                    </PinkFilter>
                </Col>
                <Col sm={12} lg={2}>
                    {mode === 'success' &&
                    <FormGroup controlId="formControlsSelect" className={classes.FormGroup}>
                        <FormControl
                            value={searchAdminId}
                            componentClass="select" placeholder="select" onChange={adminChanged}>
                            <option value="">Select Admin</option>
                            {admins.map(x => <option key={x.id} value={x.id}>{x.user.name}</option>)}
                        </FormControl>
                    </FormGroup>}
                </Col>
            </Row>
        </Form>
    )
};

export default React.memo(RoleFilterSearchRow);