import React from 'react';
import {Row, Col, Button, FormGroup, FormControl, ControlLabel, HelpBlock} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import classes from './AddTalentFormItem.module.css';

const AddTalentFormItem = props => {
    const {errors, touched} = props;

    const getTalentValidationState = (item,index) => {
        return touched.talent && touched.talent[index] && touched.talent[index][item] &&
        errors.talent && errors.talent[index] && errors.talent[index][item] ? 'error' : null
    }

    const getTalentErrorMessage = (item,index) => {
        return errors.talent && errors.talent[index] ?
            <HelpBlock>{errors.talent[index][item]}</HelpBlock> :
            null
    }

    return (
        <Row style={{marginBottom: '20px'}}>
            <Col xs={12} lg={1} style={{paddingTop: '25px'}}>
                <Button className={classes.CounterButton} block bsStyle="primary">{props.index + 1}</Button>
            </Col>
            <Col xs={12} lg={3}>
                <FormGroup validationState={getTalentValidationState('name', props.index)}>
                    <ControlLabel>Talent Name</ControlLabel>
                    <FormControl
                        name={`talent.${props.index}.name`}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.talent.name}
                        required
                        type="text"
                        placeholder="Talent Name"
                        disabled={props.isSubmitting}
                    />
                    {getTalentErrorMessage('name', props.index)}
                </FormGroup>
            </Col>
            <Col xs={12} lg={4}>
                <FormGroup validationState={getTalentValidationState('link', props.index)}>
                    <ControlLabel>Talent Link</ControlLabel>
                    <FormControl
                        name={`talent.${props.index}.link`}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.talent.link}
                        required
                        type="text"
                        placeholder="Talent Link"
                        disabled={props.isSubmitting}
                    />
                    {getTalentErrorMessage('link', props.index)}
                </FormGroup>
            </Col>
            <Col xs={12} lg={3}>
                <FormGroup validationState={getTalentValidationState('company', props.index)}>
                    <ControlLabel>Current Company</ControlLabel>
                    <FormControl
                        name={`talent.${props.index}.company`}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.talent.company}
                        type="text"
                        placeholder="Current Company"
                        disabled={props.isSubmitting}
                    />
                    {getTalentErrorMessage('company', props.index)}
                </FormGroup>
            </Col>
            <Col xs={1} style={{paddingTop: '25px'}}>
                <Button
                    disabled={props.isSubmitting}
                    onClick={() => props.deleteTalent(props.index)}
                    block
                    bsStyle="danger"
                >
                    {<FontAwesomeIcon icon={faTrashAlt}/>}
                </Button>
            </Col>
        </Row>
    )
};

export default AddTalentFormItem;

AddTalentFormItem.propTypes = {
    talent: PropTypes.object.isRequired,
};

AddTalentFormItem.defaultProps = {};