import React, { useReducer } from 'react';
import {Checkbox, Col, FormGroup} from "react-bootstrap";
import PinkFilter from "../../../PinkFilter/PinkFilter";
import classes from "./RoleMetricsFilters.module.css";
import FilterSection from "./FilterSection";
import PinkButton from "../../../PinkFilter/components/PinkButton/PinkButton";

function reducer(state, action) {
  switch (action.type) {
    case 'showFiltersIsChanged':
      return {
        ...state,
        showFilters: action.payload,
      };
    case 'filterByActiveRolesChanged':
      return {
        ...state,
        filterByActiveRoles: action.payload,
        filterByCompletedRoles: false,
      };
    case 'filterByCompletedRolesChanged':
      return {
        ...state,
        filterByCompletedRoles: action.payload,
        filterByActiveRoles: false,
      };
    default:
      throw new Error();
  }
}

const RoleMetricsFilters = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    showFilters: false,
    filterByActiveRoles: false,
    filterByCompletedRoles: false,
  });

  const handleFilterByActiveRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterByActiveRolesChanged', payload: true });
      props.onFilterChange('active-roles');
    } else {
      dispatch({ type: 'filterByActiveRolesChanged', payload: false });
      props.onFilterChange('all');
    }
  };

  const handleFilterByCompletedRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterByCompletedRolesChanged', payload: true });
      props.onFilterChange('completed-roles');
    } else {
      dispatch({ type: 'filterByCompletedRolesChanged', payload: false });
      props.onFilterChange('all');
    }
  };

  const filterButton = (
      <PinkButton icon="funnel" label="Filter" count={(state.filterByActiveRoles || state.filterByCompletedRoles) ? 1 : 0}/>
  );

  return (
      <Col xs={12} lg={6} style={{paddingLeft: 0}}>
        <PinkFilter button={filterButton} isActive={state.filterByActiveRoles || state.filterByCompletedRoles}>
          <div className={classes.Popup}>
            <div style={{minWidth: '250px'}}>
              <FilterSection title="Filter by Status">
                <Col xs={12}>
                  {/*<FormGroup className={classes.FormGroup}>*/}
                    <Checkbox className={classes.Checkbox} onChange={handleFilterByActiveRoles} checked={state.filterByActiveRoles}>
                      Active Roles
                    </Checkbox>
                  {/*</FormGroup>*/}
                </Col>
                <Col xs={12} style={{paddingBottom: 10}}>
                  {/*<FormGroup className={classes.FormGroup}>*/}
                    <Checkbox className={classes.Checkbox} onChange={handleFilterByCompletedRoles} checked={state.filterByCompletedRoles}>
                      Completed Roles
                    </Checkbox>
                  {/*</FormGroup>*/}
                </Col>
              </FilterSection>
            </div>
          </div>
        </PinkFilter>
      </Col>
  );
};

export default RoleMetricsFilters;
