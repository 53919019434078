import {
    POPUP_FILTERS_RESET,
    POPUP_FILTER_CHANGE_REQUEST
} from './actionsTypes';


export const popupFilterChangeRequest = (filter) => {
    return {type: POPUP_FILTER_CHANGE_REQUEST, payload: filter};
}

export const popupFilterResetRequest = () => {
    return {type: POPUP_FILTERS_RESET}
}