import React, {Component} from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classes from './SideMenu.module.css';
import Aux from '../../../hoc/AuxWrapper/AuxWrapper';
import * as actions from '../../../store/actions';
import NavBackdrop from './../../Ui/NavBackdrop/NavBackdrop';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    signOutHandler = event => {
        this.props.onSignOut();
    };

    render () {
        let attachedClasses = [classes.SideDrawer, classes.Close];

        if(this.props.showMenu){
            attachedClasses = [classes.SideDrawer, classes.Open];
        }

        return (
            <Aux>
                <NavBackdrop show={this.props.showMenu} clicked={this.props.clicked} />
                <div className={attachedClasses.join(' ')}>
                    <ul>
                        <li className={classes.NavigationItem}>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/billing">Billing</NavLink>
                        </li>
                        <li onClick={this.signOutHandler}>
                            <span>Sign Out</span>
                        </li>
                    </ul>
                </div>
            </Aux>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSignOut : () => dispatch(actions.authLogout())
    }
};

export default withRouter(connect(null, mapDispatchToProps)(SideMenu));