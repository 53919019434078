import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./FeedbackRow.module.css";
import { getTimeinHourOrDayByDate } from "../../../../../../utils/dates";
import useGetUserInitials from "../../../../../../hooks/useGetUserInitials";

const FeedbackRow = ({ feedbackData }) => {
  return (
    <div className={classes.feedback_container}>
      <Row>
        <Col xs={6}>
          <div className={classes.feedback_container_row}>
            <div className={classes.avatar}>
              <span className={feedbackData?.user?.deleted_at ? classes.inactive_avatar_name : classes.avatar_name}>
                {useGetUserInitials(feedbackData?.user?.name)}
              </span>
            </div>
            <span className={feedbackData?.user?.deleted_at ? classes.inactive_user_name : ''}>
              {feedbackData?.user?.name} {feedbackData?.user?.deleted_at ? '(Inactive)' : ''}
            </span>
          </div>
        </Col>
        <Col xs={6} className={classes.feedback_timer}>
          {getTimeinHourOrDayByDate(feedbackData?.created_at)}
        </Col>
      </Row>
      <Row className={classes.feedback_description}>
        <Col xs={12}>{feedbackData?.feedback}</Col>
      </Row>
    </div>
  );
};

export default FeedbackRow;
