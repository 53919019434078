import React from 'react';
import classes from './MainMenuItem.module.css';
import {Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {useSelector, useDispatch} from 'react-redux';
import {leftMenuReset, AddCompanyMenuClicked, roleRequestsMenuClicked} from '../../../../../../store/actions';

const ListItem = ({active, text, icon, handleClick, counter}) => {
    const style = active ? {color: '#43d2c8'} : {};
    return (
        <li style={style} onClick={handleClick}>
            <FontAwesomeIcon icon={icon}/>{text} {counter > 0 && <Badge className={classes.Badge}>{counter}</Badge>}
        </li>
    );
};


const MainMenuItem = () => {
    const dispatch = useDispatch();
    const menuMode = useSelector(state => state.rolesSearchSettings.menuMode);
    const showAddCompanySection = useSelector(state => state.rolesSearchSettings.showAddCompanySection);
    const showRoleRequestsSection = useSelector(state => state.rolesSearchSettings.showRoleRequestsSection);
    const roleRequests = useSelector(state => state.roleRequests.data);

    const activeRequests = roleRequests.filter(x => x.on_hold === false);

    const handleALlCompanyClicked = () => {
        dispatch(leftMenuReset());
    }

    const handleAddCompanyClicked = () => {
        dispatch(AddCompanyMenuClicked());
    };

    const handleRoleRequestsClicked = () => {
        dispatch(roleRequestsMenuClicked());
    }

    return (
        <ul className={classes.MainMenuItem}>
            <ListItem active={menuMode === 'all'} icon={faList} text="All Customers" handleClick={handleALlCompanyClicked}/>
            <ListItem active={showAddCompanySection} icon={faPlusCircle} text="Add Customer" handleClick={handleAddCompanyClicked}/>
            <ListItem active={showRoleRequestsSection} icon={faCheckCircle} text="Client Requests" handleClick={handleRoleRequestsClicked} counter={activeRequests.length}/>
        </ul>
    )
};

export default MainMenuItem;