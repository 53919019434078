import React, {Fragment, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import AddUsersSearch from "../AddUsersSearch/AddUsersSearch";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {useFirstRender} from "../../../../../../../hooks/useFirstRender";
import useDebounce from "../../../../../../../hooks/useDebounce";
import {validateEmail} from "../../../../../../../utils/misc";
import NewTitleHeader from "../../../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";

const initialState = (users) => {
    return {
        ignoreUsersIds: users.map(x => x.id),

        searchValue: '',
        isEmail: false,
        loading: false,
        searchResults: [],

        showAddNewUserSection: false,
    };
};

function reducer(state, action) {
    switch (action.type) {
        case 'SearchValueChanged':
            return {
                ...state,
                searchValue: action.payload,
                loading: true,
                isEmail: validateEmail(action.payload),
                error: ''
            }
        case 'userEmailReadyToBeAdded':
            return {
                ...state,
                loading: false,
                showAddNewUserSection: true,
                error: ''
            }
        case 'startedUsersSearch':
            return {
                ...state,
                loading: true,
                showAddNewUserSection: false
            }
        case 'emailIsInvalid':
            return {
                ...state,
                loading: false,
                error: 'Must be a valid email!',
                showAddNewUserSection: false,
            }
        default:
            throw new Error();
    }
}

const AddUserSection = ({loading, users, onCancelSearchClicked, onAddNewUserButtonClicked, onAddExistingUserClicked, companyName, companyId}) => {
    const [state, dispatch] = useReducer(reducer, users, initialState);
    const isFirstRender = useFirstRender();
    const searchDebounced = useDebounce(state.searchValue, 1000);

    useEffect(() => {
        let isMounted = true;
        if(!isFirstRender && searchDebounced !== ''){

            dispatch({type: 'startedUsersSearch'});

            if(state.isEmail){
                dispatch({type: 'userEmailReadyToBeAdded'});
            } else {
                dispatch({type: 'emailIsInvalid'});
            }

        }
        return () => { isMounted = false };
    }, [searchDebounced]);

    const handleSearchValueChanged = (e) => {
        dispatch({type: "SearchValueChanged", payload: e.currentTarget.value});
    }

    const handleAddUserClicked = () => {
        if (!validateEmail(state.searchValue)) {
            return;
        }
        onAddNewUserButtonClicked(state.searchValue);
    }

    const titleHeader = `Add User's to ${companyName}`;

    return (
        <Fragment>
            <NewTitleHeader title={titleHeader}/>
            <AddUsersSearch
                label="Enter user's name or email address"
                searchResults={state.searchResults}
                searchValue={state.searchValue}
                handleSearchValueChanged={handleSearchValueChanged}
                showNewAddUserSection={state.showAddNewUserSection}
                onAddNewUserButtonClicked={handleAddUserClicked}
                onAddExistingUserClicked={onAddExistingUserClicked}
                handleAddUserClicked={handleAddUserClicked}
                loading={loading}
            />
            {state.error && (
                <Row>
                    <Col xs={12}>
                        <Alert bsStyle="danger">
                            {state.error}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={2} xsOffset={10}>
                    <Button block onClick={onCancelSearchClicked}>Cancel</Button>
                </Col>
            </Row>
            <div style={{borderBottom: '3px gray solid', margin: '30px 0'}}/>
        </Fragment>
    )
};

export default AddUserSection;

AddUserSection.propTypes = {
    onCancelSearchClicked: PropTypes.func.isRequired,
    onAddNewUserButtonClicked: PropTypes.func.isRequired,
    onAddExistingUserClicked: PropTypes.func.isRequired
};