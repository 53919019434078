import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { MdOpenInNew } from 'react-icons/md';
import classes from './ProfileInputComponent.module.css';
import greenCheckIcon from "../../../../../src/assets/svg/green-check-icon.svg"
import redXIcon from "../../../../../src/assets/svg/red-x-icon.svg"

import { Tooltip, OverlayTrigger } from "react-bootstrap";

const ProfileInputComponent = props => {

    const handleLinkClicked = () => {
        window.open(props.value, '_blank');
    }

    const tooltip = (
        <Tooltip id="tooltip">
            {props.IsRoverScanner ? "Scanned with Rover" : "Not scanned with Rover"}
        </Tooltip>
    );

    return (
        <FormGroup validationState={props.validationState}>
            <ControlLabel className={classes.Label}>{props.label}<MdOpenInNew onClick={handleLinkClicked} className={classes.Icon} />
                {typeof props.IsRoverScanner === "boolean" && (<OverlayTrigger placement="top" overlay={tooltip}>
                    <img src={props.IsRoverScanner ? greenCheckIcon : redXIcon} className={classes.check_img} alt="Rover scanner" />
                </OverlayTrigger>)}

            </ControlLabel>
            <FormControl
                disabled={props.disabled}
                required={props.required}
                value={props.value}
                onChange={props.onChange}
                type="text" />
            <FormControl.Feedback />
        </FormGroup>
    )
};

export default ProfileInputComponent;

ProfileInputComponent.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func
};

ProfileInputComponent.defaultProps = {
    disabled: false,
    required: false
};