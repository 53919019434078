import React from 'react';
import {Row, Col} from 'react-bootstrap';
// import PropTypes from 'prop-types';

/**
 * Used to simplify a nested Column in a Row.
 *
 * <Row><Col xs='12'>{props.children}</Col></Row>
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BootstrapRowColCombo = props => {
    return (
        <Row>
            <Col {...props}>
                {props.children}
            </Col>
        </Row>
    )
};

export default BootstrapRowColCombo;

BootstrapRowColCombo.propTypes = {
}

BootstrapRowColCombo.defaultProps = {
    xs: 12
};