import React from 'react';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import classes from './DatePickerInput.module.css';

const DatePickerInput = props => {
    return (
        <DatePicker
            className={classes.DatePickerInput}
            id={props.id}
            selected={props.selected}
            onChange={props.onChange}
            showTimeSelect
            placeholderText={props.placeholderText}
            dateFormat={props.dateFormat}
            popperPlacement="top-end"
            openToDate={new Date()}
            disabled={props.disabled}
        />
    );
};

export default  DatePickerInput;

DatePickerInput.propTypes = {
    id: PropTypes.string.isRequired,
};

DatePickerInput.defaultProps = {
    disabled: false
};