import React, { useState, useEffect } from "react";
import Echo from "./utils/echo";
import { Route, Switch, withRouter } from "react-router-dom";
import Auth from "./containers/Auth/Auth";
import TurboPlus2 from "./containers/TurboPlus2/TurboPlus2";
import Billing from "./containers/Billing/Billing";
import classes from "./hoc/Layout/Layout.module.css";
import TopNav from "./components/Navigation/TopNav/TopNav";
import SideDrawer from "./components/Navigation/SideDrawer/SideMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/recharts.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminList,
  getOutcomeList,
  getRoleRequestsRequest,
} from "./store/actions";
import { getCsrfCookie } from "./utils/services/turboPlus2RestCalls";
import "react-datepicker/dist/react-datepicker.min.css"; //include this to cover all react-datepicker css!

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const adminsReady = useSelector((state) => state.globalLists.adminsReady);
  const outcomesReady = useSelector((state) => state.globalLists.outcomesReady);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    //get csrf cookie on login
    getCsrfCookie();
  }, []);

  /** start of init stack **/
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAdminList());
    } else {
      setShowMenu(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && adminsReady) {
      dispatch(getOutcomeList());
    }
  }, [isAuthenticated, adminsReady]);

  useEffect(() => {
    if (isAuthenticated && adminsReady && outcomesReady) {
      dispatch(getRoleRequestsRequest());
    }
  }, [isAuthenticated, adminsReady, outcomesReady]);
  /** end of init stack **/
  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === "false" || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
      Echo.channel("public").listen("RoleRequestGenerated", (e) => {
        if (isAuthenticated) {
          dispatch(getRoleRequestsRequest());
        }
      });
      return () => Echo.leaveChannel("public");
    }
  }, []);

  const sideDrawerToggleClickedHandler = () => {
    setShowMenu(!showMenu);
  };

  const routes = isAuthenticated ? (
    <Switch>
      <Route exact path="/" render={() => <TurboPlus2 />} />
      <Route exact path="/billing" component={Billing} />
    </Switch>
  ) : (
    <Route path="/" component={Auth} />
  );

  let layout = isAuthenticated
    ? classes.Layout
    : [classes.Layout, classes.IsAuth].join(" ");

  return (
    <div style={{ height: "100%" }}>
      <div className={layout}>
        {isAuthenticated && <TopNav clicked={sideDrawerToggleClickedHandler} />}
        <SideDrawer
          showMenu={showMenu}
          clicked={sideDrawerToggleClickedHandler}
        />
        <main className={classes.Main}>{routes}</main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default withRouter(App);
