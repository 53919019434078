import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import useOutsideAreaClick from "../../../hooks/useOutsideAreaClick";
import RoleTags from "./components/RoleTags/RoleTags";

const RoleTagsWrapper = (props) => {
    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if(clickedOutside){
            handleClickedOutside();
        }
    }, [clickedOutside])

    const handleClickedOutside = () => {
        props.clearSearchResults();
        props.setSearchTerm('');
    }

    return (
        <Row>
            <Col xs={12}>
                <div ref={wrapperRef}>
                    <RoleTags {...props}/>
                </div>
            </Col>
        </Row>
    )
};

export default RoleTagsWrapper

RoleTagsWrapper.propTypes = {
    onSubmitSubmit: PropTypes.func.isRequired,
    clearSearchResults: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired
};

RoleTagsWrapper.defaultProps = {};