import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import RoleInfoHistory from "../RoleInfoHistory/RoleInfoHistory";
import {HiOutlineClock} from 'react-icons/hi';
import {FaExchangeAlt} from 'react-icons/fa';
import {AiOutlineUserSwitch} from 'react-icons/ai'
import {BiCommentDetail} from 'react-icons/bi';
import classes from './RoleHistoryItem.module.css';

const RoleHistoryItem = (props) => {

    let icon;
    switch (props.item.type) {
        case 'Client Request':
            icon = <AiOutlineUserSwitch className={classes.TitleColumnIcon}/>;
            break;
        case 'Role Status':
            icon = <FaExchangeAlt className={classes.TitleColumnIcon}/>;
            break;
        default:
            icon = <BiCommentDetail className={classes.TitleColumnIcon}/>;
    }

    return (
        <tr className={classes.RoleHistoryItem}>
            <td className={classes.DateColumn}>
                <HiOutlineClock className={classes.ClockIcon}/>{moment.utc(props.item.created_at).fromNow(true)}
            </td>
            <td style={{textAlign: 'center'}}>{props.item.user?.name}</td>
            <td className={classes.TitleColumn}>
                <span className={classes.TitleIconWrapper}>{icon}</span>{props.item.type}
            </td>
            <RoleInfoHistory type={props.item.type} oldValue={props.item.old_value} newValue={props.item.new_value}/>
        </tr>
    )
};

export default RoleHistoryItem;

RoleHistoryItem.propTypes = {
    item: PropTypes.object.isRequired
};

RoleHistoryItem.defaultProps = {};