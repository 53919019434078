import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormGroup, Row, Col, Checkbox, FormControl, Button} from "react-bootstrap";
import classes from "./RoleTags.module.css";
import TagDragDropSection from "../TagDragDropSection/TagDragDropSection";
import TagSearchDropDown from "../TagSearchDropDown/TagSearchDropDown";
import RecommendationSection from "../RecommendationSection/RecommendationSection";
import {useFirstRender} from "../../../../../hooks/useFirstRender";
import PinkButton from "../../../PinkFilter/components/PinkButton/PinkButton";
import PinkFilter from "../../../PinkFilter/PinkFilter";
import FilterSection from "../../../../TurboPlus2/RoleTagWrapper/components/Popup/components/FilterSection/FilterSection";
import robotina from '../../../../../assets/svg/robotina-pet.svg';
import confusedRolebot from '../../../../../assets/svg/confused-rolebot.svg';

const RoleTags = React.memo( props => {
    // Local data will be used to cache the data that is passed in as props
    const [localData, setLocalData] = useState(props.data);

    // This is used to keep track of the selected locations so we can filter the results
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const firstRender = useFirstRender();

    useEffect(() => {
        if(firstRender) return;

        setLocalData(props.data);
    }, [props.data]);

    useEffect(() => {
        props.setLocationsToFilter(selectedLocations);
    },[selectedLocations]);

    const handleKeyDown = useCallback( (event) => {
        switch (event.key) {
            case "Enter":
            case "Tab":
                event.preventDefault();
                props.onSubmitSubmit(props.searchTerm);
        }
    }, [props]);

    const handleChangeInput = useCallback( (event) => {
        props.setSearchTerm(event.currentTarget.value);
    }, [props]);

    const handleDeleteTag = useCallback( (id) => {
        //delete the tag from the local data to give the user immediate feedback
        setLocalData(localData.filter(x => x.id !== id));

        //finally send delete request to server
        props.onTagRemoved(id);
    }, [props]);

    const handleItemSelected = useCallback( (value) => {
        props.clearSearchResults();
        props.onTagAdded(value, 'id');
        props.setSearchTerm('');
    }, [props]);

    /**
     * This function is used to temporally reorder the tags for this component.
     * The way it SHOULD work is to tell the server what tag id should be in what sort order.
     * The issue is rolebot is splitting the tags from the same table to skills and nice to haves.
     * Since it's using the sort order located in the pivot table, it will not work.
     * This version below looks up the id position and the tagId position and gets the proper index to do local
     * swapping. When done it returns tagId and sort order to the server as intended
     */
    const handleTagOrderChanged = useCallback( (tagId, sortOrder) => {
        //update the local state to show the user intently that the order has changed
        const newLocalData = [...localData];

        //get the index of the tagId supplied
        const itemMovedIndex = newLocalData.findIndex(x => x.id === tagId);

        //get the index of the tagId by its sort order in the pivot table.
        const itemMovedToIndex = newLocalData.findIndex(x => x.pivot.sort_order === sortOrder);

        //rip the item that we are moving out of the array
        const movedTag = newLocalData.splice(itemMovedIndex, 1)[0];

        //insert the item into the new position
        newLocalData.splice(itemMovedToIndex, 0, movedTag);

        //update the local state
        setLocalData(newLocalData);

        // finally send the request to the server to get new data
        props.onTagOrderChanged(tagId,sortOrder);
    }, [localData, props]);

    const handleSelectedLocation = (location) => {
        const newSelectedLocations = [...selectedLocations];

        if(newSelectedLocations.includes(location)){
            newSelectedLocations.splice(newSelectedLocations.indexOf(location), 1);
        } else {
            newSelectedLocations.push(location);
        }

        setSelectedLocations(newSelectedLocations);
    }

    const handleChangeLocationInput = (event) => {
        setSearchTerm(event.currentTarget.value);
        props.setLocationsSearchText(event.currentTarget.value);
    }

    const clearAll = () => {
        setSelectedLocations([]);
    }

    const filterButton = (
        <PinkButton className={classes.locationsFilter_label} icon="funnel" label="Filter by Location" count={selectedLocations.length}/>
    );

    return <FormGroup className={classes.roleTag__formGroup} controlId={props.title}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={12} lg={7}>
                <ControlLabel className={classes.roleTag_label} style={{ width: '50%' }}>{props.title}</ControlLabel>
            </Col>
            {props.locationsFilter && <Col xs={12} lg={3}>
                <PinkFilter button={filterButton} isActive={selectedLocations.length > 0} disabled={localData.length === 0} isLookalike={true}>
                    <div className={classes.Popup}>
                        <FormGroup>
                            <FormControl
                                onChange={handleChangeLocationInput}
                                type="text"
                                placeholder="Add a location"
                            />
                        </FormGroup>
                        <FilterSection>
                            {!props.filterInitialized &&
                                <Col xs={12}>
                                    <div className={classes.NoLocations}>
                                        <img className={classes.Robot} src={robotina} alt=""/>
                                        <h5>Rolebot will display all search results here.</h5>
                                    </div>
                                </Col>
                            }
                            {props.filterInitialized && props.locationsData.length === 0 &&
                                <Col xs={12}>
                                    <div className={classes.NoLocations}>
                                        <img className={classes.Robot} src={confusedRolebot} alt=""/>
                                        <h5>No results found for { searchTerm }</h5>
                                    </div>
                                </Col>
                            }
                            {props.filterInitialized && props.locationsData.length > 0 && props.locationsData.map((location) => {
                                return <Col xs={12}>
                                    <FormGroup className={classes.FormGroup}>
                                        <Checkbox className={classes.Checkbox} onChange={() => handleSelectedLocation(location)} checked={selectedLocations.includes(location)}>
                                            {location.name}
                                        </Checkbox>
                                    </FormGroup>
                                </Col>
                            })}
                        </FilterSection>
                        {props.locationsData.length > 0 &&
                            <div>
                                <hr style={{ paddingBottom: '0px' }}/>
                                <button className={classes.clearAll} onClick={clearAll}>Clear All</button>
                            </div>
                        }
                    </div>
                </PinkFilter>
            </Col>}
            {props.locationsFilter &&
                <Col xs={12} lg={2} style={{ display: 'flex', justifyContent: 'end' }}>
                    <button className={classes.clearAllSkills} onClick={props.clearTags}>Clear All</button>
                </Col>
            }
        </Row>
        <TagDragDropSection
            data={localData}
            onTagOrderChanged={handleTagOrderChanged}
            title={props.title}
            value={props.searchTerm}
            placeholderValue={props.placeholderValue}
            handleOnDelete={handleDeleteTag}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}/>
        <TagSearchDropDown searchResults={props.searchResults} handleItemSelected={handleItemSelected}/>
        <RecommendationSection recommendations={props.recommendations} onTagAdded={props.onTagAdded}/>
    </FormGroup>;
});

export default RoleTags;

RoleTags.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    onTagOrderChanged: PropTypes.func.isRequired,
    placeholderValue: PropTypes.string.isRequired,
    searchResults: PropTypes.array.isRequired,
    recommendations: PropTypes.array.isRequired,
    onTagAdded: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    setLocationsSearchText: PropTypes.func,
    setLocationsToFilter: PropTypes.func,
    clearSearchResults: PropTypes.func.isRequired,
    onSubmitSubmit: PropTypes.func.isRequired,
    locationsFilter: PropTypes.bool.isRequired,
    locationsData: PropTypes.array.isRequired,
    clearTags: PropTypes.func,
    filterInitialized: PropTypes.bool
};

RoleTags.defaultProps = {
    filterInitialized: false
};