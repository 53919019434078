import {
    getAdmins,
    getOutcomes
} from '../../utils/services/turboPlus2RestCalls';

export const getOutcomeList = () => {
    return (dispatch) => {
        getOutcomes()
            .then(({data})  => {
                dispatch(outcomeListReturned(data.outcomes))
            })
            .catch(e=>console.log(e));
    };
}

export const outcomeListReturned = (payload) => {
    return {
        type: 'GLOBAL_LISTS_GET_OUTCOMES_SUCCESS',
        payload
    }
};

export const getAdminList = () => {
    return (dispatch) => {
        getAdmins()
            .then(({data}) => {
                dispatch(adminListReturned(data.admins));
            })
            .catch(e=>console.log(e));
    };
}

export const adminListReturned = (payload) => {
    return {
        type: 'GLOBAL_LISTS_GET_ADMINS_SUCCESS',
        payload
    }
};