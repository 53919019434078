import React from 'react';
import {Badge} from 'react-bootstrap';

const buildNameIcon = (str) => {
    return str.split(' ').map(function(item){return item[0]}).join('');
};

export default function useNameAdminBadgeBuilder(name, color) {
    const styles = {backgroundColor: color};
    return (
        <Badge style={styles}>
            {buildNameIcon(name)}
        </Badge>
    );
}