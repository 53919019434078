import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classes from './RoleStatusBasicHistory.module.css';
import {FiChevronRight} from 'react-icons/fi';

const RoleStatusBasicHistory = ({newValue, oldValue}) => {

    const buildStatus = (value) => {
        switch (value){
            case '1':
                return "Yes";
            case '0':
                return "No";
            case 'active':
                return "Active";
            case 'on_hold':
                return "On Hold";
            case 'completed':
                return "Completed";
            default:
                return value;
        }
    }

    return (
        <Fragment>
            <td>
                <div className={classes.RoleStatusHistory}>
                    <span className={classes.TextWrapper}>{buildStatus(oldValue)}</span>
                    <FiChevronRight/>
                    <span  className={classes.TextWrapper}>{buildStatus(newValue)}</span>
                </div>
            </td>
        </Fragment>
    )
};

export default RoleStatusBasicHistory;

RoleStatusBasicHistory.propTypes = {
    newValue: PropTypes.string.isRequired,
    oldValue: PropTypes.string.isRequired
};

RoleStatusBasicHistory.defaultProps = {};