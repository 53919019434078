import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";

const NewAdvanceDropdown = props => {
    return (
        <FormGroup>
            <ControlLabel>{props.label}</ControlLabel>
            <FormControl onChange={props.onChange} value={props.value} componentClass="select" placeholder='' disabled={props.disabled}>
                {props.placeholder && <option value="">{props.placeholder}</option>}
                {props.options.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </FormControl>
        </FormGroup>
    )
};

export default NewAdvanceDropdown;

NewAdvanceDropdown.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.array
};

NewAdvanceDropdown.defaultProps = {
    validationState: null,
    required: false,
    loading: false,
    placeholder: null,
    options: [],
    disabled: false
};
