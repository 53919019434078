import React from 'react';
import {Col, Row} from "react-bootstrap";
// import classes from './UserListHeader.module.css';

const UserListHeader = () => {
    return (
        <Row>
            <Col xs={12}>
                {/*<Col xs={4} xsOffset={1}>*/}
                {/*    <div className={classes.Wrapper}>Name</div>*/}
                {/*</Col>*/}
                {/*<Col xs={3}>*/}
                {/*    <div className={classes.Wrapper}>Company</div>*/}
                {/*</Col>*/}
                {/*<Col xs={1}>*/}
                {/*    <div className={classes.Wrapper}>Roles</div>*/}
                {/*</Col>*/}
                {/*<Col xs={2}>*/}
                {/*    <div className={classes.Wrapper}>Last Online</div>*/}
                {/*</Col>*/}
            </Col>
        </Row>
    )
};

export default UserListHeader;