import React, {useEffect, useReducer} from 'react';
import {Button, Form, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import classes from "../Billing.module.css";
import moment from "moment";
import RoleNameItem from '../RoleNameItem/RoleNameItem';
import RoleBillingItem from '../RoleBillingItem/RoleBillingItem';
import RoleCompanyItem from '../RoleCompanyItem/RoleCompanyItem';
import RoleNotesItem from '../RoleNotesItem/RoleNotesItem';

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
});

function reducer(state, action) {
    switch (action.type) {
        case 'updateClicked':
            return {
                ...state,
                inEditMode: true,
                showUpdateButton: false,
                enableInput: true,
                enableCancelButton: true,
            };
        case 'reset':
            return {
                ...state,
                ...action.payload
            };
        case "inputValueChanged": {
            const isValid =  action.payload === "0" || /^\d+(?:\.\d{2})$/.test(action.payload);
            return {
                ...state,
                value : action.payload,
                formValidStatus: isValid ? null : "error",
                enableSave: isValid,
            };
        }
        case 'submitting':
            return {
                ...state,
                loading: true,
                enableSave: false,
                enableInput: false
            };
        default:
            throw new Error();
    }
}

const BillingTableRow = props => {
    const {id, is_rolebot_invoice, refunded_at, paid, updated_at, talent} = props.charge;
    const {name: talentName} = talent;

    const { id: roleId, name: roleName, customer, price: rolePrice, hosted_invoice_url, info} = props.charge.role;

    const {notes, billing_notes} = info;

    const resetData = {
        value: paid !== null ? formatter.format(paid / 100) : "",
        inEditMode: false,
        loading: false,
        showUpdateButton: !refunded_at && is_rolebot_invoice,
        enableSave: false,
        enableInput: false,
        enableCancelButton: false,
    };

    const [state, dispatch] = useReducer(reducer, resetData);
    const {showUpdateButton, inEditMode, loading, enableInput, value, formValidStatus, enableSave} = state;

    useEffect(() => {
        dispatch({type: 'reset', payload: resetData});
    }, [props.charge]);

    const handleUpdateClicked = () => {
        dispatch({type: 'updateClicked'});
    };

    const handleInputChanged = (e) => {
        dispatch({type: 'inputValueChanged', payload: e.target.value});
    };

    const handleCancelClicked = () => {
        dispatch({type: 'reset', payload: resetData});
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        dispatch({type:"submitting"});
        props.handleSaveClicked(id, parseFloat(value) * 100);
    };

    const updateButton = showUpdateButton ? (
        <Button
            onClick={handleUpdateClicked}
            type="button"
            bsStyle="default">Update</Button>
    ) : null;

    const refundedButton = refunded_at ? (
        <Button
            disabled
            type="button"
            bsStyle="danger">Refunded</Button>
    ) : null;

    const saveButton = inEditMode ? (
        <Button
            disabled={!enableSave}
            onClick={handleFormSubmit}
            type="submit"
            bsStyle="success">{loading ? "Saving" : "Save"}</Button>
    ) : null;

    const cancelButton = inEditMode ? (
        <Button
            disabled={loading}
            style={{marginLeft: "5px"}}
            onClick={handleCancelClicked}>Cancel</Button>
    ) : null;

    return (
        <tr key={id}>
            <td>
                <RoleBillingItem id={roleId} isInvoice={is_rolebot_invoice} url={hosted_invoice_url}/>
            </td>
            <td>
                <RoleNotesItem notes={notes} billing_notes={billing_notes} id={roleId} chargeId={id} handleShowNotesModal={props.handleShowNotesModal} />
            </td>
            <td>
                <RoleCompanyItem id={roleId} name={customer.name}/>
            </td>
            <td>
                <RoleNameItem roleId={roleId} roleName={roleName}/>
            </td>
            <td>{talentName}</td>
            <td>${formatter.format((rolePrice) / 100)}</td>
            <td>{moment.utc(updated_at).local().format("L")}</td>
            <td>
                <Form inline className={classes.ChargeForm}>
                    <FormGroup className={classes.InputForm} validationState={formValidStatus}>
                        <InputGroup>
                            <InputGroup.Addon>$</InputGroup.Addon>
                            <FormControl
                                value={value}
                                disabled={!enableInput}
                                type="text"
                                onChange={handleInputChanged}
                                placeholder="Enter Amount"/>
                        </InputGroup>
                    </FormGroup>
                    {saveButton}
                    {updateButton}
                    {cancelButton}
                    {refundedButton}
                </Form>
            </td>
        </tr>
    );
};
export default BillingTableRow;