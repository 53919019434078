import React from 'react';
import PropTypes from 'prop-types';
import TurboPlus2Loader from "../../../../../TurboPlus2Loader/TurboPlus2Loader";
import classes from './LoadingWrapper.module.css';

const LoadingWrapper = props => {

    if(!props.loading){
        return null;
    }

    return (
        <div className={classes.LoadingWrapper}>
            <TurboPlus2Loader/>
        </div>
    )
};

export default LoadingWrapper;

LoadingWrapper.propTypes = {
    loading: PropTypes.bool.isRequired
};

LoadingWrapper.defaultProps = {};