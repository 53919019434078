import React from 'react';
import PropTypes from 'prop-types';
import ClientRequestHistory from "./Components/ClientRequestHistory/ClientRequestHistory";
import RoleStatusBasicHistory from "./Components/RoleStatusBasicHistory/RoleStatusBasicHistory";

const RoleInfoHistory = ({type, oldValue, newValue}) => {
    switch (type){
        case 'Client Request':
            return <ClientRequestHistory newValue={newValue}/>;
        default:
            return <RoleStatusBasicHistory newValue={newValue} oldValue={oldValue}/>;
    }
};

export default RoleInfoHistory;

RoleInfoHistory.propTypes = {
    oldValue: PropTypes.string,
    newValue: PropTypes.string.isRequired
};

RoleInfoHistory.defaultProps = {

};