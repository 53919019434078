import {Col, Row, Button, Form, FormGroup, ControlLabel} from "react-bootstrap";
import React, { useEffect, useReducer, useRef, Fragment} from "react";
import classes from './ApolloRoleLinker.module.css';
import {
    getApolloSequences,
    postRoleToApolloSequence,
    deleteRoleToApolloSequence,
    reSyncApolloData, clearApolloNotifications
} from '../../../utils/services/turboPlus2RestCalls';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import FormControl from "react-bootstrap/lib/FormControl";
import {useFirstRender} from "../../../hooks/useFirstRender";
import useDebounce from "../../../hooks/useDebounce";
import {showToastError, showToastSuccess} from "../../../utils/toastMessages";
import ApolloDropDown from "./components/ApolloDropDown/ApolloDropDown";
import _ from "lodash";
import PropTypes from "prop-types";

function reducer(state, action) {
    switch (action.type) {
        case 'SearchValueChanged':
            return {
                ...state,
                selectedApolloSequenceName: action.payload,
                searchEnabled: true,
                displayApolloSequencesDropdown: false
            }
        case 'startedApolloSequencesSearch':
            return {
                ...state,
                displayApolloSequencesDropdown: false,
                apolloSequences: [],
            }
        case 'receivedApolloSequences':
            return {
                ...state,
                apolloSequences: action.payload,
                selectedApolloDisabled: state.roleLinkedToApollo,
                displayApolloSequencesDropdown: true,
                // selectedApolloSequence: action.payload.find(x => x.id === state.selectedApolloSequenceId)
            };
        case "apolloSequenceSelected":
            return {
                ...state,
                selectedApolloSequenceId: action.payload.id,
                selectedApolloSequenceName: action.payload.name,
                displayApolloSequencesDropdown: false,
                searchEnabled: false,
                disableSubmit: false,
            };
        case 'formSubmitted':
            return {
                ...state,
                formLoading: true
            };
        case 'showDisconnectModal':
            return {
                ...state,
                showApolloModal: true
            };
        case 'cancelDisconnectModal':
            return {
                ...state,
                showApolloModal: false,
                formLoading: false
            };
        case "modalLoading":
            return {
                ...state,
                modalLoading: true
            };
        case "apolloDataSyncing":
            return {
                ...state,
                apolloDataSyncing: action.payload
            };
        case "searchInputFocused":
            return {
                ...state,
                displayApolloSequencesDropdown: state.apolloSequences.length > 0,
                searchEnabled: true
            };
        case "clickedOutsideOfDropdown":
            return {
                ...state,
                searchEnabled: action.payload
            };
        case 'reset':
            return {
                ...state,
                ...action.payload
            };
        default:
            throw new Error();
    }
}

const ApolloRoleLinker = props => {
    const [state, dispatch] = useReducer(reducer, {
        roleLinkedToApollo: !!props.apolloSequenceId,
        selectedApolloSequenceName: props.apolloSequenceName ? props.apolloSequenceName : '',
        selectedApolloSequenceId: props.apolloSequenceId,

        disableSubmit: true,
        formLoading: false,
        showApolloModal: false,
        modalLoading: false,
        apolloDataSyncing: false,
        displayApolloSequencesDropdown: false,
        searchEnabled: true,
        apolloSequences: []
    });

    const componentActive = useRef(false);
    const isFirstRender = useFirstRender();
    const searchDebounced = useDebounce(state.selectedApolloSequenceName, 1000);

    useEffect(() => {
        componentActive.current = true;
        return () => {
            componentActive.current = false;
        }
    });

    useEffect(() => {
        let isMounted = true;
        if(!isFirstRender || (!isFirstRender && state.searchEnabled)){
            dispatch({type: 'startedApolloSequencesSearch'})
            getApolloSequences({params: {search_term: state.selectedApolloSequenceName}})
                .then(({data}) => {
                    if(isMounted){
                        dispatch({type: 'receivedApolloSequences', payload: data});
                    }
                })
                .catch(e => console.log(e));
        }
        return () => { isMounted = false };
    }, [searchDebounced]);

    // useEffect(() => {
    //     dispatch({type: "reset",
    //         payload : {
    //             roleLinkedToApollo: !!props.apolloSequenceId,
    //             selectedApolloSequenceName: null,
    //             selectedApolloSequenceId: props.apolloSequenceId,
    //             formLoading: false,
    //             showApolloModal: false
    //         }});
    // }, [props.apolloSequenceId]);

    const refreshApolloData = () => {
        dispatch({type: "apolloDataSyncing", payload: true});
        reSyncApolloData({params: {role_id: props.roleId}})
            .then(response => {
                if(componentActive.current){
                    props.initRole();
                    dispatch({type: "apolloDataSyncing", payload: false});
                    showToastSuccess(response.message);
                }
            })
            .catch(e => {
                if (e.message === "No apollo sequence id found") {
                    dispatch({type: "apolloDataSyncing", payload: false});
                    showToastError("No Apollo Sequence linked to Role");
                    return;
                }
                if (e.message === "No admin for role.") {
                    dispatch({type: "apolloDataSyncing", payload: false});
                    showToastError("Please select a role administrator!");
                    return;
                }
                dispatch({type: "apolloDataSyncing", payload: false});
                const errors = _.flattenDeep(Object.values(e.errors));
                showToastError(e.message, errors);
            });
    };

    const resetApolloNotifications = () => {
        clearApolloNotifications(props.roleId)
            .then(response => {
                props.initRole();
                showToastSuccess("Apollo notifications reset successfully");
            })
            .catch(e => {
                const errors = _.flattenDeep(Object.values(e.errors));
                showToastError(e.message, errors);
            });
    };

    const handleSearchChange = (e) => {
        dispatch({type: "SearchValueChanged", payload: e.currentTarget.value});
    }

    const handleApolloSequenceSelect = (id, name) => {
        dispatch({type: "apolloSequenceSelected", payload: {id, name}});
    }

    const handleClickedOutside = () => {
        dispatch({type: "clickedOutsideOfDropdown", payload: false});
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        dispatch({type: "formSubmitted"});
        if(state.roleLinkedToApollo){
            showDisconnectModal();
        } else {
            submitUpdate();
        }
    };

    const showDisconnectModal = () => {
        dispatch({type: 'showDisconnectModal'});
    };

    const handleCancelDisconnect = () => {
        dispatch({type: 'cancelDisconnectModal'});
    };

    const submitUpdate = () => {
        const params = {
            apollo_sequence_id: state.selectedApolloSequenceId,
            apollo_sequence_name: state.selectedApolloSequenceName,
        }

        postRoleToApolloSequence(props.roleId, params)
            .then(() => {
                props.updateRole();
        })
        .catch(e => console.log(e));
    };

    const submitDisconnect = () => {
        dispatch({type: 'modalLoading'});
        deleteRoleToApolloSequence(props.roleId, {params: {sequence_id: null}})
            .then(() => {
                props.updateRole();
            })
            .catch(e => console.log(e));
    };

    const onSearchInputFocus = () => {
        dispatch({type: 'searchInputFocused'});
    };

    const modal = state.showApolloModal ? (
        <Row>
            <Col xs={12}>
                <ConfirmationModal
                    show={state.showApolloModal}
                    disabled={state.modalLoading}
                    handleCancel={handleCancelDisconnect}
                    handleConfirm={submitDisconnect}
                >
                    <h2 style={{fontWeight:"Bold"}}>Are you sure you want to disconnect Apollo from this role?</h2>
                    <p>
                        Clicking confirm will disconnect this role
                        from <span className={classes.ModalGroupName}>{state.selectedApolloSequenceName}</span> at Apollo.
                        This will break all relations that you can add back later.
                    </p>
                </ConfirmationModal>
            </Col>
        </Row>
    ) : null;

    return (
        <Fragment>
            {modal}
            <Row className={classes.ApolloRoleLinker}>
                <Form onSubmit={formSubmitted}>
                    <Col xs={12} md={12} lg={4}>
                        <FormGroup controlId="apolloBoardSelect">
                            <ControlLabel>Apollo Sequence</ControlLabel>{' '}
                            <FormControl
                                type="text"
                                value={state.selectedApolloSequenceName}
                                disabled={state.selectedApolloSequenceId && state.roleLinkedToApollo}
                                placeholder="Enter name for an existing Apollo sequence"
                                onFocus={onSearchInputFocus}
                                onChange={handleSearchChange}
                            />
                            { state.displayApolloSequencesDropdown && state.searchEnabled &&
                                <ApolloDropDown
                                    handleApolloSequenceSelect={handleApolloSequenceSelect}
                                    apolloSequences={state.apolloSequences}
                                    clickedOutSide={handleClickedOutside}
                                />
                            }
                        </FormGroup>
                    </Col>
                    <Col xs={8} md={8} lg={4}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <Button
                                disabled={state.formLoading || !state.selectedApolloSequenceId || !state.selectedApolloSequenceName}
                                block
                                type="submit"
                            >
                                {state.roleLinkedToApollo ? "Unlink Sequence" : "Link Sequence"}
                            </Button>
                        </FormGroup>
                    </Col>
                    {/*<Col xs={4} md={4} lg={4}>*/}
                    {/*    <FormGroup>*/}
                    {/*        <ControlLabel>&nbsp;</ControlLabel>*/}
                    {/*        <Button disabled={state.apolloDataSyncing} block onClick={refreshApolloData}>*/}
                    {/*            Sync To Apollo*/}
                    {/*        </Button>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                </Form>
            </Row>
            { true &&
                <Row className={classes.ApolloRoleLinker}>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <ControlLabel>&nbsp;</ControlLabel>
                            <Button disabled={state.apolloDataSyncing} block onClick={resetApolloNotifications}>
                                Clear Apollo Notifications
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            }
        </Fragment>
    );
};

export default ApolloRoleLinker;

ApolloRoleLinker.propTypes = {
    roleId: PropTypes.number.isRequired,
    apolloSequenceId: PropTypes.string,
    apolloSequenceName: PropTypes.string,
    updateRole: PropTypes.func.isRequired,
    initRole: PropTypes.func.isRequired,
};

ApolloRoleLinker.defaultProps = {};
