import React from 'react';
import PropTypes from 'prop-types';

const EllipseMenuItem = (props) => {
    return (
        <div onClick={props.onClick} style={{margin: '5px 0', cursor: 'pointer', whiteSpace: "nowrap", textAlign: 'left'}}>
            {props.children}
        </div>
    )
}

export default EllipseMenuItem;

EllipseMenuItem.propTypes = {
    onClick: PropTypes.func.isRequired
};

EllipseMenuItem.defaultProps = {};