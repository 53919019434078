import React, {useReducer,useEffect} from 'react';
import {Col, Panel, Row, Button} from "react-bootstrap";
import classes from './ReviewedTalentSection.module.css';
import ReviewTalentItem from './components/ReviewTalentItem/ReviewTalentItem';
import usePagination from "../../../../../hooks/usePagination";
import IconList
    from "../../../RoleDeliveryDropDownWrapper/components/ReviewTalentSection/components/TalentForReviewForm/components/IconList/IconList";
import TalentProspectsFoldingContainer
    from "../../../RoleDeliveryDropDownWrapper/components/TalentProspectsFoldingContainer/TalentProspectsFoldingContainer";
import TalentForReviewFilter
    from "../../../RoleDeliveryDropDownWrapper/components/TalentProspectsFoldingContainer/components/TalentForReviewFilter/TalentForReviewFilter";
import TalentForReviewDownArrow
    from "../../../RoleDeliveryDropDownWrapper/components/TalentProspectsFoldingContainer/components/TalentForReviewDownArrow/TalentForReviewDownArrow";
import TalentWrapperComingSoonMessage
    from "../../../RoleDeliveryDropDownWrapper/components/TalentWrapperComingSoonMessage/TalentWrapperComingSoonMessage";
import {
    getTalentProspects,
    getAutoSurfacedTalentProspects,
    getTalentProspectsStats,
    patchTalentForReview
} from "../../../../../utils/services/turboPlus2RestCalls";
import {useSelector} from "react-redux";
import {showToastSuccess} from "../../../../../utils/toastMessages";

const reducer = (state, action) => {
    switch (action.type) {
        case "editOpen":
            return {
                ...state,
                isOpen: !state.isOpen
            }
        case 'talentForReviewedReturned':
            return {
                ...state,
                talent: action.payload.data,
                page: action.payload.current_page,
                lastPage: action.payload.last_page,
                perPage: action.payload.per_page
            }
        case 'autoSurfacedProspectsReturned':
            return {
                ...state,
                autoSurfacedTalent: action.payload.data,
                page: action.payload.current_page,
                lastPage: action.payload.last_page,
                perPage: action.payload.per_page
            }
        case 'talentForReviewStatsReturned':
            return {
                ...state,
                stats: action.payload
            }
        case 'TalentForReviewPageChanged':
            return {
                ...state,
                page: action.payload
            }
        case 'talentForReviewFilterChanged':
            return {
                ...state,
                filter: action.payload
            }
        case 'talentForReviewUpdated':
            return {
                ...state,
                talent: state.talent.map(
                    x => x.id === action.payload.id ? {...x, ...action.payload.talent, talent_info: {...x.talent_info, current_company_name: action.payload.talent ? action.payload.talent.company : null}}:x
                )
            }
        default:
            throw new Error();
    }
}


const ReviewedTalentSection = props => {
    const roles = useSelector(state => state.roles.data);
    const [state, dispatch] = useReducer(reducer, {
        isOpen: false,
        stats: {
            prospect_approved: 0,
            saved: 0,
        },
        talent: [],
        autoSurfacedTalent: [],
        page: 1,
        lastPage: 1,
        perPage: 10,
        filter: 'prospect_approved'
    });

    useEffect(() => {
        let isMounted = true;
        getTalentProspects(props.roleId, {
            params: {
                page: state.page,
                perPage: state.perPage,
                filter: state.filter
            }
        })
            .then(({talent_prospects}) => {
                if(isMounted)
                    dispatch({type:'talentForReviewedReturned', payload: talent_prospects});
            })
            .then(() => {
                if(isMounted)
                    return getTalentProspectsStats(props.roleId);
            })
            .then(({stats}) => {
                if(isMounted)
                    dispatch({type: 'talentForReviewStatsReturned', payload: stats});
            })
            .catch(e => console.log(e));

            getAutoSurfacedTalentProspects(props.roleId, {
                params: {
                    page: state.page,
                    perPage: state.perPage,
                    filter: state.filter
                }
            })
            .then(({auto_surfaced_prospects}) => {
                if(isMounted)
                    dispatch({type:'autoSurfacedProspectsReturned', payload: auto_surfaced_prospects});
            })
            .catch(e => console.log(e));

        return () => { isMounted = false };
    }, [state.page, state.filter, roles]);

    // Check if we have auto surfaced prospects, if so, pre-populate them
    // in the add talent and send view
    useEffect(() => {
        if (state.autoSurfacedTalent?.length) {

            for (let i = 0; i < state.autoSurfacedTalent.length; i++) {
                props.handleReviewedTalentAddedToAddList(state.autoSurfacedTalent[i], true);
            }

            /* 
            this is used to remove automatically added first entry from the add talent form
            that way when we navigate to add talent form, we only see the auto surfaced one 
            on initial render - if any
            */
            props.handleRemoveZeroIndex(); 
        }

    }, [state.autoSurfacedTalent]);


    const reviewedTalentPageChange = (page) => {
        dispatch({type: 'TalentForReviewPageChanged', payload: page});
    }

    const talentForReviewFilterChanged = (value) => {
        dispatch({type: 'talentForReviewFilterChanged', payload: value});
    };

    const updateTalentForReview = (payload) => {
        patchTalentForReview(payload.id,props.roleId, payload)
            .then(() => {
                dispatch({type: 'talentForReviewUpdated', payload: {id: payload.id, talent: payload}});
                showToastSuccess("Talent for review has been updated");
            })
            .catch(e => console.log(e));
    };

    const [pagination] = usePagination( state.page, state.lastPage, reviewedTalentPageChange);

    const openAllLinksInNewWindowsClicked = () => {
        state.talent.forEach(x => window.open(x.link));
    };

    const handleEditClicked = () => {
        dispatch({type: 'editOpen'});
    }

    const topHeader = () => {
        return (
            <Panel.Title toggle style={{marginBottom: '10px'}}>
                <Row>
                    <Col xs={12}>
                        <Col xs={6}>
                            <div style={{ fontSize: '23px'}}>
                                Talent for Review
                            </div>
                        </Col>
                        <Col xs={3} xsOffset={3}>
                            <div className={classes.HeaderIconWrapper}>
                                <IconList stats={state.stats} showDeclined={false} showWaiting={false}/>
                                <TalentForReviewDownArrow isOpen={state.isOpen}/>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Panel.Title>
        );
    };

    return (
        <TalentProspectsFoldingContainer expanded={state.isOpen} headerComponent={topHeader} onToggle={handleEditClicked}>
            <Row>
                <Col xs={12}>
                    <Col lg={3} lgOffset={9}>
                        <TalentForReviewFilter filter={state.filter} talentForReviewFilterChanged={talentForReviewFilterChanged}>
                            <option value="prospect_approved">Approved</option>
                            <option value="saved">Saved for later</option>
                        </TalentForReviewFilter>
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{marginBottom: '10px'}}>
                    <Col xs={12}>
                        <div className={classes.ReviewedTalentSectionBody}>
                            {/* Don't show auto surfaced talent in the review tabs */}
                            {state.talent.filter(x => !x.pivot.is_auto_surfaced).length === 0 && <TalentWrapperComingSoonMessage/>}
                            {state.talent.filter(x => !x.pivot.is_auto_surfaced).map((x, i) =>
                                <ReviewTalentItem
                                    key={x.id}
                                    filter={state.filter}
                                    handleReviewedTalentAddedToAddList={() => props.handleReviewedTalentAddedToAddList(x)}
                                    handleReviewedTalentRemovedFromAddList={() => props.handleReviewedTalentRemovedFromAddList(x.link)}
                                    count={i+1}
                                    talent={x}
                                    updateTalentForReview={updateTalentForReview}
                                    addedToAddTalentForm={props.arrayOfLinksInUse.includes(x.link)}
                                />
                            )}
                        </div>
                    </Col>

                    <Col xs={6} xsOffset={4}>
                        <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                            {pagination}
                        </div>
                    </Col>
                    <Col xs={2}>
                        <Button onClick={openAllLinksInNewWindowsClicked} block>Open All</Button>
                    </Col>
                </Col>
            </Row>
        </TalentProspectsFoldingContainer>
    );
};

export default ReviewedTalentSection;
