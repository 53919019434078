import React from 'react';
import {Col, Row} from 'react-bootstrap';
import classes from './CompanyUserItem.module.css';
import useGetUserInitials from '../../../../../../../hooks/useGetUserInitials';
import PropTypes from 'prop-types';
import EllipseButton from "../../../../../../Ui/EllipseButton/EllipseButton";
import EllipseMenuItem from "../../../../../../Ui/EllipseButton/Components/EllipseMenuItem/EllipseMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTrash} from "@fortawesome/free-solid-svg-icons";

const CompanyUserItem = ({user, showUserProfileClicked, companyId, menuOpen, menuClicked, removeUserClicked}) => {
    const [initials] = useGetUserInitials(user.name);

    const handleEditUser = () => {
        showUserProfileClicked(user.id);
    }

    return (
        <div className={classes.UserItem}>
            <Row>
                <Col xs={12}>
                    <Col xs={1}>
                        <div className={classes.ColumnWrapper}>
                            <div className={classes.Avatar}>{initials}</div>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className={classes.ColumnWrapper}>
                            <div className={classes.UserSummarySection}>
                                <div className={classes.Name}>{user.name ? user.name : 'New User'}</div>
                                <div className={classes.Email}>{user.email}</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className={classes.ColumnWrapper}>

                        </div>
                    </Col>
                    <Col xs={1}>
                        <div className={classes.ColumnWrapper}>

                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className={classes.ColumnWrapper}>

                        </div>
                    </Col>
                    <Col xs={1}>
                        <div className={classes.ColumnWrapper}>
                            <EllipseButton open={menuOpen} onClick={menuClicked} id={user.id}>
                                <EllipseMenuItem onClick={handleEditUser}>
                                    <FontAwesomeIcon icon={faEye}/> View profile
                                </EllipseMenuItem>
                                <EllipseMenuItem onClick={() => removeUserClicked(user.id)}>
                                    <FontAwesomeIcon icon={faTrash}/> Remove From Company
                                </EllipseMenuItem>
                            </EllipseButton>
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>
    )
};

export default CompanyUserItem;

CompanyUserItem.propTypes = {
    user: PropTypes.object.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    companyId: PropTypes.number.isRequired,
    showUserProfileClicked: PropTypes.func.isRequired,
    removeUserClicked: PropTypes.func.isRequired,
    menuClicked: PropTypes.func.isRequired
};

CompanyUserItem.defaultProps = {};