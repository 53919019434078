import React from 'react';
import PropTypes from 'prop-types';
import classes from "./RoleTagInput.module.css";

function RoleTagInput(props) {
    return <input
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        name={`autosize-input-${props.title}`}
        value={props.value}
        placeholder={props.data.length > 0 ? "" : props.placeholderValue}
        className={classes.DragContainerInput}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}/>;
}

export default RoleTagInput;

RoleTagInput.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.string,
    data: PropTypes.any,
    placeholderValue: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func
};

RoleTagInput.defaultProps = {};