import React, {useState, useEffect, useReducer, useCallback} from 'react';
import { Col } from "react-bootstrap";
import FormGreyWrapper from "../../../../../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";
import {
    getRoleById,
    // getRecommendationsToJobTitles,
    // getRecommendationsToSkills,
    getSkillsByRoleSearch,
    getIdealTalentsForRole,
    getTalentsLocation
} from "../../../../../../../utils/services/turboPlus2RestCalls";
import RoleTagsWrapper from '../../../../../RoleTagWrapper/RoleTagsWrapper';
import SearchResultsSection from './components/SearchResultsSection';
import NoLookalikeResults from './components/NoLookalikeResults';
import useDebounce from "../../../../../../../hooks/useDebounce";

// will turn an array of integers to a parameter list for the url. example [1, 2, 3, 4]
function buildParametersArray(intArray, key = 'values') {
    return intArray.map((n, index) => `${key}[${index}]=${n}`).join('&');
}


// helper function to sort skills by search keywords
function sortArrayByMatchingKeywords(array, keywords) {

    return array.sort((a, b) => {
        const aKeyword = a.name.toLowerCase();
        const bKeyword = b.name.toLowerCase();
        for (const keyword of keywords) {
            if (aKeyword.startsWith(keyword.toLowerCase()) && !bKeyword.startsWith(keyword.toLowerCase())) {
                return -1;
            } else if (!aKeyword.startsWith(keyword.toLowerCase()) && bKeyword.startsWith(keyword.toLowerCase())) {
                return 1;
            }
        }
        return 0;
    });
}

// helper function that sorts displayed talents by count of matching skills
function sortByMatchingSkillsCount(skillsToMatch, objects) {

    return objects.sort((a, b) => {
        let countA = 0;
        let countB = 0;
        for (let i = 0; i < a.skills.length; i++) {
            if (skillsToMatch.includes(a.skills[i]?.name)) {
                countA++;
            }
        }
        for (let i = 0; i < b.skills.length; i++) {
            if (skillsToMatch.includes(b.skills[i]?.name)) {
                countB++;
            }
        }
        return countB - countA;
    });
}

//helper to reduce the skills array into only 4 + the count of the remaining ones
function reduceSkills(talents, searchTerms) {
    sortByMatchingSkillsCount(searchTerms, talents).forEach(talent => {
        let skillCount = talent.skills.length

        talent.reducedSkills = []

        if(skillCount > 0) {
            let name = skillCount-4 + " other skills";
            let lastTag = {
                id: -1,
                name: "+" + name
            }
    
            let reducedSkills = sortArrayByMatchingKeywords(talent.skills, searchTerms).slice(0,4);
    
            reducedSkills.push(lastTag);
    
            talent.reducedSkills = reducedSkills;

            talent.current_position = null;

            if(talent.job_titles.length > 0) {
                talent.current_position = talent.job_titles[0];
            }
        }

    });
    return talents;
}

function reducer(state, action) {
    switch (action.type) {
        case 'roleReturned':
            return {
                ...state,
                role: { ...state.role, ...action.payload },
                editableIndustry: action.payload.industry,
                editableDeliveryNotes: action.payload.info === null ? "" : action.payload.info.delivery_notes,
                formSubmitting: false
            }
        case 'titleRecommendationsReturned':
            return {
                ...state,
                titleRecommendations: action.payload
            }
        case 'skillRecommendationsReturned':
            return {
                ...state,
                skillRecommendations: action.payload
            }
        case 'idealTalentsReturned':
            return {
                ...state,
                searchResults: action.payload,
                isFetching: false
            }
        case 'skillSearchTermUpdated':
            return {
                ...state,
                skillSearchTerm: action.payload
            }
        case 'skillSearchResultsReturned':
            return {
                ...state,
                skillSearchResults: action.payload
            }
        case 'locationSearchTermUpdated':
            return {
                ...state,
                locationSearchTerm: action.payload
            }
        case 'locationsFilterChanged':
            return {
                ...state,
                locationsFilter: action.payload
            }
        case 'locationsReturned':
            return {
                ...state,
                locations: action.payload.locations,
                filterInitialized: action.payload.initialized
            }
        case 'gettingTalents':
            return {
                ...state,
                isFetching: true
            }
        default:
            throw new Error();
    }
}


const LookALikeSearch = React.memo( props => {

    const [state, dispatch] = useReducer(reducer, {
        ...props,
        skillSearchTerm: '',
        skillSearchResults: [],
        skillRecommendations: [],
        searchResults: [],
        locationSearchTerm: '',
        locations: [],
        locationsFilter: [],
        filterInitialized: false,
        isFetching: true
    });
    
    const [localData, setLocalData] = useState([]);

    const debouncedSkillSearchTerm = useDebounce(state.skillSearchTerm, 600);

    const debouncedLocationSearchTerm = useDebounce(state.locationSearchTerm, 600);

    useEffect(() => {
        if (debouncedSkillSearchTerm !== '') {
            searchForSkillsTag(debouncedSkillSearchTerm);
        }
    }, [debouncedSkillSearchTerm])

    useEffect(() => {
        if (debouncedLocationSearchTerm !== '') {
            searchForLocations(debouncedLocationSearchTerm);
        } else {
            dispatch({ type: 'locationsReturned', payload: {locations: [], initialized: false} });
        }
    }, [debouncedLocationSearchTerm])

    useEffect(() => {
        dispatch({ type: 'gettingTalents' });
        if (localData.length > 0) {
            let newLocalData = [...localData];
            newLocalData = newLocalData.map(x => x.id)
            let locations = state.locationsFilter.length > 0 ? state.locationsFilter.map(x => x.talent_location_id).toString() : "";

            getIdealTalentsForRole(props.roleId, newLocalData.toString(), locations)
                .then(response => {
                    // take an array of just the names of skills as second argument
                    dispatch({type: 'idealTalentsReturned', payload: reduceSkills(response.data.data, localData.map(x => x.name))})
                })
                .catch(e => console.log(e))
        } else {
            dispatch({type: 'idealTalentsReturned', payload: []})
        }
    }, [localData, props, state.locationsFilter]);

    useEffect(() => {
        getRole();
    }, [])

    const getRole = () => {
        getRoleById(props.roleId)
            .then(({ role }) => {
                dispatch({ type: 'roleReturned', payload: role });
            })
            .catch(e => console.log(e));
    }

    useEffect(() => {
        const titleIds = state.role && state.role.job_titles && state.role.job_titles.length > 0 ?
            buildParametersArray(state.role.job_titles.map(x => x.id), 'titleIds') : [];

        const skillIds = state.role && state.role.skills && state.role.skills.length > 0 ?
            buildParametersArray(state.role.skills.map(x => x.id), 'skillIds') : [];

        if (titleIds.length > 0) {
            getRecommendationsToJobTitles(state.role.id, titleIds)
                .then(response => {
                    dispatch({ type: 'titleRecommendationsReturned', payload: response.data });
                }).catch(e => console.log(e));

            getRecommendationsToSkills(state.role.id, titleIds, skillIds)
                .then(response => {
                    let test = state.role.skills.concat(response.data);
                    dispatch({ type: 'skillRecommendationsReturned', payload: test });
                }).catch(e => console.log(e));
        }

    }, [state.role]);

    const onSkillsTagRemoved = useCallback( (id) => {
        let filtered = localData.filter(x => x.id !== id);
        setLocalData(filtered);
    }, [localData]);

    const searchForSkillsTag = useCallback((searchTerm) => {
        getSkillsByRoleSearch({ params: { 'search-term': searchTerm } })
            .then(({ data }) => {
                dispatch({ type: 'skillSearchResultsReturned', payload: data });
            }).catch(e => console.log(e));
    }, []);

    const searchForLocations = useCallback((searchTerm) => {
        getTalentsLocation({ params: { 'search-term': searchTerm } })
            .then(({ locations }) => {
                dispatch({ type: 'locationsReturned', payload: {locations: locations, initialized: true} });
            })
            .catch(e => console.log(e));
    }, []);

    const addSkillTagAdded = useCallback((id) => {

        let element = state.skillRecommendations?.find(skill => skill.id === id)

        if (element === undefined) {
            element = state.skillSearchResults?.find(skill => skill.id === id)
        };

        if (element !== undefined) {

            // only update the state if the element is not already part of the state
            if (localData.find(x => x.id === element.id)) {
                return;
            } else {
                setLocalData(prevLocalData => [...prevLocalData, element]);
            }
        }

    }, [props, state.skillRecommendations, state.skillSearchResults, localData]);

    const handleClearSkillSearchResults = useCallback(() => {
        if (state.skillSearchResults.length > 0) {
            dispatch({ type: 'skillSearchResultsReturned', payload: [] });
        }
    }, [state.skillSearchResults]);

    const handleSetSkillSearchTerm = useCallback((value) => {
        dispatch({ type: 'skillSearchTermUpdated', payload: value });
    }, []);

    const handleSetLocationSearchText = useCallback((value) => {
        dispatch({ type: 'locationSearchTermUpdated', payload: value });
    }, []);

    const handleLocationsFilterChanged = useCallback((value) => {
        dispatch({ type: 'locationsFilterChanged', payload: value });
    }, []);

    const handleTagOrderChanged = useCallback( (tagId, sortOrder) => {
        //update the local state to show the user intently that the order has changed
        const newLocalData = [...localData];

        //get the index of the tagId supplied
        const itemMovedIndex = newLocalData.findIndex(x => x.id === tagId);

        //get the index of the tagId by its sort order in the pivot table.
        const itemMovedToIndex = newLocalData.findIndex(x => x.pivot.sort_order === sortOrder);

        //rip the item that we are moving out of the array
        const movedTag = newLocalData.splice(itemMovedIndex, 1)[0];

        //insert the item into the new position
        newLocalData.splice(itemMovedToIndex, 0, movedTag);

        //update the local state
        setLocalData(newLocalData);

        // finally send the request to the server to get new data
        props.onTagOrderChanged(tagId,sortOrder);
    }, [localData, props]);

    const handleClearSkills = useCallback( () => {
        setLocalData([]);
    }, []);

    return (
        <FormGreyWrapper>
            <Col xs={12}>
                <div style={{fontSize: '23px', marginBottom : '20px'}}>Look-alike search</div>
            </Col>
            <RoleTagsWrapper
                title={"Search by Skills"}
                data={localData ?? []}
                recommendations={state.skillRecommendations ?? []}
                searchResults={state.skillSearchResults}
                onTagRemoved={onSkillsTagRemoved}
                onTagOrderChanged={handleTagOrderChanged}
                onTagAdded={addSkillTagAdded}
                searchTerm={state.skillSearchTerm}
                setSearchTerm={handleSetSkillSearchTerm}
                setLocationsSearchText={handleSetLocationSearchText}
                clearSearchResults={handleClearSkillSearchResults}
                onSubmitSubmit={() => ({})}
                placeholderValue={"e.g. javascript, react, python"}
                locationsFilter={true}
                locationsData={state.locations}
                setLocationsToFilter={handleLocationsFilterChanged}
                clearTags={handleClearSkills}
                filterInitialized={state.filterInitialized}
            />
            {/* pass keywords down the component chain for them to be eventually used when determining whether a tag should have a specific background */}
            {state.searchResults.length > 0 && <SearchResultsSection searchResults={state.searchResults} keywords={localData.map(x => x.name)}/>}
            {!state.isFetching && localData.length > 0 && state.searchResults.length === 0 && <NoLookalikeResults/>}
        </FormGreyWrapper>
    )
});

export default LookALikeSearch;