import React from 'react';
import PropTypes from 'prop-types';
import {Tabs} from "react-bootstrap";

const TabsSection = props => {

    /**
     * The styles for this are handled globally. Bootstrap does some odd thing for tab child where the class adding
     * must be done at a top level and not being able to be passed down with props.
     */

    return (
        <Tabs
            id="main-tab-section"
            mountOnEnter={false}
            bsStyle="tabs"
            activeKey={props.activeKey}
            onSelect={props.handleTabSelected}
            unmountOnExit={props.unmountOnExit}
        >
            {props.children}
        </Tabs>
    )
};

export default TabsSection;

TabsSection.propTypes = {
    activeKey: PropTypes.string.isRequired,
    handleTabSelected: PropTypes.func.isRequired,
    unmountOnExit: PropTypes.bool
};

TabsSection.defaultProps = {};