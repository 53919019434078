import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import classes from './CardFormArea.module.css';
import {ControlLabel} from "react-bootstrap";

const CardFormArea = ({onChange}) => {

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                border: 'solid 1px grey',
                padding: '10px',
                color: "#555",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "14px",
                "::placeholder": {
                    color: '#999',
                    opacity: 1
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    return (
        <div>
            <ControlLabel>Card Info</ControlLabel>
            <div className={classes.CardWrapper}>
                <CardElement onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
            </div>
        </div>
    )
};

export default CardFormArea;

CardFormArea.propTypes = {
    onChange: PropTypes.func.isRequired
};

CardFormArea.defaultProps = {};