import React from 'react';
import PropTypes from 'prop-types';
import classes from "./TagSearchDropDown.module.css";

function TagSearchDropDown(props) {
    return props.searchResults.length > 0 ?
        <div className={classes.AutoCompleteListWrapper}>
            <div className={classes.AutoCompleteList}>
                {props.searchResults.map(x =>
                    <div className={classes.AutoCompleteListItem} onClick={() => props.handleItemSelected(x.id)} key={x.id}>
                        {x.name}
                    </div>)
                }
            </div>
    </div> : null;
}

export default TagSearchDropDown;

TagSearchDropDown.propTypes = {
    searchResults: PropTypes.array.isRequired,
    handleItemSelected: PropTypes.func.isRequired
};

TagSearchDropDown.defaultProps = {};