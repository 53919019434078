import React from 'react';
import rolebotImage from "../../../../assets/images/rolebot-bot.png";
import classes from './WaitingForCustomer.module.css';
import {Row, Col} from 'react-bootstrap';

const WaitingForCustomer = props => {
    const message = props.waitingForCard ? `Waiting For Customer to fill out the card info!` :
        `Waiting For Customer to fill out the Spec Form!`;

    return (
        <Row className={classes.CreateRoleScreen}>
            <Col xs={12}>
                <img className={classes.RolebotImage} src={rolebotImage} alt="rolebot" />
            </Col>
            <Col xs={12}>
                <h1 className={classes.Header}>{message}</h1>
            </Col>
        </Row>
    )
};

export default WaitingForCustomer;
