import React from 'react';
import PropTypes from 'prop-types';
import {leftMenuModeSelected} from "../../../../../../../../store/actions";
import classes from "./MenuModeNav.module.css";
import {useDispatch, useSelector} from "react-redux";

const MenuModeNav = ({children, mode, active}) => {
    const dispatch = useDispatch();
    const menuMode = useSelector(state => state.rolesSearchSettings.menuMode);

    const handleSubItemClicked = () => {
        if(menuMode !== mode){
            dispatch(leftMenuModeSelected(mode));
        }
    };

    const styleClass = active ? classes.Active : classes.Default;

    return (
        <li className={styleClass} onClick={handleSubItemClicked}>{children}</li>
    )
};

export default MenuModeNav;

MenuModeNav.propTypes = {
    mode: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired
};

MenuModeNav.defaultProps = {};