import React from 'react';
import PropTypes from 'prop-types';
import classes from './RoleLimitDisplay.module.css';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const RoleLimitDisplay = ({roleLimit, roleCount}) => {

    const tooltipTop = (
        <Tooltip placement="top" id="role-limit-display">
            Active Roles Limit
        </Tooltip>
    );


    return (
        <OverlayTrigger  trigger={["hover", "focus"]} overlay={tooltipTop} placement="top">
            <div className={classes.RoleLimitDisplay}>
                <div className={classes.Column}>
                    <div className={classes.Number}>{roleCount}</div>
                    <div className={classes.Titles}>Active</div>
                </div>
                <span className={classes.Divider}>/</span>
                <div className={classes.Column}>
                    <div className={classes.Number}>{roleLimit}</div>
                    <div className={classes.Titles}>Limit</div>
                </div>
            </div>
        </OverlayTrigger>
    )
};

export default RoleLimitDisplay;

RoleLimitDisplay.propTypes = {
    roleLimit: PropTypes.number,
    roleCount: PropTypes.number
};

RoleLimitDisplay.defaultProps = {};