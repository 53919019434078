import React from 'react';
import PropTypes from 'prop-types';
import classes from "../../FilterTypePopUp.module.css";
import Button from "react-bootstrap/lib/Button";
import FilterItem from "../FilterItem/FilterItem";
import PopupWrapper from "../../../PopupWrapper/PopupWrapper";

const Popup = props => {
    const {accepted, unavailable, interviews, skipped, rejected, waiting, scheduled} = props.filters;

    return (
        <PopupWrapper>
            <Button className={classes.ClearButton} onClick={props.reset}>Clear</Button>
            <div className={classes.FilterListWrapper}>
                <FilterItem filterChanged={props.filterChanged} isActive={accepted} type="accepted" color="green" />
                <FilterItem filterChanged={props.filterChanged} isActive={unavailable} type="unavailable" color="blueviolet" />
                <FilterItem filterChanged={props.filterChanged} isActive={interviews} type="interviews" color="darkgoldenrod" />
                <FilterItem filterChanged={props.filterChanged} isActive={skipped} type="skipped" color="#515151" />
                <FilterItem filterChanged={props.filterChanged} isActive={rejected} type="rejected" color="red" />
                <FilterItem filterChanged={props.filterChanged} isActive={waiting} type="waiting" color="#979797" />
                <FilterItem filterChanged={props.filterChanged} isActive={scheduled} type="scheduled" color="deepskyblue" />
            </div>
        </PopupWrapper>
    )
};

export default Popup;

Popup.propTypes = {
    filters: PropTypes.object.isRequired,
    filterChanged: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
};

Popup.defaultProps = {};