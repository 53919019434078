import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import classes from "../NewAdvanceTextInput/NewAdvanceTextInput.module.css";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";

const NewAdvanceDateTimePicker = props => {
    const {
        label,
        controlId,
        validationState,
        loading,
        helpText,
        onChange,
        value,
        disabled,
        placeholderText = 'Click to select a date'
    } = props;

    return (
        <FormGroup controlId={controlId} validationState={validationState} className={classes.FormGroup}>
            <ControlLabel className={classes.Label}>{label}</ControlLabel>
                {
                    loading ?
                        <Skeleton height={34}/> :
                        <DatePicker
                            disabled={disabled}
                            className={classes.DatePickerInput}
                            calendarClassName={classes.CalendarFix}
                            id={props.id}
                            selected={value}
                            onChange={onChange}
                            // showTimeSelect
                            placeholderText={placeholderText}
                            dateFormat="MMMM d, yyyy"
                            popperPlacement="top-end"
                            openToDate={new Date()}
                        />
                }
            <HelpBlock>{helpText}</HelpBlock>
        </FormGroup>
    )
};

export default NewAdvanceDateTimePicker;

NewAdvanceDateTimePicker.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    helpText: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

NewAdvanceDateTimePicker.defaultProps = {
    componentClass: 'input',
    validationState: null,
    placeholder: '',
    type: 'text',
    required: false,
    loading: false
};