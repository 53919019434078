import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import classes from "./TagDragDropSection.module.css";
import {DndContext} from "@dnd-kit/core";
import {horizontalListSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import RoleTagInput from "../RoleTagInput/RoleTagInput";
import Tag from "../Tag/Tag";

const TagDragDropSection = (props) => {
    //this is used internal to have fewer props to pass down
    const [isInputActive, setIsInputActive] = useState(false);

    const containerStyle = useMemo(() => {
        return isInputActive ?
            [classes.DragContainer, classes.DragContainerFocused].join(' ') : classes.DragContainer;
    }, [isInputActive]);

    const handleDragEnd = useCallback( ({active, over}) => {
        props.onTagOrderChanged(active.id, props.data.find(x => x.id === over.id).pivot.sort_order);
    }, [props.data]);

    const handleOnBlur = useCallback(() => {
        setIsInputActive(false);
    }, []);

    const handleOnFocus = useCallback( () => {
        setIsInputActive(true);
    }, []);

    return (
        <div className={containerStyle}>
            <DndContext
                onDragEnd={handleDragEnd}>
                <SortableContext
                    id={props.title}
                    items={props.data.map(item => item.id)}
                    strategy={horizontalListSortingStrategy}>
                    {props.data.map(x => <Tag key={x.id} {...x} handleDelete={props.handleOnDelete}/> )}
                </SortableContext>
            </DndContext>
            <RoleTagInput
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                title={props.title}
                value={props.value}
                data={props.data}
                placeholderValue={props.placeholderValue}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}/>
        </div>
    );
}

export default TagDragDropSection;

TagDragDropSection.propTypes = {
    data: PropTypes.any,
    onTagOrderChanged: PropTypes.func,
    tagComponent: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.string.isRequired,
    placeholderValue: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    handleOnDelete: PropTypes.func
};

TagDragDropSection.defaultProps = {
};