import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import classes from './RoleUserItem.module.css';
import useGetUserInitials from '../../../../../../../hooks/useGetUserInitials';
import PropTypes from 'prop-types';
import EllipseButton from "../../../../../../Ui/EllipseButton/EllipseButton";
import EllipseMenuItem from "../../../../../../Ui/EllipseButton/Components/EllipseMenuItem/EllipseMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrash, faWallet} from "@fortawesome/free-solid-svg-icons";
import greenCheckmark from '../../../../../../../assets/images/green-checkmark.png';

const RoleUserItem = ({user, menuOpen, menuClicked, removeUserClicked, setuserAsInterviewerClicked, isBillable, isInterviewer, cronofyCalendarId, makeBillable}) => {
    const [initials] = useGetUserInitials(user.name);

    const handleDeleteRemove = () => {
        removeUserClicked(user.id);
        menuClicked();
    }

    const handleSetUserAsInterviewer = () => {
        setuserAsInterviewerClicked(user.id);
        menuClicked();
    }

    return (
        <div className={classes.UserItem}>
            <Row>

                <Col xs={6}>
                    <Col xs={3}>
                        <div className={classes.ColumnWrapper}>
                            <div className={classes.Avatar}>{initials}</div>
                        </div>
                    </Col>
                    <Col xs={9}>
                        <div className={classes.ColumnWrapper}>
                            <div className={classes.UserSummarySection}>
                                <div className={classes.Name}>{user.name ? user.name : 'New User'} {isBillable && 'Billable'} {isInterviewer == 1 && <span className={classes.Title} style={{backgroundColor: "#D7D7D7", marginLeft: ".8rem", padding: ".3rem 0.6rem"}}>Interviewer</span>}</div>
                                <div className={classes.Email}>{user.email}</div>
                                { cronofyCalendarId && <div className={classes.CalendarSyncedCaption}><img className={classes.GreenCheckmark} src={greenCheckmark} alt="green checkmark" />{"Calendar Synced"}</div> }
                            </div>
                        </div>
                    </Col>
                </Col>

                <Col xs={6}>
                    <Col xs={10}>
                        <div className={classes.ColumnWrapper}>
                            <div className={classes.Title}>{user.title !== "" ? user.title : 'No Title'}</div>
                            { user.default_payment_method &&
                                <div>{user.payment_methods.length > 0 && user.default_payment_method.card ?
                                    `${user.default_payment_method.card.brand} ${user.default_payment_method.card.exp_month}/${user.default_payment_method.card.exp_year}` :
                                    "No Default Cards"}</div>
                            }
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className={classes.ColumnWrapper}>
                            <EllipseButton open={menuOpen} onClick={menuClicked} id={user.id}>
                                {user.payment_methods && user.payment_methods.length > 0 && !isBillable &&
                                <EllipseMenuItem onClick={() => makeBillable(user.id)}>
                                    <FontAwesomeIcon icon={faWallet}/> Bill This User
                                </EllipseMenuItem>
                                }
                                {isInterviewer == 0 &&
                                <Fragment>
                                    <EllipseMenuItem onClick={handleSetUserAsInterviewer}><FontAwesomeIcon icon={faPencilAlt}/> Set as 'Interviewer'</EllipseMenuItem>
                                    <hr style={{border: "none", height: ".5px", color: "#D7D7D7", backgroundColor: "#D7D7D7"}}/>
                                </Fragment>
                                }
                                <EllipseMenuItem onClick={handleDeleteRemove}><FontAwesomeIcon icon={faTrash}/> Remove From Role</EllipseMenuItem>
                            </EllipseButton>
                        </div>
                    </Col>
                </Col>

            </Row>
        </div>
    )
};

export default RoleUserItem;

RoleUserItem.propTypes = {
    user: PropTypes.object.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    removeUserClicked: PropTypes.func.isRequired,
    menuClicked: PropTypes.func.isRequired,
    isBillable: PropTypes.bool,
    makeBillable: PropTypes.func
};

RoleUserItem.defaultProps = {};