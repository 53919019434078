import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormGroup, Row, Col, Checkbox, FormControl, Button} from "react-bootstrap";
import classes from "./SearchCriteriaRoleTags.module.css";
import {useFirstRender} from "../../../../../hooks/useFirstRender";
import PinkButton from "../../../PinkFilter/components/PinkButton/PinkButton";
import SearchCriteriaRoleTagInput from "../SearchCriteriaRoleTagInput/SearchCriteriaRoleTagInput";

const SearchCriteriaRoleTags = React.memo(props => {
    // Local data will be used to cache the data that is passed in as props
    const [localData, setLocalData] = useState(props.data);


    const firstRender = useFirstRender();

    useEffect(() => {
        if(firstRender) return;

        setLocalData(props.data);
    }, [props.data]);


    return <FormGroup className={classes.roleTag__formGroup} controlId={props.title}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={12} lg={7}>
                <ControlLabel className={classes.roleTag_label} style={{ width: '50%' }}>{props.title}</ControlLabel>
            </Col>
        </Row>
        <SearchCriteriaRoleTagInput data={props.data} />
    </FormGroup>;
});

export default SearchCriteriaRoleTags;

SearchCriteriaRoleTags.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};

SearchCriteriaRoleTags.defaultProps = {
    filterInitialized: false
};