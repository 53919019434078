import React from "react";
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  ControlLabel,
  Table,
} from "react-bootstrap";
import PropTypes from "prop-types";
import Tag from "./Tag";
import classes from "./SearchResultItem.module.css";
import moment from "moment";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineWorkOutline } from "react-icons/md";
import useGetUserInitials from "../../../../../../../../hooks/useGetUserInitials";

const SearchResultItem = ({ result, isOpen, onRowClicked, keywords }) => {
  const [initials] = useGetUserInitials(result.name);

  const buttonClicked = (e) => {
    e.stopPropagation();
    window.open(result.link, "_blank");
  };

  const roleStatus = (role) => {
    if (role?.completed) {
      return "#E82429";
    } else if (
      !role?.on_hold &&
      !role?.completed &&
      !role?.show_info &&
      !role?.vault
    ) {
      return "#6FBE44";
    } else if (role?.show_info) return "#B3B3B3";
    else if (role?.on_hold) return "#7039ED";

    return "";
  };

  return (
    <Row className={classes.resultsCard}>
      <Col xs={12}>
        <Row onClick={onRowClicked}>
          <Col xs={1}>
            <div className={classes.ColumnWrapper}>
              <div className={classes.Avatar}>{initials}</div>
            </div>
          </Col>
          <Col xs={8} className={classes.personalInfo}>
            <div className={classes.ColumnWrapper}>
              <Row>
                <div className={classes.nameSection}>{result.name}</div>
                <div className={classes.invisibleIdSection}>{result.id}</div>
              </Row>
              <div className={classes.matchLocation}>
                <HiOutlineLocationMarker className={classes.Icon} />
                <span className={classes.resultLocation}>
                  {result.talent_info?.location}
                </span>
              </div>
              <div>
                {/* pass keywords down the component chain for them to be eventually used when determining whether a tag should have a specific background */}
                {result.reducedSkills.map((skill, index) => {
                  return (
                    <Tag key={`${index}-${skill.name}`} name={skill.name} keywords={keywords} />
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xs={3} className="mx-auto">
            <div className="text-center">
              <span className={classes.updatedDate}>
                Updated{" "}
                {moment
                  .utc(result.current_position?.updated_at)
                  .local()
                  .format("MMM d, YYYY")}
              </span>
              <Button className={classes.detailButton} onClick={buttonClicked}>
                Open Profile
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          {isOpen && (
            <Col xs={12}>
              <Tabs
                defaultActiveKey="overview"
                id="candidate-tab"
                variant="pills"
                className={classes.matchTabs}
              >
                <Tab eventKey="overview" title="Overview">
                  {result.current_position && (
                    <Col xs={12} className={classes.currentPositionBox}>
                      <ControlLabel className={classes.position}>
                        Current position
                      </ControlLabel>
                      <div>
                        <span className={classes.jobTitle}>
                          <MdOutlineWorkOutline className={classes.jobIcon} />
                          <span className={classes.jobTitleDescription}>
                            {result.current_position.description ?? ""}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span className={classes.jobCompany}>
                          {result.current_position.company_name ?? ""}
                        </span>
                      </div>
                      <div>
                        <span className={classes.jobDuration}>
                          {moment
                            .utc(result.current_position.start_date)
                            .local()
                            .format("MMMM, YYYY")}{" "}
                          -
                          {result.current_position.end_date
                            ? moment
                                .utc(result.current_position.start_date)
                                .local()
                                .format("MMMM, YYYY")
                            : " Present"}
                        </span>
                      </div>
                      <div>
                        <span className={classes.jobDescription}>
                          {result.current_position.notes ?? ""}
                        </span>
                      </div>
                    </Col>
                  )}
                  <Col xs={12}>
                    <ControlLabel className={classes.position}>
                      Skills
                    </ControlLabel>
                    <div>
                      {result.skills.map((skill) => {
                        return <Tag key={skill.id} name={skill.name} keywords={keywords} />;
                      })}
                    </div>
                  </Col>
                </Tab>

                {result.notes ? (
                  <Tab eventKey="notes" title="Notes">
                    <div>
                      <Col xs={12} className={classes.noteContainer}>
                        <ControlLabel className={classes.noteTitle}>
                          Notes:
                        </ControlLabel>
                        <div className={classes.noteSection}>
                          {result.notes}
                        </div>
                      </Col>
                    </div>
                  </Tab>
                ) : null}

                <Tab eventKey="activity" title="Candidate Activity">
                  <Table responsive className={classes.candidateActivity}>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Role ID</th>
                        <th>Role Name</th>
                        <th>Company</th>
                        <th>Location</th>
                        <th>Outcome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Nice to have: substring of each value, and show
                                        all the info on hover! */}
                      {result.roles.map((role) => {
                        return (
                          <tr key={role.id}>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "12px",
                              }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: `${roleStatus(role)}`,
                                }}
                              >
                                {" "}
                              </div>
                            </td>
                            <td>{role.id}</td>
                            <td>{role.name}</td>
                            <td>{role.company.name}</td>
                            <td>{role.info?.office_locations}</td>
                            <td>{role.pivot.outcome_id ?? "Not interested"}</td>
                            {/* TODO: add outcome to UI */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default SearchResultItem;

SearchResultItem.propTypes = {
  result: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRowClicked: PropTypes.func.isRequired,
};

SearchResultItem.defaultProps = {};
