import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import classes from './SwitchInput.module.css';

const SwitchInput = ({message, onChange, checked, disabled}) => {
    return (
        <div className={classes.SwitchInput}>
            <Switch
                disabled={disabled}
                height={25}
                width={45}
                offColor="#D6D6D6"
                offHandleColor="#727373"
                onHandleColor="#3375B7"
                onColor="#78BFDD"
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={onChange}
                checked={checked}/>
                <span className={classes.Message}>{message}</span>
        </div>
    )
};

export default SwitchInput;

SwitchInput.propTypes = {
    message: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired
};

SwitchInput.defaultProps = {
    disabled: false
};