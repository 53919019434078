import React from 'react';
import PropTypes from 'prop-types';
import classes from "./SearchCriteriaRoleTagInput.module.css";

const SearchCriteriaRoleTagInput = ({ data }) => {
    return (
        <div className={classes.TagInput}>
            {data?.map((tag, index) => (
                <div className={classes.Tag} key={index}>
                    {tag.name || tag.title}
                </div>
            ))}
        </div>
    );
};

export default SearchCriteriaRoleTagInput;

SearchCriteriaRoleTagInput.propTypes = {
    data: PropTypes.any,
};

SearchCriteriaRoleTagInput.defaultProps = {};