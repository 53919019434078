import * as actionTypes from './actionsTypes';

import {
    deleteRoleRequests, getRoleRequests,
    patchRoleRequests
} from '../../utils/services/turboPlus2RestCalls';


export const getRoleRequestsRequest = () => {
    return dispatch => {
        dispatch({type: actionTypes.GET_ROLE_REQUEST_REQUEST});
        getRoleRequests()
            .then(response => dispatch({type: actionTypes.GET_ROLE_REQUEST_SUCCESS, payload: response.data.role_requests}))
            .catch(e => console.log(e));
    }
}


export const patchRoleRequestRequest = (roleRequestsId, params) => {
    return dispatch => {
        dispatch({type: actionTypes.PATCH_ROLE_REQUEST_REQUEST});
        patchRoleRequests(roleRequestsId, params)
            .then(response => dispatch({type: actionTypes.PATCH_ROLE_REQUEST_SUCCESS, payload: response.data.role_request}))
            .catch(e => console.log(e));

    }
}

export const deleteRoleRequestRequest = (roleRequestsId, params) => {
    return dispatch => {
        dispatch({type: actionTypes.DELETE_ROLE_REQUEST_REQUEST});
        deleteRoleRequests(roleRequestsId, params)
            .then(() => dispatch({type: actionTypes.DELETE_ROLE_REQUEST_SUCCESS, payload: roleRequestsId}))
            .catch(e => console.log(e));
    }
}