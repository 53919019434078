import React from 'react';
import {FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import classes from './RolesFilterInput.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {
    roleSearchTypeChanged,
    roleSearchValueChanged,
    roleSearchValueReset
} from '../../../store/actions';

const RolesFilterInput = props => {
    const dispatch = useDispatch();
    const searchText = useSelector(state => state.rolesSearchSettings.searchText);
    const searchModeType = useSelector(state => state.rolesSearchSettings.searchModeType);

    const searchValueChanged = (e) => {
        dispatch(roleSearchValueChanged(e.target.value));
    };

    const searchValueReset = () => {
        dispatch(roleSearchValueReset());
    }

    const roleButtonClass = searchModeType === "role" ?
        [classes.RolesButton, classes.RolesButtonActive].join(' ') : classes.RolesButton;

    const talentButtonClass = searchModeType === "talent" ?
        [classes.TalentButton, classes.TalentButtonActive].join(' '): classes.TalentButton;

    return (
        <div className={classes.RolesFilterInput}>
            <FontAwesomeIcon className={classes.SearchIcon} icon={faSearch}/>
            <div className={classes.InputWrapper}>
                <FormControl
                    className={classes.Input}
                    value={searchText}
                    onChange={searchValueChanged}
                    type="text"
                    placeholder='Search for a Role or Talent Name' />
                <div className={classes.ClearButton} onClick={searchValueReset}>
                    {searchText !== "" && <FontAwesomeIcon icon={faTimesCircle}/>}
                </div>
                <div className={roleButtonClass} onClick={() => dispatch(roleSearchTypeChanged('role'))}>Roles</div>
                <div className={talentButtonClass} onClick={() => dispatch(roleSearchTypeChanged('talent'))}>Talent</div>
            </div>
        </div>
    )
};

export default RolesFilterInput;