import * as actionTypes from  '../actions/actionsTypes';

const initialState = {
    loading: false,
    currentPage: 1,
    lastPage: 1,
    totalPages: 1,
    data: []
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ROLE_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                date: []
            }
        case actionTypes.GET_ROLE_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                currentPage: action.payload.current_page,
                lastPage: action.payload.last_page,
                totalPages: action.payload.total,
                data: action.payload.data
            }
        case actionTypes.CHANGE_ROLE_HISTORY_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
