import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classes from "./AddMoreTalentButton.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FormControl} from "react-bootstrap";

const AddMoreTalentButton = props => {
    const [talentCount, setTalentCount] = useState(1);

    const handleTalentAmountFocused = e => {
        e.target.select();
    };

    const handleTalentCountChanged = (e) => {
        const number = e.target.value;
        if(/^[1-9][0-9]*$/gm.test(number)){
            setTalentCount(number);
        }
    }

    const handleTalentSubmitAddRows = () => {
        if(props.isSubmitting){
            return;
        }
        props.addTalentRowsClicked(talentCount);
    }

    return (
        <div className={classes.AddRowSection}>
            <span onClick={handleTalentSubmitAddRows}>
                <FontAwesomeIcon className={classes.AddRowPlusIcon} icon={faPlusCircle}/>
            </span>
            <span onClick={handleTalentSubmitAddRows}>Add</span>
            <FormControl disabled={props.isSubmitting} onFocus={handleTalentAmountFocused} onChange={handleTalentCountChanged} value={talentCount} className={classes.AddRowInput} type="text"/>
            <span onClick={handleTalentSubmitAddRows}>more talent</span>
        </div>
    )
};

export default AddMoreTalentButton;

AddMoreTalentButton.propTypes = {
    addTalentRowsClicked: PropTypes.func.isRequired,
};

AddMoreTalentButton.defaultProps = {};