import React, { useState } from 'react';
import classes from './DeleteSection.module.css';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { deleteRole } from "../../../utils/services/turboPlus2RestCalls";
import { showToastSuccess } from "../../../utils/toastMessages";
import { useDispatch } from 'react-redux';
import { refreshRoles } from '../../../store/actions';

const DeleteSection = props => {
    const dispatch = useDispatch();
    const { roleId } = props;

    const [isDeleting, setIsDeleting] = useState(false);

    const cancel = () => {
        props.handleCancel();
    };

    const deleteSubmitted = () => {
        setIsDeleting(true);
        deleteRole(roleId)
            .then(() => {
                showToastSuccess("Role has been deleted.");
                dispatch(refreshRoles());
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <Row>
            <Col xs={12}>
                {props.deleteOpen &&
                    <Alert bsStyle="danger" className={classes.Alert}>
                        <h4>Are you sure you want to delete this role?</h4>
                        <p>
                            This will delete this role and all its history and pending talent.
                        </p>
                        <p>
                            Once you delete there is no going back!
                        </p>
                        <br />
                        <p>
                            <Button disabled={isDeleting} bsStyle="danger" onClick={deleteSubmitted}>Delete</Button>
                            <span className={classes.OrPadding}>or</span>
                            <Button onClick={cancel}>Cancel</Button>
                        </p>
                    </Alert>
                }
            </Col>
        </Row>
    )
};

export default DeleteSection;

DeleteSection.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleDeleteClicked: PropTypes.func.isRequired
};