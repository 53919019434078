import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classes from './ClientRequestHistory.module.css';
import {MdHighlightOff, MdSlowMotionVideo} from 'react-icons/md';

const ClientRequestHistory = ({newValue}) => {
    return (
        <Fragment>
            <td>
                <div className={classes.ClientRequestHistory}>
                    {newValue === "open" ?
                        <MdSlowMotionVideo className={classes.CloseIcon}/> :
                        <MdHighlightOff className={classes.OpenIcon}/>
                    }
                    {newValue === "open" ? "Reopen Request"  : "Close Request"}
                </div>
            </td>
        </Fragment>
    );
}

export default ClientRequestHistory;

ClientRequestHistory.propTypes = {
    newValue: PropTypes.string.isRequired
};

ClientRequestHistory.defaultProps = {};