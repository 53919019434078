import React, {
  Fragment,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
  Row,
  Well,
  Form,
  HelpBlock,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classes from "../../../AdminFormComponents/AdminInfoSection/AdminInfoSection.module.css";
import Switch from "react-switch";
import {
  patchRole,
  getRoleById,
  deleteJobTitle,
  deleteSkill,
  getJobTitlesByRoleSearch,
  getSkillsByRoleSearch,
  postJobTitles,
  postRoleSkills,
  // getRecommendationsToJobTitles,
  // getRecommendationsToSkills,
  patchJobTitle,
  patchSkill,
  refreshRoleTags,
  deleteKeyword,
  patchKeyword,
  postKeywords,
  getKeywordByRoleSearch, deleteUserCreatedSkill, patchUserCreatedSkill, postUserCreatedSkills,
} from "../../../../../utils/services/turboPlus2RestCalls";
import TurboPlus2Loader from "../../../TurboPlus2Loader/TurboPlus2Loader";
import {
  showToastSuccess,
  showToastError,
} from "../../../../../utils/toastMessages";
import _ from "lodash";
import ProfileInputComponent from "../../../AdminFormComponents/ProfileInputComponent/ProfileInputComponent";
import AdminMustHaveFeedback from "../../../AdminFormComponents/AdminMustHaveFeedback/AdminMustHaveFeedback";
import RoleTagsWrapper from "../../../RoleTagWrapper/RoleTagsWrapper";
import useDebounce from "../../../../../hooks/useDebounce";
import SearchCriteriaRoleTagsWrapper from "../../../SearchCriteriaRoleTagWrapper/SearchCriteriaRoleTagsWrapper";
import SearchCriteriaRoleTagInput
  from "../../../SearchCriteriaRoleTagWrapper/components/SearchCriteriaRoleTagInput/SearchCriteriaRoleTagInput";

function convertNullToString(value) {
  return value === null ? "" : value;
}

// will turn an array of integers to a parameter list for the url. example [1, 2, 3, 4]
function buildParametersArray(intArray, key = "values") {
  return intArray.map((n, index) => `${key}[${index}]=${n}`).join("&");
}

function reducer(state, action) {
  switch (action.type) {
    case "roleReturned":
      //This needs to be deleted once the backend is fixed to use hybrid instead of blended!!
      let role = action.payload;
      if (role?.info && role?.info.environment === 'blended') {
        role.info.environment = 'Hybrid';
      }
      return {
        ...state,
        role: { ...state.role, ...role },
        editableIndustry: action.payload.industry,
        editableDeliveryNotes:
          action.payload.info === null
            ? ""
            : action.payload.info.delivery_notes,
        formSubmitting: false,
      };
    case "industryUpdated":
      return {
        ...state,
        editableIndustry: action.payload,
      };
    case "deliveryNotesUpdated":
      return {
        ...state,
        editableDeliveryNotes: action.payload,
      };
    case "submittingForm":
      return {
        ...state,
        formSubmitting: true,
      };
    case "submittingFormFailed":
      return {
        ...state,
        formSubmitting: false,
      };
    case "titleSearchResultsReturned":
      return {
        ...state,
        titleSearchResults: action.payload,
      };
    case "skillSearchResultsReturned":
      return {
        ...state,
        skillSearchResults: action.payload,
      };
    case "keywordSearchResultsReturned":
      return {
        ...state,
        keywordSearchResults: action.payload,
      };
    case "titleRecommendationsReturned":
      return {
        ...state,
        titleRecommendations: action.payload,
      };
    case "skillRecommendationsReturned":
      return {
        ...state,
        skillRecommendations: action.payload,
      };
    case "niceToHaveSkillRecommendationsReturned":
      return {
        ...state,
        niceToHaveSkillRecommendations: action.payload,
      };
    case "niceToHaveSkillSearchResultsReturned":
      return {
        ...state,
        niceToHaveSkillSearchResults: action.payload,
      };
    case "titlesSortedReturned":
      return {
        ...state,
        role: {
          ...state.role,
          job_titles: action.payload,
        },
      };
    case "skillsSortedReturned":
      return {
        ...state,
        role: {
          ...state.role,
          skills: action.payload,
        },
      };
    case "niceToHaveSkillsSortedReturned":
      return {
        ...state,
        role: {
          ...state.role,
          skills: action.payload,
        },
      };
    case "keywordsSortedReturned":
      return {
        ...state,
        role: {
          ...state.role,
          keywords: action.payload,
        },
      };
    case "userCreatedSkillsSortedReturned":
      return {
        ...state,
        role: {
          ...state.role,
          skills: action.payload,
        },
      };
    case "titleSearchTermUpdated":
      return {
        ...state,
        titleSearchTerm: action.payload,
      };
    case "skillSearchTermUpdated":
      return {
        ...state,
        skillSearchTerm: action.payload,
      };
    case "niceToHaveSkillSearchTermUpdated":
      return {
        ...state,
        niceToHaveSkillSearchTerm: action.payload,
      };
    case "keywordSearchTermUpdated":
      return {
        ...state,
        keywordSearchTerm: action.payload,
      };
    case "userCreatedSkillSearchTermUpdated":
      return {
        ...state,
        userCreatedSkillSearchTerm: action.payload,
      };
    case "reset":
      return {
        ...state,
        editableIndustry: state.role.industry,
        editableDeliveryNotes: state.role.info.delivery_notes,
      };
    default:
      throw new Error();
  }
}

const RoleDeliveryInfoSection = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    role: null,
    editableIndustry: null,
    editableDeliveryNotes: null,
    formSubmitting: false,
    titleSearchTerm: "",
    titleSearchResults: [],
    titleRecommendations: [],
    keywordSearchTerm: "",
    keywordSearchResults: [],
    skillSearchTerm: "",
    skillSearchResults: [],
    skillRecommendations: [],
    niceToHaveSkillSearchTerm: "",
    niceToHaveSkillSearchResults: [],
    niceToHaveSkillRecommendations: [],
    userCreatedSkillSearchTerm: "",
    userCreatedSkillSearchResults: [],
  });

  const debouncedTitleSearchTerm = useDebounce(state.titleSearchTerm, 600);

  const debouncedSkillSearchTerm = useDebounce(state.skillSearchTerm, 600);

  const debouncedNiceToHaveSkillSearchTerm = useDebounce(
    state.niceToHaveSkillSearchTerm,
    600
  );

  const debouncedKeywordSearchTerm = useDebounce(state.keywordSearchTerm, 600);


  useEffect(() => {
    if (debouncedTitleSearchTerm !== "") {
      searchForTitleTags(debouncedTitleSearchTerm);
    }
  }, [debouncedTitleSearchTerm]);

  useEffect(() => {
    if (debouncedSkillSearchTerm !== "") {
      searchForSkillsTag(debouncedSkillSearchTerm);
    }
  }, [debouncedSkillSearchTerm]);

  useEffect(() => {
    if (debouncedNiceToHaveSkillSearchTerm !== "") {
      searchForNiceToHaveSkillsTag(debouncedNiceToHaveSkillSearchTerm);
    }
  }, [debouncedNiceToHaveSkillSearchTerm]);

  useEffect(() => {
    if (debouncedKeywordSearchTerm !== "") {
      searchForKeywordTags(debouncedKeywordSearchTerm);
    }
  }, [debouncedKeywordSearchTerm]);

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    const titleIds =
      state.role && state.role.job_titles && state.role.job_titles.length > 0
        ? buildParametersArray(
            state.role.job_titles.map((x) => x.id),
            "titleIds"
          )
        : [];

    const skillIds =
      state.role && state.role.skills && state.role.skills.length > 0
        ? buildParametersArray(
            state.role.skills
              .filter((x) => !x.pivot.nice_to_have)
              .map((x) => x.id),
            "skillIds"
          )
        : [];

    const niceToHaveSkillIds =
      state.role && state.role.skills && state.role.skills.length > 0
        ? buildParametersArray(
            state.role.skills
              .filter((x) => x.pivot.nice_to_have)
              .map((x) => x.id),
            "skillIds"
          )
        : [];

    // if (titleIds.length > 0) {
    //   getRecommendationsToJobTitles(props.roleId, titleIds)
    //     .then((response) => {
    //       dispatch({
    //         type: "titleRecommendationsReturned",
    //         payload: response.data,
    //       });
    //     })
    //     .catch((e) => console.log(e));

    //   getRecommendationsToSkills(props.roleId, titleIds, skillIds)
    //     .then((response) => {
    //       dispatch({
    //         type: "skillRecommendationsReturned",
    //         payload: response.data,
    //       });
    //     })
    //     .catch((e) => console.log(e));

    //   getRecommendationsToSkills(props.roleId, titleIds, niceToHaveSkillIds)
    //     .then((response) => {
    //       dispatch({
    //         type: "niceToHaveSkillRecommendationsReturned",
    //         payload: response.data,
    //       });
    //     })
    //     .catch((e) => console.log(e));
    // }
  }, [state.role]);

  const getRole = () => {
    getRoleById(props.roleId)
      .then(({ role }) => {
        dispatch({ type: "roleReturned", payload: role });
      })
      .catch((e) => console.log(e));
  };

  const handleIndustryUpdated = (e) => {
    dispatch({ type: "industryUpdated", payload: e.target.value });
  };

  const handleDeliveryNotesUpdated = (e) => {
    dispatch({ type: "deliveryNotesUpdated", payload: e.target.value });
  };

  const handleTitlesTagOrderChanged = useCallback(
    (titleId, order) => {
      patchJobTitle(props.roleId, titleId, { sort_order: order })
        .then(({ data }) =>
          dispatch({ type: "titlesSortedReturned", payload: data })
        )
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const handleSkillsTagOrderChanged = useCallback(
    (skillId, order) => {
      patchSkill(props.roleId, skillId, { sort_order: order })
        .then(({ data }) =>
          dispatch({ type: "skillsSortedReturned", payload: data })
        )
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const handleNiceToHaveSkillsTagOrderChanged = useCallback(
    (skillId, order) => {
      patchSkill(props.roleId, skillId, { sort_order: order })
        .then(({ data }) =>
          dispatch({ type: "niceToHaveSkillsSortedReturned", payload: data })
        )
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const handleKeywordsTagOrderChanged = useCallback(
      (keywordId, order) => {
        patchKeyword(props.roleId, keywordId, { sort_order: order })
            .then(({ data }) =>
                dispatch({ type: "keywordsSortedReturned", payload: data })
            )
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const handleUserCreatedSkillsTagOrderChanged = useCallback(
      (keywordId, order) => {
        patchUserCreatedSkill(props.roleId, keywordId, { sort_order: order })
            .then(({ data }) =>
                dispatch({ type: "userCreatedSkillsSortedReturned", payload: data })
            )
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const handleRoleTagsRefreshed = useCallback(() => {
    refreshRoleTags(props.roleId)
      .then(({ data }) => {
        dispatch({ type: "titlesSortedReturned", payload: data?.job_titles });
        dispatch({ type: "skillsSortedReturned", payload: data?.skills });
      })
      .catch((e) => console.log(e));
  }, [props.roleId]);

  const handleRestForm = () => {
    dispatch({ type: "reset" });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    dispatch({ type: "submittingForm" });

    const { editableIndustry, editableDeliveryNotes } = state;

    let payload = editableIndustry ? { industry: editableIndustry } : {};

    payload = editableDeliveryNotes
      ? {
          ...payload,
          info: {
            delivery_notes: editableDeliveryNotes,
          },
        }
      : payload;

    patchRole(props.roleId, payload)
      .then(({ role }) => {
        showToastSuccess("Role updated.");
        dispatch({ type: "roleReturned", payload: role });
      })
      .catch((e) => {
        const message = e.message ? e.message : e;
        const errors = e.errors ? _.flattenDeep(Object.values(e.errors)) : [];
        showToastError(message, errors);
        dispatch({ type: "submittingFormFailed" });
      });
  };

  const addJobTitleTagHandler = useCallback(
    (value, type = "id") => {
      const payload = type === "id" ? { id: value } : { name: value };
      postJobTitles(props.roleId, payload)
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const addSkillTagHandler = useCallback(
    (value, type = "id") => {
      const payload = type === "id" ? { id: value } : { name: value };
      postRoleSkills(props.roleId, payload)
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const addNiceToHaveSkillTagHandler = useCallback(
    (value, type = "id") => {
      const payload =
        type === "id"
          ? { id: value, nice_to_have: 1 }
          : { name: value, nice_to_have: 1 };
      postRoleSkills(props.roleId, payload)
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const addKeywordTagHandler = useCallback(
      (value, type = "id") => {
        const payload = type === "id" ? { id: value } : { name: value };
        postKeywords(props.roleId, payload)
            .then(() => {
              getRole();
            })
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const addUserCreatedSkillTagHandler = useCallback(
      (value, type = "id") => {
        const payload = type === "id" ? { id: value } : { name: value };
        postUserCreatedSkills(props.roleId, payload)
            .then(() => {
              getRole();
            })
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const onTitlesTagRemoved = useCallback(
    (id) => {
      deleteJobTitle(props.roleId, { params: { id } })
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const onSkillsTagRemoved = useCallback(
    (id) => {
      deleteSkill(props.roleId, { params: { id } })
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const onNiceToHaveSkillsTagRemoved = useCallback(
    (id) => {
      deleteSkill(props.roleId, { params: { id } })
        .then(() => {
          getRole();
        })
        .catch((e) => console.log(e));
    },
    [props.roleId]
  );

  const onKeywordsTagRemoved = useCallback(
      (id) => {
        deleteKeyword(props.roleId, { params: { id } })
            .then(() => {
              getRole();
            })
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const onUserCreatedSkillsTagRemoved = useCallback(
      (id) => {
        deleteUserCreatedSkill(props.roleId, { params: { id } })
            .then(() => {
              getRole();
            })
            .catch((e) => console.log(e));
      },
      [props.roleId]
  );

  const searchForTitleTags = useCallback((searchTerm) => {
    getJobTitlesByRoleSearch({ params: { "search-term": searchTerm } })
      .then(({ data }) => {
        dispatch({ type: "titleSearchResultsReturned", payload: data });
      })
      .catch((e) => console.log(e));
  }, []);

  const searchForSkillsTag = useCallback((searchTerm) => {
    getSkillsByRoleSearch({ params: { "search-term": searchTerm } })
      .then(({ data }) => {
        dispatch({ type: "skillSearchResultsReturned", payload: data });
      })
      .catch((e) => console.log(e));
  }, []);

  const searchForNiceToHaveSkillsTag = useCallback((searchTerm) => {
    getSkillsByRoleSearch({ params: { "search-term": searchTerm } })
      .then(({ data }) => {
        dispatch({
          type: "niceToHaveSkillSearchResultsReturned",
          payload: data,
        });
      })
      .catch((e) => console.log(e));
  }, []);

  const searchForKeywordTags = useCallback((searchTerm) => {
    getKeywordByRoleSearch({ params: { "search-term": searchTerm } })
        .then(({ data }) => {
          dispatch({ type: "keywordSearchResultsReturned", payload: data });
        })
        .catch((e) => console.log(e));
  }, []);

  const handleClearTitleSearchResults = useCallback(() => {
    if (state.titleSearchResults.length > 0) {
      dispatch({ type: "titleSearchResultsReturned", payload: [] });
    }
  }, [state.titleSearchResults]);

  const handleClearSkillSearchResults = useCallback(() => {
    if (state.skillSearchResults.length > 0) {
      dispatch({ type: "skillSearchResultsReturned", payload: [] });
    }
  }, [state.skillSearchResults]);

  const handleClearNiceToHaveSkillSearchResults = useCallback(() => {
    if (state.niceToHaveSkillSearchResults.length > 0) {
      dispatch({ type: "niceToHaveSkillSearchResultsReturned", payload: [] });
    }
  }, [state.niceToHaveSkillSearchResults]);

  const handleClearKeywordSearchResults = useCallback(() => {
    if (state.keywordSearchResults.length > 0) {
      dispatch({ type: "keywordSearchResultsReturned", payload: [] });
    }
  }, [state.keywordSearchResults]);

  const handleClearUserCreatedSkillSearchResults = useCallback(() => {
    if (state.userCreatedSkillSearchResults.length > 0) {
      dispatch({ type: "userCreatedSkillSearchResultsReturned", payload: [] });
    }
  }, [state.userCreatedSkillSearchResults]);

  const handleSetTitleSearchTerm = useCallback((value) => {
    dispatch({ type: "titleSearchTermUpdated", payload: value });
  }, []);

  const handleSetSkillSearchTerm = useCallback((value) => {
    dispatch({ type: "skillSearchTermUpdated", payload: value });
  }, []);

  const handleSetNiceToHaveSkillSearchTerm = useCallback((value) => {
    dispatch({ type: "niceToHaveSkillSearchTermUpdated", payload: value });
  }, []);

  const handleSetKeywordSearchTerm = useCallback((value) => {
    dispatch({ type: "keywordSearchTermUpdated", payload: value });
  }, []);

  const handleSetUserCreatedSkillSearchTerm = useCallback((value) => {
    dispatch({ type: "userCreatedSkillSearchTermUpdated", payload: value });
  }, []);

  const handleTitleSearchSubmit = useCallback((searchTerm) => {
    handleClearTitleSearchResults();
    addJobTitleTagHandler(searchTerm, "name");
    handleSetTitleSearchTerm("");
  }, []);

  const handleSKillSearchSubmit = useCallback((searchTerm) => {
    handleClearSkillSearchResults();
    addSkillTagHandler(searchTerm, "name");
    handleSetSkillSearchTerm("");
  }, []);

  const handleNiceToHaveSkillSearchSubmit = useCallback((searchTerm) => {
    handleClearNiceToHaveSkillSearchResults();
    addNiceToHaveSkillTagHandler(searchTerm, "name");
    handleSetNiceToHaveSkillSearchTerm("");
  }, []);

  const handleKeywordSearchSubmit = useCallback((searchTerm) => {
    handleClearKeywordSearchResults();
    addKeywordTagHandler(searchTerm, "name");
    handleSetKeywordSearchTerm("");
  }, []);

  const handleUserCreatedSkillSearchSubmit = useCallback((searchTerm) => {
    handleClearUserCreatedSkillSearchResults();
    addUserCreatedSkillTagHandler(searchTerm, "name");
    handleSetUserCreatedSkillSearchTerm("");
  }, []);

  if (!state.role) {
    return <TurboPlus2Loader />;
  }

  const linkOne = state.role.talent?.[0] ? state.role.talent?.[0].link : "";
  const linkTwo = state.role.talent?.[1] ? state.role.talent?.[1].link : "";

  return (
    <Form onSubmit={handleSubmitForm}>
      {!!state.role.info && (
        <Fragment>
          <Well bsSize="large">
            <Row>
              <Col xs={12}>
                <FormGroup
                  controlId="roleNotes"
                  validationState={props.notesValidationState}
                >
                  <ControlLabel>Role Notes</ControlLabel>
                  <FormControl
                    disabled
                    value={convertNullToString(state.role.info.notes)}
                    componentClass="textarea"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup
                  controlId="roleNotes"
                  validationState={props.notesValidationState}
                >
                  <ControlLabel>Delivery Notes</ControlLabel>
                  <FormControl
                    value={convertNullToString(state.editableDeliveryNotes)}
                    onChange={handleDeliveryNotesUpdated}
                    componentClass="textarea"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>
          </Well>
          
          {state.role.user_searches.length ?
              <Well bsSize="large">
                <label style={{fontSize: "18px"}}>Search Criteria</label>

                {state.role.user_searches[0]?.job_titles?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Job titles (or similar)"}
                        data={state.role.user_searches[0]?.job_titles}
                    /> : null
                }

                {state.role.user_searches[0]?.skills?.filter(x => !x.pivot.nice_to_have)?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Must have skills include"}
                        data={state.role.user_searches[0]?.skills?.filter(x => !x.pivot.nice_to_have) || []}
                    /> : null
                }

                {state.role.user_searches[0]?.skills?.filter(x => x.pivot.nice_to_have)?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Nice to have skills include"}
                        data={state.role.user_searches[0]?.skills?.filter(x => x.pivot.nice_to_have) || []}
                    /> : null
                }

                {(state.role.info.preferred_experience_min !== null || state.role.info.preferred_experience_max !== null) ?
                    <FormGroup className={classes.roleTag__formGroup} controlId={'candidate-experience'}>
                      <Row style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                        <Col xs={12} lg={7}>
                          <ControlLabel className={classes.roleTag_label}
                                        style={{width: '50%'}}>{"Candidate's experience"}</ControlLabel>
                        </Col>
                      </Row>
                      {(state.role.info.preferred_experience_min !== null && state.role.info.preferred_experience_max !== null)
                        || (state.role.info.preferred_experience_min === null && state.role.info.preferred_experience_max !== null)
                        ?
                          <p className={classes.TalentPreferredExperienceRange}>Between <span
                              className={classes.TalentPreferredExperienceRangeSpan}>{state.role.info.preferred_experience_min ?? 0} and {state.role.info.preferred_experience_max}</span> years
                            of experience</p> :
                          <p>More than <span
                              className={classes.TalentPreferredExperienceRangeSpan}>{state.role.info.preferred_experience_min}</span> year(s)
                            of experience</p>}
                    </FormGroup> : null
                }

                {state.role.user_searches[0]?.languages?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Languages"}
                        data={state.role.user_searches[0]?.languages}
                    /> : null
                }

                {state.role.user_searches[0]?.industries?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Industries"}
                        data={state.role.user_searches[0]?.industries}
                    /> : null
                }

                {state.role.user_searches[0]?.certificates?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Certificates or specific degrees"}
                        data={state.role.user_searches[0]?.certificates}
                    /> : null
                }

                {state.role.user_searches[0]?.keywords?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Keywords"}
                        data={state.role.user_searches[0]?.keywords}
                    /> : null
                }

                {state.role.user_searches[0]?.companies?.filter(x => !x.pivot.is_excluded)?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Target companies"}
                        data={state.role.user_searches[0]?.companies?.filter(x => !x.pivot.is_excluded) || []}
                    /> : null
                }

                {state.role.user_searches[0]?.companies?.filter(x => x.pivot.is_excluded)?.length ?
                    <SearchCriteriaRoleTagsWrapper
                        title={"Exclude companies"}
                        data={state.role.user_searches[0]?.companies?.filter(x => x.pivot.is_excluded) || []}
                    /> : null
                }

                {state.role.user_searches[0]?.prioritize_gender_diversity ?
                    <FormGroup
                        style={{marginTop: "1rem", textAlign: "start"}}
                        controlId="prioritize-gender-diversity"
                        validationState={props.visaSponsorshipValidationState}
                        className={classes.ButtonSliderGroup}
                    >
                      <ControlLabel>Prioritize Gender Diversity</ControlLabel>
                      <InputGroup className={classes.SliderGroup} style={{textAlign: "start"}}>
                        <Switch
                            disabled
                            onChange={() => {
                            }}
                            checked={state.role.user_searches[0]?.prioritize_gender_diversity || false}
                        />
                      </InputGroup>
                    </FormGroup> : null
                }

                {/* {isRoverScannerProfile1 && isRoverScannerProfile2 && (
                <Row style={{ marginTop: "15px" }}>
                  <Col xs={12} lg={2} style={{ marginLeft: "81%" }}>
                    <Button
                        disabled={state.formSubmitting}
                        onClick={() => handleRoleTagsRefreshed()}
                        style={{ width: "120px" }}
                        bsStyle="success"
                        block
                        type="submit"
                    >
                      {state.formSubmitting ? "Loading.." : "Refresh Tags"}
                    </Button>
                  </Col>
                </Row>
            )} */}

              <AdminMustHaveFeedback
                feedback={state.role}
                mustHaves={state.role.info.must_haves}
              />
            </Well> : null
          }
          <Well bsSize="large">
            <Row>
              <Col xs={12} lg={6}>
                <ProfileInputComponent
                  label="Profile Link 1"
                  validationState={props.profileOneValidationState}
                  value={linkOne}
                  disabled={true}
                />
              </Col>

              <Col xs={12} lg={6}>
                <ProfileInputComponent
                  label="Profile Link 2"
                  validationState={props.profileTwoValidationState}
                  value={linkTwo}
                  disabled={true}
                />
              </Col>
            </Row>
            { false && <Row>
              <Col xs={12}>
                <FormGroup
                  controlId="industry"
                  validationState={props.industryValidation}
                >
                  <ControlLabel>Industry</ControlLabel>
                  <FormControl
                    value={convertNullToString(state.editableIndustry)}
                    onChange={handleIndustryUpdated}
                    type="text"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>}
          </Well>

          <Well bsSize="large">
            <Row>
              <Col xs={12} lg={6}>
                <FormGroup
                  controlId="targetSalary"
                  validationState={props.targetSalaryValidationState}
                >
                  <ControlLabel>Max Salary</ControlLabel>
                  <FormControl
                    disabled
                    value={state.role.info.target_salary}
                    type="text"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              {!state.role.sourcing_only && (
                <Col xs={12} lg={6}>
                  <FormGroup
                    controlId="currentJDLink"
                    validationState={props.jDLinkValidation}
                  >
                    <ControlLabel>Link to current JD</ControlLabel>
                    <FormControl
                      disabled
                      value={convertNullToString(state.role.info.jd_link)}
                      type="text"
                    />
                    <FormControl.Feedback />
                  </FormGroup>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs={12} lg={12}>
                <FormGroup
                  controlId="candidateLocations"
                  validationState={props.officeLocationsValidation}
                >
                  <ControlLabel>Candidate Location</ControlLabel>
                  <div style={{border: '1px solid #ccc', backgroundColor:'#EEE', borderRadius:4,
                                        display: 'flex', alignItems:'center', flexWrap: 'wrap',
                                        padding: '6px 12px'}}>
                            {state.role.info.locations.length > 0 ? state.role.info.locations.map(location => (
                                <div style={{ margin: '1px 4px', padding: '0px 2px',border: '1px solid #0A7AA2',
                                                borderRadius: 2, backgroundColor: '#DCF1F8' }}>
                                    {location.location_name}
                                </div>
                            )) : (
                              <span>Not specified</span>
                            )}
                        </div>
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <FormGroup
                  controlId="environment"
                  validationState={props.environmentValidation}
                >
                  <ControlLabel>Work Environment</ControlLabel>
                  <FormControl
                    disabled
                    value={state.role.info.environment}
                    type="text"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col xs={12} lg={6}>
                <FormGroup
                  controlId="officeLocations"
                  validationState={props.officeLocationsValidation}
                >
                  <ControlLabel>Office Location</ControlLabel>
                  <div style={{border: '1px solid #ccc', backgroundColor:'#EEE', borderRadius:4,
                                        display: 'flex', alignItems:'center', flexWrap: 'wrap',
                                        padding: '6px 12px'}}>
                            {state.role.info.offices.length > 0 ? state.role.info.offices.map(location => (
                                <div style={{ margin: '1px 4px', padding: '0px 2px',border: '1px solid #0A7AA2',
                                                borderRadius: 2, backgroundColor: '#DCF1F8' }}>
                                    {location.location_name}
                                </div>
                            )) : (<div style={{ margin: '1px 4px', padding: '0px 2px',border: '1px solid #0A7AA2',
                                                borderRadius: 2, backgroundColor: '#DCF1F8' }}>
                                    {state.role.info.office_locations ?? 'Not specified'}
                                </div>
                            )}
                        </div>
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <FormGroup
                  controlId="sellOpportunity"
                  validationState={props.sellOpportunityValidation}
                >
                  <ControlLabel>
                    How will you sell this opportunity?
                  </ControlLabel>
                  <FormControl
                    disabled
                    value={state.role.info.sell_opportunity ?? ''}
                    componentClass="textarea"
                  />
                  <FormControl.Feedback />
                  <HelpBlock>0 to 7000 chars only</HelpBlock>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup
                  controlId="visa-sponsorship"
                  validationState={props.visaSponsorshipValidationState}
                  className={classes.ButtonSliderGroup}
                >
                  <ControlLabel>Visa Sponsorship</ControlLabel>
                  <InputGroup className={classes.SliderGroup}>
                    <Switch
                      disabled
                      onChange={() => {}}
                      checked={state?.role?.info?.visa_sponsorship || false}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup
                  controlId="offerRelocation"
                  validationState={props.offerRelocationValidation}
                  className={classes.ButtonSliderGroup}
                >
                  <ControlLabel>Offer Relocation?</ControlLabel>
                  <InputGroup className={classes.SliderGroup}>
                    <Switch
                      disabled
                      onChange={() => {}}
                      checked={state?.role?.info?.offer_relocation || false}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Well>

          <Row style={{ marginBottom: "15px" }}>
            <Col xs={12} lg={2} lgOffset={8}>
              <Button
                disabled={
                  state.formSubmitting ||
                  (state.editableIndustry === state.role.industry &&
                    state.editableDeliveryNotes ===
                      state.role.info.delivery_notes)
                }
                bsStyle="success"
                block
                type="submit"
              >
                {state.formSubmitting ? "Saving..." : "Update Order"}
              </Button>
            </Col>
            <Col xs={12} lg={2}>
              <Button block onClick={handleRestForm}>
                Reset
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Form>
  );
};

export default RoleDeliveryInfoSection;

RoleDeliveryInfoSection.propTypes = {
  roleId: PropTypes.number.isRequired,
};

RoleDeliveryInfoSection.defaultProps = {};
