import {toast} from 'react-toastify';
import ToastMessageBox from "../components/TurboPlus2/ToastMessageBox/ToastMessageBox";
import React from "react";

export const showToastSuccess = (message) => {
    toast.success(message, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
    });
};

export const showToastError = (message, errors = []) => {
    const parsedMessage = message.message ? message.message : message;
    toast.error(<ToastMessageBox message={parsedMessage} errors={errors}/>, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
    });
};

export const showToastWarning = (message) => {
    toast.warn(message, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
    });
}