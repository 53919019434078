import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from "react-redux";
import {getRoleHistoryRequest, changeRoleHistoryPageRequest} from '../../../store/actions';
import {Table, Pager} from 'react-bootstrap';
import RoleHistoryItem from "./RoleHistoryItem/RoleHistoryItem";
import classes from'./RoleHistorySection.module.css';


const RoleHistorySection = props => {
    const dispatchRedux = useDispatch();
    const loadingHistory = useSelector(state => state.roleHistory.loading);
    const roleHistory = useSelector(state => state.roleHistory.data);
    const currentPage = useSelector(state => state.roleHistory.currentPage);
    const lastPage = useSelector(state => state.roleHistory.lastPage);
    const totalPages = useSelector(state => state.roleHistory.totalPages);

    useEffect(() => {
        dispatchRedux(getRoleHistoryRequest(props.roleId, {page: currentPage, "per_page": 15}));
    }, [currentPage])

    const handlePageChanged = (page) => {
        dispatchRedux(changeRoleHistoryPageRequest(page))
    }

    return (
        <div>
            <div className={classes.Title}>Role Activity</div>
            <Table bordered condensed hover responsive >
                <tbody>
                    {!loadingHistory && roleHistory.map(x => <RoleHistoryItem key={x.id} item={x}/>)}
                </tbody>
            </Table>
            {!loadingHistory && totalPages > 1 &&
                <Pager>
                    { currentPage > 1 &&
                        <Pager.Item onClick={() => handlePageChanged(currentPage-1)}>
                            &larr; Previous Page
                        </Pager.Item>
                    }
                    { lastPage !== currentPage &&
                        <Pager.Item onClick={() => handlePageChanged(currentPage+1)}>
                            Next Page &rarr;
                        </Pager.Item>
                    }
                </Pager>
            }
        </div>

    )
};

export default RoleHistorySection;

RoleHistorySection.propTypes = {
    roleId: PropTypes.number.isRequired
};

RoleHistorySection.defaultProps = {};