import * as actionTypes from  '../actions/actionsTypes';

export const initialState = {
    // mode has two options: success and delivery
    mode: 'success',
    csvExportSettings: {
        includeStatus: true,
        includeApprovedSkippedRejectionDate: false,
        includeOutcomes: false,
        includeContactInfo: false,
        includeLinkedinProfile: true,
        includeGender: false,
        includeDNI: false,
        includeInterviewDate: false,
        includeDateSurfaced: false,
        separateNames: false,
        beautifyDates: false
    }
};

const saveSettings = (state) => {
    localStorage.setItem('siteSettings', JSON.stringify(state))
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.APP_SETTINGS_UPDATE_MODE:
           const newState = {
               ...state,
               mode: action.payload
           }
            saveSettings(newState);
            return newState;
        case actionTypes.APP_SETTINGS_UPDATE_CSV_PREFERENCE:
            return {
                ...state,
                csvExportSettings: {
                    ...state.csvExportSettings,
                    ...action.payload
                }
            }
        case actionTypes.APP_SETTINGS_SAVE_CSV_PREFERENCES:
            saveSettings(state);
            return state;
        case actionTypes.APP_SETTINGS_RESET_CSV_PREFERENCES: {
            const resetState = {
                ...state,
                csvExportSettings: initialState.csvExportSettings
            };

            saveSettings(resetState);
            return resetState;
        }

        default:
            return state;
    }
}

export default reducer; 