import React from 'react';
import {Modal, Button} from 'react-bootstrap';

const ConfirmationModal = props => {
    return (
        <Modal show={props.show} onHide={props.handleCancel}  bsSize="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                    Confirm Your Change
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.disabled} bsStyle="danger" onClick={props.handleConfirm}>Confirm</Button>
                <Button disabled={props.disabled} bsStyle="primary" onClick={props.handleCancel}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmationModal;