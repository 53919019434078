import React from 'react';
import classes from "./TalentListOuterWrapper.module.css";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

const TalentListOuterWrapper = props => {
    return (
        <div className={classes.Body}>
            <Row>
                <Col lg={10} lgOffset={1}>
                    {props.children}
                </Col>
            </Row>
        </div>
    )
};

export default TalentListOuterWrapper;