import React, {useEffect, useState} from 'react';
import {
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup,
    OverlayTrigger,
    Popover,
    Row,
    Well
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {getCardMessages} from "../../../../utils/services/turboPlus2RestCalls";
import Switch from 'react-switch';
import classes from "./AdminOptionsSection.module.css";

const AdminOptionsSection = props => {
    const [cardMessages, setCardMessages] = useState([]);

    useEffect(() => {
        let isMounted = true;
        getCardMessages()
            .then( ({card_messages}) => {
                if(isMounted){
                    setCardMessages(card_messages);
                }
            })
            .catch(e => console.log(e));
        return () => { isMounted = false };
    }, []);

    return (
        <Well bsSize="large">
            <Row>
                <Col xs={12} md={4} >
                    <FormGroup controlId="isSourcingOnly" className={classes.ButtonSliderGroup}>
                        <ControlLabel>
                            Sourcing Only? {" "}
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="right"
                                overlay={(
                                    <Popover id="popover-positioned-right" title="No Sourcing">
                                        Checking this will show a message telling Admins <strong style={{color: "red"}}>NOT</strong> to contact talent under this role.
                                    </Popover>
                                )}>
                                <FontAwesomeIcon icon={faQuestionCircle}/>
                            </OverlayTrigger>
                        </ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.isSourcingOnlyOnChange} checked={props.isSourcingOnly ? props.isSourcingOnly : false} />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={6} md={4}>
                    <FormGroup controlId="isTrial" className={classes.ButtonSliderGroup}>
                        <ControlLabel>is Trial?</ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.isTrialOnChange} checked={props.isTrialValue} />
                        </InputGroup>
                    </FormGroup>
                </Col>
                {/* <Col xs={6} md={2}>
                    <FormGroup controlId="isInvoice" className={classes.ButtonSliderGroup} validationState={props.isInvoiceValidateState}>
                        <ControlLabel>is Invoice?</ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.isInvoiceOnChange} checked={props.isInvoiceValue} />
                        </InputGroup>
                    </FormGroup>
                </Col> */}
                <Col xs={6} md={4}>
                    <FormGroup controlId="isVault" className={classes.ButtonSliderGroup}>
                        <ControlLabel>is Vault?</ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.isVaultOnChange} checked={props.isVaultValue} />
                        </InputGroup>
                    </FormGroup>
                </Col>
                {/*<Col xs={6} md={2}>*/}
                {/*    <FormGroup controlId="forceCard" className={classes.ButtonSliderGroup} validationState={props.isForceCardValidateState}>*/}
                {/*        <ControlLabel>*/}
                {/*            Collect Card{" "}*/}
                {/*            <OverlayTrigger*/}
                {/*                trigger={["hover", "focus"]}*/}
                {/*                placement="right"*/}
                {/*                overlay={(*/}
                {/*                    <Popover id="popover-positioned-right" title="Force Collect Card">*/}
                {/*                        Checking this will force the user to submit a card. Can only be used if <strong style={{color: "red"}}>ALL</strong> the users added to the role are*/}
                {/*                        missing card data.*/}
                {/*                    </Popover>*/}
                {/*                )}>*/}
                {/*                <FontAwesomeIcon icon={faQuestionCircle}/>*/}
                {/*            </OverlayTrigger>*/}
                {/*        </ControlLabel>*/}
                {/*        <InputGroup className={classes.SliderGroup}>*/}
                {/*            <Switch onChange={props.isForceCardOnChange} checked={props.isForceCardValue} />*/}
                {/*        </InputGroup>*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
            </Row>
            {(props.showCardMessage && cardMessages.length > 0) &&
            <Row>
                <Col xs={12}>
                    <FormGroup controlId="credit-card-message">
                        <ControlLabel>Credit Card Message</ControlLabel>
                        <FormControl
                            componentClass="select"
                            placeholder="select"
                            onChange={props.handleCardMessageChanged}
                            value={props.selectedCardMessageValue === null ? "" : props.selectedCardMessageValue}>
                            <option value="">No Message</option>
                            {cardMessages.map(x => <option key={x.id} value={x.id}>{x.title}</option>)}
                        </FormControl>
                    </FormGroup>
                </Col>
                {!!props.selectedCardMessageValue &&
                <Col xs={12}>
                    <pre style={{fontSize: '24px', width: '100%', whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                        {cardMessages.find(x => x.id === parseInt(props.selectedCardMessageValue, 10)).message}
                    </pre>
                </Col>
                }
            </Row>
            }
        </Well>
    );
}

export default React.memo(AdminOptionsSection);