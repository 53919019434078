import React from 'react';
import classes from './Button.module.css';

const button = (props) => (
    <button
        className={ props.disabled ? classes.Disabled + " " + classes.Button : classes.Button}
        onClick={props.clicked}
        disabled={props.disabled}>
        {props.children}
        </button>
);

export default button;
