import { useEffect , useState} from "react";

/**
 * Hook that alerts clicks outside the passed ref
 */
function useOutsideAreaClick(ref) {
    const [clickedOutside, setClickedOutside] = useState(false);

    useEffect(() => {
        if(clickedOutside)
            setClickedOutside(false);
    }, [clickedOutside]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setClickedOutside(true);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return clickedOutside;
}

export default useOutsideAreaClick;