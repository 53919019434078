import React from 'react';
import {FormControl, ControlLabel} from 'react-bootstrap';
import classes from './AddUsersSearch.module.css';
import BootstrapRowColCombo from "../../../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import AddUserButtonSection from "./Components/AddUserButtonSection/AddUserButtonSection";
import PropTypes from 'prop-types';
import UserFoundSection from "./Components/UserFoundSection/UserFoundSection";

const AddUsersSearch = (
    {
        label,
        onAddExistingUserClicked,
        handleSearchValueChanged,
        searchValue,
        showNewAddUserSection,
        searchResults,
        onAddNewUserButtonClicked,
        onSearchFocused,
        loading
    }) => {

    return (
        <BootstrapRowColCombo>
            <BootstrapRowColCombo>
                <ControlLabel className={classes.Label}>{label}</ControlLabel>
                <div className={classes.AddUsersForm}>
                    <BootstrapRowColCombo>
                        <FormControl
                            style={{border: 'none'}}
                            type="text"
                            value={searchValue}
                            placeholder="Type here"
                            onChange={handleSearchValueChanged}
                            onFocus={onSearchFocused}
                        />
                    </BootstrapRowColCombo>
                    {searchResults.length > 0 && !loading && <UserFoundSection users={searchResults} userClicked={onAddExistingUserClicked}/>}
                    {showNewAddUserSection && <AddUserButtonSection onAddUserClicked={onAddNewUserButtonClicked}/>}
                </div>
            </BootstrapRowColCombo>
        </BootstrapRowColCombo>
    )
};

export default AddUsersSearch;

AddUsersSearch.propTypes = {
    label: PropTypes.string,
    searchResults: PropTypes.array.isRequired,
    searchValue: PropTypes.string.isRequired,
    onSearchFocused: PropTypes.func,
    loading: PropTypes.bool,
    handleSearchValueChanged: PropTypes.func.isRequired,
    showNewAddUserSection: PropTypes.bool.isRequired,
    onAddNewUserButtonClicked: PropTypes.func.isRequired,
    onAddExistingUserClicked: PropTypes.func.isRequired,
    handleAddUserClicked: PropTypes.func.isRequired
};