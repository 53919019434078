import React, {useState, useEffect} from 'react';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import useSendReminderTimerLogic, {isBefore} from "../../../hooks/useSendReminderTimerLogic";
import moment from "moment";

const SendInfoReminderButton = props => {
    const {info_notified_on} = props.role;
    const [loading, setLoading] = useState(false);
    const [{isTimerRunning, runningText}, setNotifiedOn] = useSendReminderTimerLogic(info_notified_on, 1, "minute");

    useEffect(() => {
        if(isBefore(info_notified_on)){
            setNotifiedOn(info_notified_on);
        }
        setLoading(false);
    }, [info_notified_on]);


    const handleRemindInfoClicked = () => {
        setLoading(true);
        props.handleInfoReminder();
    };

    const lastUsedTime = info_notified_on ? moment.utc(info_notified_on).local().format('LLL') : null;

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{lastUsedTime ? `Last used on ${lastUsedTime}.` : "Has never been used."}</strong>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="top" overlay={tooltip}>
            <Button
                block
                onClick={handleRemindInfoClicked}
                disabled={loading || isTimerRunning}
                bsStyle="default">{isTimerRunning ? runningText : "Send Reminder"}</Button>
        </OverlayTrigger>
    )
};

export default SendInfoReminderButton;