import { useState } from 'react';

const buildInitials = name => {
    if(!name || name === ''){
        return '';
    }

    const nameArray = name.split(" ");
    const firstNameLetter = nameArray[0].charAt(0);
    const lastNameLetter = nameArray[1] ? nameArray[1].charAt(0) : "";

    return firstNameLetter + lastNameLetter;
}


export default function useGetUserInitials(name) {
    const [initials, setInitials] = useState(buildInitials(name));

    const setName = (name) => {
        setInitials(buildInitials(name));
    }

    return [initials, setName];
}