import React from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import classes from './AdminBubbleCluster.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

const AdminBubbleCluster = props => {

    const buildNameIcon = (str) => {
        return str.split(' ').map(function (item) {
            return item[0]
        }).join('');
    };

    const defaultAdminToolTip = (
        <Tooltip id="defaultAdminTooltip">
            Default talent admin.
        </Tooltip>
    );

    const adminToolTip = (key) => {
        const id = "adminTooltip" + key;
        return (
            <Tooltip id={id}>
                Extra talent admins
            </Tooltip>
        );
    };

    const defaultAdmin = props.defaultAdmin ? (
        <OverlayTrigger placement="top" overlay={defaultAdminToolTip}>
            <Badge
                className={classes.Bubble}
                style={{backgroundColor: props.defaultAdmin.color}}>
                <FontAwesomeIcon className={classes.Star} icon={faStar}/>{buildNameIcon(props.defaultAdmin.user.name)}
            </Badge>
        </OverlayTrigger>
    ) : null;

    const admins = props.talentAdmins
        //filter out the default admin from the admins list
        .filter(x => !(props.defaultAdmin && (props.defaultAdmin.id === x.id)))
        .map(x => <OverlayTrigger key={x.id} placement="top" overlay={adminToolTip(x.id)}>
            <Badge
                className={classes.Bubble}
                style={{backgroundColor: x.color}}>
                {buildNameIcon(x.user.name)}
            </Badge>
        </OverlayTrigger>);

    return (
        <div className={classes.BubbleWrapper}>
            {defaultAdmin}
            {admins}
        </div>
    );
};

export default AdminBubbleCluster;