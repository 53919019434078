import React from 'react';
import PropTypes from 'prop-types';
import {RiHistoryFill} from 'react-icons/ri'
import {HiOutlineDownload} from 'react-icons/hi';
import classes from './SmallIconButton.module.css';

const SmallIconButton = ({type, onClick, active}) => {

    let icon;

    switch (type){
        case 'RiHistoryFill':
            icon = <RiHistoryFill/>;
            break;
        case 'HiOutlineDownload':
            icon = <HiOutlineDownload/>;
            break;
        default:
            //
    }

    return (
        <div className={classes.SmallIconButton} onClick={onClick} style={{backgroundColor: active ? '#DFE1E1':'#FFFFFF'}}>
            {icon}
        </div>
    )
};

export default SmallIconButton;

SmallIconButton.propTypes = {
    type: PropTypes.oneOf(['RiHistoryFill', 'HiOutlineDownload']),
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool
};

SmallIconButton.defaultProps = {
    active: false
};