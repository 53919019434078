import React from 'react';
import logo from '../../../../../../../../../assets/images/rolebot-bot.png';
import classes from './NoTalentMessage.module.css';

const NoTalentMessage = () => {
    return (
        <div className={classes.Wrapper}>
            <img src={logo} alt="No Talent"/>
            <div className={classes.Text}>
                Could not find any talent with the current filters.
            </div>
        </div>
    )
};

export default NoTalentMessage;