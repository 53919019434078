import React, {useReducer, useEffect, useCallback} from 'react';
import classes from './NewUserForm.module.css';
import {Col, Button} from 'react-bootstrap';
import BootstrapRowColCombo from "../../../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import PropTypes from 'prop-types';
import NewTitleHeader from "../../../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import NewUserFormArea from "../NewUserFormArea/NewUserFormArea";
import {
    isSuccess,
    isSuccessOrNull,
    userNameValidation,
    userPhoneValidation,
    userTitleValidation,
    phoneAddPlusOnSubmit,
    stripeOutNonDigits,
    formatPhoneNumber
} from "../../../../../../../utils/validation";

const initialState = (props) => {
    return {
        name: "",
        title: "",
        email: props.email,
        phone: "",
        allowNotifications: true,
        pointOfContact: false,
        isAdmin: false,

        //validation Area
        submitDisabled: true,
        resetDisabled: false,
        validation: {
            nameValidState: null,
            titleValidState: null,
            phoneValidState: null,

            validateName: false,
            validateTitle: false,
            validatePhone: false
        }
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'nameChanged': {
            const isValid = userNameValidation(action.payload);
            return {
                ...state,
                name: action.payload,
                validation: {
                    ...state.validation,
                    nameValidState: isValid ? 'success' : 'error',
                    validateName: true
                }
            }
        }
        case 'titleChanged': {
            const isValid = userTitleValidation(action.payload);
            return {
                ...state,
                title: action.payload,
                validation: {
                    ...state.validation,
                    titleValidState: isValid ? 'success' : 'error',
                    validateTitle: true
                }
            }
        }
        case 'emailChanged':
            return {
                ...state,
                email: action.payload,
                validation: {
                    ...state.validation,
                }
            }
        case 'phoneChanged': {
            const isValid = userPhoneValidation(action.payload);
            return {
                ...state,
                phone: action.payload,
                validation: {
                    ...state.validation,
                    phoneValidState: isValid ? 'success' : 'error',
                    validatePhone: true
                }
            }
        }
        case 'allowNotificationsChanged':
            return {
                ...state,
                allowNotifications: action.payload
            }
        case 'pointOfContactChanged':
            return {
                ...state,
                pointOfContact: action.payload
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'submittingData':
            return {
                ...state,
                submitDisabled: true,
                resetDisabled: true
            }
        case 'isAdminChanged':
            return {
                ...state,
                isAdmin: action.payload
            }
        default:
            throw new Error();
    }
}


const NewUserForm = (props) => {
    const {onCancelClicked, onSubmit} = props;

    const [state, dispatch] = useReducer(reducer, props, initialState);

    useEffect(() => {
        const {nameValidState, titleValidState, phoneValidState} = state.validation;
        if(
            isSuccess(nameValidState) &&
            isSuccessOrNull(titleValidState) &&
            isSuccessOrNull(phoneValidState)
        ){
            dispatch({type: 'enableSubmitButton'});
        } else {
            dispatch({type: 'disableSubmitButton'});
        }
    },[state.validation]);

    const handleNameChanged = (e) => {
        dispatch({type: 'nameChanged', payload: e.currentTarget.value})
    }

    const handleTitleChanged = (e) => {
        dispatch({type: 'titleChanged', payload: e.currentTarget.value})
    }

    const handleEmailChanged = (e) => {
        dispatch({type: 'emailChanged', payload: e.currentTarget.value})
    }

    const handlePhoneChanged = (e) => {
        dispatch({type: 'phoneChanged', payload: stripeOutNonDigits(e.currentTarget.value)})
    }

    const handleNotificationsChanged = (value) => {
        dispatch({type: 'allowNotificationsChanged', payload: value})
    }

    const handlePointOfContactChanged = (value) => {
        dispatch({type: 'pointOfContactChanged', payload: value})
    }

    const handleIsAdminChanged = useCallback((value) => {
        dispatch({type: 'isAdminChanged', payload: value});
    },[]);

    const handleSubmitUser = () => {
        dispatch({type: 'submittingData'});
        const payload = {
            name: state.name,
            email: state.email,
            allow_notifications: state.allowNotifications,
            point_of_contact: state.pointOfContact
        }

        if(state.title.trim() !== ''){
            payload.title = state.title;
        }

        if(state.phone !== ''){
            payload.phone = phoneAddPlusOnSubmit(state.phone);
        }


        if(state.isAdmin){
            payload['is-admin'] = true;
        }

        onSubmit(payload);
    }

    return (
        <BootstrapRowColCombo>
            <div className={classes.NewUserForm}>
                <BootstrapRowColCombo>
                    <Col xs={4}>
                        <NewTitleHeader title='New User'/>
                    </Col>
                </BootstrapRowColCombo>

                <NewUserFormArea
                    name={state.name}
                    nameValidationState={state.validation.nameValidState}
                    onNameChanged={handleNameChanged}

                    isAdmin={state.isAdmin}
                    onIsAdminChanged={handleIsAdminChanged}

                    title={state.title}
                    titleValidationState={state.validation.titleValidState}
                    onTitleChanged={handleTitleChanged}

                    email={state.email}
                    onEmailChanged={handleEmailChanged}
                    emailDisabled={true}

                    phone={state.phone ? formatPhoneNumber(state.phone) : ""}
                    phoneValidationState={state.validation.phoneValidState}
                    onPhoneChanged={handlePhoneChanged}


                    allowNotifications={state.allowNotifications}
                    onAllowNotificationsChanged={handleNotificationsChanged}
                    pointOfContact={state.pointOfContact}
                    onMainContactChanged={handlePointOfContactChanged}

                    disableIsAdmin={props.disableIsAdmin}
                />

                <BootstrapRowColCombo>
                    <Col xs={2} xsOffset={7}>
                        <Button disabled={state.resetDisabled} onClick={onCancelClicked} block>Cancel</Button>
                    </Col>
                    <Col xs={3}>
                        <Button disabled={state.submitDisabled} onClick={handleSubmitUser} block>Create and Add</Button>
                    </Col>
                </BootstrapRowColCombo>
            </div>
        </BootstrapRowColCombo>
    )
};

export default NewUserForm;

NewUserForm.propTypes = {
    onCancelClicked: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disableIsAdmin: PropTypes.bool
};

NewUserForm.defaultProps = {
    disableIsAdmin: false
};