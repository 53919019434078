import React from 'react';
import classes from "../../../../containers/TurboPlus2/TurboPlus2.module.css";
import RoleSection from "../../../TurboPlus2/RoleSection/RoleSection";
import {useSelector} from 'react-redux';
import AccountSection from "../../../TurboPlus2/AccountSection/AccountSection";
import AddCompanySection from "../../../TurboPlus2/AddCompanySection/AddCompanySection";
import AddRoleSection from "../../../TurboPlus2/AddRoleSection/AddRoleSection";
import RoleRequestSection from "../../../TurboPlus2/RoleRequestSection/RoleRequestSection";
import MetricsSection from "../../../TurboPlus2/MetricsSection/MetricsSection";

const RightColumn = () => {
    const showRoleSection = useSelector(state => state.rolesSearchSettings.showRoleSection);
    const showCompanyInfoSection = useSelector(state => state.rolesSearchSettings.showCompanyInfoSection);
    const showAddCompanySection = useSelector(state => state.rolesSearchSettings.showAddCompanySection);
    const showAddRoleSection = useSelector(state => state.rolesSearchSettings.showAddRoleSection);
    const showRoleRequestsSection = useSelector(state => state.rolesSearchSettings.showRoleRequestsSection);
    const showCompanyMetrics = useSelector(state => state.rolesSearchSettings.showCompanyMetrics);

    return (
        <div className={classes.RightColumn}>
            {showRoleSection && <RoleSection/>}
            {showCompanyInfoSection && <AccountSection/>}
            {showAddCompanySection && <AddCompanySection/>}
            {showAddRoleSection && <AddRoleSection/>}
            {showRoleRequestsSection && <RoleRequestSection/>}
            {showCompanyMetrics && <MetricsSection/>}
        </div>
    )
};

export default RightColumn;