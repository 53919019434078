import React from 'react';
import {Modal, Row, Col, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from './ConfirmationModal.module.css';
import { CgDanger} from 'react-icons/cg';
import {GiBananaBunch} from "react-icons/gi";
import {HiOutlineDownload} from 'react-icons/hi';


const ConfirmationModal = props => {

    let icon;
    switch (props.icon) {
        case 'download':
            icon = <HiOutlineDownload className={classes.DownLoadIcon}/>;
            break;
        case 'danger':
            icon = <CgDanger className={classes.Icon}/>;
            break;
        default:
            icon = <GiBananaBunch className={classes.Icon}/>;
    }

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header className={classes.Header} closeButton>
                <Modal.Title className={classes.Title}>
                    {icon}{props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.Body}>
                {props.children}
            </Modal.Body>
            <Modal.Footer className={classes.Footer}>
                <Row>
                    <Col xs={12} lg={4} lgOffset={4}>
                        <Button
                            disabled={props.loading}
                            block
                            mode="neutral"
                            onClick={props.onHide}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Button
                            bsStyle="success"
                            disabled={props.loading}
                            block
                            mode="dangerConfirmation"
                            onClick={props.onSubmit}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    icon: PropTypes.oneOf(['danger', 'download'])
};

ConfirmationModal.defaultProps = {
    show: false,
    loading: false
};
