import React from 'react';
import classes from './RoleRow.module.css';
import RoleRowItemTop from '../RoleRowItemTop/RoleRowItemTop';
import Collapsible from 'react-collapsible';
import {Col, Alert, Row} from 'react-bootstrap';
import RoleSuccessDropDownWrapper from '../RoleSuccessDropDownWrapper/RoleSuccessDropDownWrapper';
import RoleDeliveryDropDownWrapper from '../RoleDeliveryDropDownWrapper/RoleDeliveryDropDownWrapper';
import {useSelector} from 'react-redux';

const RoleRow = props => {
    const mode = useSelector(state => state.siteSettings.mode);

    const {roleId, role, talentAdmins} = props;

    const sourcingOnly = role.sourcing_only ? <Alert bsStyle="danger">
        <h4>Do NOT contact talent for this role!</h4>
        This role has been marked as sourcing only in role info. Please contact a manager before reaching out to talent.
    </Alert> : null;

    return (
        <div style={{backgroundColor: '#ffffff'}}>
            <RoleRowItemTop talentAdmins={talentAdmins} role={role} isOpen={props.isOpen}/>
            <Collapsible
                open={props.isOpen}
                contentInnerClassName={classes.Panel}
                transitionTime={100}
                lazyRender={true}
                overflowWhenOpen='visible'>
                <Row>
                    <Col xs={12}>
                        <Col xs={12} className={classes.RoleContentWrapper}>
                            {sourcingOnly}
                            {mode === 'success' ?
                                <RoleSuccessDropDownWrapper
                                    roleId={roleId}
                                    displayNotificationsClearButton={role.failedApolloSyncCandidatesCount > 0}
                                    rowIsOpen={props.isOpen}
                                    roleType={props.roleType}
                                    reloadBarInfo={props.reloadBarInfo}
                                /> :
                                <RoleDeliveryDropDownWrapper role={role} roleId={roleId}/>}
                        </Col>
                    </Col>
                </Row>

            </Collapsible>
        </div>
    );
};

export default React.memo(RoleRow);