import {getRoleHistory} from "../../utils/services/turboPlus2RestCalls";
import {GET_ROLE_HISTORY_REQUEST, GET_ROLE_HISTORY_SUCCESS, CHANGE_ROLE_HISTORY_PAGE} from "./actionsTypes";

export const getRoleHistoryRequest = (roleId, payload={}) => {
    return dispatch => {
        dispatch({type: GET_ROLE_HISTORY_REQUEST});
        getRoleHistory(roleId, payload)
            .then(response => {
                dispatch({type: GET_ROLE_HISTORY_SUCCESS, payload: response.role_history});
            })
            .catch(e => console.log(e));
    }
}


export const changeRoleHistoryPageRequest = (page) => {
    return {
        type: CHANGE_ROLE_HISTORY_PAGE,
        payload: page
    }
}