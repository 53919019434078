import React  from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classes from './RoleCompanyItem.module.css';

const RoleCompanyItem = props => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip id={"companyTooltip" + props.id}>{props.name}</Tooltip>}>
            <div className={classes.RoleCompanyItem}>{props.name}</div>
        </OverlayTrigger>
    );
};

export default RoleCompanyItem;