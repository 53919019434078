import * as actionTypes from  '../actions/actionsTypes';

const initialState = {
    loading: false,
    data: []
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ROLE_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
                data: []
            }
        case actionTypes.GET_ROLE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case actionTypes.PATCH_ROLE_REQUEST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.PATCH_ROLE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case actionTypes.PATCH_ROLE_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.DELETE_ROLE_REQUEST_SUCCESS:
            return {
                ...state,
                data: state.data.filter(x => x.id !== action.payload)
            }
        default:
            return state;
    }
}

export default reducer;