import React from 'react';
import {Scrollbars} from "react-custom-scrollbars-2";
import {Col} from "react-bootstrap";

const GreyScrollBoxArea = props => {
    return (
        <Scrollbars style={{backgroundColor: '#E5E5E5', height: '100%'}}>
            <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                <Col xs={12}>
                    {props.children}
                </Col>
            </div>
        </Scrollbars>
    )
};

export default GreyScrollBoxArea;