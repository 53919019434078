import React from 'react';
import {Grid} from 'react-bootstrap';
import classes from './TurboPlus2.module.css';
import LeftColumn from "../../components/Ui/MainView/LeftColumn/LeftColumn";
import RightColumn from "../../components/Ui/MainView/RightColumn/RightColumn";

const TurboPlus2 = () => {
    return (
        <Grid className={classes.MainGrid}>
            <LeftColumn />
            <RightColumn />
        </Grid>
    )
};

export default TurboPlus2;