import React, {useState, useEffect} from 'react';
import {OverlayTrigger , Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag as faFlagSolid} from "@fortawesome/free-solid-svg-icons";
import {faFlag} from '@fortawesome/free-regular-svg-icons';
import PropTypes from "prop-types";
import moment from "moment";

const TalentInterestedFlag = props => {
    const [interestedOn, setInterestedOn] = useState(props.interestedOn);

    useEffect(()=>{
        setInterestedOn(props.interestedOn);
    },[props.interestedOn]);

    const handleOnClick = (e) => {
        e.stopPropagation();
        const date = props.interestedOn ? null : moment().utc().format("YYYY-MM-DD HH:mm:ss");
        setInterestedOn(date);
        props.handleFlagClicked(date);
    };


    const tooltip = props.interestedOn ? (
        <Tooltip id="tooltip">
            {moment.utc(props.interestedOn).local().format("LL")}
        </Tooltip>
    ): (
        <Tooltip id="tooltip">
            Click to mark interested
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="top" overlay={tooltip}>
            <span style={{marginRight: "5px"}} onClick={handleOnClick}>
                <FontAwesomeIcon
                    style={{
                        color: interestedOn ? "green":"grey",
                        fontSize: "15px"
                    }}
                    icon={interestedOn ? faFlagSolid : faFlag}
                />
            </span>
        </OverlayTrigger>
    );
};

export default TalentInterestedFlag;
TalentInterestedFlag.propTypes = {
    interested: PropTypes.bool,
    handleFlagClicked: PropTypes.func.isRequired
};
