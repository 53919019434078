import { useEffect, useReducer } from 'react';
import GreenHouseIcon from '../../../../../assets/svg/greenhouse_icon.svg';
import LeverIcon from '../../../../../assets/svg/lever_icon.svg';
import SapIcon from '../../../../../assets/svg/sap-logo.svg';
import moment from 'moment';
import { getNumberOfDaysByDate } from '../../../../../utils/dates';
import { BsFillCircleFill } from 'react-icons/bs';
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/lib/Tooltip";
import Image from "react-bootstrap/lib/Image";

const reducer = (state, action) => {
  switch (action.type) {
    case 'setRowsToShow':
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const MetricsDetail = ({ roleId, data }) => {
  const [state, dispatch] = useReducer(reducer, {
    data: {},
  });

  useEffect(() => {
    if (data.length > 0) {
      const dataRow = data.filter((e) => e.id === roleId);
      dispatch({ type: 'setRowsToShow', payload: dataRow });
    }
    // eslint-disable-next-line
  }, [data, roleId]);

  const roleStatus = () => {
    if (state.data[0]?.completed) {
      return 'Completed';
    } else if (
      !state.data[0]?.on_hold &&
      !state.data[0]?.completed &&
      !state.data[0]?.show_info &&
      !state.data[0]?.vault
    ) {
      return 'Active';
    } else if (state.data[0]?.show_info) return 'Waiting';
    else if (state.data[0]?.on_hold) return 'On hold';
  };

  return (
      <div style={{ display: 'flex', width: '100%', marginTop: '10px', paddingBottom: '10px' }}>
        <div style={{ width: '60%' }}>
          <div style={{ display: 'flex', alignItems: "start", marginBottom: 5 }}>
            <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    {roleStatus() === 'Completed'
                        ? 'Completed'
                        : roleStatus() === 'Active'
                            ? 'Active'
                            : roleStatus() === 'Waiting'
                                ? 'Waiting'
                                : roleStatus() === 'On hold'
                                    ? 'On hold'
                                    : ''}
                  </Tooltip>
                }
            >
              <BsFillCircleFill
                  color={
                    roleStatus() === 'Completed'
                        ? '#E82429'
                        : roleStatus() === 'Active'
                            ? '#6FBE44'
                            : roleStatus() === 'On hold'
                                ? '#7039ED'
                                : roleStatus() === 'Waiting'
                                    ? '#B3B3B3'
                                    : ''
                  }
                  style={{ width: '10px', marginRight: '10px', paddingTop: '3px', display: 'flex', alignItems: 'center' }}
              />
            </OverlayTrigger>
            <p style={{ fontSize: '18px', fontFamily: 'Helvetica, sans-serif', fontWeight: 400, lineHeight: "21px", color: '#242424', marginBottom: 0 }}>{state.data[0]?.name}</p>
          </div>
          <div style={{ display: 'flex', alignItems: "center", marginLeft: 20 }}>
            <span style={{ fontSize: '16px', fontFamily: 'Helvetica, sans-serif', fontWeight: 400, lineHeight: "18px", color: '#4F4F4F' }}>Order: HRGH-{state.data[0]?.id?.toString()?.padStart(6, '0')}</span>
          </div>
          <span style={{ fontSize: '15px', marginLeft: '20px', color: '#838485' }}>
          {state.data[0]?.info?.office_locations}
         </span>
        </div>
        <div style={{ width: '40%', textAlign: 'right', flex: 1 }}>
          <div style={{ width: '100%' }}>
            <span style={{ fontSize: '16px', fontFamily: 'Helvetica, sans-serif', lineHeight: "18px", paddingBottom: '1px', color: '#4F4F4F' }}>
              Created {getNumberOfDaysByDate(state.data[0]?.created_at.slice(0, 10))} {' days ago, on '}
              {moment(state.data[0]?.created_at.slice(0, 10)).format('MMM DD, YYYY')}
            </span>
          </div>
          <div style={{width: '100%'}}>
                        <span style={{ fontSize: '15px', display: 'flex', justifyContent: 'flex-end', color: '#838485', marginLeft: 20 }}>
              {state.data[0]?.greenhouse_job?.requisition_id && (
                  <>
                    <Image
                        src={GreenHouseIcon}
                        style={{ marginRight: '5px', paddingTop: '3px' }}
                        width={10}
                        responsive
                    />
                  </>
              )}
                          {state.data[0]?.lever_job && (
                              <>
                                <Image src={LeverIcon} style={{ marginLeft: '5px', marginRight: '5px', paddingTop: '3px' }} width={16} responsive />
                              </>
                          )}
                          {state.data[0]?.sap_job?.requisition_id && (
                              <>
                                <Image src={SapIcon} style={{ marginLeft: '5px', marginRight: '5px', paddingTop: '3px' }} width={17} responsive />
                              </>
                          )}
            </span>
          </div>
        </div>
      </div>
  );

  return null;
};

export default MetricsDetail;
