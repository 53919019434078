import React from 'react';
import classes from './IconInput.module.css';
import Label from '../../Labels/Label';
import Aux from '../../../../hoc/AuxWrapper/AuxWrapper';

const IconInput = props => {
    let errors = null;

    if(props.inputErrors){
        errors = props.inputErrors.map((e,k) => {
            return <span key={k} className={classes.ErrorMessages}>{e}</span>;
        });
    }

    return (
            <Aux>
            <Label inputLabel={props.inputLabel} htmlFor={props.inputId} />
            {errors}
            <div className={ props.inputErrors ? classes.IconInput + " " + classes.Errors : classes.IconInput}>
                <div className={classes.Icon}>
                    <i className={props.fontAwesomeClass + " " + classes.FontAwesome}></i>
                </div>
                <input
                    required={props.required}
                    name={props.inputName}
                    placeholder={props.placeholderText}
                    className={classes.Input}
                    id={props.inputId} 
                    type={props.inputType} 
                    value={props.inputValue} 
                    onChange={props.inputOnChange}
                    onBlur={props.inputOnBlur}/>
            </div>
        </Aux>
    )
}

export default IconInput;