import React from 'react';
import {Row, Col, Form, Button} from "react-bootstrap";
import FormGreyWrapper from "../../../../../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";
import AddTalentFormItem from './components/AddTalentFormItem/AddTalentFormItem';
import AddMoreTalentButton from './components/AddMoreTalentButton/AddMoreTalentButton';
import {postTalentProspects} from "../../../../../../../utils/services/turboPlus2RestCalls";
import {showToastError, showToastSuccess} from '../../../../../../../utils/toastMessages'
import _ from "lodash";
import * as yup from "yup";
import {FieldArray, Formik} from "formik";

const BASE_EMPTY_USER = {
    name: '',
    link: '',
    company: ''
}

const AddTalentForReviewForm = props => {
    const handleSubmitTalentClicked = (values, form) => {

        values.talent = values.talent.map((talent) => {
            talent.link = encodeURI(talent.link);
            return talent;
        });

        const payload = values.talent;

        postTalentProspects(props.roleId,{talent_prospects: payload})
            .then( (value) => {
                form.resetForm();
                showToastSuccess("Talent added to role for Review");
                props.reloadAddedTalent();
            })
            .catch(e => {
                console.log(e);
                const message = e.message ? e.message: e;
                const errors = e.errors ? _.flattenDeep(Object.values(e.errors)): [];
                showToastError(message,errors);
                form.setSubmitting(false);
            });
    };

    const schema = yup.object().shape({
        talent: yup.array().of(
            yup.object().shape(
                {
                    name: yup
                        .string()
                        .trim('Remove extra spaces.')
                        .strict()
                        .min(3, "Must be over 3 chars.")
                        .max(100, "Must be under 100 chars.")
                        .required("Must be between 3 and 100 chars."),

                    link: yup
                        .string()
                        .trim('Remove extra spaces.')
                        .strict()
                        .url("Must be a valid url")
                        .matches(/^https:\/\/*/, "Must be a secure url(https).")
                        .required("Must be between 3 and 100 chars."),
                    company: yup
                        .string()
                        .trim('Remove extra spaces.')
                        .strict()
                        .max(100, "Must be under 100 chars."),
                }
            )
        )
    });

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmitTalentClicked}
            validationSchema={schema}
            initialValues={{
                talent: [BASE_EMPTY_USER],
            }}
        >
            {({
                  handleSubmit,
                  handleBlur,
                  handleReset,
                  values,
                  handleChange,
                  touched,
                  errors,
                  isSubmitting
              }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <FieldArray name="talent">
                        {
                            ({remove, replace, push}) => {

                                const handleAddEmptyItemToForm = (count) => {
                                    for (let i = 0; i < count; i++) {
                                        push(BASE_EMPTY_USER);
                                    }
                                }

                                const handleRemoveItemFromFormByIndex = (index) => {
                                    if(values.talent.length > 1){
                                        remove(index);
                                    } else {
                                        replace(0, BASE_EMPTY_USER);
                                    }
                                }

                                return (
                                    <FormGreyWrapper>

                                            <Col xs={12}>
                                                <div style={{ paddingBottom: '20px', fontSize: '23px'}}>
                                                    Add Talent for Review
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                {values.talent.map((x,index) =>
                                                    <AddTalentFormItem
                                                        key={index}
                                                        talent={x}
                                                        index={index}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        deleteTalent={handleRemoveItemFromFormByIndex}
                                                        touched={touched}
                                                        errors={errors}
                                                        isSubmitting={isSubmitting}
                                                    />)}
                                            </Col>
                                            <Row style={{paddingBottom :'20px'}}>
                                                <Col xs={12}>
                                                    <Col xs={12} lg={6}>
                                                        <AddMoreTalentButton
                                                            isSubmitting={isSubmitting}
                                                            addTalentRowsClicked={handleAddEmptyItemToForm}
                                                        />
                                                    </Col>
                                                    <Col xs={12} lg={3} lgOffset={3}>
                                                        <Button disabled={isSubmitting} type="submit" block bsStyle="success">Add for Review</Button>
                                                    </Col>
                                                </Col>
                                            </Row>
                                    </FormGreyWrapper>
                                )
                            }
                        }
                    </FieldArray>
                </Form>
            )}
        </Formik>
    )
};

export default AddTalentForReviewForm;
