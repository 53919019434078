import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from './LittleIconWrapper.module.css';
import {faCheck, faMinus, faTimes} from "@fortawesome/free-solid-svg-icons";

const LittleIconWrapper = props => {
    const styles = props.active ? [classes.LittleIconWrapper, classes.iconActive ].join(' ') : classes.LittleIconWrapper;

    let icon = null;

    switch (props.type) {
        case "prospect_approved":
            icon = faCheck;
            break;
        case "declined":
            icon = faTimes;
            break;
        case "saved":
            icon = faMinus;
            break;
        default:

    }

    return (
        <div onClick={props.onClick} className={styles}
            style={{backgroundColor: props.active ? props.color : '#EFEFEF', ...props.containerStyle}}
        >
            {icon && <FontAwesomeIcon icon={icon}/>}
        </div>
    )
};

export default LittleIconWrapper;

LittleIconWrapper.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["saved", "prospect_approved", "declined", "empty"]).isRequired,
    containerStyle: PropTypes.object
};

LittleIconWrapper.defaultProps = {};