import React from 'react';
import classes from './NewTitleHeader.module.css';
import PropTypes from 'prop-types';

const NewTitleHeader = ({title}) => {

    return (
        <span className={classes.NewTitleHeader}>{title}</span>
    )
};

export default NewTitleHeader;

NewTitleHeader.propTypes = {
    title: PropTypes.string.isRequired
};

NewTitleHeader.defaultProps = {};