import * as actionTypes from  '../actions/actionsTypes';

const initialState = {
    loading: false,
    company_metrics: {},
    role_metrics: {},
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_METRICS_REQUEST:
            return {
                ...state,
                loading: true,
                company_metrics: {},
                role_metrics: {},
            }
        case actionTypes.GET_COMPANY_METRICS_SUCCESS:
            return {
                ...state,
                loading: false,
                company_metrics: action.payload
            }
        case actionTypes.GET_ROLE_METRICS_SUCCESS:
            return {
                ...state,
                loading: false,
                role_metrics: action.payload
            }
        default:
            return state;
    }
}

export default reducer; 