import React, {useState} from 'react';
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup, Row, Well} from "react-bootstrap";
import currency from 'currency.js';

const priceInputCleanUp = price => {
    return (price === "" || price === 0) ? price : currency(price, {fromCents: true}).toString();
};

const AdminCoreSection = props => {
    const [price, setPrice] = useState(priceInputCleanUp(props.pricePerInterviewValue));

    const handlePriceChanged = (e) => {
        const price = e.target.value;
        if( price === "" || price === 0 || /^[0-9]*\.?[0-9]{0,2}$/.test(price)){
            setPrice(price);
            props.pricePerInterviewOnChange(currency(price).intValue);
        }
    };

    return (
        <Well bsSize="large">
            <Row>
                <Col xs={12} lg={12}>
                    <FormGroup controlId="roleName" validationState={props.roleNameValidateState}>
                        <ControlLabel>Role Name</ControlLabel>
                        <FormControl
                            required
                            value={props.roleNameValue}
                            onChange={props.roleNameOnChange}
                            type="text"
                            placeholder="User visible role name like 'React Developer'"/>
                        <FormControl.Feedback/>
                        <HelpBlock>Must be between 3 to 100 chars</HelpBlock>
                    </FormGroup>
                </Col>
            </Row>
        </Well>
    )
}

export default React.memo(AdminCoreSection);