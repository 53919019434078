import React, {useEffect, useState, useRef} from 'react';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';
import useSendReminderTimerLogic, {isBefore} from '../../../hooks/useSendReminderTimerLogic';

const SendTalentReminderButton = props => {
    const {id, talent_notified_on, completed} = props.role;
    const {talent_waiting_count} = props.role.metrics;
    const [loading, setLoading] = useState(false);
    const [{isTimerRunning, runningText}, setNotifiedOn] = useSendReminderTimerLogic(talent_notified_on, 1, "minute");
    const overlayTrigger = useRef(null);

    useEffect(() => {
        if(isBefore(talent_notified_on)){
            setNotifiedOn(talent_notified_on);
        }
        setLoading(false);
    }, [talent_notified_on]);

    const handleRemindTalentClicked = () => {
        setLoading(true);
        props.handleTalentReminder();
        overlayTrigger.current.handleHide();
    };

    const canShowReminderButton = () => {
        return !completed && !!talent_waiting_count;
    };

    const lastUsedTime = talent_notified_on ? moment.utc(talent_notified_on).local().format('LLL') : null;

    const tooltip = (
        <Tooltip id={'tooltip-reminder-' + id}>
            <strong>{lastUsedTime ? `Last used on ${lastUsedTime}.` : "Has never been used."}</strong>
        </Tooltip>
    );

    return (
        <OverlayTrigger ref={overlayTrigger} placement="top" overlay={tooltip} trigger={['hover', 'focus']}>
            <Button
                block
                onClick={handleRemindTalentClicked}
                disabled={loading || isTimerRunning || !canShowReminderButton()}
                bsStyle="default">{isTimerRunning ? runningText : "Send Reminders"}</Button>
        </OverlayTrigger>
    )
};

export default SendTalentReminderButton;
