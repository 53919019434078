import React from 'react';
import classes from './TurboPlus2Loader.module.css';

const TurboPlus2Loader = props => {
    return (
        <div className={classes.TurboPlus2Loader}>
            <div className={classes.Spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
};

export default TurboPlus2Loader;