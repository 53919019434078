import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import classes from './EllipseButton.module.css';

const EllipseButton = ({children, onClick, id, open}) => {
    const mainClass = open ? [classes.EllipseButtonWrapper, classes.Open].join(' ') : classes.EllipseButtonWrapper;

    return (
        <div className={mainClass}>
            <div className={classes.EllipseButton} onClick={() => {onClick(id)}}>
                <FontAwesomeIcon icon={faEllipsisH}/>
            </div>
            <div className={classes.MenuBox}>
                {children}
            </div>
        </div>
    )
}

export default EllipseButton;

EllipseButton.propTypes = {
    id: PropTypes.number.isRequired
};

EllipseButton.defaultProps = {};