import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";
import classes from './ButtonLink.module.css';
import {useDispatch, useSelector} from "react-redux";
import {roleTypeChanged} from '../../../../store/actions';

const ButtonLink = ({text, type}) => {
    const dispatch = useDispatch();
    const roleFilterType = useSelector(state => state.rolesSearchSettings.roleFilterType);

    const handleRoleTypeSelected = () => {
        dispatch(roleTypeChanged(type));
    };

    const styleClass = type === roleFilterType ? [classes.Button, classes.ActiveButton].join(' ') : classes.Button;

    return (
        <Button className={styleClass} bsSize="large" bsStyle="link" onClick={handleRoleTypeSelected}>{text}</Button>
    )
};

export default ButtonLink;

ButtonLink.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

ButtonLink.defaultProps = {};