import React, { useReducer, useEffect } from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Row } from "react-bootstrap";
import { useFirstRender } from '../../../../../../../../../hooks/useFirstRender';

const init = props => {
    const { id, name, link, notes, talent_info } = props.talent;
    return {
        id,
        name,
        company: talent_info && talent_info.current_company_name ? talent_info.current_company_name : '',
        link,
        notes,

        formUpdated: false,
        loading: false,
        submitPayload: {
            id,
        }
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'nameChanged':
            return {
                ...state,
                name: action.payload,
                formUpdated: true,
                submitPayload: {
                    ...state.submitPayload,
                    name: action.payload,
                }
            }
        case 'linkChanged':
            return {
                ...state,
                link: action.payload,
                formUpdated: true,
                submitPayload: {
                    ...state.submitPayload,
                    link: action.payload,
                }
            }
        case 'companyChanged':
            return {
                ...state,
                company: action.payload,
                formUpdated: true,
                submitPayload: {
                    ...state.submitPayload,
                    company: action.payload,
                }
            }
        case 'notesChanged':
            return {
                ...state,
                notes: action.payload,
                formUpdated: true,
                submitPayload: {
                    ...state.submitPayload,
                    notes: action.payload,
                }
            }
        case 'reset':
            return init(action.payload);
        default:
            throw new Error();
    }
}

const EditTalentProspectForm = props => {
    const [state, dispatch] = useReducer(reducer, props, init);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!props.isOpen && !firstRender) {
            dispatch({ type: 'reset', payload: props });
        }
    }, [props.isOpen, firstRender]);

    const handleNameChanged = (e) => {
        dispatch({ type: 'nameChanged', payload: e.target.value })
    };

    const handleLinkChanged = (e) => {
        dispatch({ type: 'linkChanged', payload: e.target.value })
    };

    const handleCompanyChanged = (e) => {
        dispatch({ type: 'companyChanged', payload: e.target.value })
    };

    const handleNotesChanged = (e) => {
        dispatch({ type: 'notesChanged', payload: e.target.value })
    };

    const patchTalent = () => {
        const { talent_info } = props.talent;
        const payload = {
            ...state.submitPayload,
            ...(state.submitPayload.company ? { company: state.submitPayload.company } : talent_info && talent_info.current_company_name !== '' ? { company: talent_info.current_company_name } : {})
        }
        props.updateTalentForReview(payload);
    };

    return (
        <div>
            <Row>
                <Col xs={4}>
                    <FormGroup>
                        <ControlLabel>Talent Name</ControlLabel>
                        <FormControl
                            required
                            type="text"
                            placeholder="Talent Name"
                            value={state.name}
                            onChange={handleNameChanged}
                        />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <ControlLabel>Talent Link</ControlLabel>
                        <FormControl required type="text" placeholder="Talent Link" value={state.link} onChange={handleLinkChanged} />
                    </FormGroup>
                </Col>
                {/*<Col xs={4}>*/}
                {/*    <FormGroup>*/}
                {/*        <ControlLabel>Current Company</ControlLabel>*/}
                {/*        <FormControl*/}
                {/*            type="text"*/}
                {/*            placeholder="Current Company"*/}
                {/*            disabled={true}*/}
                {/*            value={state.company ? state.company : ''}*/}
                {/*            onChange={handleCompanyChanged}*/}
                {/*        />*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>Notes</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            placeholder="Notes"
                            value={state.notes ? state.notes : ''}
                            onChange={handleNotesChanged}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={2} lgOffset={10}>
                    <div style={{ paddingTop: '20px' }}>
                        <Button disabled={state.loading || !state.formUpdated} bsStyle="success" block onClick={patchTalent}>Save</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default EditTalentProspectForm;
