import React, { useEffect, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendTalentReminderButton from "../SendTalentReminderButton/SendTalentReminderButton";
import SendInfoReminderButton from "../SendInfoReminderButton/SendInfoReminderButton";
import classes from "./RoleRowTopButtonRow.module.css";
import DropDownTalentStatusRow from "../DropDownTalentStatusRow/DropDownTalentStatusRow";
import PinkFilter from "../PinkFilter/PinkFilter";
import PinkButton from "../PinkFilter/components/PinkButton/PinkButton";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import LastReviewedClock from "./components/LastReviewedClock/LastReviewedClock";
import SmallIconButton from "./components/SmallIconButton/SmallIconButton";

const RoleRowTopButtonRow = (props) => {
  const {
    role,
    talent,
    isLoading,
    roleType,
    deleteOpen,
    talentSurfacedDate,
    surfaceDates,
    handleToggleShowInfo,
    handleTalentReminder,
    handleInfoReminder,
    handleToggleDeleteButton,
    talentFilter,
    handleTalentDropDownChange,
    handleTalentSurfedDateChange,
    handleClearTalentSurfedDate,
    onShowHistoryClicked,
    historyActive,
    handleShowExportModal,
  } = props;
  const componentActive = useRef(false);

  useEffect(() => {
    componentActive.current = true;
    return () => {
      componentActive.current = false;
    };
  }, []);

  const handleCalendarDateChangedHandler = (value) => {
    handleTalentSurfedDateChange(moment(value).format("YYYY-MM-DD HH:mm:ss"));
  };

  const infoButtonColumn = !isLoading ? (
    <Col xs={12} lg={3} className={classes.RoleInfoButton}>
      <Button block onClick={handleToggleShowInfo}>
        Role Info
      </Button>
    </Col>
  ) : null;

  const talentReminderButtonColumn =
    !isLoading &&
    role &&
    !!talent.length &&
    roleType !== "waiting" &&
    roleType !== "completed" ? (
      <Col xs={12} lg={3} className={classes.TalentReminderButton}>
        <SendTalentReminderButton
          role={role}
          handleTalentReminder={handleTalentReminder}
        />
      </Col>
    ) : null;

  const infoReminderColumn =
    !isLoading && roleType === "waiting" ? (
      <Col xs={12} lg={3} className={classes.InfoReminderButton}>
        <SendInfoReminderButton
          role={role}
          handleInfoReminder={handleInfoReminder}
        />
      </Col>
    ) : null;

  const deleteButtonColumn =
    !isLoading && !talent.length ? (
      <Col xs={12} lg={3} className={classes.DeleteRow}>
        <Button
          block
          bsStyle="danger"
          disabled={deleteOpen}
          onClick={handleToggleDeleteButton}
        >
          Delete <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </Col>
    ) : null;

  const dropDownTalentStatusRow =
    !isLoading && !!talent.length ? (
      <DropDownTalentStatusRow
        talent_waiting_count={role.metrics.talent_waiting_count}
        talent_in_progress_count={role.metrics.talent_in_progress_count}
        talent_rejected_count={role.metrics.talent_rejected_count}
        talent_scheduled_at_count={role.metrics.talent_scheduled_at_count}
        talent_refused_count={role.metrics.talent_refused_count}
        talent_interview_count={role.metrics.talent_interview_count}
        talent_skipped_count={role.metrics.talent_skipped_count}
        handleTalentDropDownChange={handleTalentDropDownChange}
        value={talentFilter}
      />
    ) : null;

  const exportButtonColumn = (
    <Col xs={12} lg={3}>
      <div className={classes.SmallIconButtonSpace}>
        <SmallIconButton
          onClick={handleShowExportModal}
          type="HiOutlineDownload"
        />
        <SmallIconButton
          active={historyActive}
          onClick={onShowHistoryClicked}
          type="RiHistoryFill"
        />
      </div>
    </Col>
  );

  const lastReviewedColumn = (
    <Col sm={12} lg={1}>
      <LastReviewedClock className={classes.LastReviewed} roleId={role.id} />
    </Col>
  );

  const buildSurfacedButton = () => {
    const button = <PinkButton icon="funnel" label="Surfaced" />;

    //The dates coming in are in UTC time so loop each one and get local
    const surface = surfaceDates.map((x) => moment.utc(x).local().toDate());

    const selectedDates = talentSurfacedDate
      ? [moment(talentSurfacedDate).toDate()]
      : [];

    const highlightWithRanges = [
      {
        "react-datepicker__day--highlighted-available": surface,
      },
      {
        "react-datepicker__day--highlighted-selected": selectedDates,
      },
    ];

    return (
      <Col xs={12} lg={3}>
        <PinkFilter button={button} isActive={!!talentSurfacedDate}>
          <DatePicker
            inline
            closeOnScroll={true}
            shouldCloseOnSelect={true}
            highlightDates={highlightWithRanges}
            includeDates={surface}
            onChange={handleCalendarDateChangedHandler}
          >
            <div className={classes.CalendarFooter}>
              <Button
                className={classes.CalendarFooterButton}
                onClick={handleClearTalentSurfedDate}
                bsStyle="link"
              >
                Clear
              </Button>
            </div>
          </DatePicker>
        </PinkFilter>
      </Col>
    );
  };

  const surfaced = buildSurfacedButton();

  return (
    <Row style={{ marginBottom: "15px" }}>
      <Col xs={12}>
        <Row>
          <Col xs={12} lg={9}>
            <Row>
              {infoButtonColumn}
              {talentReminderButtonColumn}
              {infoReminderColumn}
              {deleteButtonColumn}
              {exportButtonColumn}
              {surfaced}
            </Row>
          </Col>
          {lastReviewedColumn}
          <Col xs={12} lg={2}>
            {dropDownTalentStatusRow}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(RoleRowTopButtonRow);
