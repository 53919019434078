import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classes from './TalentRowItem.module.css';
import TalentRowItemTop from '../TalentRowItemTop/TalentRowItemTop';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import useNameAdminBadgeBuilder from "../../../hooks/useNameAdminBadgeBuilder";
import AdminDropDown from '../AdminDropDown/AdminDropDown';
import TalentRowInfoForm from '../TalentRowInfoForm/TalentRowInfoForm';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { getTalentContactOutInfo, getTalentRocketReachInfo } from '../../../utils/services/turboPlus2RestCalls';

class TalentRowItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            talent: props.talent,

            apollo_clear_notifications: props.apollo_clear_notifications,

            formLoading: false,

            // follow up section
            followUpDate: null,
            followUpId: '',
            hasFollowUpData: false,

            // interview section
            interviewDate: null,
            interviewTimeZone: "America/Los_Angeles",
            interviewButtonDisabled: true,
            hasInterviewData: false,

            // delete model
            showDeleteTalentModal: false,
            deleteTalentLoading: false,

            contactOutInfo: [],
            contactOutFetched: false,

            rocketReachInfo: [],
            rocketReachFetched: false
        }
    }

    resetForm = () => {
        this.setState({
            talent: this.props.talent,

            formLoading: false,

            // follow up section reset
            followUpDate: null,
            followUpId: '',
            hasFollowUpData: false,

            // interview section reset
            interviewDate: null,
            interviewTimeZone: "America/Los_Angeles",
            interviewButtonDisabled: true,
            hasInterviewData: false,

            outcome: '',

            // delete talent modal
            showDeleteTalentModal: false,
            deleteTalentLoading: false,
        })
    };


    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.talent !== this.props.talent)) {
            this.resetForm();
        }

        if (this.props.isOpen && !this.state.contactOutFetched) {
            this.setState({ contactOutFetched: true });
            getTalentContactOutInfo(this.state.talent.id)
                .then(response => this.setState({ contactOutInfo: response.contact_outs }))
                .catch(e => console.log(e));
        }

        if (this.props.isOpen && !this.state.rocketReachFetched) {
            this.setState({ rocketReachFetched: true });
            getTalentRocketReachInfo(this.state.talent.id)
                .then(response => this.setState({ rocketReachInfo: response.rocket_reach }))
                .catch(e => console.log(e));
        }
    }

    handleDeleteClicked = () => {
        this.setState({ showDeleteTalentModal: true });
    };

    handleFollowUpDeleteClicked = (followUpPivotId) => {
        this.setState({
            talent: {
                ...this.state.talent,
                follow_ups: this.state.talent.follow_ups.filter(x => x.id !== followUpPivotId)
            }
        }
        );

        this.props.deleteFollowUpByPivotId(this.state.talent.id, followUpPivotId);
    };

    handleInterestedClicked = ($interested_on) => {
        this.setState({
            talent: {
                ...this.state.talent,
                pivot: { ...this.state.talent.pivot, interested_on: $interested_on }
            }
        }
        );

        this.props.handlePatchTalent(this.props.roleId, this.state.talent.id, { interested_on: $interested_on });
    };

    handleReviewedClicked = (value) => {
        this.setState({
            talent: { ...this.state.talent, is_reviewed: value }
        }
        );

        this.props.handlePatchTalent(this.props.roleId, this.state.talent.id, { is_reviewed: value });
    };

    convertTimeToZoneValue = (date, zone = "America/Los_Angeles") => {
        const localtime = moment(date).format("YYYY-MM-DD HH:mm:ss");

        // return the localtime zoned the value passed in.
        return moment.tz(localtime, zone).utc().format("YYYY-MM-DD HH:mm:ss");
    };

    onInputChange = (e) => {
        const key = e.target.id.substring(e.target.id.indexOf("-") + 1);
        let value = e.target.value;

        if (key === 'is_diverse') {
            value = (value === 'select') ? null : parseInt(value, 10);
        }

        let payload = {
            [key]: value
        }

        // if gender changed and is female we set is_diverse to 1
        if (key === 'gender' && value === 'female') {
            payload = { ...payload, is_diverse: 1 }
        }

        this.setState({
            talent: {
                ...this.state.talent,
                ...payload
            }
        });
    };

    handleFollowUpMethodChanged = (e) => {
        this.setState({
            followUpId: e.target.value,
            hasFollowUpData: !!e.target.value && !!this.state.followUpDate
        });
    };

    handleFollowUpDateChanged = (date) => {
        this.setState({
            followUpDate: date,
            hasFollowUpData: !!date && !!this.state.followUpId
        });
    };

    handleInterviewDateChanged = (date) => {
        this.setState({
            interviewDate: date,
            interviewButtonDisabled: !date,
            hasInterviewData: !!date,
        });
    };

    handleTimeZoneChanged = (zone) => {
        this.setState({ interviewTimeZone: zone });
    };

    handleOutcomeChanged = (e) => {
        this.setState({
            talent: {
                ...this.state.talent,
                pivot: { ...this.state.talent.pivot, outcome_id: parseInt(e.target.value, 10) }
            }
        });
    };

    handleTalentAdminChanged = (e) => {
        let talentId = null;
        if (e.currentTarget.value !== '') {
            talentId = parseInt(e.currentTarget.value, 10);
        }
        this.props.handleTalentAdminChanged(this.state.talent.id, talentId);
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteTalentModal: false })
    };

    handleDeleteTalentSubmit = () => {
        this.setState({ deleteTalentLoading: true });
        this.props.handleDeleteTalent(this.props.talent.id, this.props.roleId);
    };

    onSubmit = (e) => {
        e.preventDefault();

        let reloadTalent = false;

        this.setState({ formLoading: true });

        let payLoad = {};

        // TODO:HOLY SMOKES DRY THIS UP
        payLoad = !isEqual(this.props.talent.name, this.state.talent.name) ?
            { ...payLoad, name: this.state.talent.name } :
            { ...payLoad };

        payLoad = !isEqual(this.props.talent.email, this.state.talent.email) ?
            { ...payLoad, email: this.state.talent.email } :
            { ...payLoad };

        payLoad = !isEqual(this.props.talent.mobile_number, this.state.talent.mobile_number) ?
            { ...payLoad, mobile_number: this.state.talent.mobile_number } :
            { ...payLoad };

        payLoad = !isEqual(this.props.talent.alt_number, this.state.talent.alt_number) ?
            { ...payLoad, alt_number: this.state.talent.alt_number } :
            { ...payLoad };

        payLoad = !isEqual(this.props.talent.link, this.state.talent.link) ?
            { ...payLoad, link: this.state.talent.link } :
            { ...payLoad };

        payLoad = !isEqual(this.props.talent.notes, this.state.talent.notes) ?
            { ...payLoad, notes: this.state.talent.notes } :
            { ...payLoad };

        if (!isEqual(this.props.talent.gender, this.state.talent.gender)) {
            payLoad = { ...payLoad, gender: this.state.talent.gender };
        }

        if (!isEqual(this.props.talent.is_diverse, this.state.talent.is_diverse)) {
            payLoad = { ...payLoad, is_diverse: this.state.talent.is_diverse };
        }

        if (!isEqual(this.props.talent.pivot.outcome_id, this.state.talent.pivot.outcome_id)) {
            payLoad = { ...payLoad, outcome_id: this.state.talent.pivot.outcome_id };
            reloadTalent = true;
        }

        if (this.state.hasFollowUpData) {
            payLoad = {
                ...payLoad,
                follow_ups: [{
                    id: parseInt(this.state.followUpId, 10),
                    follow_up_at: moment(this.state.followUpDate).utc().format("YYYY-MM-DD HH:mm:ss")
                }]
            };
            reloadTalent = true;
        }

        if (this.state.hasInterviewData) {
            payLoad = {
                ...payLoad,
                interviews: [{
                    interview_at: this.convertTimeToZoneValue(this.state.interviewDate, this.state.interviewTimeZone)
                }]
            };
            reloadTalent = true;
        }

        if (isEmpty(payLoad)) {
            toast.error("Talent form has nothing to save.", {
                position: "bottom-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            this.resetForm();
        } else {
            this.props.handlePatchTalent(this.props.roleId, this.state.talent.id, payLoad, reloadTalent, () => {
                this.setState({ formLoading: false })
            });
        }
    };

    render() {
        const { admin_id } = this.props.talent.pivot;
        const admins = this.props.admins;

        let adminName;

        //This admins.find(x => x.id === admin_id) was added because there is a bug that allows a user
        //to be deleted that is still an admin in the company. For records I dont think we should delete said admin but maybe
        // allow the admin list to return past deleted users for record keeping.
        if (admin_id && admins.find(x => x.id === admin_id)) {
            adminName = useNameAdminBadgeBuilder(admins.find(x => x.id === admin_id).user.name,
                admins.find(x => x.id === admin_id).color
            )
        }

        const adminDropDown = this.state.talent.pivot.accepted === true ? (
            <AdminDropDown
                title="Admin"
                admins={admins}
                value={admin_id ? admin_id : ""}
                handledAdminChanged={this.handleTalentAdminChanged}
            />
        ) : null;

        return (
            <Fragment>
                <TalentRowItemTop
                    admin={adminName}
                    isOpen={this.props.isOpen}
                    talent={this.state.talent}
                    apollo_clear_notifications={this.state.apollo_clear_notifications}
                    handleTalentRowClicked={this.props.handleTalentRowClicked}
                    handleInterestedClicked={this.handleInterestedClicked}
                    handleReviewedClicked={this.handleReviewedClicked}
                    connectedToApollo={!!this.props.talent.pivot.apollo_connected}
                />
                {
                    this.props.isOpen && <div className={classes.OuterWrapper}>
                        {this.state.showDeleteTalentModal ? (
                            <ConfirmationModal
                                show={true}
                                disabled={this.state.deleteTalentLoading}
                                handleCancel={this.closeDeleteModal}
                                handleConfirm={this.handleDeleteTalentSubmit}>
                                <h2 style={{ fontWeight: "Bold" }}>Are you sure you want to
                                    delete {this.props.talent.name}?</h2>
                                <p>
                                    Clicking confirm will destroy ALL information related to this talent and this role.
                                    If the customer has already been billed it will also delete any reference to that
                                    purchase.
                                </p>
                            </ConfirmationModal>
                        ) : null}
                        {adminDropDown}
                        <TalentRowInfoForm
                            talent={this.state.talent}
                            isSourcingOnly={this.props.isSourcingOnly}
                            followUpMethod={this.state.followUpMethod}
                            followUpDate={this.state.followUpDate}
                            follow_ups={this.props.talent.follow_ups}
                            interviewDate={this.state.interviewDate}
                            interviewTimeZone={this.state.interviewTimeZone}
                            hasInterviewData={this.state.hasInterviewData}
                            interviews={this.props.talent.interviews}
                            followUpList={this.props.followUpList}
                            formLoading={this.state.formLoading}
                            contactOutInfo={this.state.contactOutInfo}
                            rocketReachInfo={this.state.rocketReachInfo}
                            onInputChange={this.onInputChange}
                            handleFollowUpDateChanged={this.handleFollowUpDateChanged}
                            handleInterviewDateChanged={this.handleInterviewDateChanged}
                            handleTimeZoneChanged={this.handleTimeZoneChanged}
                            handleOutcomeChanged={this.handleOutcomeChanged}
                            handleFollowUpMethodChanged={this.handleFollowUpMethodChanged}
                            handleFollowUpDeleteClicked={this.handleFollowUpDeleteClicked}
                            handleInterviewCanceled={this.props.cancelInterviewById}
                            handleDeleteTalentInterviews={this.props.handleDeleteTalentInterviews}
                            handleDeleteClicked={this.handleDeleteClicked}
                            onSubmit={this.onSubmit}
                        />
                    </div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        admins: state.globalLists.admins
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentRowItem);

TalentRowItem.propTypes = {
    handlePatchTalent: PropTypes.func.isRequired
};
