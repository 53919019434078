import React from 'react';
import {Well, Col, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classes from './PlanStatusSection.module.css';
import moment from "moment/moment";

const PlanStatusSection = ({customerId}) => {
    console.log(customerId)
    const companies = useSelector(state => state.companies.data);
    const company = companies.find(x => x.id === customerId);
    console.log(company)

    if(!company.plan_start_date && !company.plan_end_date){
        return null;
    }

    return (
        <Well bsSize="small">
            <Row>
                <Col xs={6}>
                    <div className={classes.ItemRow}>
                        Plan Start Date: {company.plan_start_date ?
                        moment.utc(company.plan_start_date).local().format('MM/DD/YYYY') :
                        ""}
                    </div>
                </Col>
                <Col xs={6}>
                    <div className={classes.ItemRow}>
                        Plan End Date: {company.plan_end_date ?
                        moment.utc(company.plan_end_date).local().format('MM/DD/YYYY') :
                        ""}
                    </div>
                </Col>
            </Row>

        </Well>

    )
};

export default PlanStatusSection;

PlanStatusSection.propTypes = {
    customerId: PropTypes.number.isRequired
}

PlanStatusSection.defaultProps = {
};