import React from 'react';
import PropTypes from 'prop-types';
import classes from "./OpenCloseArrowButton.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const OpenCloseArrowButton = props => {

    const styles = props.open ?
        [classes.OpenCloseArrowButton, classes.Open].join(' ') : classes.OpenCloseArrowButton;

    return (
        <div className={styles} onClick={props.onClick}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    )
};

export default OpenCloseArrowButton;

OpenCloseArrowButton.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

OpenCloseArrowButton.defaultProps = {};