import React from 'react';
import PropTypes from 'prop-types';
import classes from './CompanyMenuItem.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {leftMenuCompanySelected} from '../../../../../../store/actions';
import MenuModeNav from "./components/MenuModeNav/MenuModeNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArchive, faPlusCircle} from "@fortawesome/free-solid-svg-icons";

const CompanyMenuItem = ({company,isArchive}) => {
    const dispatch = useDispatch();
    const {id, name} = company;

    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const menuMode = useSelector(state => state.rolesSearchSettings.menuMode);
    const menuOpen = id === companyId;

    let styles =  menuOpen ? [classes.CompanyMenuItem, classes.Open].join(' ') : classes.CompanyMenuItem;

    const handleCompanyClicked = () => {
        if (!menuOpen){
            dispatch(leftMenuCompanySelected(id));
        }
    };

    return (
        <div className={styles}>
            <ul>
                <li className={classes.CompanyName} onClick={handleCompanyClicked}>
                    {name} {isArchive && <FontAwesomeIcon className={classes.Icon} icon={faArchive}/>}
                </li>
                <li className={classes.Drawer}>
                    <ul>
                        <MenuModeNav active={menuMode === 'roles'} mode="roles">
                            Roles
                        </MenuModeNav>
                        <MenuModeNav active={menuMode === 'addRole'} mode="addRole">
                            <FontAwesomeIcon icon={faPlusCircle}/> Add Role
                        </MenuModeNav>
                        <MenuModeNav active={menuMode === 'metrics'} mode="metrics">
                            Metrics
                        </MenuModeNav>
                        <MenuModeNav active={menuMode === 'info'} mode="info">
                            Account
                        </MenuModeNav>
                    </ul>
                </li>
            </ul>
        </div>
    )
};

export default CompanyMenuItem;

CompanyMenuItem.propTypes = {
    company: PropTypes.object.isRequired,
    isArchive: PropTypes.bool
};

CompanyMenuItem.defaultProps = {
    isArchive: false
};