import React, {Fragment} from 'react';
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup, Row, Well} from "react-bootstrap";
import Switch from 'react-switch';
import classes from './AdminInfoSection.module.css';
import {inputNullToEmptyString} from '../AdminFormValidation/AdminFormValidation';
import {useSelector} from 'react-redux';

const AdminInfoSection = props => {
    const mode = useSelector(state => state.siteSettings.mode);

    return (
        <Well bsSize="large">
            <Row>
                {mode === 'success' && !props.sourcingOnly &&
                    <Fragment>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="interviewer" validationState={props.interviewValidationState}>
                                <ControlLabel>Interviewer</ControlLabel>
                                <FormControl
                                    value={props.interviewer}
                                    disabled
                                    onChange={props.handleInterviewChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="interviewTitle" validationState={props.interviewTitleValidationState}>
                                <ControlLabel>Interviewer Title</ControlLabel>
                                <FormControl
                                    disabled
                                    value={props.interviewer_title}
                                    onChange={props.handleInterviewTitleChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="calendlyLink" validationState={props.calendlyLinkValidationState}>
                                <ControlLabel>Calendar link</ControlLabel>
                                <FormControl
                                    disabled
                                    value={inputNullToEmptyString(props.calendly_link)}
                                    onChange={props.handleCalendlyLinkChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                    </Fragment>
                }
                <Col xs={12} lg={6}>
                    <FormGroup controlId="targetSalary" validationState={props.targetSalaryValidationState}>
                        <ControlLabel>Max Salary</ControlLabel>
                        <FormControl
                            required
                            value={props.target_salary}
                            onChange={props.handleTargetSalaryChanged}
                            type="text"/>
                        <FormControl.Feedback/>
                    </FormGroup>
                </Col>
                {mode === 'success' && !props.sourcingOnly &&
                    <Fragment>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="willReportTo" validationState={props.willReportToValidation}>
                                <ControlLabel>Will report to</ControlLabel>
                                <FormControl
                                    disabled
                                    value={props.report_to}
                                    onChange={props.handleReportToChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="titleReportTo" validationState={props.reportToTitleValidation}>
                                <ControlLabel>Title of person reporting to</ControlLabel>
                                <FormControl
                                    disabled
                                    value={props.report_to_title}
                                    onChange={props.handleReportToTitleChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={6}>
                            <FormGroup controlId="teamSize" validationState={props.teamSizeValidation}>
                                <ControlLabel>Team Size</ControlLabel>
                                <FormControl
                                    value={props.team_size}
                                    onChange={props.handleTeamSizeChanged}
                                    type="text"/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </Col>
                    </Fragment>
                }
                {!props.sourcingOnly &&
                    <Col xs={12} lg={6}>
                        <FormGroup controlId="currentJDLink" validationState={props.jDLinkValidation}>
                            <ControlLabel>Link to current JD</ControlLabel>
                            <FormControl
                                value={inputNullToEmptyString(props.jd_link)}
                                onChange={props.handleJDChanged}
                                type="text"/>
                            <FormControl.Feedback/>
                        </FormGroup>
                    </Col>
                }
                <Col xs={12} lg={12}>
                    <FormGroup controlId="candidateLocations" validationState={props.officeLocationsValidation}>
                        <ControlLabel>Candidate Location</ControlLabel>
                        <div style={{border: '1px solid #ccc', backgroundColor:'#FFF', borderRadius:4,
                                        display: 'flex', alignItems:'center', flexWrap: 'wrap',
                                        padding: '6px 12px'}}>
                            {(props.locations && props.locations.length > 0) ? props.locations.map(location => (
                                <div style={{ margin: '1px 4px', padding: '0px 2px',border: '1px solid #0A7AA2',
                                                borderRadius: 2, backgroundColor: '#DCF1F8' }}>
                                    {location.location_name}
                                </div>
                            )) : (
                                <span>Not specified</span>
                            )}
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup controlId="environment" validationState={props.environmentValidation}>
                        <ControlLabel>Work Environment</ControlLabel>
                        <FormControl
                            required
                            value={props.environment}
                            onChange={props.handleEnvironmentChanged}
                            type="text"/>
                        <FormControl.Feedback/>
                    </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <FormGroup controlId="officeLocations" validationState={props.officeLocationsValidation}>
                        <ControlLabel>Office Location</ControlLabel>
                        <div style={{border: '1px solid #ccc', backgroundColor:'#FFF', borderRadius:4,
                                        display: 'flex', alignItems:'center', flexWrap: 'wrap',
                                        padding: '6px 12px'}}>
                            {(props.offices && props.offices.length > 0) ? props.offices.map(location => (
                                <div style={{ margin: '1px 4px', padding: '0px 2px',border: '1px solid #0A7AA2',
                                                borderRadius: 2, backgroundColor: '#DCF1F8' }}>
                                    {location.location_name}
                                </div>
                            )): (
                                <span>{props.office_locations ?? 'Not specified'}</span>
                            )}
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {mode === 'success' && !props.sourcingOnly &&
                <Row>
                    <Col xs={12}>
                        <FormGroup controlId="sellOpportunity" validationState={props.sellOpportunityValidation}>
                            <ControlLabel>How will you sell this opportunity?</ControlLabel>
                            <FormControl
                                value={props.sell_opportunity}
                                onChange={props.handleOpportunityChanged}
                                componentClass="textarea"/>
                            <FormControl.Feedback/>
                            <HelpBlock>0 to 7000 chars only</HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
            }
            <Row>
                {mode === 'success'  && !props.sourcingOnly && (
                    <Fragment>
                        <Col xs={12} sm={3}>
                            <FormGroup controlId="bonusOffered" validationState={props.bonusOfferedValidationState}
                                       className={classes.ButtonSliderGroup}>
                                <ControlLabel>Bonus offered?</ControlLabel>
                                <InputGroup className={classes.SliderGroup}>
                                    <Switch onChange={props.handleBonusOfferedChanged} checked={props.bonus_offered}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                            <FormGroup controlId="equityOffered" validationState={props.equityOfferedValidation}
                                       className={classes.ButtonSliderGroup}>
                                <ControlLabel>Equity offered?</ControlLabel>
                                <InputGroup className={classes.SliderGroup}>
                                    <Switch onChange={props.handleEquityOfferedChanged} checked={props.equity_offered}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Fragment>
                    )}
                <Col xs={12} sm={mode === 'success' && !props.sourcingOnly ? 3 : 6}>
                    <FormGroup controlId="offerRelocation" validationState={props.offerRelocationValidation}
                               className={classes.ButtonSliderGroup}>
                        <ControlLabel>Offer Relocation?</ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.handleOfferRelocationChanged} checked={props.offer_relocation}/>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={12} sm={mode === 'success' && !props.sourcingOnly ? 3 : 6}>
                    <FormGroup controlId="visa-sponsorship" validationState={props.visaSponsorshipValidationState}
                               className={classes.ButtonSliderGroup}>
                        <ControlLabel>Visa Sponsorship</ControlLabel>
                        <InputGroup className={classes.SliderGroup}>
                            <Switch onChange={props.handleVisaSponsorshipChanged} checked={props.visaSponsorship}/>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        </Well>
    );
};

export default React.memo(AdminInfoSection);