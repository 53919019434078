import * as actionTypes from '../actions/actionsTypes';
import _ from "lodash";

const buildTalentAdminsFromRole = role => _.unionBy(role.talent.filter(x => !!x.admin), 'admin_id').map(x => x.admin);

const initialState = {
    loading: false,
    data: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ROLES_REQUEST:
            return {
                ...state,
                data: [],
                loading: true
            }
        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data.map(x => {
                    // First get the admins from the nested talent object.
                    const talentAdmins = buildTalentAdminsFromRole(x);

                    // Strip the useless talent object from the roles
                    const { talent, ...role } = x;
                    const failedApolloSyncCandidatesCount = talent.filter(x => x.pivot.apollo_sync_failed == 1).length;
                    // merge the admins into the role table
                    return {
                        ...role,
                        talentAdmins,
                        failedApolloSyncCandidatesCount
                    }
                })
            }
        case actionTypes.GOT_ROLE:
            return {
                ...state,
                data: state.data.map(x => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            ...action.payload,
                            talentAdmins: buildTalentAdminsFromRole(action.payload)
                        }
                    }

                    return x;
                })
            }
        case actionTypes.POST_COMPANY_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.POST_COMPANY_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [
                    ...state.data,
                    { ...action.payload.role, talentAdmins: [] },
                ]
            }
        case actionTypes.LEFT_MENU_ARCHIVED_CLICKED:
            return {
                ...state,
                // loading: true,
                data: []
            }
        case actionTypes.GET_TYPE_OF_PLAN:
            return {
                ...state,
                typeOfPlans: action.payload
            }
        case actionTypes.GET_MONDAY_BOARDS_DROPDOWN:
                return {
                    ...state,
                    boards: action.payload
                }
        default:
            return state;
    }
}

export default reducer;
