import React, { Fragment, useReducer, useMemo, useEffect, useState } from 'react';
import NewAdvanceTextInput from "../../../../Ui/Inputs/NewAdvanceTextInput/NewAdvanceTextInput";
import { useDispatch, useSelector } from 'react-redux';
import { patchCompanyItem } from '../../../../../store/actions';
import { useFirstRender } from '../../../../../hooks/useFirstRender';
import FormEditButtonArea from "../../../../Ui/Inputs/FormEditButtonArea/FormEditButtonArea";
import NewAdvanceDateTimePicker from "../../../../Ui/Inputs/NewAdvanceDateTimePicker/NewAdvanceDateTimePicker";
import { Col, OverlayTrigger, Popover,FormGroup, Checkbox, FormControl,ControlLabel } from 'react-bootstrap';
import moment from 'moment';
import BootstrapRowColCombo from "../../../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import NewAdvanceDropdown from "../../../../Ui/Inputs/NewAdvanceDropdown/NewAdvanceDropdown";
import NewAdvanceSliderButton from "../../../../Ui/Inputs/NewAdvanceSliderButton/NewAdvanceSliderButton";
import NewTitleHeader from "../../../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import classes from './CompanyPlanForm.module.css';
import {
    isNumber,
    isSuccessOrNull,
    isDollarAmount
} from '../../../../../utils/validation';
import ReactivatePlanModal from '../../../../Modals/ReactivePlanModal/ReactivatePlanModal';

const init = company => {
    return {
        inEditMode: false,
        loading: false,
        submitDisabled: true,
        activeRoleLimit: company.active_role_limit ? company.active_role_limit.toString() : '',
        planStartDate: company.plan_start_date ? moment.utc(company.plan_start_date).toDate() : null,
        planRenewalDate: company.customer_pricing_plan ? new Date(company.customer_pricing_plan.next_billing_date.split('T')[0] + 'T00:00:00') : null,
        planEndDate: company.plan_end_date ? moment.utc(company.plan_end_date).toDate() : null,
        cancelationDate: company.customer_pricing_plan && company.customer_pricing_plan.cancelation_date ? moment.utc(company.customer_pricing_plan.cancelation_date).toDate() : null,
        billingCycle: company.customer_pricing_plan ? company.customer_pricing_plan.subscription_type : 'Annual',
        requiresReactivation: company.customer_pricing_plan && company.customer_pricing_plan.requires_reactivation ? true : false,
        hearAboutUs: company.customer_pricing_plan ? company.customer_pricing_plan.how_did_you_hear_about_us : null,
        card_info: company.card_info,
        typeOfPlan: company.role_type_id ? company.role_type_id : company.role_type.id,
        defaultPaymentMethod: company.invoice ? 'inv' : 'cc',
        validation: {
            activeRoleLimitValidState: null,
            validateActiveRoleLimit: false,
            pricePerMonthValidState: null,
            validatePricePerMonth: false
        },
        ...(company.customer_pricing_plan ? { pricePerMonth: company.customer_pricing_plan.amount } : {})
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'editEnabled':
            return {
                ...state,
                inEditMode: true,
            }
        case 'loading':
            return {
                ...state,
                loading: true
            }
        case 'enableSubmitButton':
            return {
                ...state,
                submitDisabled: false
            }
        case 'disableSubmitButton':
            return {
                ...state,
                submitDisabled: true
            }
        case 'activeRoleLimitChanged':
            const isValid = isNumber(action.payload);
            return {
                ...state,
                activeRoleLimit: action.payload,
                validation: {
                    ...state.validation,
                    activeRoleLimitValidState: isValid ? 'success' : 'error',
                    validateActiveRoleLimit: true
                }
            }
        case 'planStartDateChanged':
            return {
                ...state,
                planStartDate: action.payload
            }
        case 'planEndDateChanged':
            return {
                ...state,
                planEndDate: action.payload
            }
        case 'forceCollectCardChanged':
            return {
                ...state,
                forceCollectCard: action.payload
            }
        case 'typeOfPlanChanged':
            return {
                ...state,
                typeOfPlan: action.payload
            }
        case 'paymentMethodChanged':
            return {
                ...state,
                defaultPaymentMethod: action.payload
            }
        case 'billingCycleChanged':
            return {
                ...state,
                billingCycle: action.payload
            }
        case 'hearAboutUsChanged':
            return {
                ...state,
                hearAboutUs: action.payload
            }
        case 'pricePerMonthChanged':
            return {
                ...state,
                pricePerMonth: action.payload,
                
            }
        case 'reset':
            return init(action.payload);
        default:
            throw new Error();
    }
}

const CompanyPlanForm = () => {
    const dispatchRedux = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = useMemo(() => companies.find(x => x.id === companyId), [companies]);
    const [state, dispatch] = useReducer(reducer, company, init);
    const isFirstRender = useFirstRender();
    const typeOfPlans = useSelector(state => state.roles.typeOfPlans)
    const paymentMethodsDropdown = [{id: 'inv', name: "Invoice"},{id: 'cc', name: "Credit Card"}]
    const billingCycleDropdown = [{id: 'Month to Month', name: "Month to Month"},{id: 'Annual', name: "Annual"}]
    const hearAboutUsOptions =[
        { id: null, name: "Select one" },
        { id: "Social Media (LinkedIn, Twitter, etc)", name: "Social Media (LinkedIn, Twitter, etc)" },
        { id: "Search Engines (Google, Yahoo, etc)", name: "Search Engines (Google, Yahoo, etc)" },
        { id: "Lever", name: "Lever" },
        { id: "Greenhouse", name: "Greenhouse" },
        { id: "ICIMs", name: "ICIMs" },
        { id: "SAP", name: "SAP" },
        { id: "Word of Mouth", name: "Word of Mouth" },
        { id: "Website", name: "Website" },
        { id: "Other", name: "Other" },
    ];

    const subscriptionTypePopOver = (
        <Popover id="subscription-type-popover">
            The 'Subscription Type' indicates whether the customer is on a month-to-month or yearly plan.
        </Popover>
    );

    const planRenewalDatePopOver = (
    <Popover id="plan-renewal-date-popover">
        The 'Plan Renewal Date' is the date when a plan is scheduled to renew unless canceled. For e-commerce plans, customers can 
        choose between monthly or yearly plans. For manually created companies, we suggest setting the 'Plan Renewal Date' one year
        ahead of the 'Plan Start Date'.
    </Popover>
    );

    const PopoverHandler = ({ overlay }) => (
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="top"
            overlay={overlay}
        >
            <BsFillQuestionCircleFill className={classes.HelpIcon} />
        </OverlayTrigger>
    );

    useEffect(() => {
        if (!isFirstRender) {
            dispatch({ type: 'reset', payload: company });
        }
    }, [company]);

    const handleEditEnabled = () => {
        dispatch({ type: 'editEnabled' });
    }

    const handleEditCanceled = () => {
        dispatch({ type: 'reset', payload: company });
    }

    useEffect(() => {
        const { activeRoleLimitValidState } = state.validation;
        if (isSuccessOrNull(activeRoleLimitValidState)) {
            dispatch({ type: 'enableSubmitButton' });
        } else {
            dispatch({ type: 'disableSubmitButton' });
        }
    }, [state.validation]);

    const handleSubmitChanges = () => {
        dispatch({ type: 'loading' });

        const payload = {
            active_role_limit: state.activeRoleLimit === "" ? null : state.activeRoleLimit,
            plan_start_date: state.planStartDate === "" ? null : state.planStartDate,
            plan_end_date: state.planEndDate === "" ? null : state.planEndDate,
            force_collect_card: state.forceCollectCard,
            role_type_id: parseInt(state.typeOfPlan, 10),
            invoice : state.defaultPaymentMethod === 'inv' ? true : false,
            price_per_month : state.pricePerMonth === "" ? null : state.pricePerMonth
        };

        let extraData = {
            card_info: state.card_info
        }

        dispatchRedux(patchCompanyItem(
            company.id,
            payload,
            extraData
        ));
    }

    const handleActiveRoleLimitChanged = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            dispatch({ type: 'activeRoleLimitChanged', payload: value });
        }
    }

    const handleBillingCycleChanged = (e) => {
        dispatch({ type: 'billingCycleChanged', payload: e.currentTarget.value });
    }

    const handleHearAboutUsChanged = (e) => {
        dispatch({ type: 'hearAboutUsChanged', payload: e.currentTarget.value });
    }

    const handleTypeOfPlanChanged = (e) => {
        dispatch({ type: 'typeOfPlanChanged', payload: e.currentTarget.value });
    }

    const handlePlanStartDateChanged = (value) => {
        dispatch({ type: 'planStartDateChanged', payload: value });
    }

    const handlePlanEndDateChanged = (value) => {
        dispatch({ type: 'planEndDateChanged', payload: value });
    }

    const handlePaymentMethodChanged = (e) => {
        dispatch({ type: 'paymentMethodChanged', payload: e.currentTarget.value });
    }

    const handleForceCollectCardChanged = (value) => {
        dispatch({ type: 'forceCollectCardChanged', payload: value });
    }

    const handlePricePerMonthChanged = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value) || value === "") {
            dispatch({ type: 'pricePerMonthChanged', payload: value });
        }
    }

    const isReactivating = state.inEditMode && company.is_from_drive_through && state.cancelationDate;
    const [showReactivatePlanModal, setShowReactivatePlanModal] = useState(false);

    const handleSaveButtonBehavior = () => {
        if (isReactivating) {
            setShowReactivatePlanModal(true);
        } else {
            handleSubmitChanges();
        }
    }

    const planRenewalDate = () => {
        if (isReactivating) {
            return null;
        }
        if (state.billingCycle === 'Month to Month') {
            return state.planRenewalDate;
        }
        return state.planEndDate;
    }

    return (
        <>
            <Fragment>
                <FormEditButtonArea
                    editDisabled={state.loading}
                    inEditMode={state.inEditMode}
                    onEditClicked={handleEditEnabled}
                    onCancelClicked={handleEditCanceled}
                    onSaveClicked={handleSaveButtonBehavior}
                    saveDisabled={state.submitDisabled}
                    editBtnText={(state.cancelationDate && company.is_from_drive_through) ? 'Reactivate subscription' : 'Edit'}
                    showEdit={(moment().isAfter(moment.utc(company.plan_end_date)) || !state.cancelationDate)}
                />
                <BootstrapRowColCombo>
                    <Col lg={6}>
                        <NewTitleHeader title="Company Plan" />
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col xs={12}>
                        <p className={classes.IsDriveThroughLabel}>
                                {company.is_from_drive_through ? 
                                    'This company was created via Rolebot\'s e-commerce' : 
                                    'This company was created manually'
                                }
                        </p>
                    </Col>
                </BootstrapRowColCombo>

                {isReactivating && <BootstrapRowColCombo>
                    <Col xs={12}>
                        <div style={{backgroundColor: '#FFC4001A', padding: 5, borderRadius: 4, marginBottom: '21px'}}>
                            <p><strong>Important:</strong>You're reactivating a customer's canceled e-commerce plan. If the active role limit is 5 or fewer, advise the customer to reactivate the plan themselves to set up their payment method. If it exceeds 5, you can proceed to set it up from the admin side.</p>
                            <p>Once payment is processed, the plan's start and renewal dates will be automatically set.</p>
                        </div>
                    </Col>
                </BootstrapRowColCombo>}


                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handleActiveRoleLimitChanged}
                            value={state.activeRoleLimit}
                            disabled={!state.inEditMode}
                            validationState={state.validation.activeRoleLimitValidState}
                            label="Active Role Limit" />
                    </Col>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            disabled={!state.inEditMode}
                            options={typeOfPlans}
                            value={state.typeOfPlan}
                            onChange={handleTypeOfPlanChanged}
                            label="Default Role Type" />
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDateTimePicker
                            disabled={company.is_from_drive_through || !state.inEditMode}
                            label="Plan Start Date"
                            value={isReactivating ? null : state.planStartDate}
                            onChange={handlePlanStartDateChanged}
                            placeholderText={isReactivating ? 'Will be set automatically' : null}
                        />
                    </Col>
                    <Col xs={6}>
                        {company.is_from_drive_through ? 
                            <NewAdvanceDateTimePicker
                                disabled={true}
                                value={planRenewalDate()}
                                onChange={handlePlanEndDateChanged}
                                placeholderText={isReactivating ? 'Will be set automatically' : null}
                                label={
                                    state.cancelationDate ? "Plan End Date" : 
                                    <div className={classes.LabelWithPopover}>
                                        <span>Plan Renewal Date</span>
                                        <PopoverHandler overlay={planRenewalDatePopOver} />
                                    </div>
                                } 
                            /> :
                            <NewAdvanceDateTimePicker
                                disabled={!state.inEditMode}
                                value={state.planEndDate}
                                onChange={handlePlanEndDateChanged}
                                label={'Plan End Date'} 
                            /> }
                    </Col>
                </BootstrapRowColCombo>
                {company.is_from_drive_through ?
                    <BootstrapRowColCombo>
                        <Col xs={6}>
                            <NewAdvanceDropdown
                                disabled={!isReactivating}
                                options={billingCycleDropdown}
                                value={state.billingCycle}
                                onChange={handleBillingCycleChanged}
                                label={
                                    <div className={classes.LabelWithPopover}>
                                        <span style={{fontSize: 16}}>Subscription Type</span>
                                        <PopoverHandler overlay={subscriptionTypePopOver} />
                                    </div>
                                }
                            />
                        </Col>
                        <Col xs={6}>
                            <NewAdvanceDropdown
                                disabled={true}
                                options={hearAboutUsOptions}
                                value={state.hearAboutUs}
                                onChange={handleHearAboutUsChanged}
                                label={<span style={{fontSize: 16}}>How did they hear about Rolebot?</span>} />
                        </Col>
                    </BootstrapRowColCombo> : null }

                {company.customer_pricing_plan && <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceTextInput
                            loading={state.loading}
                            onChange={handlePricePerMonthChanged}
                            value={state.pricePerMonth}
                            disabled={!isReactivating}
                            type="number"
                            step="0.01"
                            label="Price per month (USD)" />
                    </Col>
                </BootstrapRowColCombo> }

                {(state.cancelationDate && !state.requiresReactivation) && (
                    <BootstrapRowColCombo>
                        <Col xs={12} className={classes.CancellationInfoBox}>
                            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className={classes.IconExclamationTriangle} />
                            <span> This customer canceled their subscription on {moment.utc(company.customer_pricing_plan.cancelation_date + 'T12:00:00Z').local().format('MMMM D, YYYY')}. </span>
                            <strong>Plan active until {state.planEndDate ? moment.utc(company.plan_end_date).local().format('MMMM D, YYYY') : 'N/A'}.</strong>
                        </Col>
                    </BootstrapRowColCombo>
                )}

                <br></br><br></br>

                <BootstrapRowColCombo>
                    <Col lg={6}>
                        <NewTitleHeader title="Payment Method" />
                    </Col>
                </BootstrapRowColCombo>
                <BootstrapRowColCombo>
                    <Col xs={6}>
                        <NewAdvanceDropdown
                            label="Payment Method"
                            disabled={!state.inEditMode}
                            options={paymentMethodsDropdown}
                            value={state.defaultPaymentMethod}
                            onChange={handlePaymentMethodChanged}
                            placeholder="-- SELECT --"/>
                    </Col>
                </BootstrapRowColCombo>
                {state.defaultPaymentMethod === 'cc' && (<><BootstrapRowColCombo>
                    <Col xs={12} lg={4}>
                        <NewAdvanceSliderButton
                            value={state.forceCollectCard}
                            onChange={handleForceCollectCardChanged} label={(<>Collect Card
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="right"
                                    overlay={(
                                        <Popover id="popover-positioned-right" title="Force Collect Card">
                                            Checking this will force the user to submit a card. Can only be used if <strong style={{ color: "red" }}>ALL</strong> the users added to the role are
                                            missing card data.
                                        </Popover>
                                    )}>
                                    <BsFillQuestionCircleFill className={classes.HelpIcon} />
                                </OverlayTrigger>
                            </>)} disabled={!state.inEditMode} />
                    </Col>
                </BootstrapRowColCombo>
                {state.card_info &&
                    <BootstrapRowColCombo style={{ backgroundColor: "#e5e5e5", backgroundClip: "content-box" }}>
                        <Col lg={12}>
                            <h5 style={{ fontWeight: "bold" }}>Name: <span style={{ marginLeft: 10 }}>{state.card_info ? state.card_info.cardholder_name : ''}</span></h5>
                        </Col>
                        <Col lg={6}>
                            <h4><FontAwesomeIcon icon={faCreditCard} /><span style={{ marginLeft: 10 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 3 }}>•</span><span style={{ marginLeft: 10 }}>
                                {state.card_info ? state.card_info.card_last_four ? state.card_info.card_last_four.toString().padStart(4, '0') : '' : ''}</span></h4>
                        </Col>
                        <Col lg={6} style={{ textAlign: "end" }}>
                            <h5><span style={{ fontWeight: "bold" }}>Stripe ID: </span>{state.card_info ? state.card_info.stripe_id : ''}</h5>
                        </Col>
                    </BootstrapRowColCombo>
                }</>)}

                {state.card_info && <br />}
            </Fragment>
            <ReactivatePlanModal show={showReactivatePlanModal} customer={company} state={state} onHide={() => setShowReactivatePlanModal(false)} />
        </>
    );
};

export default React.memo(CompanyPlanForm);