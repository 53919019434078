import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import classes from './FilterSection.module.css';

const FilterSection = props => {
    return (
        <div>
            <Row>
                <Col xs={12}>
                    {props.children}
                </Col>
            </Row>
        </div>
    )
};

export default FilterSection;

FilterSection.propTypes = {
    // title: PropTypes.string.isRequired
};

FilterSection.defaultProps = {};