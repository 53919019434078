import React, {useEffect, useState} from 'react';
import {Popover, OverlayTrigger} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import classes from './LastReviewedClock.module.css';
import Loading from "../../../../Ui/Loading/Loading";
import {getRoleLastReviewedDate} from "../../../../../utils/services/turboPlus2RestCalls";
import moment from "moment";

const LastReviewedClock = ({roleId}) => {
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [lastReviewedDate, setLastReviewedDate] = useState(null);

    useEffect(() => {
        if(isReady){
            getRoleLastReviewedDate(roleId)
                .then(response => {
                    setIsLoading(false);
                    setLastReviewedDate(response.last_reviewed_date);
                })
                .catch(e => console.log(e));
        }
    }, [isReady])


    const buildPopup = () => {
        if(!lastReviewedDate){
            return <div>No Selected Talent</div>;
        }

        const date = moment.utc(lastReviewedDate).local();

        return (
            <div>
                <div>Last Candidate Reviewed</div>
                <div className={classes.DateWrapper}>
                    <span>{date.format('L')}</span>
                    <span>{date.format('LT')}</span>
                </div>
            </div>
        )
    };

    const popover = (
        <Popover id='LastReviewedClock' className={classes.PopOver}>
            { isLoading ? <Loading/> : buildPopup()}
        </Popover>
    )

    const onMouseOverHandler = () => {
        if(!isReady){
            setIsReady(true);
        }
    }

    return (
        <OverlayTrigger overlay={popover} trigger={['hover', 'focus']}  placement="top">
            <div className={classes.LastSurfacedClock} onMouseOver={onMouseOverHandler}>
                <FontAwesomeIcon className={classes.Icon} icon={faClock}/>
            </div>
        </OverlayTrigger>
    )
};

export default React.memo(LastReviewedClock);

LastReviewedClock.propTypes = {
    roleId: PropTypes.number.isRequired
};

LastReviewedClock.defaultProps = {};