import React, {useEffect, useMemo} from 'react';
import {Row, Col} from 'react-bootstrap';
import SmallMetricItem from "./Components/SmallMetricItem/SmallMetricItem";
import LargeMetricWheel from "./Components/LargeMetricWheel/LargeMetricWheel";
import {MdPlaylistAddCheck, MdPlaylistAdd} from 'react-icons/md';
import {IoMdSync} from 'react-icons/io';
import {RiWechatLine} from 'react-icons/ri'
import {GiArcheryTarget} from 'react-icons/gi'
import {HiOutlineUserGroup} from 'react-icons/hi'
import TalentReviewedBreakDown from "./Components/TalentReviewedBreakDown/TalentReviewedBreakDown";
import SmallPercentBox from "./Components/SmallPercentBox/SmallPercentBox";
import DuelMetricBarBox from "./Components/DuelMetricBarBox/DuelMetricBarBox";

function calculatePercent(small, big) {
    if(small === 0 & big === 0) {
        return 0;
    }
    return (small * 100) / big;
}

function number_format(value, decimals) {
    //Parse the value as a float value
    value = parseFloat(value);
    //Format the value w/ the specified number
    //of decimal places and return it.
    return parseInt(value.toFixed(decimals), 10);
}

const GeneralMetrics = ({metrics}) => {

    const talentBreakDown = {
        accepted: (isNaN(metrics.talent_accepted_count) || isNaN(metrics.talent_previously_contacted_count)) ? 0 : ((metrics.talent_accepted_count - metrics.talent_previously_contacted_count) < 0 ? 0 : metrics.talent_accepted_count - metrics.talent_previously_contacted_count),
        rejected: metrics.talent_rejected_count,
        previouslyContacted: metrics.talent_previously_contacted_count,
        profileError: metrics.talent_profile_error_count
    };

    const responseRateData = useMemo(() => {
        const values =  []

        if(metrics.talent_interview_count){
            values.push({
                name: 'Confirmed Interviews',
                value: metrics.talent_interview_count,
                color: '#4eb448'
            })
        }

        if(metrics.talent_interested_count){
            values.push({
                name: 'Interested Candidates',
                value: metrics.talent_interested_count,
                color: '#ffd250'
            })
        }

        if(metrics.talent_happy_in_role_count){
            values.push({
                name: 'Happy in Role',
                value: metrics.talent_happy_in_role_count,
                color: '#18a9b7'
            })
        }

        if(metrics.talent_comp_differential_count){
            values.push({
                name: 'Comp. Differential',
                value: metrics.talent_comp_differential_count,
                color: '#e16726'
            })
        }

        if(metrics.talent_commute_distance_count){
            values.push({
                name: 'Commute Distance',
                value: metrics.talent_commute_distance_count,
                color: '#4a2e91'
            })
        }

        if(metrics.talent_not_interested_count){
            values.push({
                name: 'Not Interested',
                value: metrics.talent_not_interested_count,
                color: '#c7489a'
            })
        }

        if(metrics.talent_seeking_remote_work_count){
            values.push({
                name: 'Seeking remote opportunities',
                value: metrics.talent_seeking_remote_work_count,
                color: '#004EC3'
            })
        }

        if(metrics.talent_seeking_hybrid_work_count){
            values.push({
                name: 'Seeking hybrid opportunities',
                value: metrics.talent_seeking_hybrid_work_count,
                color: '#109153'
            })
        }

        if(metrics.talent_requires_visa_sponsorship_count){
            values.push({
                name: 'Requires visa Sponsorship',
                value: metrics.talent_requires_visa_sponsorship_count,
                color: '#B3B3B3'
            })
        }

        if(metrics.talent_not_open_to_relocation_count){
            values.push({
                name: 'Not interested in relocating',
                value: metrics.talent_not_open_to_relocation_count,
                color: '#838485'
            })
        }

        return values;

    }, [metrics]);

    const responseValue = metrics.talent_interview_count +
        metrics.talent_interested_count +
        metrics.talent_happy_in_role_count +
        metrics.talent_comp_differential_count +
        metrics.talent_not_interested_count +
        metrics.talent_seeking_remote_work_count +
        metrics.talent_seeking_hybrid_work_count +
        metrics.talent_requires_visa_sponsorship_count +
        metrics.talent_not_open_to_relocation_count +
        metrics.talent_commute_distance_count;

    const responsePercent = number_format(calculatePercent(responseValue, metrics.talent_accepted_count));

    return metrics ? (
        <div>
            <Row>
                <Col xs={12} lg={4}>
                    <SmallMetricItem
                        title="Talent Surfaced"
                        value={metrics.talent_surfaced}
                        icon={<MdPlaylistAdd/>}
                        iconColor="#18234d"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <SmallMetricItem
                        title="Talent Reviewed"
                        value={metrics.talent_reviewed}
                        icon={<MdPlaylistAddCheck/>}
                        iconColor="#18234d"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <SmallMetricItem
                        title="Talent In Progress"
                        value={metrics.talent_in_progress_count}
                        icon={<IoMdSync/>}
                        iconColor="#4d9ec6"
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs={12} lg={4}>
                    <TalentReviewedBreakDown
                        title="Talent Reviewed"
                        icon={<MdPlaylistAdd/>}
                        data={talentBreakDown}
                        iconColor="#18234d"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <SmallPercentBox
                        title="Sourcing Accuracy"
                        icon={<GiArcheryTarget/>}
                        subTitleOne="Accepted & Previously Contacted"
                        subTitleOneValue={metrics.talent_accepted_count}
                        subTitleTwo="Talent Reviewed"
                        subTitleTwoValue={metrics.talent_reviewed}
                        percent={number_format(calculatePercent(metrics.talent_accepted_count,metrics.talent_reviewed))}
                        primaryColor="#a75da5"
                        iconColor="#a75da5"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <DuelMetricBarBox
                        title="Diversity & Inclusion"
                        icon={<HiOutlineUserGroup/>}
                        barTitleOne="Sourced"
                        barValueOne={number_format(calculatePercent(metrics.talent_diverse_count, metrics.talent_surfaced))}
                        barTitleTwo="Accepted"
                        barValueTwo={number_format(calculatePercent(metrics.talent_diverse_accepted_count, metrics.talent_diverse_count))}
                        subTitleOne="Total Diversity Sourced"
                        subTitleOneValue={metrics.talent_diverse_count}
                        subTitleTwo="Total Diversity Accepted"
                        subTitleTwoValue={metrics.talent_diverse_accepted_count}
                        primaryColor="#3F7BBE"
                        iconColor="#3F7BBE"
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs={12}>
                    <LargeMetricWheel
                        data={responseRateData}
                        icon={<RiWechatLine/>}
                        title="Response Rate"
                        summaryPercentage={responsePercent}
                        summaryTitle="Responses"
                        summaryValue={responseValue}
                        iconColor="#4eb448"
                    />
                </Col>
            </Row>
            <br/>
        </div>
    ) :null
};

export default GeneralMetrics;
