import * as actionTypes from "../actions/actionsTypes";

export const initialState = {
    sideDrawerOpen: true,

    showHeader: true,
    showRoleSection: true,
    showCompanyInfoSection: false,
    showAddCompanySection: false,
    showAddRoleSection: false,
    showRoleRequestsSection: false,
    showCompanyMetrics: false,

    //Left Menu items
    selectedCompanyId: null,
    menuMode: 'all', //all, info, company, addCompany, roleRequests

    //Search Items
    roleFilterType: 'active', //all,active,waiting,completed,vault
    page: 1,
    perPage: 20,
    lastPage: 1,
    total: 0,
    searchText: '',
    searchModeType: 'role', //role,talent
    searchAdminId: '',
    showArchived: false,
    sortType: 'default', // can be default, info-date or date-reviewed
    sortOrder: 'desc', // can be asc or desc

    // Toggle this to trigger a reload of ALL role info
    refreshRolesToggle: false,

    roleRowOpen: null,
};

const saveSettings = (state) => {
    localStorage.setItem('searchSettings', JSON.stringify(state))
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.ROLES_SEARCH_ADMIN_CHANGED:
            return {
                ...state,
                searchAdminId: action.payload,
                page: 1
            }
        case actionTypes.ROLES_SEARCH_TYPE_CHANGED:
            return {
                ...state,
                searchModeType: action.payload,
                page: 1
            }
        case actionTypes.ROLES_SEARCH_VALUE_CHANGED:
            return {
                ...state,
                searchText: action.payload,
                page: 1
            }
        case actionTypes.ROLES_SEARCH_VALUE_RESET:
            return {
                ...state,
                searchText: ''
            }
        case actionTypes.ROLES_SEARCH_PAGE_CHANGED:
            return {
                ...state,
                page: action.payload
            }
        case actionTypes.ROLE_TYPE_CHANGED:
            return {
                ...state,
                roleFilterType: action.payload,
                page: 1
            }
        case actionTypes.ROLE_ROW_CLICKED:
            return {
                ...state,
                roleRowOpen: action.payload === state.roleRowOpen ? null : action.payload
            }
        case actionTypes.REFRESH_ROWS:
            return {
                ...state,
                refreshRolesToggle: !state.refreshRolesToggle
            }
        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                page: action.payload.data.current_page,
                perPage: parseInt(action.payload.data.per_page, 10),
                lastPage: action.payload.data.last_page,
                total: action.payload.data.total
            }
        case actionTypes.LEFT_MENU_COMPANY_CLICKED:
            return {
                ...state,
                selectedCompanyId: action.payload,
                menuMode: 'roles',
                roleFilterType: 'active',
                showHeader: true,
                showRoleSection: true,
                showCompanyInfoSection: false,
                showAddCompanySection: false,
                showAddRoleSection: false,
                showRoleRequestsSection: false,
                showCompanyMetrics: false,
                page: 1
            }
        case actionTypes.LEFT_MENU_SUB_MENU_ITEM_CLICKED:
            return {
                ...state,
                menuMode: action.payload,
                showHeader: true,
                showRoleSection: action.payload === 'all' || action.payload === 'roles',
                showCompanyInfoSection: action.payload === 'info',
                showAddCompanySection: action.payload === 'addCompany',
                showAddRoleSection: action.payload === 'addRole',
                showCompanyMetrics: action.payload === 'metrics',
                showRoleRequestsSection: false
            }
        case actionTypes.LEFT_MENU_MODE_RESET:
            return {
                ...state,
                selectedCompanyId: null,
                menuMode: 'all',
                roleFilterType: 'active',
                showHeader: true,
                showRoleSection: true,
                showCompanyInfoSection: false,
                showAddCompanySection: false,
                showAddRoleSection: false,
                showRoleRequestsSection: false,
                showCompanyMetrics: false,
            }
        case actionTypes.LEFT_MENU_ADD_COMPANY_CLICKED:
            return {
                ...state,
                selectedCompanyId: null,
                menuMode: 'addCompany',
                showHeader: false,
                showRoleSection: false,
                showCompanyInfoSection: false,
                showAddCompanySection: true,
                showRoleRequestsSection: false,
                showAddRoleSection: false,
                showCompanyMetrics: false,
            }
        case actionTypes.LEFT_MENU_ROLES_REQUESTS_CLICKED:
            return {
                ...state,
                selectedCompanyId: null,
                menuMode: 'roleRequests',
                showHeader: false,
                showRoleSection: false,
                showCompanyInfoSection: false,
                showAddCompanySection: false,
                showRoleRequestsSection: true,
                showAddRoleSection: false,
                showCompanyMetrics: false,
            }
        case actionTypes.ROLES_SEARCH_SIDE_DRAWER_OPENED:
            return {
                ...state,
                sideDrawerOpen: true
            }
        case actionTypes.ROLES_SEARCH_SIDE_DRAWER_CLOSED:
            return {
                ...state,
                sideDrawerOpen: false
            }
        case actionTypes.POSTED_COMPANY_ADDED:
            return {
                ...state,
                selectedCompanyId: action.payload.id,
                menuMode: 'info',
                roleFilterType: 'all',
                showHeader: true,
                showRoleSection: false,
                showCompanyInfoSection: true,
                showAddCompanySection: false
            }
        case actionTypes.POST_COMPANY_ROLE_SUCCESS:
            return {
                ...state,
                showRoleSection: !!state.selectedCompanyId,
                showAddRoleSection: false,
                showRoleRequestsSection: !state.selectedCompanyId,
                menuMode: state.selectedCompanyId ? 'company' : 'roleRequests',
            }
        case actionTypes.LEFT_MENU_ARCHIVED_CLICKED:
            return {
                ...state,
                showArchived: !state.showArchived,
                showRoleRequestsSection: false,
                showAddCompanySection: false,
                showCompanyInfoSection: false,
                showCompanyMetrics: false,
                showAddRoleSection: false,
                showRoleSection: true,
                selectedCompanyId: null,
                menuMode: 'all',
                roleRowOpen: null
            }
        case actionTypes.POPUP_FILTER_CHANGE_REQUEST:
            return {
                ...state,
                page: 1
            }
        case actionTypes.SET_SEARCH_ORDER: {
            saveSettings({
                sortType: action.sortType,
                sortOrder: action.payload
            })

            return {
                ...state,
                sortOrder: action.payload
            }
        }
        case actionTypes.SET_SEARCH_ORDER_TYPE: {
            saveSettings({
                sortType: action.payload,
                sortOrder: state.sortOrder
            });

            return {
                ...state,
                sortType: action.payload
            };
        }
        case actionTypes.RESET_SEARCH_ORDER: {
            const newState = {
                ...state,
                sortType: 'default',
                sortOrder: 'desc',
            }
            saveSettings({});
            return newState;
        }
        default:
            return state;
    }
}

export default reducer; 