import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classes from "./ApolloDropDown.module.css";
import useOutsideAreaClick from "../../../../../hooks/useOutsideAreaClick";

const ApolloDropDown = props => {
    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if (clickedOutside)
            props.clickedOutSide();
    }, [clickedOutside]);

    return (
        <div className={classes.Wrapper} ref={wrapperRef}>
            { props.apolloSequences.length > 0 ?
            <div className={classes.DropDown}>
                {props.apolloSequences.map((x) => (
                    <div className={classes.ApolloSequenceItem} onClick={() => props.handleApolloSequenceSelect(x.id, x.name)} key={x.id}>{ x.name }</div>
                ))}
            </div>
            : <div className={classes.ApolloNoResultsItem}>Your search yielded no results!</div> }
        </div>
    )
};

export default ApolloDropDown;

ApolloDropDown.propTypes = {
    apolloSequences: PropTypes.array.isRequired,
    handleApolloSequenceSelect: PropTypes.func.isRequired
};

ApolloDropDown.defaultProps = {};