import React, {Fragment, useState} from 'react';
import TopModeSelector from './components/TopModeSelector/TopModeSelector'
import ReviewTalentSection from './components/ReviewTalentSection/ReviewTalentSection';
import RoleDeliveryInfoSection from './components/RoleDeliveryInfoSection/RoleDeliveryInfoSection';
import AddTalentForm from "../AddTalentForm/AddTalentForm";
import MetricsInfo from "./components/MetricsInfo/MetricsInfo";
import PlanStatusSection from "../PlanStatusSection/PlanStatusSection";

const RoleDeliveryDropDownWrapper = (props) => {
    const [mode, setMode] = useState('review');

    const handleModeChanged = (newMode) => {
        setMode(newMode);
    };

        return (
        <Fragment>
            <TopModeSelector mode={mode} modeChanged={handleModeChanged}/>
            <PlanStatusSection customerId={props.role.customer_id}/>
            <MetricsInfo role={props.role} />
            {mode === 'addTalent' && <AddTalentForm role={props.role} roleId={props.roleId} isWaiting={props.role.show_info}/>}
            {mode === 'review' && <ReviewTalentSection roleId={props.roleId} />}
            {mode === 'roleInfo' && <RoleDeliveryInfoSection roleId={props.roleId}/>}
        </Fragment>
    )
};

export default RoleDeliveryDropDownWrapper;