import React, {Fragment, useReducer, useEffect} from 'react';
import AddCompanyHeader from "../AddCompanySection/Components/AddCompanyHeader/AddCompanyHeader";
import NewTitleHeader from "../../Ui/Headers/NewTitleHeader/NewTitleHeader";
import RoleRequestItem from "./Components/RoleRequestItem/RoleRequestItem";
import {useSelector, useDispatch} from 'react-redux';
import {deleteRoleRequestRequest, patchRoleRequestRequest} from '../../../store/actions';
import {useFirstRender} from '../../../hooks/useFirstRender';
import AddRoleForm from "../AddRoleForm/AddRoleForm";
import classes from './RoleRequestSection.module.css';
import {Col} from 'react-bootstrap';
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import GreyScrollBoxArea from "../GreyScrollBoxArea/GreyScrollBoxArea";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";
import {patchRole} from "../../../utils/services/turboPlus2RestCalls";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const init = ({roleRequests}) => {
    return {
        loading: false,
        roleRequests,
        company: null,
        activeRequests: roleRequests.filter(x => x.on_hold === false),
        onHoldRequests: roleRequests.filter(x => x.on_hold === true),

        showAddRoleForm: false,
        roleRequestIdSelected: null,

        //modals
        showDeleteRoleRequestModal: false,
        showCloseRoleModal: false,
        showOpenRoleModal: false
    }
}

/**
 * This does NOT use the company info from the state!!
 * This is so it always works no matter what filters are in play in the rest of the site.
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'createRoleClicked':
            return {
                ...state,
                showAddRoleForm: true,
                roleRequestIdSelected: action.payload,
                company: state.roleRequests.find(x => x.id === action.payload).customer
            }
        case 'createRoleCanceled':
            return {
                ...state,
                showAddRoleForm: false,
                roleRequestIdSelected: null
            }
        case 'deleteClicked':
            return {
                ...state,
                loading: false,
                showDeleteRoleRequestModal: true,
                roleRequestIdSelected: action.payload,
                company: state.roleRequests.find(x => x.id === action.payload).customer,
            }
        case 'submitting':
            return {
                ...state,
                loading: true
            }
        case 'showCloseRoleModal':
            return {
                ...state,
                loading: false,
                showCloseRoleModal: true,
                roleRequestIdSelected: action.payload,
                company: state.roleRequests.find(x => x.id === action.payload).customer,
            }
        case 'showOpenRoleModal':
            return {
                ...state,
                loading: false,
                showOpenRoleModal: true,
                roleRequestIdSelected: action.payload,
                company: state.roleRequests.find(x => x.id === action.payload).customer,
            }
        case 'showDeleteRoleRequestModal':
            return {
                ...state,
                loading: false,
                showDeleteRoleRequestModal: true,
                roleRequestIdSelected: action.payload.payload,
                company: state.roleRequests.find(x => x.id === action.payload).customer,
            }
        case 'cancelModal':
            return {
                ...state,
                loading: false,
                showDeleteRoleRequestModal: false,
                showCloseRoleModal: false,
                showOpenRoleModal: false
            }
        case 'reset':
            return init(action.payload);
        default:
            throw new Error();
    }
}

const RoleRequestSection = () => {
    const dispatchRedux = useDispatch();
    const roleRequests = useSelector(state => state.roleRequests.data);
    const [state, dispatch] = useReducer(reducer, {roleRequests}, init);
    const isFirstRender = useFirstRender();

    const roleNameRequested = state.roleRequestIdSelected ?
        state.roleRequests.find(x => x.id === state.roleRequestIdSelected).name : null;

    const roleNameToDelete = state.roleRequestIdSelected ?
        state.roleRequests.find(x => x.id === state.roleRequestIdSelected).name : null;

    const admins = state.roleRequestIdSelected ?
        state.roleRequests.find(x => x.id === state.roleRequestIdSelected).role_request_admins : null;

    useEffect(() => {
        if (!isFirstRender) {
            dispatch({type: 'reset', payload: {roleRequests}});
        }
    }, [roleRequests])

    const onCreateClickedHandler = (id) => {
        dispatch({type: 'createRoleClicked', payload: id});
    }

    const onHoldClickedHandler = (id) => {
        dispatchRedux(patchRoleRequestRequest(id, {on_hold: true}))
    }

    const onDeleteSubmit = () => {
        dispatch({type: 'submitting'});
        dispatchRedux(deleteRoleRequestRequest(state.roleRequestIdSelected));
    }

    const handleCancelAddUserClicked = () => {
        dispatch({type: 'createRoleCanceled'});
    }

    const onShowCloseRoleModalHandler = (roleRequestId) => {
        dispatch({type: 'showCloseRoleModal', payload: roleRequestId});
    }

    const onShowOpenRoleModalHandler = (roleRequestId) => {
        dispatch({type: 'showOpenRoleModal', payload: roleRequestId});
    }

    const onShowDeleteModalHandler = (id) => {
        dispatch({type: "deleteClicked", payload: id});
    }

    const onCloseRoleSubmit = () => {
        dispatch({type: 'submitting'});
        const roleId = state.roleRequests.find(x => x.id === state.roleRequestIdSelected).role_id

        patchRole(roleId, {completed: true})
            .then(() => {
                dispatchRedux(deleteRoleRequestRequest(state.roleRequestIdSelected));
            })
            .catch(e => console.log(e))
    }

    const onOpenRoleSubmit = () => {
        dispatch({type: 'submitting'});
        const roleId = state.roleRequests.find(x => x.id === state.roleRequestIdSelected).role_id

        patchRole(roleId, {completed: false})
            .then(() => {
                dispatchRedux(deleteRoleRequestRequest(state.roleRequestIdSelected));
            })
            .catch(e => console.log(e))
    }

    const handleCancelModal = () => {
        dispatch({type: 'cancelModal'});
    }

    const companyName = state.roleRequestIdSelected ?
        `${state.company.name} - ${roleNameToDelete}` :
        null;

    return (
        <Fragment>
            <AddCompanyHeader title="Client Requests">
                Below are our customers’ recent requests.
            </AddCompanyHeader>
            <GreyScrollBoxArea>
                {state.showAddRoleForm && (
                    <div className={classes.AddRoleWrapper}>
                        <BootstrapRowColCombo>
                            <Col xs={12}>
                                <AddRoleForm
                                    company={state.company}
                                    roleName={roleNameRequested}
                                    roleRequestId={state.roleRequestIdSelected}
                                    preLoadUsers={admins}
                                    onCancelClicked={handleCancelAddUserClicked}
                                    showCancelButton={true}
                                    showCompanyInput={true}
                                />
                            </Col>
                        </BootstrapRowColCombo>
                    </div>
                )}

                {!state.showAddRoleForm && state.activeRequests.map(x =>
                    <RoleRequestItem
                        key={x.id}
                        request={x}
                        onCreateClicked={onCreateClickedHandler}
                        onHoldClicked={onHoldClickedHandler}
                        onDeleteClicked={onShowDeleteModalHandler}
                        onCloseRoleClicked={onShowCloseRoleModalHandler}
                        onOpenRoleClicked={onShowOpenRoleModalHandler}
                    />)
                }

                {state.onHoldRequests.length > 0 && <NewTitleHeader title="On Hold"/>}

                {!state.showAddRoleForm && state.onHoldRequests.map(x =>
                    <RoleRequestItem
                        key={x.id}
                        request={x}
                        onCreateClicked={onCreateClickedHandler}
                        onHoldClicked={onHoldClickedHandler}
                        onDeleteClicked={onShowDeleteModalHandler}
                        onCloseRoleClicked={onShowCloseRoleModalHandler}
                        onOpenRoleClicked={onShowOpenRoleModalHandler}
                    />)
                }

                {
                    state.onHoldRequests.length === 0 &&
                    state.activeRequests.length === 0 &&
                    <NoResultsMessage message="You're up to date!" icon="dog"/>
                }

                {state.showCloseRoleModal &&
                    <ConfirmationModal
                        show={true}
                        loading={state.loading}
                        title="Close Role"
                        onHide={handleCancelModal}
                        onSubmit={onCloseRoleSubmit}
                        icon="danger"
                    >
                        <div>You’re closing {companyName}</div>
                        <ul>
                            <li>Mark this role as completed in Rolebot.</li>
                        </ul>
                        <div>Are you sure you want to close it?</div>
                    </ConfirmationModal>
                }

                {state.showOpenRoleModal &&
                <ConfirmationModal
                    show={true}
                    loading={state.loading}
                    title="Re Open Role Request"
                    onHide={handleCancelModal}
                    onSubmit={onOpenRoleSubmit}
                    icon="danger"
                >
                    <div>You’re about to re open a role request:</div>
                    <ul>
                        <li>{companyName}</li>
                    </ul>
                    <div>Are you sure you want to open it?</div>
                </ConfirmationModal>
                }

                {state.showDeleteRoleRequestModal &&
                    <ConfirmationModal
                        show={true}
                        loading={state.loading}
                        title="Delete Role Request"
                        onHide={handleCancelModal}
                        onSubmit={onDeleteSubmit}
                        icon="danger"
                    >
                        <div>You’re about to delete a role request:</div>
                        <ul>
                            <li>{companyName}</li>
                        </ul>
                        <div>This cannot be undone. Are you sure?</div>
                    </ConfirmationModal>
                }
            </GreyScrollBoxArea>
        </Fragment>
    )
}

export default RoleRequestSection;