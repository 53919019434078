import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";

const NewTextInput = (props) => {
    const {
        controlId,
        label,
        helpText,
        componentClass,
        validationState,
        placeholder,
        type,
        required,
        value,
        onChange
    } = props;

    return (
        <FormGroup controlId={controlId} validationState={validationState}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl
                componentClass={componentClass}
                type={type}
                placeholder={placeholder}
                required={required}
                value={value}
                onChange={onChange}
            />
            <HelpBlock>{helpText}</HelpBlock>
        </FormGroup>
    )
};

export default NewTextInput;

NewTextInput.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    helpText: PropTypes.string,
    componentClass: PropTypes.oneOf(['input', 'textarea']),
    validationState: PropTypes.oneOf(['success', 'warning', 'error', null]),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

NewTextInput.defaultProps = {
    componentClass: 'input',
    validationState: null,
    placeholder: '',
    type: 'text',
    required: false
};