import React from 'react';
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import classes from './Preferences.module.css';
import SwitchInput from "./components/SwitchInput/SwitchInput";
import Button from "react-bootstrap/lib/Button";

const Preferences = ({show, preferences, onChange,preferenceSaved, handleResetPreferences}) => {


    const {
        beautifyDates,
        includeApprovedSkippedRejectionDate,
        includeContactInfo,
        includeDNI,
        includeDateSurfaced,
        includeGender,
        includeInterviewDate,
        includeLinkedinProfile,
        includeOutcomes,
        includeStatus,
        separateNames,
    } = preferences;

    return show ? (
        <div className={classes.Preferences}>
            <Row>
                <Col xs={6}>
                    <div className={classes.Title}>Attribute Columns</div>
                    <SwitchInput
                        checked={includeStatus}
                        message="Status"
                        onChange={() => onChange({includeStatus: !includeStatus})}/>
                    <SwitchInput
                        checked={includeApprovedSkippedRejectionDate}
                        message="Approval, Skipped & rejection date"
                        onChange={() => onChange({includeApprovedSkippedRejectionDate: !includeApprovedSkippedRejectionDate})}/>
                    <SwitchInput
                        checked={includeOutcomes}
                        message="Outcomes"
                        onChange={() => onChange({includeOutcomes: !includeOutcomes})}/>
                    <SwitchInput
                        checked={includeContactInfo}
                        message="Contact Information"
                        onChange={() => onChange({includeContactInfo: !includeContactInfo})}/>
                    <SwitchInput
                        checked={includeLinkedinProfile}
                        message="Linkedin Profile"
                        onChange={() => onChange({includeLinkedinProfile: !includeLinkedinProfile})}/>
                    <SwitchInput
                        checked={includeGender}
                        message="Gender"
                        onChange={() => onChange({includeGender: !includeGender})}/>
                    <SwitchInput
                        checked={includeDNI}
                        message="DNI"
                        onChange={() => onChange({includeDNI: !includeDNI})}/>
                    <SwitchInput
                        checked={includeInterviewDate}
                        message="Interview Date"
                        onChange={() => onChange({includeInterviewDate: !includeInterviewDate})}/>
                    <SwitchInput
                        checked={includeDateSurfaced}
                        message="Date Surfaced"
                        onChange={() => onChange({includeDateSurfaced: !includeDateSurfaced})}/>
                </Col>
                <Col xs={6}>
                    <div className={classes.Title}>Additional Settings</div>
                    <SwitchInput
                        checked={separateNames}
                        message="Separate Candidates Name and Last name"
                        onChange={() => onChange({separateNames: !separateNames})}/>
                    <SwitchInput
                        checked={beautifyDates}
                        message="Beautify Dates"
                        onChange={() => onChange({beautifyDates: !beautifyDates})}/>
                        <div>
                            <Button onClick={preferenceSaved}>Save Settings</Button>{' '}
                            <Button onClick={handleResetPreferences}>Reset Settings</Button>
                        </div>

                </Col>
            </Row>
        </div>
    ) : null;
};

export default Preferences;