import React from 'react';
import {Tooltip} from "react-bootstrap";
import moment from 'moment';

const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

const useBuildAcceptedTooltip = (id, isAccepted, acceptedOn, scheduled_at, surfaced_at ,created_at, skipped_at, skipped_reason) => {

    let message = "";

    //TODO: Convert to switch!!
    if(skipped_at !== null){
        const date = moment.utc(skipped_at).local().format("ll");
        message = (
            <div>
                <strong>{capitalize(skipped_reason)}</strong>
                <div>Skipped on {date}</div>
            </div>
        );
    }else if(acceptedOn) {
        const acceptedDate = moment.utc(acceptedOn).local().format("ll");

        const surfacedDate = scheduled_at ? moment.utc(scheduled_at).local().format("ll") :
            moment.utc(surfaced_at).local().format("ll")

        message = (
            <div>
                <strong>Date Created</strong>
                <div style={{marginBottom: '3px'}}>{moment.utc(created_at).local().format("ll")}</div>

                <strong>Date Surfaced</strong>
                <div style={{marginBottom: '3px'}}>{surfacedDate}</div>
                <strong>{isAccepted ? "Date Accepted" : "Date Rejected"}</strong>
                <div>{acceptedDate}</div>
            </div>
        );
    }else if(scheduled_at && moment.utc(scheduled_at).local().isAfter(moment()) ){
        const date = moment.utc(scheduled_at).local().format("lll");
        message = (
            <div>
                <div>
                    <strong>Date Scheduled</strong>
                    <div>{date}</div>
                </div>
            </div>
        );

    } else if(isAccepted === null){
        const surfacedDate = scheduled_at ? moment.utc(scheduled_at).local().format("ll") :
            moment.utc(surfaced_at).local().format("ll")
        message = (
            <div>
                <strong>Date Created</strong>
                <div>{moment.utc(created_at).local().format("ll")}</div>
                <strong>Date Surfaced</strong>
                <div style={{marginBottom: '3px'}}>{surfacedDate}</div>
            </div>
        );
    } else {
        message = (
            <div>
                <strong>No accepted date info</strong>
            </div>
        );
    }

    return (
        <Tooltip id={id}>
            {message}
        </Tooltip>
    );
};

export default useBuildAcceptedTooltip;
