import React from 'react';
import {Row, Col} from 'react-bootstrap';
import classes from './TalentWrapper.module.css';

const TalentWrapper = props => {
    return (
        <Row className={classes.TalentWrapper}>
            <Col xs={12}>
                {props.children}
            </Col>
        </Row>
    )
};

export default TalentWrapper;
