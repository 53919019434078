import { useState } from "react";
import { Col, ControlLabel, Row, Well } from "react-bootstrap";
import { markTemplateReviewed } from "../../../../utils/services/turboPlus2RestCalls";
import moment from "moment";

const getContactName = (contacts, type) => {
  const contact = contacts.find(contact => contact.role_contact_info.type === type);
  return contact ? contact.name : '';
}

const AdminTemplateSection = ({template, role}) => {

  const [open, setOpen] = useState(false);
  const [reviewed, setReviewed] = useState(template?.last_revision_reviewed ?? true);

  const data_mapping = {
    candidate_name : "{{first_name}}",
    role_name : role.name,
    company_name : role.customer.name,
    interviewer_name: getContactName(role.info.contacts, 'interviewer'),
    hiring_manager_name: getContactName(role.info.contacts, 'report_to')
  }

  const parser = new DOMParser();

  const generateTemplateContent = (html) => {
    html.querySelectorAll('span').forEach(span => {
      if (span.hasAttribute('data-id')) {
        const key = span.getAttribute('data-id');
        if (data_mapping[key]) {
          span.textContent = data_mapping[key];
        }
      }
    });
    return html.body.innerHTML;
  };

  const handleChevronClick = () => {
    setOpen(!open);

    if (!reviewed){
      markTemplateReviewed(role.id).then(() => {
        setReviewed(true);
      });
    }
  }

  const html = parser.parseFromString(template?.content ?? '', 'text/html');
  const modifiedTemplate = generateTemplateContent(html);

  return (
    <Well>
      <Row>
        <Col xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <ControlLabel>Apollo Email Template</ControlLabel>
              {!reviewed && template?.content && <div style={{ backgroundColor: '#D14C6B', borderRadius: 5, padding: '4px 5px', marginBottom: 5, color: 'white' }}>Updated!</div>}
            </div>
            {template?.content && <Chevron open={open} onClick={handleChevronClick} />}
          </div>
          {(!template) && (
            <div style={{ paddingTop: 20}}>
              <p style={{ fontSize: '14px', fontFamily: '"Helvetica Neue", Helvetica, sans-serif', fontWeight: 400, lineHeight: "18px", color: '#737373' }}>Looks like no template has been created yet!</p>
            </div>
          )}
          {(template && !template.content) && (
            <div style={{ paddingTop: 20}}>
              <p style={{ fontSize: '14px', fontFamily: '"Helvetica Neue", Helvetica, sans-serif', fontWeight: 400, lineHeight: "18px", color: '#737373' }}>
                {template.last_reviewed_by ?? 'Unknown'} removed the template for candidate outreach on {moment(template.updated_at).format('MMMM Do, YYYY')}
              </p>
            </div>
          )}
          {template?.content && open && (<><p style={{ paddingTop: 20, paddingBottom: 20 }}>Please use the following email as the first email to be sent to candidates via Apollo. If updated, we recommend creating a new email version and turning off the previous one (e.g. create version “B” of the email and turn off version “A” of the first email).</p>
            <div style={{ backgroundColor: '#F7F5F5', border: '1px solid #DFE1E1', borderRadius: 5 }}>
              <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: modifiedTemplate }}></div>
            </div></>)}
        </Col>
      </Row>
    </Well>
  )
};

const Chevron = ({open, onClick}) => {
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      {open ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.52583 7.17618C9.78696 6.94127 10.2092 6.94127 10.4676 7.17618L15.8041 11.9742C16.0653 12.2091 16.0653 12.5889 15.8041 12.8213C15.543 13.0537 15.1208 13.0562 14.8624 12.8213L10.0009 8.44815L5.13656 12.8238C4.87542 13.0587 4.45317 13.0587 4.19481 12.8238C3.93645 12.5889 3.93368 12.2091 4.19481 11.9767L9.52583 7.17618Z" fill="#838485" />
      </svg>
        : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.52583 12.8238C9.78696 13.0587 10.2092 13.0587 10.4676 12.8238L15.8041 8.02582C16.0653 7.79092 16.0653 7.41108 15.8041 7.17868C15.543 6.94627 15.1208 6.94377 14.8624 7.17868L10.0009 11.5519L5.13656 7.17618C4.87542 6.94127 4.45317 6.94127 4.19481 7.17618C3.93645 7.41108 3.93368 7.79092 4.19481 8.02332L9.52583 12.8238Z" fill="#838485" />
        </svg>

      }

    </div>
  )
}
export default AdminTemplateSection;


