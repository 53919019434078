import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Badge} from "react-bootstrap";
import classes from './PinkButton.module.css';
import {FaFilter, FaSort} from 'react-icons/fa'

const PinkButton = ({count, label, icon, disabled}) => {

    let buttonIcon;
    switch (icon){
        case 'sort':
            buttonIcon = <FaSort className={classes.sortIcon}/>;
            break;
        case 'funnel':
            buttonIcon = <FaFilter className={classes.funnelIcon}/>;
            break;
        default:
            //
    }

    return (
        <Fragment>
            <span style={{ paddingRight: '10px' }}>{buttonIcon}{label}</span>
            {count > 0 && <Badge className={classes.Counter}>{count}</Badge>}
        </Fragment>
    )
};

export default PinkButton;

PinkButton.propTypes = {
    count: PropTypes.number,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['sort','funnel'])
};

PinkButton.defaultProps = {
    count: 0,
    label: 'filter'
};