import React from 'react';
//import PropTypes from 'prop-types';
import {Well, Col, Row} from "react-bootstrap";
import classes from './MetricsInfo.module.css';

function calculatePercent(small, big) {
    return (small * 100) / big;
}

function number_format(val, decimals) {
    //Parse the value as a float value
    val = parseFloat(val);
    //Format the value w/ the specified number
    //of decimal places and return it.
    return parseInt(val.toFixed(decimals), 10);
}

const MetricsInfo = ({role}) => {
    const {talent_accepted_count, talent_diverse_count, talent_reviewed} = role.metrics;
    const {talent_count} = role;

    const sourcingAccuracyPercentage = number_format(calculatePercent(talent_accepted_count, talent_reviewed));

    const sourcedDiversityPercentage = number_format(calculatePercent(talent_diverse_count, talent_count));


    return (
        <Well bsSize="small">
            <Row>
                <Col xs={6} className="text-center">
                    <div className={classes.TextWrapper}>
                        Sourcing Accuracy: {sourcingAccuracyPercentage}%
                    </div>
                </Col>
                <Col xs={6} className="text-center">
                    <div className={classes.TextWrapper}>
                        Total Diversity sourced: {sourcedDiversityPercentage}%
                    </div>
                </Col>
            </Row>

        </Well>
    )
};

export default MetricsInfo;

MetricsInfo.propTypes = {};

MetricsInfo.defaultProps = {};
