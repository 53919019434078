import React from 'react';
import PropTypes from 'prop-types';
import classes from "./ModalHeader.module.css";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import FilterTypePopUp from "./components/FilterTypePopUp/FilterTypePopUp";
import FilterDatePicker from "./components/FilterDatePicker/FilterDatePicker";

const ModalHeader = props => {
    return (
        <div className={classes.Header}>
            <Row>
                <Col xs={12}>
                    <Col xs={12}>
                        <h2 className={classes.Title}>
                            Export
                        </h2>
                    </Col>
                    <Col xs={12} className={classes.HeaderMessage}>
                        Please select the items you’d like to export.
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Col xs={3}>
                        <FilterTypePopUp
                            talentFilterOpen={props.talentFilterOpen}
                            talentFilterToggle={props.talentFilterToggle}
                            filters={props.filters}
                            filterChanged={props.filterChanged}
                            reset={props.reset}/>
                    </Col>
                    <Col xs={3}>
                        <FilterDatePicker
                            calendarSurfaced={props.calendarSurfaced}
                            calendarSurfacedChanged={props.calendarSurfacedChanged}
                            calendarAcceptedChanged={props.calendarAcceptedChanged}
                            calendarAccepted={props.calendarAccepted}
                            calendarFilterDate={props.calendarFilterDate}
                            calendarRangeChanged={props.calendarRangeChanged}
                            calendarFilterOpen={props.calendarFilterOpen}
                            calendarFilterToggle={props.calendarFilterToggle}/>
                    </Col>
                    <Col xs={2}>
                        <Button active={props.preferenceOpen} block onClick={props.handlePreferenceButtonClicked}>Preferences</Button>
                    </Col>
                </Col>
            </Row>
        </div>
    )
};

export default ModalHeader;


ModalHeader.propTypes = {
    filters: PropTypes.object.isRequired,
    filterChanged: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    talentFilterOpen: PropTypes.bool.isRequired,
    talentFilterToggle: PropTypes.func.isRequired,
    calendarFilterOpen: PropTypes.bool.isRequired,
    calendarFilterToggle: PropTypes.func.isRequired,
    calendarRangeChanged: PropTypes.func.isRequired,
    calendarAccepted: PropTypes.bool.isRequired,
    calendarSurfacedChanged: PropTypes.func.isRequired,
    calendarAcceptedChanged: PropTypes.func.isRequired,
    handlePreferenceButtonClicked: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {};