import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";
import DatePickerInput from "../../../DatePickerInput/DatePickerInput";
import FormGreyWrapper from "../../../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";
import { useFormikContext } from 'formik';
import classes from './ScheduledTalentSection.module.css';

const ScheduledTalentSection = props => {

    //Since DatePicker does not return an event as first parameter we need to set it directly or get an error.
    const { setFieldValue } = useFormikContext();

    return (
        <FormGreyWrapper>
            <Row>
                <Col xs={12}>
                    <div style={{ fontSize: '23px', paddingBottom: '20px' }}>
                        Schedule talent
                    </div>
                </Col>
                <Col xs={4}>
                    <FormGroup controlId="scheduled_at" className={classes.FormGroup}>
                        <ControlLabel>{" "}</ControlLabel>
                        <DatePickerInput
                            id="scheduled_at-date"
                            name='scheduled'
                            selected={props.selected}
                            onChange={(value) => setFieldValue('scheduled', value)}
                            showTimeSelect
                            placeholderText="Click to select a date"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <HelpBlock>
                            Leave this blank to submit talent live instantlyss.
                        </HelpBlock>
                    </FormGroup>
                </Col>
            </Row>
        </FormGreyWrapper>
    )
};

export default ScheduledTalentSection;

ScheduledTalentSection.propTypes = {
    selectedDate: PropTypes.instanceOf(Date),
    // scheduledChanged: PropTypes.func.isRequired
};

ScheduledTalentSection.defaultProps = {};