import React from 'react';
import dizzy from '../../../assets/svg/dizzy-robot.svg';
import dog from '../../../assets/svg/rolebot-dog.svg';
import classes from './NoResultsMessage.module.css';
import PropTypes from 'prop-types';

const NoResultsMessage = (props) => {

    let icon;
    switch (props.icon){
        case 'dog':
            icon = dog;
            break;
        default:
            icon = dizzy;
    }

    return (
        <div className={classes.NoFilteredTalent}>
            <img className={classes.Robot} src={icon} alt=""/>
            <h1>{props.message}</h1>
        </div>
    )
};

export default NoResultsMessage;

NoResultsMessage.propTypes = {
    message: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['dizzy', 'dog'])
}

NoResultsMessage.defaultProps = {

}