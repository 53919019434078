import React, {useEffect, useReducer, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getCompanyUsersRequest,
    postUserToCompanyRequest,
    removeUserFromCompanyRequest
} from '../../../../../store/actions';
import FormPanel from "../../../FormPanel/FormPanel";
import EditUserProfile from "./Components/EditUserProfile/EditUserProfile";
import AddUserButtonSection from "./Components/AddUserButtonSection/AddUserButtonSection";
import NewUserForm from "./Components/NewUserForm/NewUserForm";
import AddUserSection from "./Components/AddUserSection/AddUserSection";
import UserListHeader from "./Components/UserListHeader/UserListHeader";
import CompanyUserItem from "./Components/CompanyUserItem/CompanyUserItem";
import {Col, Row} from 'react-bootstrap';
import {getCompanyRolesRequest} from "../../../../../store/actions/companies";

const UsersListWrapper = ({loading, companyId, users, showUserProfileClicked, removeUserClicked, menuClicked, userMenuIdOpen, isActive, addClicked}) => {
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div style={{textAlign: 'right'}}>
                        <AddUserButtonSection isActive={isActive} addClicked={addClicked}/>
                    </div>
                </Col>
            </Row>
            {!loading && <UserListHeader/>}
            {!loading && users.map(x =>
                <CompanyUserItem
                    key={x.id}
                    user={x}
                    companyId={companyId}
                    menuOpen={userMenuIdOpen === x.id}
                    menuClicked={menuClicked}
                    removeUserClicked={removeUserClicked}
                    showUserProfileClicked={showUserProfileClicked}
                />)}
        </Fragment>
    )
};

const init = () => {
    return {
        loading: false,
        addActive: false,
        showUserProfile: false,
        showUsersList: true,
        showAddUserSearchForm: false,
        showAddUserSearchButton: true,
        showAddUserForm: false,
        userProfileIdSelected: null,
        newUserEmail: null,
        userMenuOpenId: null
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'showAddUserSearchForm':
            return {
                ...state,
                addActive: true,
                showUserProfile: false,
                showUsersList: true,
                showAddUserSearchForm: true,

            }
        case 'cancelAddUserSearchForm':
            return {
                ...state,
                addActive: false,
                showUserProfile: false,
                showUsersList: true,
                showAddUserSearchForm: false,
            }
        case 'showUserProfile':
            return {
                ...state,
                showUserProfile: true,
                showUsersList: false,
                showAddUserSearchForm: false,
                showAddUserForm: false,
                userProfileIdSelected: action.payload,
            }
        case 'hideUserProfile':
            return {
                ...state,
                showUserProfile: false,
                showUsersList: true,
            }
        case 'showAddUserForm':
            return {
                ...state,
                showAddUserSearchButton: false,
                showAddUserForm: true,
                showAddUserSearchForm: false,
                showUsersList: true,
            }
        case 'ShowCreateNewUserForm':
            return {
                ...state,
                showAddUserSearchButton: false,
                newUserEmail: action.payload,
                showAddUserForm: true,
                showAddUserSearchForm: false
            }
        case 'userMenuClicked':
            return {
                ...state,
                userMenuOpenId: action.payload
            }
        case 'closeUserMenu':
            return {
                ...state,
                userMenuOpenId: null
            }
        case 'addingExistingUserToCompany':
            return {
                ...state,
                loading: true
            }
        case 'reset':
            return {
                ...state,
                loading: false,
                addActive: false,
                showUserProfile: false,
                showAddUserSearchButton: true,
                showAddUserForm: false,
                showAddUserSearchForm: false,
                showUsersList: true,
            }
        default:
            throw new Error();
    }
}

const CompanyUsersForm = () => {
    const dispatchRedux = useDispatch();
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const companies = useSelector(state => state.companies.data);
    const company = companies.find(x => x.id === companyId);
    const {users, roles} = company;

    const [state, dispatch] = useReducer(reducer, {}, init);

    useEffect(() => {
        dispatchRedux(getCompanyUsersRequest(companyId));
        dispatchRedux(getCompanyRolesRequest(companyId));
    }, []);

    useEffect(() => {
        if(state.showAddUserForm || state.showAddUserSearchForm){
            dispatch({type: 'reset'})
        }
    }, [users])

    const handleAddNewUserClicked = () => {
        dispatch({type: 'showAddUserSearchForm'});
    }

    const handleProfileBackClicked = () => {
        dispatch({type: 'hideUserProfile'});
        dispatchRedux(getCompanyUsersRequest(companyId));
    };

    const handleShowUserProfile = (userId) => {
        dispatch({type: 'showUserProfile', payload: userId});
    }

    const handleCancelAddUserForm = () => {
        dispatch({type: 'reset'});
    }

    const handleCancelAddUserSearchForm = () => {
        dispatch({type: 'cancelAddUserSearchForm'});
    }

    const handleAddExistingUserToCompany = (userId) => {
        dispatch({type: 'addingExistingUserToCompany'})
        dispatchRedux(postUserToCompanyRequest(companyId, {id: userId}));
    }

    const handleShowCreateNewUserForm = (email) => {
        dispatch({type: 'ShowCreateNewUserForm', payload: email});
    }

    const handleNewUserSubmit = (params) => {
        dispatchRedux(postUserToCompanyRequest(companyId, params));
    }

    const handleRemoveUserClicked = (userId) => {
        dispatchRedux(removeUserFromCompanyRequest(companyId, userId));
    }

    const handleUserMenuClicked = (userId) => {
        dispatch({type:'userMenuClicked', payload: userId === state.userMenuOpenId ? null : userId})
    }

    return (
        <Fragment>
            {/*<UsersTopBar/>*/}
            <FormPanel title="Users">
                {/*Add User Search*/}
                {state.showAddUserSearchForm &&
                <AddUserSection
                    users={users}
                    loading={state.loading}
                    companyName={company.name}
                    onAddExistingUserClicked={handleAddExistingUserToCompany}
                    onAddNewUserButtonClicked={handleShowCreateNewUserForm}
                    onCancelSearchClicked={handleCancelAddUserSearchForm}
                />}

                {/*User Add Form*/}
                {state.showAddUserForm &&
                <NewUserForm
                    onCancelClicked={handleCancelAddUserForm}
                    email={state.newUserEmail}
                    onSubmit={handleNewUserSubmit}
                    disableIsAdmin={false}
                />}

                {/*User List Section*/}
                {users && state.showUsersList &&
                <UsersListWrapper
                    companyId={companyId}
                    users={users}
                    userMenuIdOpen={state.userMenuOpenId}
                    menuClicked={handleUserMenuClicked}
                    showUserProfileClicked={handleShowUserProfile}
                    removeUserClicked={handleRemoveUserClicked}
                    isActive={state.addActive}
                    addClicked={handleAddNewUserClicked}
                    loading={state.loading}
                />}

                {/*/!*User Profile Section*!/*/}
                {users && state.showUserProfile &&
                <EditUserProfile
                    user={users.find(x => x.id === state.userMenuOpenId)}
                    companyRoles={roles}
                    companyId={companyId}
                    userId={state.userProfileIdSelected}
                    backClicked={handleProfileBackClicked}
                />}
            </FormPanel>
        </Fragment>
    )
};

export default React.memo(CompanyUsersForm);
