import React, {Fragment, useState} from 'react';
import {Col} from 'react-bootstrap';
import FormPanel from "../FormPanel/FormPanel";
import CompanyInfoForm from "./Components/CompanyInfoForm/CompanyInfoForm";
import CompanyUsersForm from "./Components/CompanyUsersForm/CompanyUsersForm";
import CompanyPlanForm from './Components/CompanyPlanForm/CompanyPlanForm';
import RolesHeader from "../RolesHeader/RolesHeader";
import BootstrapRowColCombo from "../../Bootstrap/BootstrapRowColCombo/BootstrapRowColCombo";
import TabsSection from "../TabsSection/TabsSection";
import TabItem from "../TabsSection/Components/TabItem/TabItem";

const AccountSection = () => {
    const [currentTab, setCurrentTab] = useState('info');

    const handleTabSelected = (key) => {
        setCurrentTab(key);
    }

    return (
        <Fragment>
            <BootstrapRowColCombo>
                <Col xs={12}>
                    <RolesHeader />
                </Col>
            </BootstrapRowColCombo>
            <TabsSection activeKey={currentTab} handleTabSelected={handleTabSelected} unmountOnExit={true}>
                <TabItem eventKey="info" title="Company Info">
                    <FormPanel title="Company Basics">
                        <CompanyInfoForm />
                    </FormPanel>
                </TabItem>
                <TabItem eventKey="plan" title="Company Plan">
                    <FormPanel title="Company Plan">
                        <CompanyPlanForm />
                    </FormPanel>
                </TabItem>
                <TabItem eventKey="users" title="Users">
                    <CompanyUsersForm />
                </TabItem>
            </TabsSection>
        </Fragment>
    );
};

export default AccountSection;