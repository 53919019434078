import axios from 'axios';
import store from '../store';
import {authLogout} from "../store/actions";

const axiosAuthInstance = axios.create();

axiosAuthInstance.interceptors.response.use(null, (error) => {

  //Catch Network errors and return a proper reject
  if (error.message === "Network Error" || error.message === "Request aborted") {
    return Promise.reject({response: {data: "Network Error: Please check your network settings."}});
  }

  //Check for a 426 error that means this SPA needs to re loaded.
  if (error.config && error.response && error.response.status === 426) {
    return Promise.reject(error);
  }

  //NOTE: If you are using this to connect to a API server make sure it returns 401!
  if (error.config && error.response && error.response.status === 401) {
    store.dispatch(authLogout());
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default axiosAuthInstance;
