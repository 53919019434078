import React from 'react';
import classes from './TopNav.module.css';
import logo from './../../../assets/images/rolebot-bot.png';
import {Grid} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faIdCard, faUser} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from 'react-redux';
import {setMode} from '../../../store/actions'

const TopNav = (props) => {
    const mode = useSelector(state => state.siteSettings.mode);
    const dispatch = useDispatch();

    return (
        <Grid className={classes.TopNav}>
            <div className={"hamburger " + classes.Hamburger}>
                <div>
                    <div className={classes.LeftArrow} onClick={props.clicked}>
                        <label htmlFor="menu-left"><span></span></label>
                    </div>
                </div>
            </div>
            <div className={classes.RightLogoWrapper}>
                <div className={classes.ModeButtonWrapper}>
                    <div
                        onClick={() => dispatch(setMode('success'))}
                        className={mode ==='delivery' ? classes.ModeButton : classes.ModeButtonSelected}>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    <div
                        onClick={() => dispatch(setMode('delivery'))}
                        className={mode ==='success' ? classes.ModeButton : classes.ModeButtonSelected}>
                        <FontAwesomeIcon icon={faIdCard}/>
                    </div>
                </div>
                <div className={classes.Logo}>
                    <img src={logo} alt="rolebot"/>
                </div>
            </div>
        </Grid>
    )
}

export default TopNav;