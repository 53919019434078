import React from 'react';
import {Panel} from 'react-bootstrap';

const BillingNotesSection = props => {
    return (
        <Panel>
            <Panel.Heading>
                {props.title}
            </Panel.Heading>
            <Panel.Body>
                {props.children}
            </Panel.Body>
        </Panel>
    );
};

export default BillingNotesSection;