import React from 'react';
import {FormControl, FormGroup} from "react-bootstrap";
import classes from './SearchCompany.module.css';
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SearchCompany = (props) => {
    return (
        <div>
            <form action="">
                <FormGroup className={classes.FormGroup}>
                    <FontAwesomeIcon className={classes.SearchIcon} icon={faSearch}/>
                    {props.value &&
                        <FontAwesomeIcon className={classes.CloseIcon} icon={faTimes} onClick={props.onCancelClicked}/>
                    }
                    <FormControl
                        className={classes.SearchBox}
                        type="text"
                        value={props.value}
                        placeholder="Search..."
                        onChange={props.onChange}
                    />
                </FormGroup>
            </form>
        </div>
    )
};

export default SearchCompany;