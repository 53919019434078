import * as actionTypes from './actionsTypes';
import {login} from "../../utils/services/turboPlus2RestCalls";

export const authLoading = () => {
  return {
    type: actionTypes.AUTH_LOADING
  };
}

export const authSignInSuccess = (email) => {
  return {
    type: actionTypes.AUTH_SIGNIN_SUCCESS,
    email: email
  };
}

export const authNeedsPasswordChange = () => {
  return {
    type: actionTypes.AUTH_NEEDS_PASSWORD_CHANGE
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
}

export const authSignIn = (email, password, newPassword) => {
  return dispatch => {
    dispatch(authLoading());
    login({email, password})
      .then(({data}) => {
        const {user} = data;
        dispatch(authSignInSuccess(user.email,));
      })
      .catch(error => {
        dispatch({type: actionTypes.AUTH_FAIL, payload: error.message});
      });
  }
}

export const authLogout = () => {
  return dispatch => {
    localStorage.clear()
    dispatch({type: actionTypes.AUTH_LOGOUT});
  }
}
