import React, {useState} from 'react';
import {Panel} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from './TalentProspectItemWrapper.module.css';
import TalentProspectItem from './component/TalentProspectItem/TalentProspectItem';
import EditTalentProspectForm from './component/EditTalentProspectForm/EditTalentProspectForm';

const TalentProspectItemWrapper = props => {
    const {id, name, link} = props.talent;
    const {saved, prospect_approved, is_recommended} = props.talent.pivot;
    let {current_company_name} = '';

    if(props.talent.job_titles.length > 0 && props.talent.job_titles[0] ) {
        current_company_name = props.talent.job_titles[0].company_name
    }

    const [isOpen, setIsOpen] =useState(false)

    const OnPanelToggle = (isPanelOpen) => {
        setIsOpen(isPanelOpen);
    };

    return (
        <Panel id={id.toString()} className={classes.Panel} onToggle={OnPanelToggle}>
            <Panel.Heading
                componentClass={() =>
                    <TalentProspectItem
                        id={id}
                        name={name}
                        company={current_company_name}
                        saved={saved}
                        prospect_approved={prospect_approved}
                        is_recommended={is_recommended}
                        link={link}
                        talentForeReviewStatusUpdated={props.talentForeReviewStatusUpdated}
                    />}>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    <EditTalentProspectForm isOpen={isOpen} talent={props.talent} updateTalentForReview={props.updateTalentForReview}/>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    )
};

export default TalentProspectItemWrapper;

TalentProspectItemWrapper.propTypes = {
    talent: PropTypes.object.isRequired
};

TalentProspectItemWrapper.defaultProps = {};
