import React from 'react';
import classes from './BillingInfoItem.module.css';
import {Row, Col} from 'react-bootstrap';
import EllipseButton from "../../../../../../../../Ui/EllipseButton/EllipseButton";
import EllipseMenuItem from "../../../../../../../../Ui/EllipseButton/Components/EllipseMenuItem/EllipseMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const BillingInfoItem = ({card, menuOpenClicked, menuOpen, removeCard, makeDefault, isDefault}) => {
    return (
        <div className={classes.BillingInfoItem}>
            <Row>
                <Col xs={10}>
                    <Col xs={12}>
                        {card.billing_details.name} {card.card.brand}-{card.card.last4} - {isDefault ? 'Default' : ''}
                    </Col>
                    <Col xs={12}>
                        Expires {card.card.exp_month}/{card.card.exp_year}
                    </Col>
                </Col>
                <Col xs={2}>
                    <EllipseButton open={menuOpen} onClick={() => menuOpenClicked(card.id)} id={4}>
                        <EllipseMenuItem onClick={() => removeCard(card.id)}>
                            <FontAwesomeIcon icon={faTrash}/> Remove From Role
                        </EllipseMenuItem>

                        <EllipseMenuItem onClick={() => makeDefault(card.id)}>
                            <FontAwesomeIcon icon={faCheckCircle}/> Make Default
                        </EllipseMenuItem>
                    </EllipseButton>
                </Col>
            </Row>
        </div>
    )
}

export default BillingInfoItem;