import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import useGetUserInitials from "../../../../../../../../../hooks/useGetUserInitials";
import {Col, Row} from "react-bootstrap";
import classes from "../../EditUserProfile.module.css";

const UserProfileHeader = ({name, title, email}) => {

    const [initials, setName] = useGetUserInitials(name);

    useEffect(() => {
        setName(name)
    }, [name])

    return (
        <Row className={classes.UserProfileHeader}>
            <Col xs={12}>
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col xs={3}>
                                <div className={classes.UserAvatar}>
                                    {initials}
                                </div>
                            </Col>
                            <Col xs={9} style={{borderRight: 'solid lightgray 3px'}}>
                                <div className={classes.InfoWrapper}>
                                    <div className={classes.Name}>{name}</div>
                                    <div className={classes.Title}>{title}</div>
                                    <div className={classes.Email}>{email}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col xs={2}>
                                <div className={classes.RolesCountWrapper}>
                                    {/*<div className={classes.RolesCount}>2</div>*/}
                                    {/*<div className={classes.RolesTitle}>Roles</div>*/}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default React.memo(UserProfileHeader);

UserProfileHeader.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string
};

UserProfileHeader.defaultProps = {};