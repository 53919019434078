import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Overlay from "react-bootstrap/lib/Overlay";
import classes from './FilterDatePicker.module.css';
import Button from "react-bootstrap/lib/Button";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import Calendar from "react-calendar";
import  '../../../../../../../../../assets/css/react-calendar.module.css';
import DateRangeButton from "./components/DateRangeButton/DateRangeButton";
import IncludeCheckBoxes from "./components/IncludeCheckBoxes/IncludeCheckBoxes";

const FilterDatePicker = props => {
    const container = useRef(null);

    const handleClearButtonClicked = () => {
        props.calendarRangeChanged(null);
    };

    return (
        <div>
            <DateRangeButton calendarFilterToggle={props.calendarFilterToggle} calendarFilterDate={props.calendarFilterDate}/>
            <div className={classes.PopUpWrapper} ref={container}>
                <Overlay placment="bottom" show={props.calendarFilterOpen} container={container.current} animation={false}>
                    <PopupWrapper>
                        <IncludeCheckBoxes
                            calendarSurfaced={props.calendarSurfaced}
                            calendarSurfacedChanged={props.calendarSurfacedChanged}
                            calendarAccepted={props.calendarAccepted}
                            calendarAcceptedChanged={props.calendarAcceptedChanged}/>
                        <Calendar
                            className={classes.Calendar}
                            onChange={props.calendarRangeChanged}
                            value={props.calendarFilterDate}
                            selectRange
                            calendarType="US"
                        />
                        <Button block onClick={handleClearButtonClicked}>Clear</Button>
                    </PopupWrapper>
                </Overlay>
            </div>
        </div>
    );
};

export default React.memo(FilterDatePicker);

FilterDatePicker.propTypes = {
    calendarFilterOpen: PropTypes.bool.isRequired,
    calendarFilterToggle: PropTypes.func.isRequired,
    calendarRangeChanged: PropTypes.func.isRequired
};

FilterDatePicker.defaultProps = {};