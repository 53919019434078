import React from 'react';
import classes from './TalentReviewedBreakDown.module.css';
import PropTypes from 'prop-types';

const TalentReviewedBreakDown = props => {
    return (
        <div className={classes.TalentReviewedBreakDown}>
            <div className={classes.Icon} style={{color: props.iconColor}}>{props.icon}</div>
            <div className={classes.Title}>{props.title}</div>
            <div className={classes.ItemsWrapper}>

                <div className={classes.Item}>
                    <div className={classes.Value} style={{color: '#55aa26'}}>{props.data.accepted}</div>
                    <div className={classes.ItemTitle}>Accepted</div>
                </div>


                <div className={classes.Item}>
                    <div className={classes.Value} style={{color: '#ff0000'}}>{props.data.rejected}</div>
                    <div className={classes.ItemTitle}>Rejected</div>
                </div>


                <div className={classes.Item}>
                    <div className={classes.Value} style={{color: '#838485'}}>{props.data.previouslyContacted}</div>
                    <div className={classes.ItemTitle}>Skipped <br/><span className={classes.SkipType}>Previously contacted</span></div>
                </div>

                <div className={classes.Item}>
                    <div className={classes.Value} style={{color: '#838485'}}>{props.data.profileError}</div>
                    <div className={classes.ItemTitle}>Skipped <br/><span className={classes.SkipType}>Profile error</span></div>
                </div>

            </div>
        </div>
    )
};

export default TalentReviewedBreakDown;

TalentReviewedBreakDown.propTypes = {
    iconColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
};

TalentReviewedBreakDown.defaultProps = {
    iconColor: '#000000'
};
