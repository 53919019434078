import * as actionTypes from  '../actions/actionsTypes';

const initialState = {
    adminsReady: false,
    outcomesReady: false,
    admins: [],
    outcomes: []
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBAL_LISTS_GET_ADMINS_SUCCESS:
            return {
                ...state,
                adminsReady: true,
                admins: action.payload
            }
        case actionTypes.GLOBAL_LISTS_GET_OUTCOMES_SUCCESS:
            return {
                ...state,
                outcomesReady: true,
                outcomes: action.payload
            }
        default:
            return state;
    }
}

export default reducer; 