import React, { useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import useOutsideAreaClick from "../../../../../hooks/useOutsideAreaClick";
import MetricsRow from './Components/MetricsRow';
import classes from './MetricsRolesDropdown.module.css';


const reducer = (state, action) => {
  switch (action.type) {
    case 'setRowsToShow':
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const MetricsRolesDropdown = ({ show, rowSelected, needle = '', closeDropdown, dataToshow, filter }) => {
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    searchValue: '',
  });

  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside && show) {
      closeDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutside, show]);

  useEffect(() => {
    const dataFilted =
      filter === 'active-roles'
        ? dataToshow.filter((x) => !x.completed)
        : filter === 'completed-roles'
        ? dataToshow.filter((x) => x.completed)
        : dataToshow;
    if (dataToshow.length > 0) {
      dispatch({ type: 'setRowsToShow', payload: dataFilted });
    }
    // eslint-disable-next-line
  }, [dataToshow, filter]);

  const rowsToShow =
    state.data.length > 0 &&
    state.data.filter(
      (x) =>
        x.name.includes(needle) ||
        x.name.toLowerCase().includes(needle.toLowerCase()) ||
        x.name.toUpperCase().includes(needle.toUpperCase()) ||
        (x.greenhouse_job 
          ? x.greenhouse_job.requisition_id?.includes(needle)
          : x.lever_job
          ? x.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').includes(needle)
          : x.sap_job && x.sap_job?.requisition_id.includes(needle))
    );

  return show && state.data.length ? (
    <div className={classes.DropDownWrapper} ref={wrapperRef}>
      <div className={classes.AddedRowsDropDown}>
        {filter !== 'all' && (
          <div className={classes.ActiveRole}>
            displaying
            {filter === 'active-roles' ? ' active roles' : filter === 'completed-roles' && ' completed roles'}
          </div>
        )}
        {rowsToShow.map((x) => (
          <MetricsRow row={x} key={x.id} rowSelected={rowSelected} />
        ))}
        {rowsToShow.length === 0 && <div className={classes.NotFound}>No matches found.</div>}
      </div>
    </div>
  ) : null;
};

export default MetricsRolesDropdown;

MetricsRolesDropdown.propTypes = {
  show: PropTypes.bool.isRequired,
  rowSelected: PropTypes.func.isRequired,
};
