import React from 'react';
import {Button} from 'react-bootstrap';
import classes from './AddUserButtonSection.module.css';

const AddUserButtonSection = ({addClicked, isActive}) => {

    if(isActive){
        return null;
    }

    return (
        <Button style={{padding: '0 0'}} disabled={isActive} onClick={addClicked} className={classes.Button} bsStyle="link">
            Add
        </Button>
    )
};

export default AddUserButtonSection;