import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import classes from './FilterItem.module.css';

const FilterItem = props => {

    const filterClassName = props.isActive ? [classes.FilterItem, classes.FilterActive].join(' ') : classes.FilterItem;

    const handleClick = (e) => {
        e.stopPropagation();
        props.filterChanged(props.type);
    }

    return (
        <div className={filterClassName} onClick={handleClick}>
            <span className={classes.Title}>{props.type}</span>
            <span>
                <FontAwesomeIcon style={{color: props.color}} icon={faCircle} />
            </span>
        </div>
    )
};

export default React.memo(FilterItem);

FilterItem.propTypes = {
    type: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    filterChanged: PropTypes.func.isRequired
};

FilterItem.defaultProps = {};