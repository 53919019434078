import React from 'react';
import PropTypes from 'prop-types';
import {GiBananaBunch} from 'react-icons/gi';
import {HiOutlineDownload} from  'react-icons/hi';
import {FiArchive} from 'react-icons/fi';
import classes from './HeaderOptionsItem.module.css';

const HeaderOptionsItem = props => {

    let icon;
    switch (props.icon) {
        case 'download':
            icon = <HiOutlineDownload className={classes.Icon}/>
            break;
        case 'archive':
            icon = <FiArchive className={classes.Icon}/>;
            break;
        default:
            icon = <GiBananaBunch className={classes.Icon}/>;
    }

    return (
        <div className={classes.HeaderOptionsItem} onClick={props.onClick}>
            {props.icon ? icon : null}{props.children}
        </div>
    )
};

export default HeaderOptionsItem;

HeaderOptionsItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.oneOf(['archive', 'download'])
};

HeaderOptionsItem.defaultProps = {};