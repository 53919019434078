import React, {Component} from 'react';
import {Row, Col, Panel} from "react-bootstrap";
import classes from './ReviewTalentHeader.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenSquare} from "@fortawesome/free-solid-svg-icons";

class ReviewTalentHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleAddOrRemovedClicked = () => {
        if(this.props.addedToAddTalentForm){
            this.props.handleReviewedTalentRemovedFromAddList(this.props.talent.id)
        } else {
            this.props.handleReviewedTalentAddedToAddList(this.props.talent.id)
        }
    };

    formatCompanyName = (company) => {
        let toBeExcluded= " · Full-time"
        if (company && company.includes(toBeExcluded)) {
            return company.replace(toBeExcluded, "");
        } else {
            return company;
        }
    }

    render() {
        const addOrRemoveTalentStyle = this.props.addedToAddTalentForm ? classes.RemoveIndicator : classes.AddIndicator;
        const addOrRemoveIcon = this.props.addedToAddTalentForm ? '-' : '+';
        const counterStyle = this.props.mode === 'prospect_approved' ? classes.CountIndicator : classes.CountIndicatorWaiting;
        return (
            <Row className={classes.Row}>
                <Col xs={9}>
                    <div className={classes.NameAreaWrapper}>
                        <div className={counterStyle}>
                            {this.props.count}
                        </div>
                        <div className={classes.TalentName}>
                            <Panel.Toggle>
                                <FontAwesomeIcon className={classes.PenIcon} icon={faPenSquare} color="#BFBFBF"/>
                            </Panel.Toggle>
                            <a href={this.props.talent.link} target="_blank">{this.props.talent.name}</a>
                            { this.props.talent.pivot.is_recommended ? <span className={classes.RecommendedTalentBadge}>AI</span> : null }
                        </div>
                    </div>
                </Col>
                <Col xs={2}>
                    <div className={classes.TalentCompany}>
                        {this.formatCompanyName(this.props.talent.job_titles.length > 0 &&
                        this.props.talent.job_titles[0].pivot ? this.props.talent.job_titles[0].company_name : null)}
                    </div>
                </Col>
                <Col xs={1}>
                    <div className={addOrRemoveTalentStyle} onClick={this.handleAddOrRemovedClicked}>
                        {addOrRemoveIcon}
                    </div>
                </Col>
            </Row>
        )
    }
};

export default ReviewTalentHeader;
