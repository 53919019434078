import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import RoleTypeSelector from "../RoleTypeSelector/RoleTypeSelector";
import RoleFilterSearchRow from "../RoleFilterSearchRow/RoleFilterSearchRow";
import TurboPlus2Loader from "../TurboPlus2Loader/TurboPlus2Loader";
import {useDispatch, useSelector} from "react-redux";
import RoleRow from "../RoleRow/RoleRow";
import {getRoleList, roleSearchPageChanged} from "../../../store/actions";
import useDebounce from "../../../hooks/useDebounce";
import usePagination from "../../../hooks/usePagination";
import RolesHeader from "../RolesHeader/RolesHeader";
import GreyScrollBoxArea from "../GreyScrollBoxArea/GreyScrollBoxArea";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";

const RoleSection = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useState(null);
    const rolesLoading = useSelector(state => state.roles.loading);
    const roles = useSelector(state => state.roles.data);
    const roleRowOpen = useSelector(state => state.rolesSearchSettings.roleRowOpen);
    const roleFilterType = useSelector(state => state.rolesSearchSettings.roleFilterType);
    const showArchived = useSelector(state => state.rolesSearchSettings.showArchived);
    const page = useSelector(state => state.rolesSearchSettings.page);
    const lastPage = useSelector(state => state.rolesSearchSettings.lastPage);
    const perPage = useSelector(state => state.rolesSearchSettings.perPage);
    const searchModeType = useSelector(state => state.rolesSearchSettings.searchModeType);
    const searchText = useSelector(state => state.rolesSearchSettings.searchText);
    const searchAdminId = useSelector(state => state.rolesSearchSettings.searchAdminId);
    const companyId = useSelector(state => state.rolesSearchSettings.selectedCompanyId);
    const refreshRolesToggle = useSelector(state => state.rolesSearchSettings.refreshRolesToggle);
    const sortType = useSelector(state => state.rolesSearchSettings.sortType);
    const sortOrder = useSelector(state => state.rolesSearchSettings.sortOrder);
    const sourcingPriority = useSelector(state => state.filters.sourcingPriority);
    const lowerPriority = useSelector(state => state.filters.lowerPriority);
    const onHold = useSelector(state => state.filters.onHold);
    const talentWaiting = useSelector(state => state.filters.talentWaiting);
    const isTurnkey = useSelector(state => state.filters.isTurnkey);
    const isTrial = useSelector(state => state.filters.isTrial);
    const sourcingOnly = useSelector(state => state.filters.sourcingOnly);
    const filterPopUpCount = useSelector(state => state.filters.filterPopUpCount);
    const isReviewed = useSelector(state => state.filters.isReviewed);

    const searchParamsDebounce = useDebounce(params, 1000);

    useEffect(() => {
        const params = {
            'role-filter-type': roleFilterType,
            page,
            perPage: perPage,
            admins: 1,
            'search-type': searchModeType,
            'sort-by-options-created': 1,
            'sort-type': sortType,
            'sort-order': sortOrder
        }

        if(searchText){
            params['search-text'] = searchText;
        }

        if(showArchived){
            params['show-archived'] = 1;
        }

        if(searchAdminId !== '')
            params['search-admin-id'] = searchAdminId;

        if(companyId)
            params['company-id'] = companyId;

        if(onHold)
            params['on-hold'] = 1;

        if(sourcingPriority)
            params['sourcing-priority'] = 1;

        if(lowerPriority)
            params['lower-priority'] = 1;

        if(talentWaiting)
            params['talent-waiting'] = 1;

        if(isTurnkey)
            params['is-turnkey'] = 1;

        if(isTrial)
            params['is-trial'] = 1;

        if(sourcingOnly)
            params['sourcing-only'] = 1;

        if(isReviewed)
            params['is-reviewed'] = 1;

        setParams(params);
    }, [
        roleFilterType,
        page,
        perPage,
        searchModeType,
        searchText,
        showArchived,
        searchAdminId,
        companyId,
        onHold,
        sourcingPriority,
        lowerPriority,
        talentWaiting,
        isReviewed,
        isTurnkey,
        isTrial,
        sourcingOnly,
        refreshRolesToggle,
        sortType,
        sortOrder
    ])

    useEffect(() => {
        if(params){
            dispatch(getRoleList(companyId, searchParamsDebounce));
        }
    }, [searchParamsDebounce]);

    const changePage = (page) => {
        dispatch(roleSearchPageChanged(page));
    };

    const [pagination] = usePagination(page, lastPage, changePage);

    const pageArea = roles.length && lastPage > 1 ? (
        <Row>
            <Col xs={12} style={{textAlign: "center"}}>
                {pagination}
            </Col>
        </Row>
    ) : null;

    const rows = roles.map(x =>
        <RoleRow
            key={x.id}
            roleId={x.id}
            isOpen={roleRowOpen === x.id}
            role={x}
            talentAdmins={x.talentAdmins}
            roleType={roleFilterType}
        />
    )

    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <Col xs={12}>
                        <RolesHeader />
                        <RoleTypeSelector/>
                        <RoleFilterSearchRow/>
                    </Col>
                </Col>
            </Row>
            <GreyScrollBoxArea>
                {rolesLoading && <TurboPlus2Loader/>}
                {rows}
                {pageArea}
                {
                    !rolesLoading && filterPopUpCount > 0 && rows.length === 0 &&
                    <NoResultsMessage message="No results were found" icon="dizzy"/>
                }
            </GreyScrollBoxArea>
        </Fragment>
    );
}

export default RoleSection;