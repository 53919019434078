import moment from "moment";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const buildTalentData= (talent, preferences, outcomes) => {
    const header = buildCSVHeader(talent, preferences);
    const talentData = buildCsvTalent(talent, preferences, outcomes);
    return header.concat(talentData);
};

const returnSeparatedNameArray = (name) => {
    // Need to return an array with ONLY two items. Even if there is no last name.
    const nameArray = name.split(" ");
    const firstName = nameArray.shift();
    const lastName = nameArray.join("");
    return [firstName, lastName];
};

const beautifyDate = (date) => {
    return moment.utc(date).format("MMMM Do YYYY - h:mm:ss a");
};

const buildCSVHeader = (talent, preferences) => {
    const {
        includeApprovedSkippedRejectionDate,
        includeContactInfo,
        includeDNI,
        includeDateSurfaced,
        includeGender,
        includeInterviewDate,
        includeLinkedinProfile,
        includeOutcomes,
        includeStatus,
        separateNames,
    } = preferences;

    let headerArray = [];

    const nameColumns = separateNames ? ["First Name", "Last Name"] :["Name"];
    headerArray = headerArray.concat(nameColumns);

    if(includeLinkedinProfile){
        headerArray = headerArray.concat(["Profile"]);
    }

    if(includeContactInfo){
        headerArray = headerArray.concat(["Email", "Phone"]);
    }

    if(includeGender){
        headerArray = headerArray.concat(["Gender"]);
    }

    if(includeDNI){
        headerArray = headerArray.concat(["Diverse"]);
    }

    if(includeStatus){
        headerArray = headerArray.concat(["Status"]);
    }

    if(includeApprovedSkippedRejectionDate){
        headerArray = headerArray.concat([`Approved/Skipped/Rejection Date`]);
    }

    if(includeOutcomes){
        headerArray = headerArray.concat(["Outcome"]);
    }

    if(includeInterviewDate){
        headerArray = headerArray.concat(["Interview Date"]);
    }

    if(includeDateSurfaced){
        headerArray = headerArray.concat(["Date Surfaced"]);
    }

    return [
        headerArray
    ];

    // return [[
    //     "Accepted",
    //     "Accepted On",
    //     "Scheduled",
    //     "Date Created",
    //     "Skipped Reason",
    //     "Date Skipped"
    // ]];
};

const buildCsvTalent = (talent, preferences, outcomes) => {

    /**
     Rejected
     Outcomes (no numbers but actual outcomes)
     Interview Date
     */
    return talent.map(x => {
        const {
            beautifyDates,
            includeApprovedSkippedRejectionDate,
            includeContactInfo,
            includeDNI,
            includeDateSurfaced,
            includeGender,
            includeInterviewDate,
            includeLinkedinProfile,
            includeOutcomes,
            includeStatus,
            separateNames,
        } = preferences;

        let talentArray = [];

        const name = separateNames ? returnSeparatedNameArray(x.name) : [x.name];
        talentArray = talentArray.concat(name);

        if(includeLinkedinProfile){
            talentArray = talentArray.concat([x.link]);
        }

        if(includeContactInfo){
            talentArray = talentArray.concat([x.email, x.mobile_number]);
        }

        if(includeGender){
            const gender = x.gender ? capitalizeFirstLetter(x.gender) : null;
            talentArray = talentArray.concat([gender]);
        }

        if(includeDNI){
            const isDiverse = x.is_diverse ? "True" : "False";
            talentArray = talentArray.concat([isDiverse]);
        }

        if(includeStatus){
            let status;
            switch (true){
                case x.pivot.accepted === true:
                    status = "Accepted";
                    break;
                case x.pivot.accepted === false:
                    status = "Rejected";
                    break;
                case x.pivot.skipped_reason !== null:
                    status = "Skipped";
                    break;
                default:
                    status = "Waiting";
            }

            talentArray = talentArray.concat([status]);
        }

        if(includeApprovedSkippedRejectionDate){
            let statusDate;
            switch (true){
                case x.pivot.accepted === true:
                    statusDate = x.pivot.accepted_on;
                    break;
                case x.pivot.accepted === false:
                    statusDate = x.pivot.accepted_on;
                    break;
                case x.pivot.skipped_reason !== null:
                    statusDate = x.pivot.skipped_at;
                    break;
                default:
                    statusDate = null;
            }

            const date = beautifyDates && statusDate ? beautifyDate(statusDate) : statusDate;

            talentArray = talentArray.concat([date]);
        }

        if(includeOutcomes){
            const outcome = x.pivot.outcome_id ? outcomes.find(n => n.id === x.pivot.outcome_id).name : null;
            talentArray = talentArray.concat([outcome]);
        }

        if(includeInterviewDate){
            const interviewDate = x.interviews && x.interviews.length > 0 ? x.interviews[0].interview_at : null;
            const date = beautifyDates && interviewDate ? beautifyDate(interviewDate) : interviewDate;
            talentArray = talentArray.concat([date]);
        }

        if(includeDateSurfaced){
            const scheduled_at = x.pivot.scheduled_at ? x.pivot.scheduled_at : x.pivot.surfaced_at;
            const date = beautifyDates ? beautifyDate(scheduled_at) : scheduled_at;
            talentArray = talentArray.concat([date]);
        }

        return talentArray;
        /**
         accepted: true
         accepted_on: "2020-12-04 00:27:15"
         admin: null
         admin_id: null
         alt_number: null
         created_at: "2020-12-04 00:26:50"
         delete_outcome: "waiting"
         email: null
         follow_ups: Array []
         gender: null
         id: 434
         interested_on: null
         interviews: Array []
         interviews_count: 0
         is_diverse: null
         is_reviewed: 0
         link: "https://sdsssssds.com"
         mobile_number: "1234567086"
         name: "te4sfdsdsd"
         notes: null
         notified: false
         outcome: null
         outcome_id: null
         role_id: 44
         scheduled_at: null
         skipped_at: null
         skipped_reason: null
         status: null
         updated_at: "2020-12-08 21:00:28"
         */
    });
};

const useExportCSV = (roleId, talent, preferences, outcomes) => {
    const rows = buildTalentData(talent, preferences, outcomes);
    
    let csvContent = rows.map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, `role ${roleId} talent export.csv`);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `role ${roleId} talent export.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export default useExportCSV;


