import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import classes from './FilterTopSection.module.css';

const FilterTopSection = props => {
    return (
        <Row>
            <Col xs={6}>
                <div className={classes.Title}>
                    {props.title}
                </div>
            </Col>
            <Col xs={6} style={{textAlign: 'right'}}>
                <span className={classes.ClearAllButton} onClick={props.onClearAllClicked}>Clear All</span>
            </Col>
        </Row>
    )
};

export default FilterTopSection;

FilterTopSection.propTypes = {
    onClearAllClicked: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

FilterTopSection.defaultProps = {};