import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faStarSolid} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const TalentReviewedStar = props => {
    const [reviewedState, setReviewedState] = useState(props.reviewed);

    useEffect(() => {
        setReviewedState(props.reviewed);
    },[props.reviewed]);

    const handleOnClick = (e) => {
        e.stopPropagation();

        let newStatus;
        switch (reviewedState) {
            case 0:
                newStatus = 1;
                break;
            case 1:
                newStatus = 2;
                break;
            default:
                newStatus = 0;
        }
        setReviewedState(newStatus);
        props.handleFlagClicked(newStatus);
    };

    let color, icon;

    switch (reviewedState) {
        case 1:
            color = 'orange';
            icon = faStarSolid;
            break;
        case 2:
            color = '#46BAF9';
            icon = faStarSolid;
            break;
        default:
            color = 'grey';
            icon = faStar;
    }

    const tooltip = (
        <Tooltip id="tooltip">
            Reviewed status
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="top" overlay={tooltip}>
           <span style={{marginRight: "5px"}} onClick={handleOnClick}>
                <FontAwesomeIcon style={{color, fontSize: "15px"}} icon={icon}/>
            </span>
        </OverlayTrigger>
    );
};

export default TalentReviewedStar;